<template src="./CriteriaModal.html" />
<script>
import GlossaryTooltip from '../tooltip/GlossaryTooltip.vue';
import PremiumTooltip from '../tooltip/PremiumTooltip.vue';
/**
 * 銘柄スクリーニング 検索条件追加モーダルコンポーネント
 * @module StockSearch/modules/modal/CriteriaModal
 */

export default {
  name: 'CriteriaModal',
  components: {
    'glossary-tooltip': GlossaryTooltip,
    'premium-tooltip': PremiumTooltip,
  },
  data() {
    return {
      page: 'basic',
      list: {
        default: [
          {
            heading: 'デフォルト表示項目',
            items: [
              {
                id: 'minimum_purchase_price',
                name: '最低投資金額',
                premium: false,
                checked: true,
              },
              {
                id: 'market_capitalization',
                name: '時価総額',
                premium: false,
                checked: true,
              },
              {
                id: 'minkabu_ratings',
                name: '目標株価',
                premium: true,
                checked: true,
              },
            ],
          },
        ],
        basic: [
          {
            heading: '株価指標',
            items: [
              {
                id: 'per',
                name: '調整後PER',
                premium: false,
                checked: false,
              },
              {
                id: 'pbr',
                name: 'PBR',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '配当',
            items: [
              {
                id: 'dividend_yield',
                name: '配当利回り',
                premium: false,
                checked: false,
              },
              {
                id: 'consecutive_increased_dividend_count',
                name: '連続増配回数',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: '企業情報',
            items: [
              {
                id: 'capital_adequacy_ratio',
                name: '自己資本比率',
                premium: false,
                checked: false,
              },
              {
                id: 'listing_date',
                name: '上場年',
                premium: false,
                checked: false,
              },
              {
                id: 'industry_sectors',
                name: '業種',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '決算',
            items: [
              {
                id: 'sales_cagr_3y',
                name: '3年平均売上高成長率',
                premium: false,
                checked: false,
              },
              {
                id: 'operating_income_margin',
                name: '売上高営業利益率',
                premium: false,
                checked: false,
              },
              {
                id: 'settlement_evaluations',
                name: '業績評価',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'マーケット情報',
            items: [
              {
                id: 'exchange_sections',
                name: '市場',
                premium: false,
                checked: false,
              },
              {
                id: 'vroc',
                name: '出来高変化率',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: 'みんかぶ予想',
            items: [
              {
                id: 'analyst_ratings',
                name: 'アナリスト予想',
                premium: true,
                checked: false,
              },
              {
                id: 'research_ratings',
                name: 'AI株価診断',
                premium: true,
                checked: false,
              },
              {
                id: 'user_ratings',
                name: 'みんかぶ会員予想',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: '株価騰落率',
            items: [
              {
                id: 'share_price_cr_1m',
                name: '前月比',
                premium: false,
                checked: false,
              },
              {
                id: 'share_price_cr_1y',
                name: '前年比',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '株価とみんかぶ予想の比較',
            items: [
              {
                id: 'minkabu_tp_sp_dr',
                name: '目標株価 乖離率',
                premium: true,
                checked: false,
              },
              {
                id: 'analyst_tp_sp_dr',
                name: 'アナリスト予想 乖離率',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'みんかぶ目標株価の変化率',
            items: [
              {
                id: 'minkabu_tp_cr_1w',
                name: '前週比',
                premium: true,
                checked: false,
              },
              {
                id: 'minkabu_tp_cr_1m',
                name: '前月比',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: 'アナリスト予想株価の変化率',
            items: [
              {
                id: 'analyst_tp_cr_1w',
                name: '前週比',
                premium: true,
                checked: false,
              },
              {
                id: 'analyst_tp_cr_1m',
                name: '前月比',
                premium: false,
                checked: false,
              },
            ],
          },
        ],
        advance: [
          {
            heading: '財務指標',
            items: [
              {
                id: 'roe',
                name: 'ROE',
                premium: false,
                checked: false,
              },
              {
                id: 'roa',
                name: 'ROA',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '株価指標',
            items: [
              {
                id: 'psr',
                name: 'PSR',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '配当',
            items: [
              {
                id: 'payout_ratio',
                name: '配当性向',
                premium: false,
                checked: false,
              },
              {
                id: 'dps',
                name: '1株あたり配当',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '決算',
            items: [
              {
                id: 'eps',
                name: '1株あたり利益',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '優待',
            items: [
              {
                id: 'yutai_exist',
                name: '優待有無',
                premium: false,
                checked: false,
              },
              {
                id: 'yutai_yield',
                name: '優待利回り',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '株価騰落率',
            items: [
              {
                id: 'share_price_cr_1d',
                name: '前日比',
                premium: false,
                checked: false,
              },
              {
                id: 'share_price_cr_1w',
                name: '前週比',
                premium: false,
                checked: false,
              },
            ],
          },
          {
            heading: '株価とみんかぶ予想の比較',
            items: [
              {
                id: 'user_expectation_tp_sp_dr',
                name: 'みんかぶ会員予想 乖離率',
                premium: true,
                checked: false,
              },
              {
                id: 'research_tp_sp_dr',
                name: 'AI株価診断 乖離率',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'みんかぶ目標株価の変化率',
            items: [
              {
                id: 'minkabu_tp_cr_1d',
                name: '前日比',
                premium: true,
                checked: false,
              },
              {
                id: 'minkabu_tp_cr_1y',
                name: '前年比',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'アナリスト予想株価の変化率',
            items: [
              {
                id: 'analyst_tp_cr_1d',
                name: '前日比',
                premium: true,
                checked: false,
              },
              {
                id: 'analyst_tp_cr_1y',
                name: '前年比',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'AI株価診断の変化率',
            items: [
              {
                id: 'research_tp_cr_1d',
                name: '前日比',
                premium: true,
                checked: false,
              },
              {
                id: 'research_tp_cr_1w',
                name: '前週比',
                premium: true,
                checked: false,
              },
              {
                id: 'research_tp_cr_1m',
                name: '前月比',
                premium: false,
                checked: false,
              },
              {
                id: 'research_tp_cr_1y',
                name: '前年比',
                premium: true,
                checked: false,
              },
            ],
          },
          {
            heading: 'みんかぶ会員予想の変化率',
            items: [
              {
                id: 'user_expectation_tp_cr_1d',
                name: '前日比',
                premium: true,
                checked: false,
              },
              {
                id: 'user_expectation_tp_cr_1w',
                name: '前週比',
                premium: true,
                checked: false,
              },
              {
                id: 'user_expectation_tp_cr_1m',
                name: '前月比',
                premium: false,
                checked: false,
              },
              {
                id: 'user_expectation_tp_cr_1y',
                name: '前年比',
                premium: true,
                checked: false,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    showCriteriaModal() {
      return this.$store.getters['stockSearchInput/showCriteriaModal'];
    },
    isShowItem() {
      return (id) => {
        const flg = this.$store.getters['stockSearchInput/isCriteriaAvailable'][this.toUpperCase(id)];
        return flg === undefined ? true : flg;
      };
    },
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
  },
  watch: {
    showCriteriaModal(val) {
      if (val) this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 検索条件の初期化
     */
    init() {
      const defaultList = this.list.default.map((el) => el.items.map((el) => el.id))[0];
      const criteria = Array.from(new Set([...defaultList, ...this.$store.getters['stockSearchInput/criteria']]));

      for (const key in this.list) {
        for (const group of this.list[key]) {
          for (const item of group.items) {
            item.checked = criteria.includes(item.id);
          }
        }
      }
      this.setCriteria();
    },
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      const body = document.querySelector('body');
      const offsetY = -parseFloat(getComputedStyle(body).top);
      body.classList.remove('ui-drawer-scroll-stop');
      body.style.top = null;
      window.scrollTo(0, offsetY);
      this.$store.commit('stockSearchInput/setShowCriteriaModal', false);
      this.$refs.scrollWrapper.scrollTop = 0;
    },
    /**
     * タブ切り替え
     * @param {String} page
     */
    changePage(page) {
      // iOSで完成スクロール中に表示を切り替えるとスクロール位置がずれるため、
      // overflow を hidden にして要素のスクロールを不可にし、慣性スクロールを止める
      this.$refs.scrollWrapper.style.overflow = 'hidden';
      this.page = page;

      this.$nextTick(() => {
        // レンダリング後にページトップにスクロールし、要素をスクロール可能に戻す
        this.$refs.scrollWrapper.scrollTop = 0;
        this.$refs.scrollWrapper.style.overflow = 'auto';
      });
    },
    /**
     * 検索条件を Store にセットする
     */
    setCriteria() {
      const criteria = Object.keys(this.list)
        .map((item) => {
          const items = this.list[item]
            .map((el) => el.items.filter((i) => i.checked))
            .filter((el) => el.length > 0)
            .flat(1);
          return items.map((i) => i.id);
        })
        .flat(1);
      this.$store.commit('stockSearchInput/setCriteriaAll', criteria);
    },
    /**
     * 検索条件を確定させてモーダルを閉じる
     */
    confirmCriteria() {
      this.setCriteria();
      this.hide();
    },
    /**
     * スネークからキャメルに変換
     * @param {String} c
     */
    toUpperCase(c) {
      if (c === 'sales_cagr_3y') {
        return 'salesCAGR3y';
      }
      return c.replace(/_([0-9a-z])/g, (_, v) => v.toUpperCase());
    },
  },
};
</script>
