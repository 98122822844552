import FeeRankingSecurity from '../../models/FeeRankingSecurity';
import SecurityMatrix from './SecurityMatrix.vue';
import LowestSecurity from './LowestSecurity.vue';
import LowestPurchasePrice from './LowestPurchasePrice.vue';
import LowestPurchasePriceYutai from './LowestPurchasePriceYutai.vue';

/**
 * 手数料比較用ルートコンポーネント
 *
 * @property {securitiesMatrix} securitiesMatrix [component] 証券会社手数料比較テーブル
 * @property {lowestSecurity} lowestSecurity [component] 手数料最安の証券会社の紹介
 * @property {lowestPurchasePrice} lowestPurchasePrice [component] 最安購入金額
 * @property {lowestPurchasePriceYutai} lowestPurchasePriceYutai [component] 最安購入金額(優待用)
 * @property {Array<number>} unitList [data] 購入株数一覧
 * @property {Object} financialItem [data] ページで表示されている金融アイテム
 * @property {Array<FeeRankingSecurity>} securityRankings [data] 証券会社手数料比較ランキング
 * @property {FeeRankingSecurity} topSecurity [data] 手数料最安の証券会社
 * @property {number} purchasePrice [data] 購入金額
 * @property {number} selectedUnit [data] 選択された購入株数
 * @module Fee
 */
const Fee = {
  el: '#fee_ranking',
  components: {
    securityMatrix: SecurityMatrix,
    lowestSecurity: LowestSecurity,
    lowestPurchasePrice: LowestPurchasePrice,
    lowestPurchasePriceYutai: LowestPurchasePriceYutai,
  },
  data: {
    unitList: [],
    financialItem: {},
    securityRankings: [],
    selectedUnit: null,
  },
  computed: {
    topSecurity() {
      return this.securityRankings[0] || new FeeRankingSecurity({}, {}, 0);
    },
    unitedPrice() {
      return this.financialItem.price * this.selectedUnit;
    },
    isRanking() {
      return !!this.securityRankings;
    },
    mkComparisonFee() {
      return window.MK.Comparison.Fee;
    },
    purchasePrice() {
      return this.topSecurity.purchasePrice;
    },
  },
  watch: {
    selectedUnit(unitValue) {
      this.updateSecurities(unitValue);
    },
  },
  /**
   * 初期処理
   * window.MK.Comparison.Feeから
   * 金融アイテム情報、購入株数リスト、初期購入株数を取得して
   * dataにセットする。
   */
  created() {
    this.financialItem = this.mkComparisonFee.getStock();
    this.unitList = this.mkComparisonFee.Ranking.getUnitEntries(this.financialItem.price, this.financialItem.unit);
    this.selectedUnit = this.setDefaultUnit();
  },
  methods: {
    /**
     * 指定された株数に応じた手数料比較ランキングを更新する
     *
     * @param {number} unitValue 購入株数
     */
    updateSecurities(unitValue) {
      this.mkComparisonFee.Ranking.get(this.unitedPrice, this.financialItem.stock_exchange_id, (rankings) => {
        this.securityRankings = rankings.map((ranking) => {
          return new FeeRankingSecurity(ranking, this.financialItem, unitValue);
        });
      });
    },
    /**
     * 購入株数のリストボックスに初期値を設定する
     * 個別銘柄ページ優待タブ: 優待発生株数
     * それ以外: 単元株数
     */
    setDefaultUnit() {
      if (document.querySelector('#yutai_fee_calculate')) {
        return document.querySelector('#yutai_fee_calculate').dataset.defaultUnit;
      } else {
        return this.financialItem.unit;
      }
    },
  },
};

export default Fee;
