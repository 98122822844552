<template src="./FavoriteFinancialItemsTable.html" />
<script>
import FavoriteButtonHeart from '../Common/FavoriteButtonHeart.vue';
import AccordionWrapper from './modules/accordion/AccordionWrapper.vue';
import DrawerWrapper from './modules/drawer/DrawerWrapper.vue';
import { generateUrlParams, pushUrlParams, replaceUrlParams } from './utils/urlParams.js';
import { loadingPage } from './utils/loadingPage.js';
import { analysisRating, changeRatio } from './utils/htmlClassName.js';
import { iconUrl } from './utils/iconUrl.js';

/**
 * お気に入り銘柄テーブルコンポーネント
 * @vue-components {FavoriteButtonHeart} FavoriteButtonHeart お気に入りボタン
 * @vue-components {AccordionWrapper} AccordionWrapper アコーディオン枠
 * @vue-components {DrawerWrapper} DrawerWrapper ドロワー枠
 * @module Mypage/FavoriteFinancialItemsTable
 */
export default {
  name: 'FavoriteFinancialItemsTable',
  components: {
    FavoriteButtonHeart,
    AccordionWrapper,
    DrawerWrapper,
  },
  data() {
    return {
      drawerItem: {},
    };
  },
  computed: {
    /**
     * 現在ページ
     * @return {boolean}
     */
    pageCurrent() {
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current;
    },
    sortSpSelected: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/sort'].spSelected;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSortSpSelected', value);
      },
    },
    /**
     * 業績アイコンのURLを返す
     * @return {string}
     */
    iconUrl() {
      return (icon) => iconUrl(this.$store.getters['favoriteFinancialItemDetails/iconUrlBase'], icon);
    },
    search() {
      return this.$store.getters['favoriteFinancialItemDetails/search'];
    },
    sort() {
      return this.$store.getters['favoriteFinancialItemDetails/sort'];
    },
    accordion() {
      return this.$store.getters['favoriteFinancialItemDetails/accordion'];
    },
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    /**
     * 売買予想のHTMLクラス名を返す
     * @param {number} status
     * @return {string}
     */
    analysisRatingClass(status) {
      return analysisRating(status);
    },
    /**
     * 前日比のHTMLクラス名を返す
     * @param {string} ratio
     * @return {string}
     */
    changeRatioClass(ratio) {
      return changeRatio(parseFloat(ratio));
    },
    /**
     * PC版ソートボタンのHTMLクラス名を返す
     * @param {string} key
     * @return {string}
     */
    sortButtonClass(key) {
      const sort = this.$store.getters['favoriteFinancialItemDetails/sort'];
      return {
        'text-blue-500': sort.key === key,
        'border-blue-500': sort.key === key,
        'rotate-180': sort.key === key && sort.asc,
      };
    },
    /**
     * アコーディオン開閉時にGAへ送信するイベント名を返す
     * @param {string} 銘柄コード
     * @return {string}
     */
    accordionEventName(code) {
      if (this.isShowAccordion(code)) {
        return '';
      } else {
        return 'mypage_favorites_accordion';
      }
    },
    /**
     * ソート順
     * @param {string} key
     */
    async sortOrder(key) {
      await loadingPage({
        vm: this,
        scroll: false,
        callback: async () => {
          await this.$store.dispatch('favoriteFinancialItemDetails/changeSortOrder', { key: key, isSp: this.isSp });
          await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/changePage', this.pageCurrent);
          pushUrlParams(generateUrlParams({ page: this.pageCurrent, search: this.search, sort: this.sort }));
        },
      });
    },
    /**
     * アコーディオン開閉
     * @param {string} code
     */
    async toggleAccordion(code) {
      await this.$store.dispatch('favoriteFinancialItemDetails/getDetail', code);
      this.$store.dispatch('favoriteFinancialItemDetails/toggleAccordion', code);
      replaceUrlParams(
        generateUrlParams({ page: this.pageCurrent, search: this.search, sort: this.sort, accordion: this.accordion })
      );
    },
    /**
     * アコーディオンが開いているか
     * @param {string} code
     */
    isShowAccordion(code) {
      return this.$store.getters['favoriteFinancialItemDetails/accordion'].includes(code);
    },

    /**
     * ドロワーを開く
     * @param {string} code
     */
    async showDrawer(item) {
      const body = document.querySelector('body');
      const offsetY = `-${window.pageYOffset}px`;

      this.drawerItem = item;
      await this.$store.dispatch('favoriteFinancialItemDetails/getDetail', item.code);
      await this.$store.dispatch('favoriteFinancialItemDetails/changeDisplayDrawer', true);

      // jQuery側のスクロールイベントが発火してしまうためグローバルヘッダを隠す
      const header = document.querySelector('#header_sp');
      if (header) {
        header.style.opacity = 0;
      }

      // Bodyのスクロール制御
      body.classList.add('ui-drawer-scroll-stop');
      body.style.top = offsetY;
    },
  },
};
</script>
