const HIDE_TOOLTIP_TIME = 200;

const StockAnalysisStore = {
  namespaced: true,
  state: {
    visiblePickTooltip: false,
    visibleNewsTooltip: false,
    visibleStockTooltip: false,
    timer: null,
    stockTimer: null,
    mouseCordinates: { x: 0, y: 0 },
  },
  getters: {
    /**
     * 予想ツールチップ表示中なら真
     * @return {boolean}
     */
    visiblePickTooltip(state) {
      return state.visiblePickTooltip;
    },
    /**
     * ニュースツールチップ表示中なら真
     * @return {boolean}
     */
    visibleNewsTooltip(state) {
      return state.visibleNewsTooltip;
    },
    /**
     * 株価ツールチップ表示中なら真
     * @return {boolean}
     */
    visibleStockTooltip(state) {
      return state.visibleStockTooltip;
    },
    /**
     * マウスの座標
     * @returns {Object}
     */
    mouseCordinates(state) {
      return state.mouseCordinates;
    },
  },
  mutations: {
    /**
     * 予想ツールチップ表示
     */
    showPickTooltip(state) {
      clearTimeout(state.timer);
      state.visibleNewsTooltip = false;
      state.visibleStockTooltip = false;
      state.visiblePickTooltip = true;
    },
    /**
     * 予想ツールチップ非表示
     */
    hidePickTooltip(state) {
      state.timer = setTimeout(() => {
        state.visiblePickTooltip = false;
      }, HIDE_TOOLTIP_TIME);
    },
    /**
     * ニュースツールチップ表示
     */
    showNewsTooltip(state) {
      clearTimeout(state.timer);
      state.visiblePickTooltip = false;
      state.visibleStockTooltip = false;
      state.visibleNewsTooltip = true;
    },
    /**
     * ニュースツールチップ非表示
     */
    hideNewsTooltip(state) {
      state.timer = setTimeout(() => {
        state.visibleNewsTooltip = false;
      }, HIDE_TOOLTIP_TIME);
    },
    /**
     * 株価ツールチップ表示
     */
    showStockTooltip(state) {
      clearTimeout(state.timer);
      clearTimeout(state.stockTimer);
      state.visiblePickTooltip = false;
      state.visibleNewsTooltip = false;
      state.visibleStockTooltip = true;
    },
    /**
     * 株価ツールチップ非表示
     */
    hideStockTooltip(state) {
      state.stockTimer = setTimeout(() => {
        state.visibleStockTooltip = false;
      }, HIDE_TOOLTIP_TIME);
    },
    /**
     * マウスの座標情報更新
     * @param {Object} cordinates
     */
    mouseCordinates(state, cordinates) {
      state.mouseCordinates = cordinates;
    },
  },
};
export default StockAnalysisStore;
