import NewsSearchInput from './NewsSearchInput.vue';
import NewsSearchResult from './NewsSearchResult.vue';

/**
 * ニュース検索絞込ルートコンポーネント
 *
 * @vue-components {NewSearchInput} newsSearchInput 検索文字列入力
 * @vue-components {NewSearchResult} newsSearchResult 検索結果一覧表示
 * @module NewsSearch
 */
const NewsSearch = {
  el: '#v-news-search',
  components: {
    newsSearchInput: NewsSearchInput,
    newsSearchResult: NewsSearchResult
  }
};
export default NewsSearch;
