import MkApolloProvider from '../../../utils/MkApolloProvider';
import CorporateActionsCalendar from './CorporateActionsCalendar.vue';
import CorporateActionsDelistings from './CorporateActionsDelistings.vue';

/**
 * 上場廃止一覧表示用のルートコンポーネント
 * @module CorporateActions/CorporateActionDelisting
 */
const CorporateActionDelisting = {
  el: '#v-corporate-action-delisting',
  apolloProvider: MkApolloProvider,
  components: {
    CorporateActionsCalendar,
    CorporateActionsDelistings,
  },
}

export default CorporateActionDelisting;
