/**
 * 後読み処理に関するMixin
 */
const LazyLoadMixin = {
  methods: {
    /**
     * scrollイベント発火時に1度のみ処理を実行する
     * @param {Function}
     */
    lazyLoadByScrollOnce(callback) {
      window.addEventListener(
        'scroll',
        () => {
          callback();
        },
        { once: true, passive: true }
      );
    },
  },
};
export default LazyLoadMixin;
