import SideNavigationFinancialItemRanking from './SideNavigationFinancialItemRanking.vue';
import SideNavigationDividendYieldRanking from './SideNavigationDividendYieldRanking.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';
/*
 * 右ナビ 金融アイテムランキング ルートコンポーネント
 *
 */
const SideNavigationFinancialItemRankings = {
  el: '#side-navigation-financial-item-rankings',
  apolloProvider: MkApolloProvider,
  data: {
    loading_display: true,
  },
  components: {
    SideNavigationFinancialItemRanking: SideNavigationFinancialItemRanking,
    SideNavigationDividendYieldRanking: SideNavigationDividendYieldRanking,
  },
  /**
   * ダミーエリアの削除
   */
  mounted() {
    document.querySelectorAll('.side-navigation-dummy').forEach((d) => d.remove());
  },
};
export default SideNavigationFinancialItemRankings;
