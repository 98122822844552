<template src="./CorporateActionsCalendar.html"></template>
<script>
import dayjs from 'dayjs';
import { QUERY_CORPORATE_ACTIONS_CALENDAR } from '../../../queries/CorporateActionsCalendarQuery';
import CorporateActionsMonthPicker from './CorporateActionsMonthPicker.vue';

const actionNames = {
  split: '株式分割',
  reverseSplit: '株式併合',
  delisting: '上場廃止',
};

const actionAbbrNames = {
  split: '分割',
  reverseSplit: '併合',
  delisting: '廃止',
};

/**
 * コーポレートアクションカレンダーコンポーネント
 * @vue-data {String} actionName アクション名
 * @vue-data {String} todayString 本日日付
 * @vue-data {Number, Null} selectedYear 選択年
 * @vue-data {Number, Null} selectedMonth 選択月
 * @vue-data {Number, Null} selectedDay 選択日
 * @vue-data {String} calendarPeriodFrom カレンダーの表示範囲開始日付
 * @vue-data {String} calendarPeriodTo カレンダーの表示範囲終了日付
 * @module CorporateActions/CorporateActionsCalendar
 */
export default {
  name: 'CorporateActionsCalendar',
  components: {
    CorporateActionsMonthPicker,
  },
  props: {
    actionType: {
      type: String,
      require: true,
    },
    todayString: {
      type: String,
      require: true,
    },
    selectedYear: {
      type: Number,
      require: false,
      default: null,
    },
    selectedMonth: {
      type: Number,
      require: false,
      default: null,
    },
    selectedDay: {
      type: Number,
      require: false,
      default: null,
    },
    calendarPeriodFrom: {
      type: String,
      require: true,
    },
    calendarPeriodTo: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      actionName: actionNames[this.actionType],
      actionAbbrName: actionAbbrNames[this.actionType],
      corporateActionsCalendar: [],
    };
  },
  computed: {
    today() {
      return dayjs(this.todayString);
    },
    weeks() {
      return this.corporateActionsCalendar ? this.corporateActionsCalendar.weeks : [];
    },
    actions() {
      return this.corporateActionsCalendar ? this.corporateActionsCalendar.actions : [];
    },
    calendarYear() {
      return this.$store.getters['corporateActionsCalendar/calendarYear'];
    },
    calendarMonth() {
      return this.$store.getters['corporateActionsCalendar/calendarMonth'];
    },
    calendarDate() {
      return dayjs(`${this.calendarYear}/${this.calendarMonth}/01`);
    },
    isCurrentMonth() {
      return this.today.isSame(this.calendarDate, 'month');
    },
    /**
     * 表示中のページタイトル
     * @return {String}
     */
    currentTitle() {
      let title;
      if (this.selectedDate) {
        title = `${this.selectedDate.format('YYYY年M月D日')}の${this.actionName}銘柄一覧`;
      } else if (this.isCurrentMonth) {
        title = `【${this.actionName}】予定銘柄一覧`;
      } else {
        title = `${this.calendarYear}年${this.calendarMonth}月の${this.actionName}銘柄一覧`;
      }

      if (this.currentPage > 1) {
        title += `（${this.currentPage}ページ目）`;
      }

      return title + ' - みんかぶ';
    },
    /**
     * 表示中カレンダーのURLオプジェクト
     * @note 日付選択されている場合はdateパラメータを付与する
     *       当月以外の場合はURLにmonthパラメータを付与する
     * @return {URL}
     */
    currentURL() {
      const url = new URL(window.location.href);

      url.searchParams.delete('date');
      url.searchParams.delete('month');
      url.searchParams.delete('page');

      if (this.selectedDate) {
        const yyyymmdd = this.selectedDate.format('YYYYMMDD');
        url.searchParams.set('date', yyyymmdd);
      } else if (!this.isCurrentMonth) {
        const yyyymm = dayjs(`${this.calendarYear}/${this.calendarMonth}/01`).format('YYYYMM');
        url.searchParams.set('month', yyyymm);
      }

      if (this.currentPage > 1) {
        url.searchParams.set('page', this.currentPage);
      }

      return url;
    },
    selectedDate() {
      return this.$store.getters['corporateActionsCalendar/selectedDate'];
    },
    calendarWeeks() {
      return this.weeks ? this.weeks.map((week) =>
        week.days.map((day) => {
          const action = this.actions.find((action) => action.date == day.date);
          const dayjsDate = dayjs(day.date);
          return {
            day: dayjsDate.date(),
            date: dayjsDate,
            color: this.color(dayjsDate, day.isOpen, day.isCurrentMonth),
            isOpen: day.isOpen,
            isCurrentMonth: day.isCurrentMonth,
            isToday: dayjsDate.isSame(this.today),
            count: action ? action.count : 0,
          }
        })
      ) : [];
    },
    hasPrevMonth() {
      return this.$store.getters['corporateActionsCalendar/hasPrevMonth'];
    },
    hasNextMonth() {
      return this.$store.getters['corporateActionsCalendar/hasNextMonth'];
    },
    isVisibleMonthPicker() {
      return this.$store.getters['corporateActionsCalendar/isVisibleMonthPicker'];
    },
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    },
  },
  apollo: {
    corporateActionsCalendar: {
      query: QUERY_CORPORATE_ACTIONS_CALENDAR,
      variables() {
        return {
          action: this.actionType,
          year: this.calendarYear,
          month: this.calendarMonth,
        };
      }
    }
  },
  mounted() {
    this.initCalendar();
    document.querySelectorAll('[data-dummy-ca="true"]').forEach((e) => {
      e.remove();
    });
    window.addEventListener('popstate', this.selectMonthFromHistory);
  },
  methods: {
    /**
     * カレンダーを初期化する
     * 選択年月日が指定されている場合はその日付を表示し、指定されていない場合は今月を表示する
     */
    initCalendar() {
      const calendarYear = this.selectedYear || this.today.year();
      const calendarMonth = this.selectedMonth || this.today.month() + 1;

      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.commit('corporateActionsCalendar/calendarYear', calendarYear);
      this.$store.commit('corporateActionsCalendar/calendarMonth', calendarMonth);
      if (this.selectedDay) {
        this.$store.commit('corporateActionsCalendar/selectedDate', dayjs(`${calendarYear}/${calendarMonth}/${this.selectedDay}`));
      }
      this.$store.commit('corporateActionsCalendar/calendarPeriod', {
        periodFrom: this.calendarPeriodFrom,
        periodTo: this.calendarPeriodTo,
      });
    },
    /**
     * 今月のカレンダーを表示する
     */
    currentMonthCalendar() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.commit('corporateActionsCalendar/calendarYear', this.today.year());
      this.$store.commit('corporateActionsCalendar/calendarMonth', this.today.month() + 1);
      this.$store.commit('corporateActionsCalendar/calendarPeriod', {
        periodFrom: this.calendarPeriodFrom,
        periodTo: this.calendarPeriodTo,
      });
    },
    /**
     * 前の月の情報を表示
     */
    prevMonth() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.dispatch('corporateActionsCalendar/prevMonth');
    },
    /**
     * 次の月の情報を表示
     */
    nextMonth() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.dispatch('corporateActionsCalendar/nextMonth');
    },
    /**
     * 日付の色を返す
     * currentMonthでない場合は日付文字をグレー
     * 土曜なら青、日曜なら赤、それ以外の日にisOpen==falseなら赤
     */
    color(date, isOpen, isCurrentMonth) {
      if (!isCurrentMonth) {
        return 'text-slate-400';
      } else if (date.day() == 6) {
        return 'text-sky-600';
      } else if (!isOpen || date.day() == 0) {
        return 'text-red-500';
      } else {
        return 'text-slate-500';
      }
    },
    /**
     * MonthPickerを表示する
     */
    showMonthPicker() {
      this.$store.dispatch('corporateActionsCalendar/showMonthPicker');
    },
    /**
     * MonthPickerを非表示にする
     */
    hideMonthPicker() {
      this.$store.dispatch('corporateActionsCalendar/hideMonthPicker');
      this.$store.commit('corporateActionsCalendar/pickerYear', null);
    },
    /**
     * 日付を選択状態にする
     * 選択済の日付であれば選択を解除する
     */
    selectDate(date, e) {
      this.$store.dispatch('tailwindPagination/resetPage');
      if (this.isSelectedDate(date)) {
        this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      } else {
        this.$store.commit('corporateActionsCalendar/selectedDate', date);
      }
    },
    /**
     * 選択された日付と同じかどうかを判定する
     */
    isSelectedDate(date) {
      return date.isSame(this.selectedDate);
    },
    /**
     * 現在のStateとURLを履歴にセットする
     * @param {void}
     */
    setHistory() {
      let year, month, day;
      if (this.selectedDate) {
        year = this.selectedDate.year();
        month = this.selectedDate.month() + 1;
        day = this.selectedDate.date();
      } else {
        year = this.calendarYear;
        month = this.calendarMonth;
        day = null;
      }

      const page = this.currentPage;
      const state = { corporateActionsCalendar: { year, month, day, page } };
      window.history.pushState(state, '', this.currentURL);
    },
    /**
     * 履歴イベントからカレンダーを表示する
     * @note 戻る/進むボタン押下時のコールバック関数
     * @param {PopStateEvent} event
     * @return {void}
     */
    selectMonthFromHistory(event) {
      if (event.state && event.state.corporateActionsCalendar) {
        const year = event.state.corporateActionsCalendar.year;
        const month = event.state.corporateActionsCalendar.month;
        const day = event.state.corporateActionsCalendar.day;
        const page = event.state.corporateActionsCalendar.page;

        this.$store.dispatch('tailwindPagination/resetPage');
        this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
        this.$store.commit('corporateActionsCalendar/calendarYear', year);
        this.$store.commit('corporateActionsCalendar/calendarMonth', month);
        if (day) {
          this.$store.commit('corporateActionsCalendar/selectedDate', dayjs(`${year}/${month}/${day}`));
        }
        if (page) {
          this.$store.commit('tailwindPagination/currentPage', page);
        }
      }
    },
  },
  watch: {
    currentURL() {
      if (window.location.href != this.currentURL.href) {
        this.setHistory();
      }
    },
    currentTitle() {
      document.title = this.currentTitle;
    }
  }
};
</script>
