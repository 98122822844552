import BaseResource from './BaseResource';
import IntradayFxChart from '../models/FxCharts/IntradayFxChart';

/* *
 * 日中足API
 * */
class IntradayFxChartResource extends BaseResource {
  /*
   * 日中足データを取得する
   * @return {array} 営業日
   */
  async get(code) {
    const url = this._url(code);
    return await this.client
      .get(url)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        return this._toIntradayFxChart(data);
      })
      .catch(() => {
        return 'error';
      });
  }

  _url(code) {
    return `${this._jsonHostname()}/fx_intraday/${code}.json`;
  }

  _toIntradayFxChart(data) {
    return new IntradayFxChart(data);
  }
}
export default IntradayFxChartResource;
