import LatestPriceMovementChart from '../Common/LatestPriceMovementChart.vue';
import FavoriteButtonHeart from '../Common/FavoriteButtonHeart.vue';
import FavoriteCount from '../Common/FavoriteCount.vue';
import FavoriteFinancialItems from './FavoriteFinancialItems.vue';
import SettlementScheduleMail from './modules/settlementScheduleMail/SettlementScheduleMail.vue';

/**
 * マイページ ルートコンポーネント
 *
 * @vue-components {LatestPriceMovementChart} latestPriceMovementChart 最新の値動きチャート
 * @vue-components {FavoriteButtonHeart} FavoriteButtonHeart お気に入りハートボタン
 * @vue-components {FavoriteCount} FavoriteCount お気に入り数
 * @vue-components {FavoriteFinancialItems} FavoriteFinancialItems お気に入り銘柄一覧
 * @vue-components {SettlementScheduleMail} SettlementScheduleMail 決算発表予定メール
 * @module MyPage
 */
const MyPage = {
  el: '#v-myPage',
  components: {
    LatestPriceMovementChart: LatestPriceMovementChart,
    FavoriteButtonHeart: FavoriteButtonHeart,
    FavoriteCount: FavoriteCount,
    FavoriteFinancialItems,
    SettlementScheduleMail,
  },
};
export default MyPage;
