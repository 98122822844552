import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM_CODE_WITH_NAME_ABBR as FRAGMENT_F11M_CODE_WITH_NAME_ABBR } from './fragments/FinancialItemFragment';

const DIVIDEND_YIELD_RANKING_TYPE = `
number
financialItem {
  ...financialItemCodeWithNameAbbr
}
dividendYield
`;

export const QUERY_FINANCIAL_ITEM_RANKINGS_DIVIDEND_YIELDS = gql`
  query financialItemRankingsDividendYields {
    financialItemRankingsDividendYields {
      ${DIVIDEND_YIELD_RANKING_TYPE}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME_ABBR}
`;
