<template src="./NewsSearchInput.html" />
<script>
import InputUtil from '../../utils/InputUtil';

/**
 * ニュース検索結果絞込キーワード入力フォーム
 *
 * @vue-data {Array} delayQue 入力遅延制御用のQue
 * @vue-computed {string} inputValue 入力文字列
 * @module NewsSearch/NewsSearchInput
 */
export default {
  name: 'NewsSearchInput',
  props: {
    channel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      delayQue: []
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.$store.getters.inputValue;
      },
      /**
       * 遅延入力待ち実行後に
       * 入力文字列が前回と異なっていて
       *  - 文字列長が0 -> initSearchNews
       *  - 文字列長が1以上 -> searchNews
       * を呼び出す
       *
       * @param {string} val 入力文字列
       */
      async set(val) {
        if (await InputUtil.delay(this.delayQue, 300)) {
          if (this.inputValue !== val) {
            if (val.length === 0) {
              this.$store.dispatch('initInput');
            } else {
              this.$store.dispatch('searchNews', { val: val, channel: this.channel });
            }
          }
        }
      }
    }
  }
};
</script>
