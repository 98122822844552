<template src="./ModalHeader.html" />
<script>
import AnalysisComponent from './analysis/AnalysisComponent.vue';
import LinkComponent from './link/LinkComponent.vue';

/**
 * 銘柄検索 個別銘柄サマリーモーダル ヘッダーコンポーネント
 * @module StockSearch/modules/StockSummaryModal/header/HeaderComponent
 */
export default {
  name: 'ModalHeader',
  components: {
    'analysis-component': AnalysisComponent,
    'link-component': LinkComponent,
  },
};
</script>
