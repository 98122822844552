// 休憩時間か否か
function isBreak(ms) {
  const date = new Date(ms);
  const hour = date.getHours();
  const min = date.getMinutes();
  if (hour === 11) {
    return min >= 31;
  }
  if (hour === 12) {
    return min <= 29;
  }
  return false;
}

/** @class */
class IntradayStockChart {
  /**
   * 日中株価チャート
   *
   * @param {Array<Array>} items 日中株価の配列 [ [String, Number] ]
   * @param {Number} lastPrice 前営業日終値
   * @param {String} dateString チャートの対象日 YYYY-mm-dd
   * @constructor
   */
  constructor(prices, lastPrice, dateString) {
    this.prices = prices.map((price) => [Date.parse(price[0]), price[1]]);
    this.lastPrice = lastPrice;
    this.dateString = dateString;
  }

  /**
   * 何分間が空いているか
   * @param {Integer} index
   * @returns
   */
  gapMinutes(index) {
    if (index === 0) {
      return 0;
    }
    return (this.prices[index][0] - this.prices[index - 1][0]) / 1000 / 60 - 1;
  }

  /**
   * チャート用に整形した日中足のデータ
   * @returns {Array<Array>}
   */
  chartData() {
    let data = {};
    const startTime = Date.parse(`${this.dateString}T09:00:00+09:00`);
    for (let i = 0; i < 361; i++) {
      const t = startTime + i * 60 * 1000;
      data[t] = [t, null];
    }

    for (let i = 0; i < this.prices.length; i++) {
      const currentPrice = this.prices[i];
      const priorPrice = this.prices[i - 1];
      const gap = this.gapMinutes(i);
      for (let j = 0; j < gap; j++) {
        const t = currentPrice[0] - (gap - j) * 1000 * 60;
        if (isBreak(t)) {
          data[t] = [t, null];
        } else {
          data[t] = [t, priorPrice[1]];
        }
      }
      // @xxx Vue.jsがpricesの中身をObserverに変換してしまっている？ので、Arrayに戻している
      data[currentPrice[0]] = currentPrice.slice(0, 2);
    }

    return Object.values(data).sort((a, b) => a[0] - b[0]);
  }

  /**
   * 株価データの数
   * @returns {Integer}
   */
  priceSize() {
    return this.prices.length;
  }

  /**
   * 前営業日終値
   * @returns {Number}
   */
  lastClose() {
    if (!this.lastPrice) return null;

    return this.lastPrice;
  }

  /**
   * 終値時刻
   */
  closedAt() {
    if (this.prices.length === 0) return;

    const closePricedAt = this.prices[this.prices.length - 1][0];
    return closePricedAt;
  }
}
export default IntradayStockChart;
