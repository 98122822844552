import VariantUtil from '../utils/VariantUtil';

/**
 * デバイス切り替え表示用フィルター
 *
 * @module filters/VariantFilter
 */
class VariantFilter {
  constructor() {
    this.exportMethods = ['ifSp', 'ifPc', 'ifSpOrPc'];
  }

  /**
   * デバイスがSPの時のみ文字列を表示する
   *
   * @param {string} text 文字列
   * @returns {string}
   */
  ifSp(text) {
    return VariantUtil.isSp() ? text : '';
  }

  /**
   * デバイスがPCの時のみ文字列を表示する
   *
   * @param {string} text 文字列
   * @returns {string}
   */
  ifPc(text) {
    return VariantUtil.isSp() ? '' : text;
  }

  /**
   * デバイスがSPの時にspText
   * デバイスがPCの時にpcText
   * を返却する
   *
   * @param {string} spText SP用文字列
   * @param {string} pcText PC用文字列
   * @returns {string}
   */
  ifSpOrPc(spText, pcText) {
    return VariantUtil.isSp() ? spText : pcText;
  }
}

export default VariantFilter;
