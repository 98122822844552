<template src="./SectionDescription.html" />

<script>
/**
 * 銘柄検索 セクション説明文コンポーネント
 * @module StockSearch/modules/description/SectionDescription
 */

export default {
  name: 'SectionDescription',
  props: {
    /**
     * 呼び出し元コンポーネント (criteria / customize)
     */
    from: {
      type: String,
      required: true,
    },
    descriptionKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      descriptions: [
        {
          // みんかぶ予想の変化率
          minkabuEachExpectationChangeRatio: {
            criteria: 'みんかぶ予想各指標の「前月」からの変化率をもとに探す',
            customize: 'みんかぶ予想各指標の「前月」からの変化率を評価する項目',
          },
        },
        {
          // みんかぶ予想
          original: {
            criteria: 'アナリスト予想・会員予想・AI予想、またはそれらを元にした総合的な売買判断から探す',
            customize: 'アナリスト予想・会員予想・AI予想、またはそれらを元にした総合的な売買判断を評価する項目',
          },
        },
        {
          // 株価
          stock: {
            criteria: '',
            customize: '',
          },
        },
        {
          // 市場区分
          marketSegment: {
            criteria: '東証プライムや東証スタンダードなど市場区分から探す',
            customize: '',
          },
        },
        {
          // 株価指標
          marketIndicator: {
            criteria: '株価の割安・割高を評価する指標 PER、PBR、PSRから探す',
            customize: '',
          },
        },
        {
          // 財務指標
          financialIndicator: {
            criteria: '経営の効率性を示す指標 ROA、ROEから探す',
            customize: '',
          },
        },
        {
          // 業績
          settlement: {
            criteria: '1株あたり利益から探す',
            customize: '',
          },
        },
        {
          // 配当
          dividend: {
            criteria: '1株あたり配当、配当利回り、連続増配期数から探す',
            customize: '',
          },
        },
        {
          // 優待
          yutai: {
            criteria: '優待利回りから探す',
            customize: '',
          },
        },
        {
          // 企業情報
          corporate: {
            criteria: '上場年から探す',
            customize: '',
          },
        },
        {
          // 株価とみんかぶ予想の比較
          targetPriceWithSharePriceDeviationRate: {
            criteria:
              '「株価※」とアナリスト予想・会員予想・AI予想、またはそれらをもとにした総合的な予想（目標株価）との乖離率から探す',
            customize:
              '「株価」とアナリスト予想・会員予想・AI予想、またはそれらをもとにした総合的な予想（目標株価）との乖離率を評価する項目',
          },
        },
        {
          // 株価と過去株価の比較
          sharePriceChangeRatio: {
            criteria: '株価の前日比、前週比、前月比、前年比から探す',
            customize: '',
          },
        },
        {
          // 目標株価の変化率
          minkabuTargetPriceChangeRatio: {
            criteria: '目標株価の「過去時点」から「現在」までの変化率で探す',
            customize: '目標株価の「過去時点」から「現在」までの変化率を評価する項目',
          },
        },
        {
          // アナリスト予想株価の変化率
          analystTargetPriceChangeRatio: {
            criteria: 'アナリスト予想株価の「過去時点」から「現在」までの変化率で探す',
            customize: 'アナリスト予想株価の「過去時点」から「現在」までの変化率を評価する項目',
          },
        },
        {
          // みんかぶ会員予想の変化率
          userExpectationTargetPriceChangeRatio: {
            criteria: 'みんかぶ会員予想株価の「過去時点」から「現在」までの変化率で探す',
            customize: 'みんかぶ会員予想株価の「過去時点」から「現在」までの変化率を評価する項目',
          },
        },
        {
          // AI株価診断の変化率
          researchTargetPriceChangeRatio: {
            criteria: 'AI株価診断で算出した株価の「過去時点」から「現在」までの変化率で探す',
            customize: 'AI株価診断で算出した株価の「過去時点」から「現在」までの変化率を評価する項目',
          },
        },
        {
          // 株価比較
          sharePriceComparison: {
            criteria: '',
            customize: '',
          },
        },
        {
          // その他
          others: {
            criteria: '',
            customize: '',
          },
        },
      ],
    };
  },
  computed: {
    /**
     * 説明文
     * @return {string}
     */
    description: function () {
      const targetDescription = this.descriptions.find((desc) => desc[this.descriptionKey]);
      if (targetDescription && targetDescription[this.descriptionKey][this.from]) {
        return targetDescription[this.descriptionKey][this.from];
      } else {
        return '';
      }
    },
    /**
     * 説明文があるかどうか
     * @return {boolean}
     */
    isDescription() {
      return this.description !== '';
    },
  },
};
</script>
