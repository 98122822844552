/**
 * 文字列で渡される日付用フィルター
 *
 * @property {Array<string>} Vue.filterに登録するメソッド名
 * @module filters/StringDateFilter
 */
class StringDateFilter {
  constructor() {
    this.exportMethods = ['formatDate'];
  }

  /**
   * 日付をmm/dd にして返却する
   *
   * @params {string} 日付
   * @return {string} 変換済みの文字列
   */
  formatDate(stringDate) {
    const date = new Date(stringDate);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${month}/${day}`;
  }
}
export default StringDateFilter;
