<template src="./IPOCalendar.html" />

<script>
import { ContentLoader } from 'vue-content-loader';
import IPOCalendarMonthPicker from './IPOCalendarMonthPicker.vue';
import IPOCalendarBuilder from '../../../../models/IPOCalendars/IPOCalendarBuilder';
import FavoriteButtonHeart from '../../../Common/FavoriteButtonHeart.vue';
import { QUERY_IPO_CALENDAR as IPO_CALENDAR } from '../../../../queries/IPOCalendarQuery';
import dayjs from 'dayjs';

/**
 * IPOカレンダーコンポーネント
 * @vue-data {boolean} firstDisplay 初回表示したら真にする
 * @vue-components {ContentLoader} contentLoader
 * @vue-components {IPOCalendarMonthPicker} ipoCalendarMonthPicker IPOカレンダーMonthPicker
 * @vue-components {FavoriteButtonHeart} favoriteButtonHeart お気に入りボタン
 * @vue-apollo {Object} ipoCalendar IPOカレンダーAPIの返値
 * @vue
 * @module IPOCalendars/IPOCalendar
 */
export default {
  name: 'IPOCalendar',
  components: {
    contentLoader: ContentLoader,
    ipoCalendarMonthPicker: IPOCalendarMonthPicker,
    favoriteButtonHeart: FavoriteButtonHeart,
  },
  props: {
    /**
     * @vue-props {String} 今日の日付
     */
    todayString: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * @vue-props {Number} 初期年
     */
    defaultYear: {
      type: Number,
      require: true,
      default: null,
    },
    /**
     * @vue-props {Number} 初期月
     */
    defaultMonth: {
      type: Number,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      firstDisplay: false,
      ipoCalendar: null, // @note テストでsetData()を使うため、queryを初期化している
    };
  },
  apollo: {
    ipoCalendar: {
      query: IPO_CALENDAR,
      skip() {
        return true;
      },
      variables() {
        return {
          year: this.calendarYear,
          month: this.calendarMonth,
        };
      },
    },
  },
  computed: {
    /**
     * 今日の日付
     */
    today() {
      return dayjs(this.todayString);
    },
    /**
     * カレンダーの年
     * @return {Number}
     */
    calendarYear() {
      return this.$store.getters['ipoCalendar/calendarYear'];
    },
    /**
     * カレンダーの月
     * @return {Number}
     */
    calendarMonth() {
      return this.$store.getters['ipoCalendar/calendarMonth'];
    },
    /**
     * MonthPickerが表示されていれば真
     * @return {boolean}
     */
    isVisibleMonthPicker() {
      return this.$store.getters['ipoCalendar/isVisibleMonthPicker'];
    },
    /**
     * カレンダーが当月かどうか
     * History API用
     * @return {boolean}
     */
    isCurrentMonth() {
      return this.calendarYear === this.today.year() && this.calendarMonth === (this.today.month() + 1);
    },
    /**
     * 表示中のページタイトル
     * @return {string}
     */
    currentTitle() {
      let title = '';

      if (!this.isCurrentMonth) {
        title += `${this.calendarYear}年${this.calendarMonth}月の`;
      }

      return title + 'IPO・新規上場スケジュール - みんかぶ';
    },
    /**
     * 表示中カレンダーのURLオプジェクト
     * @note 当月以外はURLにmonthパラメータを付与する
     * @return {URL}
     */
    currentURL() {
      const url = new URL(window.location.href);

      if (this.isCurrentMonth) {
        url.searchParams.delete('month');
      } else {
        const yyyymm = dayjs(`${this.calendarYear}/${this.calendarMonth}/01`).format('YYYYMM');
        url.searchParams.set('month', yyyymm);
      }

      return url;
    },
    /**
     * PC版：当月でない場合に真、SP版：常に真
     * @return {boolean}
     */
    isNotCalendarMonth() {
      if (this.isSp) {
        return true;
      }
      return !this.isCurrentMonth;
    },
    /**
     * IPOカレンダー生成クラスのインスタンス
     * @return {IPOCalendarBuilder}
     */
    ipoCalendarBuilder() {
      return new IPOCalendarBuilder(this.calendarYear, this.calendarMonth);
    },
    /**
     * IPOカレンダーのヘッダー部オブジェクトの配列
     * @return {Array<Object>}
     */
    ipoCalendarHeaderCells() {
      if (this.ipoCalendar) {
        return this.ipoCalendarBuilder.buildHeader(this.ipoCalendar.holidays);
      } else {
        return [];
      }
    },
    /**
     * IPOカレンダーの金融アイテム行オブジェクトの配列
     * @return {Array<Object>}
     */
    ipoCalendarF11mRows() {
      let hasF11m;
      let f11mName;
      if (this.ipoCalendar) {
        return this.ipoCalendar.financialItemCalendars.map((f11mCalendar) => {
          hasF11m = !!f11mCalendar.financialItem;
          f11mName = hasF11m ? f11mCalendar.financialItem.nameAbbr : f11mCalendar.ipoStockName;
          return this.ipoCalendarBuilder.buildRow(f11mName, f11mCalendar.financialItemCode, hasF11m, f11mCalendar);
        });
      } else {
        return [];
      }
    },
    /**
     * IPOカレンダーの本日かどうかの配列
     * @return {Array<boolean>}
     */
    ipoCalendarTodayCells() {
      if (!this.ipoCalendar) {
        return [];
      }

      return this.ipoCalendarBuilder.buildToday();
    },
    /**
     * 初期年のIPO一覧ページのURL
     * @return {string}
     */
    ipoShowUrl() {
      return `/ipo/${this.defaultYear}`;
    },
    /**
     * 前の月にIPO情報があれば真
     * @return {boolean}
     */
    hasPrevMonth() {
      return this.$store.getters['ipoCalendar/hasPrevMonth'];
    },
    /**
     * 次の月にIPO情報があれば真
     * @return {boolean}
     */
    hasNextMonth() {
      return this.$store.getters['ipoCalendar/hasNextMonth'];
    },
    /**
     * デフォルト状態に戻るためのリンクのアンカーPC版：今月、SP版：今日
     * @return {string}
     */
    currentMonthOrDay() {
      return this.isSp ? '今日' : '今月';
    },
  },
  mounted() {
    this.initMonth();
    this.ipoCalendarLazyQuery();
    window.addEventListener('popstate', this.selectMonthFromHistory);
  },
  beforeUpdate() {
    this.mutationCalendarPeriod();
  },
  updated() {
    this.adjustDisplayStartPosition();
  },
  methods: {
    /**
     * @note
     *   mountedされるまでpropsから引数を受け取る事ができないので、
     *   mountedでskipをfalseにして、queryを発火させている
     */
    ipoCalendarLazyQuery() {
      this.$apollo.queries.ipoCalendar.skip = false;
    },
    /**
     * ipoCalendarを取得できたら、Storeにカレンダーの期間をcommitする
     */
    mutationCalendarPeriod() {
      if (this.ipoCalendar) {
        this.$store.commit('ipoCalendar/calendarPeriod', this.ipoCalendar.calendarPeriod);
      }
    },
    /**
     * IPOカレンダーが描画されたら、表示開始位置を調整する
     */
    adjustDisplayStartPosition() {
      if (this.ipoCalendar && !this.firstDisplay) {
        this.$store.dispatch('ipoCalendar/stickyScroll');
        this.firstDisplay = true;
      }
    },
    /**
     * 前の月の情報を表示
     */
    prevMonth() {
      this.$store.dispatch('ipoCalendar/prevMonth');
      this.$store.dispatch('ipoCalendar/cancelStickyScroll');
    },
    /**
     * 次の月の情報を表示
     */
    nextMonth() {
      this.$store.dispatch('ipoCalendar/nextMonth');
      this.$store.dispatch('ipoCalendar/cancelStickyScroll');
    },
    /**
     * 初期表示月を表示して、当日にstickyScrollを合わせる
     */
    initMonth() {
      this.$store.commit('ipoCalendar/calendarYear', this.defaultYear);
      this.$store.commit('ipoCalendar/calendarMonth', this.defaultMonth);
      this.$store.dispatch('ipoCalendar/stickyScroll');
      this.firstDisplay = false;
    },
    /**
     * 今月のカレンダーを表示して、当日にstickyScrollを合わせる
     */
    currentMonth() {
      this.$store.commit('ipoCalendar/calendarYear', this.today.year());
      this.$store.commit('ipoCalendar/calendarMonth', this.today.month() + 1);
      this.$store.dispatch('ipoCalendar/stickyScroll');
      this.firstDisplay = false;
    },
    /**
     * MonthPickerを表示する
     */
    toggleMonthPicker() {
      this.$store.dispatch('ipoCalendar/toggleMonthPicker');
    },
    /**
     * MonthPickerを非表示にする
     */
    hideMonthPicker() {
      this.$store.dispatch('ipoCalendar/hideMonthPicker');
      this.$store.commit('ipoCalendar/pickerYear', null);
    },
    /**
     * モーダルを表示する
     */
    showModal() {
      this.$store.commit('favoriteSignUpModal/show');
    },
    /**
     * 現在のStateとURLを履歴にセットする
     * @param {void}
     */
    setHistory() {
      const year = this.calendarYear;
      const month = this.calendarMonth;
      const state = { ipoCalendar: { year, month } };
      window.history.pushState(state, '', this.currentURL);
    },
    /**
     * 履歴イベントからカレンダーを表示する
     * @note 戻る/進むボタン押下時のコールバック関数
     * @param {PopStateEvent} event
     * @return {void}
     */
    selectMonthFromHistory(event) {
      if (event.state && event.state.ipoCalendar) {
        const year = event.state.ipoCalendar.year;
        const month = event.state.ipoCalendar.month;
        this.$store.commit('ipoCalendar/calendarYear', year);
        this.$store.commit('ipoCalendar/calendarMonth', month);
        this.$store.dispatch('ipoCalendar/cancelStickyScroll');
      }
    },
  },
  watch: {
    currentURL() {
      if (window.location.href !== this.currentURL.href) {
        this.setHistory();
      }
    },
    currentTitle() {
      document.title = this.currentTitle;
    }
  }
};
</script>
