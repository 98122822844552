import BaseResource from './BaseResource';
import HistoricalFxChart from '../models/FxCharts/HistoricalFxChart';

/* *
 * 日中足API
 * */
class HistoricalFxChartResource extends BaseResource {
  /*
   * 為替履歴データを取得する
   * @return {array} 営業日
   */
  async get(code) {
    const url = this._url(code);
    return await this.client
      .get(url)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        return this._toHistoricalFxChart(data);
      })
      .catch(() => {
        return 'error';
      });
  }

  _url(code) {
    return `${this._jsonHostname()}/fx_daily_bar/${code}.json`;
  }

  _toHistoricalFxChart(data) {
    return new HistoricalFxChart(data);
  }
}
export default HistoricalFxChartResource;
