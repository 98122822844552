/**
 * ニュース検索用のURLオブジェクトラッパー
 *
 * @property {URL} url ニュース検索URLオブジェクト
 */
class NewsSearchUrl {
  constructor(location = window.location) {
    this._pathname = '/news/search';
    this.url = new URL(location);
  }

  /**
   * 検索用URL文字列を返却する
   *
   * @param {Object} setParams 検索パラメータ
   * @param {string} format リクエストフォーマット
   */
  toString(setParams, format = '') {
    this._setSearchParam(setParams);
    this._setPathname(format);
    return this.url.toString();
  }

  _setSearchParam(setParams) {
    const searchObject = Object.assign({ page: 1 }, setParams);
    Object.entries(searchObject).forEach(([k, v]) => {
      this.url.searchParams.set(k, v);
    });
  }

  _setPathname(format) {
    if (format) {
      this.url.pathname = `${this._pathname}.${format}`;
    } else {
      this.url.pathname = this._pathname;
    }
  }
}
export default NewsSearchUrl;
