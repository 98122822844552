import FavoriteButton from '../Common/FavoriteButton.vue';
import NotificationBox from '../Common/NotificationBox.vue';
import FavoriteSignUpModal from '../Modal/FavoriteSignUpModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 複数ランキング表示ボックスのルートコンポーネント
 * @vue-data {number} currentTabId
 * @vue-data {Array.<number>} tabIds
 * @vue-components {FavoriteButton} favoriteButton お気に入り登録ボタンコンポーネント
 * @vue-components {NotificationBox} notificationBox お知らせboxコンポーネント
 * @vue-computed {boolean} isPopularTabSelected 人気ランキングが選択されていれば真
 * @vue-computed {boolean} isYutaiTabSelected 優待利回りランキングが選択されていれば真
 * @vue-computed {boolean} isHaitoTabSelected 配当利回りランキングが選択されていれば真
 * @vue-computed {boolean} isYutaiHaitoTabSelected 優待+配当利回りランキングが選択されていれば真
 * @module Yutai/YutaiMultiRankingBox
 */
const YutaiMultiRankingBox = {
  el: '#v-yutai-multi-ranking-box',
  data: {
    currentTabId: 1,
    tabIds: [1, 2, 3, 4],
  },
  components: {
    favoriteButton: FavoriteButton,
    notificationBox: NotificationBox,
    favoriteSignUpModal: FavoriteSignUpModal,
    CampaignBanner,
  },
  computed: {
    isPopularTabSelected() {
      return this.currentTabId === 1;
    },
    isYutaiTabSelected() {
      return this.currentTabId === 2;
    },
    isHaitoTabSelected() {
      return this.currentTabId === 3;
    },
    isYutaiHaitoTabSelected() {
      return this.currentTabId === 4;
    },
  },
  methods: {
    /**
     * 選択したtabIdをdataにセットする
     * @param {number} tabId
     */
    selectTab(tabId) {
      this.currentTabId = tabId;
    },
    /**
     * 指定したtabIdが現在のTabIdと一致すれば真
     * @param {number} tabId
     * @returns {boolean}
     */
    isCurrentTab(tabId) {
      return this.currentTabId === tabId;
    },
    /**
     * @fixme 将来的にはFacade側で定義した方が自然
     *
     * @param {number} tabId
     * @returns {string} タブ名
     */
    tabLabel(tabId) {
      switch (tabId) {
        case 1:
          return '人気';
        case 2:
          return '優待利回り';
        case 3:
          return '配当利回り';
        case 4:
          return '配当＋優待';
      }
    },
  },
};
export default YutaiMultiRankingBox;
