import BaseResource from './BaseResource';
import StockCalendar from '../models/StockCalendar';

/* *
 * 営業日カレンダーAPI
 * */
class StockCalendarResource extends BaseResource {
  /*
   * 営業日カレンダーオブジェクト
   * @return {StockCalendar}
   */
  async get() {
    const hostname = this._jsonHostname();
    const url = `${hostname}/calendar.json`;
    return await this.client
      .get(url)
      .then((response) => {
        return new StockCalendar(response.data);
      })
      .catch(() => {
        return 'error';
      });
  }
}
export default StockCalendarResource;
