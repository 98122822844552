/**
 * コード分類に関するMixin
 */
const CodeClassificationMixin = {
  methods: {
    /**
     * 銘柄コードが指数銘柄か否かを返す
     * @param {String} code
     * @return {Boolean}
     */
    isIndexCode(code) {
      return code.length !== 4;
    },
  },
};
export default CodeClassificationMixin;
