<template src="./SecuritiesCompanyTable.html" />

<script>
/**
 * 証券会社比較テーブルコンポーネント
 *
 * @property {Array<FeeRankingSecurity>} feeRankingSecurity [data] 手数料比較用証券会社モデル
 * @module ShareholderBenefitColumn/SecuritiesCompanyTable
*/
export default {
  name: 'SecuritiesCompanyTable',
  props: {
    feeRankingSecurity: {
      type: Object,
      required: true
    }
  }
};
</script>
