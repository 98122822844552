/**
 * IPOカレンダー金融アイテムカレンダー行クラス
 */
class IPOCalendarFinancialItemRow {
  /**
   *
   * @param {string} name
   * @param {string} code
   * @param {boolean} hasFinancialItem
   * @param {number} dayCount
   * @param {number} lastDay
   * @param {boolean} isIpoCanceled
   */
  constructor(name, code, hasFinancialItem, dayCount, lastDay, isIpoCanceled) {
    this._name = name;
    this._code = code;
    this._hasFinancialItem = hasFinancialItem;
    this._cells = new Array(dayCount).fill({ colspan: 1, label: '' });
    this.lastDay = lastDay;
    this.headerCount = dayCount;
    this._isIpoCanceled = isIpoCanceled;
  }

  /**
   * Cellの追加
   * @param {number} day
   * @param {number} colspan
   * @param {string} label
   */
  addCell(day, colspan, label) {
    let isProvisionalConditionFiling = false;
    let isBookBuilding = false;
    let isSubscriptionPriceDetermination = false;
    let isSubscription = false;
    let isListing = false;

    switch (label) {
      case '仮条件':
        isProvisionalConditionFiling = true;
        break;
      case 'ＢＢ期間':
        isBookBuilding = true;
        break;
      case '公開価格':
        isSubscriptionPriceDetermination = true;
        break;
      case '申込期間':
        isSubscription = true;
        break;
      case '上場':
        isListing = true;
        break;
    }

    this._cells.splice(day - 1, 1, {
      colspan,
      label,
      isProvisionalConditionFiling,
      isBookBuilding,
      isSubscriptionPriceDetermination,
      isSubscription,
      isListing,
    });

    // colspan分cellの描画をskipさせる為にnullを入れる
    if (colspan > 1) {
      for (let i = 1; i < colspan; i++) {
        this._cells.splice(day - 1 + i, 1, null);
      }
    }

    if (this.lastDay !== this.headerCount) {
      this._cells.splice(
        this.lastDay,
        this.headerCount - this.lastDay,
        ...new Array(this.headerCount - this.lastDay).fill({ colspan: 1, label: null })
      );
    }
  }

  /**
   * 金融アイテム名
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * 金融アイテムコード
   * @return {string}
   */
  get code() {
    return this._code;
  }

  /**
   * 金融アイテムがあれば真
   * @return {boolean}
   */
  get hasFinancialItem() {
    return this._hasFinancialItem;
  }

  /**
   * 上場中止であれば真
   * @return {boolean}
   */
  get isIpoCanceled() {
    return this._isIpoCanceled;
  }

  /**
   * 銘柄IPOページのパス
   * @return {string}
   */
  get stockIpoPath() {
    if (this._hasFinancialItem) {
      return `/stock/${this._code}/ipo`;
    } else {
      return '';
    }
  }

  /**
   * Object {
   *  colspan: number,
   *  label: string,
   *  isProvisionalConditionFiling: boolean,
   *  isBookBuilding: boolean,
   *  isSubscriptionPriceDetermination: boolean,
   *  isSubscription: boolean,
   *  isListing: boolean,
   * } の配列
   * @return {Array<Object>}
   */
  get cells() {
    return this._cells;
  }
}

export default IPOCalendarFinancialItemRow;
