/**
 * ニュース検索結果の状態
 *
 * @property {Object} collection 検索結果のコレクションオブジェクト
 * @module StockNews/StockNewsSearchResultStore
 */
const StockNewsSearchResultStore = {
  namespaced: true,
  state: {
    collection: {'items': []}
  },
  mutations: {
    collection(state, collection) {
      if (collection.totalPage > 0) {
        state.collection = collection;
      } else {
        state.collection = {'items': []};
      }
    }
  }
};
export default StockNewsSearchResultStore;
