<template src="./StockNewsSearchInput.html" />
<script>
import InputUtil from '../../utils/InputUtil';

/**
 * 銘柄ニュース絞込キーワード入力フォーム
 *
 * @vue-data {Array} delayQue 入力遅延制御用のQue
 * @vue-computed {string} inputValue 入力文字列
 * @module StockNews/NewsSearchInput
 */
export default {
  name: 'NewsSearchInput',
  props: {
    financialItemCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      delayQue: []
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.$store.getters.inputValue;
      },
      /**
       * キーワードでのニュース検索
       * @param {string} val 入力文字列
       */
      async set(val) {
        if (await InputUtil.delay(this.delayQue, 300)) {
          if (this.inputValue !== val) {
            if (val.length === 0) {
              this.$store.dispatch('searchNews', { val: '' });
            } else {
              this.$store.dispatch('searchNews', { val: val });
            }
          }
        }
      }
    }
  },
  /**
   * 初期処理
   * 銘柄コードをStoreに記憶させる
   */
  created() {
    this.$store.commit('setFinancialItemCode', this.financialItemCode);
  }
};
</script>
