/**
 * レポート銘柄ボタンを押下した際のモーダルの状態
 * @property {boolean} isVisible モーダルの状態[true: 表示, false: 表示しない]
 */
const ReportModalStore = {
  namespaced: true,
  state: {
    isSignUpVisible: false,
    isReportedVisible: false,
    isFullVisible: false,
    isReportableVisible: false,
  },
  getters: {
    /**
     * いずれかのモーダルが表示されるなら真
     * @returns {boolean}
     */
    isVisible(state) {
      return state.isSignUpVisible || state.isReportedVisible || state.isFullVisible || state.isReportableVisible;
    },
    /**
     * 会員登録モーダルが表示中なら真
     * @returns {boolean}
     */
    isSignUpVisible(state) {
      return state.isSignUpVisible;
    },
    /**
     * レポート済みモーダルが表示中なら真
     * @returns {boolean}
     */
    isReportedVisible(state) {
      return state.isReportedVisible;
    },
    /**
     * レポート登録件数上限モーダルが表示中なら真
     * @returns {boolean}
     */
    isFullVisible(state) {
      return state.isFullVisible;
    },
    /**
     * レポート可能モーダルが表示中なら真
     * @returns {boolean}
     */
    isReportableVisible(state) {
      return state.isReportableVisible;
    },
  },
  mutations: {
    /**
     * 会員登録モーダル表示
     * @returns {void}
     */
    showSignUp(state) {
      state.isSignUpVisible = true;
    },
    /**
     * レポート済みモーダル表示
     * @returns {void}
     */
    showReported(state) {
      state.isReportedVisible = true;
    },
    /**
     * レポート登録件数上限モーダル表示
     * @returns {void}
     */
    showFull(state) {
      state.isFullVisible = true;
    },
    /**
     * レポート可能モーダル表示
     * @returns {void}
     */
    showReportable(state) {
      state.isReportableVisible = true;
    },
    /**
     * モーダル非表示
     * @returns {void}
     */
    hide(state) {
      state.isSignUpVisible = false;
      state.isReportedVisible = false;
      state.isFullVisible = false;
      state.isReportableVisible = false;
    },
  },
};
export default ReportModalStore;
