<template src="./YutaiTopRankingsPopular.html" />

<script>
import FavoriteButton from '../../Common/FavoriteButton.vue';

export default {
  name: 'YutaiTopRankingsPopular',
  components: {
    favoriteButton: FavoriteButton,
  },
  props: {
    ranking: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * 引数に応じたclass名
     * @param {Integer} ランキング順位
     * @return {String}
     */
    makeClassForBgrank(number) {
      return `bgrank${number}`;
    },
    /**
     * ランキング画像のalt文言
     * @param {Object} ランキングオブジェクト
     * @return {String}
     */
    imageAlt(ranking) {
      return `${ranking.financialItem.name}の株主優待`;
    },
  },
};
</script>
