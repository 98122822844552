/**
 * ページネションを作成する必要がある際の
 * ページ情報を集約したコレクションクラス
 *
 * @property {Array<Object>} items 実オブジェクトの配列
 * @property {string} url ページネションする際のベースURL
 * @property {number} currentPage 現在ページ
 * @property {number} totalPage 総ページ
 */
class BaseCollection {
  constructor(params) {
    this.items = params.items;
    this.url   = params.url;
    if (typeof params.header !== 'undefined') {
      this.currentPage = params.header.current_page;
      this.totalPage   = params.header.total_page;
    }
  }
}

export default BaseCollection;
