<template src="./FavoriteButtonWithCount.html"></template>
<script>
import UserLoginStatus from '../../utils/UserLoginStatus';
import exportEventLabel from "../../utils/exportEventLabel";
/**
 * お気に入り数付きお気に入りボタン コンポーネント
 * @vue-prop {string} financialItemCode 銘柄コード
 * @vue-prop {number} favoriteCount お気に入り数
 * @vue-prop {array} classArrayTemplate divタグに付けるclassを持つ配列のベース
 * @vue-prop {boolean} isTailwindPage Tailwindでスタイルを適用しているページかどうか
 * @vue-computed {boolean} isUserLoginStatus ユーザがログイン状態なら真
 * @vue-computed {boolean} isFinancialItemFavored お気に入り登録している銘柄なら真
 * @vue-computed {string} eventLabel GTM送信用のイベントラベル
 * @vue-computed {number} localFavoriteCount お気に入り登録前後のお気に入り数
 * @vue-computed {array} classArray divタグに付けるclassを持つ配列
 * @module Common/FavoriteButtonWithCount
 */
export default {
  name: 'FavoriteButtonWithCount',
  props: {
    financialItemCode: {
      type: String,
      required: true
    },
    favoriteCount: {
      type: Number,
      required: true,
      validator: function (value) {
        return value >= 0;
      }
    },
    classArrayTemplate: {
      type: Array,
      required: true
    },
    isTailwindPage: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isUserLoginStatus() {
      return UserLoginStatus.isLoggedIn();
    },
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode);
    },
    eventLabel() {
      return exportEventLabel({
        isUserLoginStatus: this.isUserLoginStatus,
        isFinancialItemFavored: this.isFinancialItemFavored
      });
    },
    localFavoriteCount() {
      if (this.isFinancialItemFavored) {
        return this.favoriteCount + 1;
      } else {
        return this.favoriteCount;
      }
    },
    classArray() {
      let result = [...this.classArrayTemplate];

      /**
       * 本コンポーネントを使用しているページは、Tailwind を使えるページと使えないページがあるので、
       * classは親コンポーネントから props で受け取って使い分けるようにしている。
       * ただし、動的なclassの付け替えも必要なので、Tailwind ページかどうかに応じた付け替えをここで行なっている。
       */
      if(this.isTailwindPage) {
        if(this.isPc && !this.isFinancialItemFavored) {
          result[0] = result[0].concat(['hover:opacity-80']);
        }

        if(this.isFinancialItemFavored) {
          result[3] = result[3].concat(['is_on']);
          result[5] = result[5].concat(['is_on']);
          result[6] = result[6].concat(['is_on']);
        } else {
          result[3] = result[3].concat(['is_off']);
          result[5] = result[5].concat(['is_off']);
          result[6] = result[6].concat(['is_off']);
        }
      } else {
        if(this.isFinancialItemFavored) {
          result[1] = result[1].concat(['is_on']);
          result[3] = result[3].concat(['is_on']);
        } else {
          result[1] = result[1].concat(['is_off']);
          result[3] = result[3].concat(['is_off']);
        }
      }

      return result;
    }
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  mounted() {
    // 読み込み中に表示しておいたダミー要素を消す
    const elemDummyButtons = document.querySelectorAll('[data-js-id="favorite-button-dummy"]');
    elemDummyButtons.forEach(elemDummyButton => elemDummyButton.remove());
  },
  methods: {
    /**
     * ユーザがログイン時は金融アイテムをお気に入り登録または削除
     */
    async toggle() {
      if (this.isUserLoginStatus) {
        await this.$store.dispatch('favoriteFinancialItems/toggle', this.financialItemCode);
        this.$emit('financial-item-favored', this.isFinancialItemFavored);
      }
    }
  }
};
</script>
