<template src="./SearchResult.html" />
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import FavoriteButtonWithCount from '../../Common/FavoriteButtonWithCount.vue';
import generateQueryParams from './utils/generateQueryParams';
import PremiumTooltip from './modules/tooltip/PremiumTooltip.vue';
import StockSearchPaginationText from './modules/pagination/StockSearchPaginationText.vue';
import UrlGenerateUtil from '../../../utils/UrlGenerateUtil';
import StockSummaryModal from './modules/stockSummaryModal/StockSummaryModal.vue';
import StockSearchBanner from './modules/banner/StockSearchBanner.vue';

/**
 * 銘柄検索 検索結果コンポーネント
 * @module StockSearch/SearchResult
 */

const ITEM_NAME = {
  minimum_purchase_price: '最低投資金額',
  per: 'PER',
  pbr: 'PBR',
  psr: 'PSR',
  roa: 'ROA',
  roe: 'ROE',
  market_capitalization: '時価総額',
  vroc: '出来高変化率',
  yutai_yield: '優待利回り',
  dps: '1株あたり配当',
  consecutive_increased_dividend_count: '連続増配',
  dividend_yield: '配当利回り',
  payout_ratio: '配当性向',
  eps: '1株あたり利益',
  capital_adequacy_ratio: '自己資本比率',
  settlement_evaluations: '業績評価',
  sales_cagr_3y: '3年平均売上高成長率',
  operating_income_margin: '売上高営業利益率',
  listing_date: '上場年',
  minkabu_ratings: '目標株価',
  analyst_ratings: 'アナリスト予想',
  user_ratings: 'みんかぶ会員予想',
  research_ratings: 'AI株価診断',
  exchange_sections: '市場区分',
  industry_sectors: '業種',
  yutai_exist: '優待',
  minkabu_tp_cr_1m: '目標株価 変化率 前月比',
  analyst_tp_cr_1m: 'アナリスト予想株価 変化率 前月比',
  user_expectation_tp_cr_1m: 'みんかぶ会員予想 変化率 前月比',
  research_tp_cr_1m: 'AI株価診断 変化率 前月比',
  minkabu_tp_sp_dr: '目標株価 乖離率',
  analyst_tp_sp_dr: 'アナリスト予想 乖離率',
  user_expectation_tp_sp_dr: 'みんかぶ会員予想 乖離率',
  research_tp_sp_dr: 'AI株価診断 乖離率',
  share_price_cr_1d: '株価騰落率 前日比',
  share_price_cr_1w: '株価騰落率 前週比',
  share_price_cr_1m: '株価騰落率 前月比',
  share_price_cr_1y: '株価騰落率 前年比',
  minkabu_tp_cr_1d: '目標株価 変化率 前日比',
  minkabu_tp_cr_1w: '目標株価 変化率 前週比',
  minkabu_tp_cr_1y: '目標株価 変化率 前年比',
  analyst_tp_cr_1d: 'アナリスト予想株価 変化率 前日比',
  analyst_tp_cr_1w: 'アナリスト予想株価 変化率 前週比',
  analyst_tp_cr_1y: 'アナリスト予想株価 変化率 前年比',
  user_expectation_tp_cr_1d: 'みんかぶ会員予想 変化率 前日比',
  user_expectation_tp_cr_1w: 'みんかぶ会員予想 変化率 前週比',
  user_expectation_tp_cr_1y: 'みんかぶ会員予想 変化率 前年比',
  research_tp_cr_1d: 'AI株価診断 変化率 前日比',
  research_tp_cr_1w: 'AI株価診断 変化率 前週比',
  research_tp_cr_1y: 'AI株価診断 変化率 前年比',
};
Object.freeze(ITEM_NAME);

export default {
  name: 'SearchResult',
  components: {
    FavoriteButtonWithCount,
    PerfectScrollbar,
    StockSearchPaginationText,
    PremiumTooltip,
    StockSummaryModal,
    StockSearchBanner,
  },
  data() {
    return {
      scroll: {
        scrollable: true,
        window: {
          y: 0,
          x: 0,
        },
        wrapper: {
          x: 0,
          resizeObserver: null,
        },
        ticking: false,
        header: {
          fixed: false,
          elem: undefined,
        },
        controlBar: {
          height: 0,
        },
        tableHeader: {
          height: 0,
        },
        scrollbar: {
          elem: undefined,
        },
        rect: {
          top: 0,
          bottom: 0,
        },
      },
      compare: {
        edit: false,
        checked: [],
        old: [],
      },
    };
  },
  computed: {
    paramsString() {
      return this.buildSearchingParams();
    },
    result() {
      const result = JSON.parse(JSON.stringify(this.$store.getters['stockSearchInput/result']));

      // 銘柄比較中の場合は、チェックされた銘柄のみ表示する
      if (!this.compare.edit && this.compare.checked.length) {
        result.items = result.items.filter((item) => {
          return this.compare.checked.includes(item.financialItemCode);
        });
      }
      if (result.items) {
        this.$store.commit(
          'stockSearchInput/setSummaryCodeList',
          result.items.map((item) => item.financialItemCode)
        );
      }
      return result;
    },
    sortKey() {
      return this.$store.getters['stockSearchInput/checkedCustomize'];
    },
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    currentOrder() {
      return this.$store.getters['stockSearchInput/order'];
    },
    currentSortKey() {
      return this.$store.getters['stockSearchInput/sortKey'];
    },
    total() {
      return this.$store.getters['stockSearchInput/total'];
    },
    checkedCustomize() {
      return this.$store.getters['stockSearchInput/checkedCustomize'];
    },
    cls() {
      return this.$store.getters['stockSearchInput/cls'];
    },
    fullscreen() {
      return this.$store.getters['stockSearchInput/fullscreen'];
    },
    page() {
      return this.$store.getters['stockSearchInput/page'];
    },
    view() {
      return this.$store.getters['stockSearchInput/view'];
    },
    scrollbar() {
      return this.$refs.scrollbar.ps;
    },
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    summaryShow() {
      return this.$store.getters['stockSearchInput/summaryShow'];
    },
    summaryCode() {
      return this.$store.getters['stockSearchInput/summaryCode'];
    },
    /**
     * 横スクロール可能か
     * @return {boolean}
     */
    isScrollable() {
      return this.scroll.scrollable;
    },
    /**
     * ヘッダをウィンドウ上部に固定するか
     * @return {boolean}
     */

    hasFixed() {
      if (this.cls) {
        return false;
      }
      return (
        this.scroll.rect.top + 16 - this.headerHeight < this.scroll.window.y &&
        this.scroll.rect.bottom > this.scroll.window.y
      );
    },
    /**
     * ヘッダを固定した時のオフセット量を返す
     */
    fixedOffset() {
      if (this.hasFixed) {
        if (this.isPc && !this.fullscreen) {
          return `${this.scroll.tableHeader.height + this.scroll.controlBar.height}px`;
        }
        return `${this.scroll.tableHeader.height + this.scroll.controlBar.height - 16}px`;
      }
      return '16px';
    },
    /**
     * スクロールバーをウィンドウ下部に固定するか
     * @return {boolean}
     */
    hasScrollbarFixed() {
      return (
        this.scroll.rect.top - window.innerHeight < this.scroll.window.y &&
        this.scroll.rect.bottom - window.innerHeight > this.scroll.window.y
      );
    },
    /**
     * 共通ヘッダの高さを返す
     * @return {number}
     */
    headerHeight() {
      if (!this.scroll.header.fixed || !this.scroll.header.elem || (this.fullscreen && this.isPc)) {
        return 0;
      }
      return this.scroll.header.elem.getBoundingClientRect().height;
    },
    /**
     * 見出しの横幅を返す
     * @return {number}
     */
    headerWidth() {
      if (this.cls) {
        return 0;
      }
      if (!this.scroll.header.fixed || !this.$refs.contents || !this.scrollbar) {
        if (this.isPc) {
          return 0;
        }
        return this.scrollbar.containerWidth;
      }
      if (this.isSp && !this.scroll.scrollable) {
        return this.$refs.contents.clientWidth;
      }
      return this.scrollbar.containerWidth;
    },
    /**
     * 見出しの横幅を返す
     * @return {number}
     */
    translateX() {
      if (!this.scroll.header.fixed || this.isSp) {
        return 0;
      }
      return this.scroll.window.x;
    },
    /**
     * ラベルを表示するか
     * @return {boolean}
     */
    hasShowRatingLabel() {
      return (item) =>
        ['minkabuTargetPrice', 'analystTargetPrice', 'researchTargetPrice', 'userExpectationTargetPrice'].includes(
          item
        );
    },
    /**
     * 項目の横幅を返す
     * @return {string}
     */
    rowWidth() {
      return (item) => (this.settings[item] ? `width: ${this.settings[item].width / 4}rem` : 0);
    },
    /**
     * 表示中のURLを返す
     */
    currentUrl() {
      return location.href;
    },
    /**
     * プレミアム登録とログインのコールバックURLを返す
     */
    callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        if (path === 'premium_lp') {
          return UrlGenerateUtil.premiumPath({
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'modal',
            campaign: '/stock/search',
          });
        } else {
          return UrlGenerateUtil.idMinkabuUrl({
            pathString: path,
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'modal',
            campaign: '/stock/search',
          });
        }
      };
    },
    /**
     * モーダル内のリンクのコールバックURLを書き換える
     */
    rewriteCallbackUrl() {
      /**
       * @param {object} link link要素
       * @param {string} pathString 遷移先
       */
      return (link, pathString) => {
        if (!link) {
          return;
        }
        if (pathString === 'premium_lp') {
          return link.setAttribute(
            'href',
            UrlGenerateUtil.premiumPath({
              currentUrl: window.location.href,
              source: 'minkabu',
              medium: 'modal',
              campaign: '/stock/search',
            })
          );
        } else {
          return link.setAttribute(
            'href',
            UrlGenerateUtil.idMinkabuUrl({
              pathString: pathString,
              currentUrl: window.location.href,
              source: 'minkabu',
              medium: 'modal',
              campaign: '/stock/search',
            })
          );
        }
      };
    },
    /**
     * HTMLクラスを返す
     * @return {object}
     */
    classObj() {
      return {
        header: {
          'ui-stock-search_header': this.isSp,
          'fixed left-5 w-full overflow-hidden': this.hasFixed && this.isSp,
          'fixed overflow-hidden': this.hasFixed && this.isPc,
          '!w-max min-w-full': !this.hasFixed,
        },
        controlBar: {
          'ui-stock-search_header': this.isSp,
          'fixed left-5 w-full': this.hasFixed && this.isSp,
          'fixed': this.hasFixed && this.isPc,
          '!w-max min-w-full': !this.hasFixed,
        },
        controlBarStyle: {
          top: this.isSp || this.headerHeight > 0 || this.fullscreen ? `${this.headerHeight}px` : 'auto',
          width: this.headerWidth > 0 ? `${this.headerWidth}px` : 'auto',
        },
        heading: {
          wrapper: {
            'absolute': this.hasFixed,
            'w-full': !this.scroll.scrollable && this.hasFixed,
          },
          item: {
            'ui-stock-search_header': this.isSp,
            'grow z-10': true,
            'sticky top-0': this.hasFixed,
            'sticky': !this.hasFixed,
            'left-10': this.compare.edit,
            'left-0': !this.compare.edit,
          },
        },
        favoriteButton: [
          ['flex', 'flex-col', 'justify-center', 'text-slate-500', 'block', 'cursor-pointer'],
          ['flex', 'justify-center', 'ui-favorite-button-click', 'ui-favorite-text-active'],
          ['relative', 'text-xss', 'text-center', 'w-7.5'],
          ['ui-favorite-button', 'ui-favorite-button-normal', 'ui-favorite-button-active'],
          ['-mt-1', 'leading-4'],
          [],
          ['hidden'],
        ],
      };
    },
    /**
     * 結果リスト設定
     * @return {object}
     */
    settings() {
      return {
        financialItemName: {
          width: 32,
          name: '銘柄',
          sortKeyName: 'financial_item_code',
          premium: false,
          hasSort: true,
          unit: '',
        },
        latestTradePrice: {
          width: 24,
          name: '株価',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '円',
        },
        sharePriceCr1d: {
          width: 24,
          name: '前日比',
          sortKeyName: 'share_price_cr_1d',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        sharePriceCr1w: {
          width: 24,
          name: '前週比',
          sortKeyName: 'share_price_cr_1w',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        sharePriceCr1m: {
          width: 24,
          name: '前月比',
          sortKeyName: 'share_price_cr_1m',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        sharePriceCr1y: {
          width: 24,
          name: '前年比',
          sortKeyName: 'share_price_cr_1y',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        buyPickTotal: {
          width: 24,
          name: '買い予想\n総数',
          sortKeyName: 'buy_pick_total',
          premium: true,
          hasSort: true,
          unit: '',
        },
        sellPickTotal: {
          width: 24,
          name: '売り予想\n総数',
          sortKeyName: 'sell_pick_total',
          premium: true,
          hasSort: true,
          unit: '',
        },
        minkabuTargetPrice: {
          width: 32,
          name: '目標株価',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '円',
        },
        analystTargetPrice: {
          width: 32,
          name: 'アナリスト予想',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '円',
        },
        researchTargetPrice: {
          width: 32,
          name: 'AI株価診断',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '円',
        },
        userExpectationTargetPrice: {
          width: 32,
          name: 'みんかぶ会員予想',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '円',
        },
        per: {
          width: 24,
          name: '調整後\nPER',
          sortKeyName: 'per',
          premium: false,
          hasSort: true,
          unit: '倍',
        },
        pbr: {
          width: 24,
          name: 'PBR',
          sortKeyName: 'pbr',
          premium: false,
          hasSort: true,
          unit: '倍',
        },
        psr: {
          width: 24,
          name: 'PSR',
          sortKeyName: 'psr',
          premium: false,
          hasSort: true,
          unit: '倍',
        },
        roa: {
          width: 24,
          name: 'ROA',
          sortKeyName: 'roa',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        roe: {
          width: 24,
          name: 'ROE',
          sortKeyName: 'roe',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        marketCapitalization: {
          width: 32,
          name: '時価総額',
          sortKeyName: 'market_capitalization',
          premium: false,
          hasSort: true,
          unit: '億円',
        },
        volume: {
          width: 28,
          name: '出来高',
          sortKeyName: 'volume',
          premium: false,
          hasSort: true,
          unit: '株',
        },
        vroc: {
          width: 24,
          name: '出来高\n変化率',
          sortKeyName: 'vroc',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        bps: {
          width: 32,
          name: '1株あたり\n純資産',
          sortKeyName: 'bps',
          premium: false,
          hasSort: true,
          unit: '円',
        },
        yutaiYield: {
          width: 20,
          name: '優待\n利回り',
          sortKeyName: 'yutai_yield',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        yutaiExist: {
          width: 20,
          name: '優待',
          sortKeyName: 'yutai_exist',
          premium: false,
          hasSort: false,
          unit: '',
        },

        dps: {
          width: 24,
          name: '1株あたり\n配当',
          sortKeyName: 'dps',
          premium: false,
          hasSort: true,
          unit: '円',
        },
        consecutiveIncreasedDividendCount: {
          width: 24,
          name: '連続増配\n回数',
          sortKeyName: 'consecutive_increased_dividend_count',
          premium: true,
          hasSort: true,
          unit: '期連続',
        },
        dividendYield: {
          width: 20,
          name: '配当\n利回り',
          sortKeyName: 'dividend_yield',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        payoutRatio: {
          width: 24,
          name: '配当性向',
          sortKeyName: 'payout_ratio',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        eps: {
          width: 24,
          name: '1株あたり\n利益',
          sortKeyName: 'eps',
          premium: false,
          hasSort: true,
          unit: '円',
        },
        netAssets: {
          width: 32,
          name: '純資産',
          sortKeyName: 'net_assets',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        totalAssets: {
          width: 36,
          name: '総資産',
          sortKeyName: 'total_assets',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        capital: {
          width: 32,
          name: '資本金',
          sortKeyName: 'capital',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        industrySectors: {
          width: 40,
          name: '業種',
          sortKeyName: 'industry_sectors',
          premium: false,
          hasSort: true,
          unit: '',
        },
        capitalAdequacyRatio: {
          width: 24,
          name: '自己資本\n比率',
          sortKeyName: 'capital_adequacy_ratio',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        listingDate: {
          width: 32,
          name: '上場年月日',
          sortKeyName: 'listing_date',
          premium: false,
          hasSort: true,
          unit: '',
        },
        settlementEvaluations: {
          width: 24,
          name: '業績評価',
          sortKeyName: 'settlement_evaluations',
          premium: true,
          hasSort: true,
          unit: '',
        },
        sales: {
          width: 32,
          name: '売上高',
          sortKeyName: 'sales',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        salesCAGR3y: {
          width: 32,
          name: '3年平均\n売上高成長率',
          sortKeyName: 'sales_cagr_3y',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        operatingIncome: {
          width: 32,
          name: '営業利益',
          sortKeyName: 'operating_income',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        operatingIncomeMargin: {
          width: 32,
          name: '売上高\n営業利益率',
          sortKeyName: 'operating_income_margin',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        ordinaryIncome: {
          width: 32,
          name: '経常利益',
          sortKeyName: 'ordinary_income',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        netIncome: {
          width: 32,
          name: '当期利益',
          sortKeyName: 'net_income',
          premium: false,
          hasSort: true,
          unit: '百万円',
        },
        minimumPurchasePrice: {
          width: 32,
          name: '最低投資金額',
          sortKeyName: 'minimum_purchase_price',
          premium: false,
          hasSort: true,
          unit: '円',
        },
        shareUnit: {
          width: 32,
          name: '最低投資株数',
          sortKeyName: '',
          premium: false,
          hasSort: false,
          unit: '株',
        },
        minkabuTpSpDr: {
          width: 36,
          name: '目標株価\n乖離率',
          sortKeyName: 'minkabu_tp_sp_dr',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        analystTpSpDr: {
          width: 36,
          name: 'アナリスト予想\n乖離率',
          sortKeyName: 'analyst_tp_sp_dr',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        userExpectationTpSpDr: {
          width: 36,
          name: 'みんかぶ会員予想\n乖離率',
          sortKeyName: 'user_expectation_tp_sp_dr',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        researchTpSpDr: {
          width: 36,
          name: 'AI株価診断\n乖離率',
          sortKeyName: 'research_tp_sp_dr',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        minkabuTpCr1d: {
          width: 36,
          name: '目標株価変化率\n前日比',
          sortKeyName: 'minkabu_tp_cr_1d',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        minkabuTpCr1w: {
          width: 36,
          name: '目標株価変化率\n前週比',
          sortKeyName: 'minkabu_tp_cr_1w',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        minkabuTpCr1m: {
          width: 36,
          name: '目標株価変化率\n前月比',
          sortKeyName: 'minkabu_tp_cr_1m',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        minkabuTpCr1y: {
          width: 36,
          name: '目標株価変化率\n前年比',
          sortKeyName: 'minkabu_tp_cr_1y',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        analystTpCr1d: {
          width: 36,
          name: 'アナリスト予想株価\n変化率 前日比',
          sortKeyName: 'analyst_tp_cr_1d',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        analystTpCr1w: {
          width: 36,
          name: 'アナリスト予想株価\n変化率 前週比',
          sortKeyName: 'analyst_tp_cr_1w',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        analystTpCr1m: {
          width: 36,
          name: 'アナリスト予想株価\n変化率 前月比',
          sortKeyName: 'analyst_tp_cr_1m',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        analystTpCr1y: {
          width: 36,
          name: 'アナリスト予想株価\n変化率 前年比',
          sortKeyName: 'analyst_tp_cr_1y',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        userExpectationTpCr1d: {
          width: 36,
          name: 'みんかぶ会員予想\n変化率 前日比',
          sortKeyName: 'user_expectation_tp_cr_1d',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        userExpectationTpCr1w: {
          width: 36,
          name: 'みんかぶ会員予想\n変化率 前週比',
          sortKeyName: 'user_expectation_tp_cr_1w',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        userExpectationTpCr1m: {
          width: 36,
          name: 'みんかぶ会員予想\n変化率 前月比',
          sortKeyName: 'user_expectation_tp_cr_1m',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        userExpectationTpCr1y: {
          width: 36,
          name: 'みんかぶ会員予想\n変化率 前年比',
          sortKeyName: 'user_expectation_tp_cr_1y',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        researchTpCr1d: {
          width: 36,
          name: 'AI株価診断変化率\n前日比',
          sortKeyName: 'research_tp_cr_1d',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        researchTpCr1w: {
          width: 36,
          name: 'AI株価診断変化率\n前週比',
          sortKeyName: 'research_tp_cr_1w',
          premium: true,
          hasSort: true,
          unit: '%',
        },
        researchTpCr1m: {
          width: 36,
          name: 'AI株価診断変化率\n前月比',
          sortKeyName: 'research_tp_cr_1m',
          premium: false,
          hasSort: true,
          unit: '%',
        },
        researchTpCr1y: {
          width: 36,
          name: 'AI株価診断変化率\n前年比',
          sortKeyName: 'research_tp_cr_1y',
          premium: true,
          hasSort: true,
          unit: '%',
        },
      };
    },
    /**
     * ラベル設定
     * @return {object}
     */
    ratingLabels() {
      return [
        {
          text: '強買',
          className: ['ui-stock-search-label-1', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '買',
          className: ['ui-stock-search-label-2', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '中立',
          className: ['ui-stock-search-label-3', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '売',
          className: ['ui-stock-search-label-4', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '強売',
          className: ['ui-stock-search-label-5', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '割安',
          className: ['ui-stock-search-label-2', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '妥当',
          className: ['ui-stock-search-label-3', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '割高',
          className: ['ui-stock-search-label-4', 'text-xs', 'text-white', 'text-shadow'],
          hasRating: true,
        },
        {
          text: '対象外',
          className: ['text-sm'],
          hasRating: false,
        },
        {
          text: '分析中',
          className: ['text-sm'],
          hasRating: false,
        },
        {
          text: '',
          className: [],
          hasRating: true,
        },
      ];
    },
    /**
     * おすすめ条件経由時のサブ見出し
     * @return {string}
     */
    recommend() {
      return {
        'value_stock': '（割安銘柄）',
        'low_priced_and_famous_stock': '（少額で買える有名企業）',
        'consecutive_increased_dividend': '（連続増配銘柄）',
        'growth_stock': '（成長期待銘柄）',
        'good_result_and_laggard': '（好業績出遅れ株）',
        'high_dividend_stock': '（高配当銘柄）',
        'buffett_stock': '（バフェット銘柄）',
        'ten-bagger_possibility': '（大化け可能性株）',
        'surging_volume': '（出来高急増銘柄）',
        'analyst_upgraded': '（アナリスト上方修正銘柄）',
        'dividend_with_under50k': '（5万円以下で配当狙い）',
        'good_financial_performance': '（業績が好調な企業）',
        'buying_the_dip': '（押し目買い狙い）',
        'dividend_and_yutai': '（配当・優待二刀流銘柄）',
      }[this.$store.getters['stockSearchInput/recommend']];
    },
  },
  watch: {
    view() {
      // 表示ページを変更したら、銘柄比較をリセットする
      this.resetCompare();
      this.changeCompareEdit(false);
    },
  },
  mounted() {
    // スクロールバー取得
    this.scroll.scrollbar.elem = document.querySelector('.ps__rail-x');

    // 共通ヘッダ取得
    if (this.isSp) {
      this.scroll.header.elem = document.querySelector('#header_sp > .md_header_sp');
    } else {
      this.scroll.header.elem = document.querySelector('[data-layout="gnavi_header"]');
    }
    const fullscreenWrapper = document.querySelector('#js_stockSearch_fs_wrapper');

    // リサイズ時の処理（lazyload対応）
    const resizeObserver = new ResizeObserver(() => {
      const fullscreenWrapper = document.querySelector('#js_stockSearch_fs_wrapper');
      if (fullscreenWrapper) {
        fullscreenWrapper.scrollTo(0, 0);
      }
      const rect = this.$refs.wrapper.getBoundingClientRect();
      const pageY = window.pageYOffset;
      this.scroll.rect.top = rect.top + pageY;
      this.scroll.rect.bottom = rect.bottom + pageY;
      this.scroll.scrollbar.elem.style.transform = `translateX(${
        this.scrollbar.element.offsetLeft - this.scroll.window.x
      }px)`;

      if (this.$refs.controlBar) {
        this.scroll.controlBar.height = this.$refs.controlBar.getBoundingClientRect().height;
      }
      if (this.$refs.header) {
        this.scroll.tableHeader.height = this.$refs.header.getBoundingClientRect().height;
      }
    });
    resizeObserver.observe(document.querySelector('body'));
    resizeObserver.observe(this.$refs.contents);

    if (window) {
      window.addEventListener('scroll', () => this.onScrollWindow(), false);
    }
    if (fullscreenWrapper) {
      fullscreenWrapper.addEventListener('scroll', () => this.onScrollWindow(fullscreenWrapper), false);
    }
    if (this.$refs.wrapper) {
      this.$refs.wrapper.addEventListener('scroll', this.onScrollTable);
    }

    // テーブルリサイズ時の処理
    this.scroll.wrapper.resizeObserver = new ResizeObserver(this.onResizeTable);
    this.scroll.wrapper.resizeObserver.observe(this.$refs.contents);

    this.escFullscreen();

    window.addEventListener('popstate', () => {
      const param = new URLSearchParams(window.location.search).get('view');
      if (param !== 'result' && this.fullscreen) {
        this.toggleFullscreen();
      }
    });
  },
  methods: {
    /**
     * 画面をスクロールされたらヘッダ固定に必要な情報を保存
     */
    onScrollWindow(arg = false) {
      if (!this.scroll.ticking) {
        window.requestAnimationFrame(() => {
          this.scroll.window.y = arg ? arg.scrollTop : window.scrollY;
          this.scroll.window.x = arg ? arg.scrollLeft : window.scrollX;
          this.scroll.ticking = false;
          this.scroll.scrollbar.elem.style.transform = `translateX(${
            this.scrollbar.element.offsetLeft - this.scroll.window.x
          }px)`;
        });
        this.scroll.ticking = true;
        if (this.isSp) {
          this.scroll.header.fixed = document.querySelector('#header_sp > .js_pinned') !== null;
        } else {
          this.scroll.header.fixed = arg ? true : document.querySelector('[data-position="fixed"]') !== null;
        }
      }
    },
    /**
     * テーブルがリサイズされたら横スクロール可能かを判定
     */
    onResizeTable() {
      this.scroll.scrollable = this.scrollbar.scrollbarXActive;
    },
    /**
     * 検索結果テーブルをスクロールされたらヘッダ固定に必要な情報を保存
     */
    onScrollTable() {
      this.scroll.wrapper.x = this.scrollbar.element.scrollLeft;
    },
    /**
     * ラベルの内容とHTMLクラスを返す
     * @return {object}
     */
    ratingLabel(item, i) {
      let text = '';
      if (item === 'minkabuTargetPrice') {
        text = this.result.items[i].minkabuRating;
      } else if (item === 'analystTargetPrice') {
        text = this.result.items[i].analystRating;
      } else if (item === 'researchTargetPrice') {
        text = this.result.items[i].researchRating;
      } else if (item === 'userExpectationTargetPrice') {
        text = this.result.items[i].userExpectationRating;
      }
      text = text ? text : '';
      return this.ratingLabels.find((el) => el.text === text);
    },
    /**
     * +-のある比率系項目の色を返す
     * @param {string} item 表示文字列
     * @param {string} key 項目名
     * @return {number} プラスの場合は1、マイナスの場合は-1、符号なしの場合は0、それ以外はnull
     */
    ratioColor(item, key) {
      const list = [
        'changeRatio',
        // 'lastMonthEndPriceChangeRatio',
        'vroc',
        'salesCAGR3y',
        'operatingIncomeMargin',
        'minkabuTpSpDr',
        'analystTpSpDr',
        'userExpectationTpSpDr',
        'researchTpSpDr',
        'sharePriceCr1d',
        'sharePriceCr1w',
        'sharePriceCr1m',
        'sharePriceCr1y',
        'minkabuTpCr1d',
        'minkabuTpCr1w',
        'minkabuTpCr1m',
        'minkabuTpCr1y',
        'analystTpCr1d',
        'analystTpCr1w',
        'analystTpCr1m',
        'analystTpCr1y',
        'userExpectationTpCr1d',
        'userExpectationTpCr1w',
        'userExpectationTpCr1m',
        'userExpectationTpCr1y',
        'researchTpCr1d',
        'researchTpCr1w',
        'researchTpCr1m',
        'researchTpCr1y',
      ];
      if (list.includes(key) && item) {
        if (item.charAt() === '+') {
          return 1;
        } else if (item.charAt() === '-') {
          return -1;
        }
        return 0;
      }
      return null;
    },
    /**
     * カスタマイズモーダルを開く
     */
    openCustomize() {
      // Bodyのスクロール制御
      const body = document.querySelector('body');
      const offsetY = `-${window.pageYOffset}px`;
      body.classList.add('ui-drawer-scroll-stop');
      body.style.top = offsetY;
      this.$store.commit('stockSearchInput/setShowCustomize', true);
    },
    /**
     * 検索条件に戻る
     */
    back2Criteria() {
      const headerTitle = document.querySelector('#header_sp .js_header_title');
      const breadcrumb = document.querySelector('#breadcrumbs > li:last-child > span');
      if (this.fullscreen) {
        this.toggleFullscreen();
      }
      if (headerTitle && breadcrumb) {
        headerTitle.innerHTML = '銘柄スクリーニング<br>（条件検索）';
        breadcrumb.innerHTML = '銘柄スクリーニング（条件検索）';
      }
      if (this.$store.getters['stockSearchInput/statusCode'] === 403) {
        location.search = '';
        return false;
      }

      history.pushState(null, null, generateQueryParams({ _this: this, view: null, paginationOnly: false }));
      this.$store.commit('stockSearchInput/setView', '');
      window.scrollTo(0, 0);
    },
    /**
     * プレミアム誘導モーダルの表示
     */
    async premiumModalOpen() {
      this.$store.commit('premiumModal/show');
      this.$nextTick(function () {
        //ページ遷移後にコールバックURLが書きかわらないので後読みで書き換える
        this.rewriteCallbackUrl(document.querySelector('.modal-body .login-link'), 'login');
        this.rewriteCallbackUrl(document.querySelector('.modal-body .payment-profiles-link'), 'sign_up');
        this.rewriteCallbackUrl(document.querySelector('.modal-body .premium-lp-link'), 'premium_lp');
      });
    },
    /**
     * ログインモーダルの表示
     */
    async loginModalOpen() {
      this.$store.commit('favoriteSignUpModal/show');
      this.$nextTick(function () {
        //ページ遷移後にコールバックURLが書きかわらないので後読みで書き換える
        this.rewriteCallbackUrl(document.querySelector('.modal-container .login-link'), 'login');
        this.rewriteCallbackUrl(document.querySelector('.modal-container .sign-up-link'), 'sign_up');
      });
    },
    /**
     * 検索
     */
    async onSearch() {
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: false })
      );
      history.pushState(null, null, generateQueryParams({ _this: this, view: 'result', paginationOnly: false }));
    },
    /**
     * ソート
     * @param {string} itemName
     * @param {boolean} isPremiumItem
     */
    onSort(itemName, isPremiumItem) {
      if (isPremiumItem && !this.isPremiumUser) {
        return;
      }
      let order = 'desc';
      let sortKeyName = itemName;
      if (this.currentSorted(itemName)) {
        order = this.currentOrder === 'desc' ? 'asc' : 'desc';
        // ascからクリックされたら初期化
        if (this.currentOrderAsc()) {
          sortKeyName = 'favorite_count';
        }
      }
      this.$store.commit('stockSearchInput/setSortKey', sortKeyName);
      this.$store.commit('stockSearchInput/setOrder', order);
      this.onSearch();
    },
    /**
     * 引数の項目が現在のソート項目か返す
     * @param {string} itemName
     */
    currentSorted(itemName) {
      if (this.currentSortKey === itemName) {
        return true;
      }
      return false;
    },
    /**
     * 現在のオーダー順がASCかどうか返す
     */
    currentOrderAsc() {
      if (this.currentOrder === 'asc') {
        return true;
      }
      return false;
    },
    /**
     * 検索中の条件を日本語で返す
     */
    buildSearchingParams() {
      let paramsString = '';
      const paramsList = this.$store.getters['stockSearchInput/criteria'].reduce((hash, key) => {
        const name = ITEM_NAME[key];
        if (this.$store.getters['stockSearchInput/list'](key)) {
          hash[name] = this.$store.getters['stockSearchInput/list'](key);
        }
        return hash;
      }, {});
      for (const [key, val] of Object.entries(paramsList)) {
        if (paramsList[key].value) {
          // 検索条件がスライダーの項目のパラメータを日本語にして画面表示用の変数に追加
          let valueString = this.buildSliderParamString(val.value, val.range, val.unit, val.name);
          paramsString += `${key} : ${valueString} ・`;
        } else if (paramsList[key].checked) {
          // 検索条件がチェックボックスの項目のパラメータを日本語にして画面表示用の変数に追加
          let valueString = '';
          if (paramsList[key].name !== 'yutai_exist') {
            valueString += val.checked
              .map((e) => {
                if (paramsList[key].name === 'exchange_sections' && e === '0') {
                  return;
                }
                // 業種コードが4桁以外の場合は追加しない
                if (paramsList[key].name === 'industry_sectors' && e.length !== 4) {
                  return;
                }
                const fn = paramsList[key].name.replace(/_./g, (s) => s.charAt(1).toUpperCase()) + 'String';
                return this[fn](e) + ' ';
              })
              .join('');
          }
          if (paramsList[key].name === 'yutai_exist') {
            for (const check of val.checked) {
              valueString += check === '1' ? '有 ' : '無 ';
            }
          }
          const checkParamString = valueString ? `${key} : ${valueString}・` : '';
          paramsString += checkParamString;
        }
      }
      return paramsString.slice(0, -2);
    },
    /**
     * スライダーの選択範囲を日本語で返す
     * @param {array} value 選択範囲 ex:[0, 5]
     * @param {array} range デフォルトの範囲 ex:[0, 10]
     * @param {string} unit 単位 ex:'円'
     * @param {string} name スライダー名 ex:'minimum_purchase_price'
     */
    buildSliderParamString(value, range, unit, name) {
      let firstString = '';
      let lastString = '';
      if (['listing_date', 'consecutive_increased_dividend_count'].includes(name)) {
        firstString = `${value[0]}${unit}〜`;
        lastString = `${value[1]}${unit}`;
      } else if (value[0] == range[0] && value[1] == range[1]) {
        lastString = '指定なし';
      } else if (value[0] == range[0]) {
        lastString = `${Number(value[1]).toLocaleString()}${unit}以下`;
      } else if (value[1] == range[1]) {
        lastString = `${Number(value[0]).toLocaleString()}${unit}以上`;
      } else {
        firstString = `${Number(value[0]).toLocaleString()}${unit}〜`;
        lastString = `${Number(value[1]).toLocaleString()}${unit}`;
      }
      return `${firstString}${lastString}`;
    },
    exchangeSectionsString(value) {
      // 0は東証全体だが、市場区分ではないので空文字(呼び出し側制御)
      return ['', '東証プライム', '東証スタンダード', '東証グロース', '東証投資信託', 'その他'][value];
    },
    settlementEvaluationsString(value) {
      return ['なし', '雷', '雨', '曇り', '曇り時々晴', '晴'][value];
    },
    industrySectorsString(value) {
      const industrySectors = {
        '0050': '水産・農林業',
        '1050': '鉱業',
        '2050': '建設業',
        '3050': '食料品',
        '3100': '繊維製品',
        '3150': 'パルプ・紙',
        '3200': '化学',
        '3250': '医薬品',
        '3300': '石油・石炭製品',
        '3350': 'ゴム製品',
        '3400': 'ガラス・土石製品',
        '3450': '鉄鋼',
        '3500': '非鉄金属',
        '3550': '金属製品',
        '3600': '機械',
        '3650': '電気機器',
        '3700': '輸送用機器',
        '3750': '精密機器',
        '3800': 'その他製品',
        '4050': '電気・ガス業',
        '5050': '陸運業',
        '5100': '海運業',
        '5150': '空運業',
        '5200': '倉庫・運輸関連業',
        '5250': '情報・通信業',
        '6050': '卸売業',
        '6100': '小売業',
        '7050': '銀行業',
        '7100': '証券、商品先物取引業',
        '7150': '保険業',
        '7200': 'その他金融業',
        '8050': '不動産業',
        '9050': 'サービス業',
        '9997': 'ETF',
        '9998': 'REIT',
        '9992': 'ETN',
        '9999': 'その他',
      };
      return industrySectors[value];
    },
    minkabuRatingsString(value) {
      return ['', '買い', '売り', '対象外'][value];
    },
    analystRatingsString(value) {
      return ['', '強気買い', '買い', '中立', '売り', '強気売り', '対象外'][value];
    },
    userRatingsString(value) {
      return ['', '買い', '売り', '対象外'][value];
    },
    researchRatingsString(value) {
      return ['', '割高', '割安', '妥当', '分析中', '対象外'][value];
    },
    /**
     * フルスクリーンモードを切り替える
     */
    toggleFullscreen() {
      if (!this.fullscreen) {
        document.querySelector('body').classList.add('ui-drawer-scroll-stop');
      } else {
        document.querySelector('body').classList.remove('ui-drawer-scroll-stop');
      }
      this.scroll.window.y = 0;
      this.$store.commit('stockSearchInput/toggleFullscreen');
    },
    /**
     * ESCキーでフルスクリーンモードを解除する
     */
    escFullscreen() {
      const _this = this;
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Escape' && _this.fullscreen) {
          _this.toggleFullscreen();
        }
      });
    },
    /**
     * 銘柄比較モードを切り替える
     * @param {boolean} edit 編集モードかどうか
     */
    changeCompareEdit(edit = false) {
      if (!this.isPremiumUser) {
        return;
      }

      // 変更されたらスクロール位置をトップに戻す
      if (JSON.stringify(this.compare.old) !== JSON.stringify(this.compare.checked.sort())) {
        window.scrollTo(0, 0);
      }
      this.compare.edit = edit;
      this.compare.old = this.compare.checked.sort();
    },
    /**
     * 銘柄比較をリセット
     */
    resetCompare() {
      this.compare.checked = [];
    },
    /**
     * 銘柄サマリーを表示
     * @param {string} code 銘柄コード
     */
    async showSummary(code) {
      this.$store.commit('stockSearchInput/setSummaryCode', code);
      await this.$store.dispatch('stockSearchInput/getSummary');
      this.$store.commit('stockSearchInput/setSummaryShow', true);

      if (!this.fullscreen) {
        const body = document.querySelector('body');
        const offsetY = `-${window.pageYOffset}px`;
        body.classList.add('ui-drawer-scroll-stop');
        body.style.top = offsetY;
      }
    },
    /**
     * 条件保存モーダルを表示
     */
    showSaveModal() {
      const body = document.querySelector('body');
      const offsetY = `-${window.pageYOffset}px`;
      body.classList.add('ui-drawer-scroll-stop');
      body.style.top = offsetY;
      this.$store.commit('stockSearchInput/setSavaConditionsModalMode', 'create');
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataName', '');
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataCondition', this.paramsString);
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataUrl', location.href);
      this.$store.commit('stockSearchInput/setSavaConditionsModalShow', true);
    },
  },
};
</script>
