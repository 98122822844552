<template src="./TopSlider.html"></template>
<script>
import Swiper, { Pagination, Autoplay } from 'swiper';

/**
 * おすすめ銘柄トップ 注目コンテンツスライダー
 * @module Discover/modules/TopSlider {TopSlider}
 */
export default {
  name: 'TopSlider',
  mounted() {
    new Swiper(this.$refs.discoverTopSwiper, {
      modules: [Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      slidesPerView: 'auto',
      loopedSlides: 20,
      centeredSlides: true,
    });
  },
};
</script>
