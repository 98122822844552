import dayjs from 'dayjs';

class StockChartPrice {
  constructor(time, close = null, ma25 = null, ma75 = null) {
    this.time = time;
    this.close = close;
    this.ma25 = ma25;
    this.ma75 = ma75;
  }
}

/** @class */
class StockChart {
  /**
   * 日中足
   *
   * @param {Object} data 日足データ
   * @constructor
   */
  constructor(data) {
    this.resourceData = data;
  }

  /**
   * APIから取得した株価オブジェクトのリスト
   * @returns {Array<StockChartPrice>}
   */
  _resourcePrices() {
    if (!this.resourcePrices) {
      this.resourcePrices = this.resourceData.items.map(
        (p) => new StockChartPrice(dayjs(p[0]).valueOf(), p[1], p[2], p[3])
      );
    }

    return this.resourcePrices;
  }

  /**
   * APIから取得した株価の一番古い日付
   */
  _oldestPricedDate() {
    return Math.min(...this._resourcePrices().map((p) => p.time));
  }

  /**
   * 5年分の日足(time, open, high, low)
   * APIに5年分の足がない場合は、空のデータを入れている
   * @return {Array<Array>}
   */
  _dailyPrices() {
    if (!this.dailyPrices) {
      let prices = [];
      const dummyStartDay = dayjs().subtract(5, 'year');
      const dummyEndDay = dayjs(this._oldestPricedDate());
      const dayDiff = dummyEndDay.diff(dummyStartDay, 'day');

      for (let i = 0; i < dayDiff; i++) {
        prices.push(new StockChartPrice(dummyStartDay.add(i, 'day').valueOf()));
      }

      this.dailyPrices = prices.concat(this._resourcePrices());
    }
    return this.dailyPrices;
  }

  /**
   * 週最後の日の足をまとめたデータ
   * closeしか正しくなく、厳密には週足では無い
   * @returns {Array<Array>}
   */
  _weeklyPrices() {
    if (!this.weeklyPrices) {
      let pricesMap = {};
      this._dailyPrices().forEach((dailyPrice) => {
        const key = dayjs(dailyPrice.time).day(1).format('YYYY-MM-DD'); // その週を特定できる1日
        if (pricesMap[key]) {
          pricesMap[key].push(dailyPrice);
        } else {
          pricesMap[key] = [dailyPrice];
        }
      });
      this.weeklyPrices = this._toPrices(pricesMap);
    }
    return this.weeklyPrices;
  }

  /**
   * 月最後の日の足をまとめたデータ
   * closeしか正しくなく、厳密には月足では無い
   * @returns {Array<Array>}
   */
  _monthlyPrices() {
    if (!this.monthlyPrices) {
      let pricesMap = {};
      this._dailyPrices().forEach((dailyPrice) => {
        const key = dayjs(dailyPrice.time).format('YYYY-MM');
        if (pricesMap[key]) {
          pricesMap[key].push(dailyPrice);
        } else {
          pricesMap[key] = [dailyPrice];
        }
      });
      this.monthlyPrices = this._toPrices(pricesMap);
    }
    return this.monthlyPrices;
  }

  /**
   * 特定のグルーピングをされた株価オブジェクトのグループの中の一番日付が新しいものの配列
   * 配列を日付順でソート
   *
   * @param {Object} pricesMap
   * @returns {Array<StockChartPrice>}
   */
  _toPrices(pricesMap) {
    let prices = [];
    for (const k in pricesMap) {
      const rangedPrices = pricesMap[k];
      const lastPrice = rangedPrices[rangedPrices.length - 1]; // pricesMap[key]は日時でソート済の前提
      prices.push(lastPrice);
    }
    return prices.sort((a, b) => a.time - b.time);
  }

  /**
   * チャート用に整形した足データ
   * @returns {Array<Array>}
   */
  chartData(barType) {
    switch (barType) {
      case 'monthly':
        return this._monthlyChartData();
      case 'weekly':
        return this._weeklyChartData();
      case 'daily':
      default:
        return this._dailyChartData();
    }
  }

  /**
   * チャート用に整形した日足のデータ
   * @returns {Array<Array>}
   */
  _dailyChartData() {
    return this._dailyPrices().map((e) => [e.time, e.close]);
  }

  /**
   * チャート用に整形した週足のデータ
   * @returns {Array<Array>}
   */
  _weeklyChartData() {
    return this._weeklyPrices().map((e) => [e.time, e.close]);
  }

  /**
   * チャート用に整形した月足のデータ
   * @returns {Array<Array>}
   */
  _monthlyChartData() {
    return this._monthlyPrices().map((e) => [e.time, e.close]);
  }

  /**
   * 移動平均線
   */
  movingAverage(barType, period) {
    switch (barType) {
      case 'monthly':
        return this._monthlyMovingAverage(period);
      case 'weekly':
        return this._weeklyMovingAverage(period);
      case 'daily':
      default:
        return this._dailyMovingAverage(period);
    }
  }

  /**
   * 移動平均線
   *
   * @param {Number} period N個のデータによる移動平均線を返す
   * @return {Array}
   */
  _dailyMovingAverage(period) {
    return this._dailyPrices().map((e) => [e.time, period === 25 ? e.ma25 : e.ma75]);
  }

  /**
   * 移動平均線(週足)
   *
   * @param {Number} period N個のデータによる移動平均線(週足)を返す
   * @return {Array}
   */
  _weeklyMovingAverage(period) {
    return this._weeklyPrices().map((e) => [e.time, period === 25 ? e.ma25 : e.ma75]);
  }

  /**
   * 移動平均線(月足)
   *
   * @param {Number} period N個のデータによる移動平均線(月足)を返す
   * @return {Array}
   */
  _monthlyMovingAverage(period) {
    return this._monthlyPrices().map((e) => [e.time, period === 25 ? e.ma25 : e.ma75]);
  }

  /**
   * チャートラベル用最終日付データ
   * @return {String}
   */
  lastDateData() {
    return dayjs(this._dailyPrices()[this._dailyPrices().length - 1].time).format('YYYY-MM-DD');
  }
}
export default StockChart;
