<template src="./SaveConditions.html" />
<script>
/**
 * 銘柄スクリーニング 保存条件
 * @module StockSearch/modules/save/SaveConditions
 */
import axios from 'axios';
import UrlGenerateUtil from '../../../../../utils/UrlGenerateUtil';

export default {
  name: 'SaveModal',
  props: {
    isRecommendAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      disabled: false,
      pageStartNumber: 1,
      perPage: 10,
    };
  },
  computed: {
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    saveConditions() {
      return this.$store.getters['stockSearchInput/saveConditions'];
    },
    /**
     * 保存条件のリストを1ページ分返す
     */
    list() {
      const start = this.pageStartNumber - 1;
      const end = start + this.perPage;
      return this.saveConditions.list.slice(start, end);
    },
    listCount() {
      return this.saveConditions.list.length;
    },
    page() {
      return this.saveConditions.page;
    },
    /**
     * 表示中の件数の後ろを返す
     * Ex) 1〜10件表示 ←10の部分
     */
    pageLastNumber() {
      return Math.min(this.pageStartNumber + this.perPage - 1, this.listCount);
    },
    hasPrev() {
      return this.pageStartNumber > 1;
    },
    hasNext() {
      return this.pageStartNumber + this.perPage - 1 < this.listCount;
    },
    /**
     * プレミアム登録とログインのコールバックURLを返す
     */ callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        if (path === 'premium_lp') {
          return UrlGenerateUtil.premiumPath({
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'save_condition',
            campaign: '/stock/search',
          });
        } else {
          return UrlGenerateUtil.idMinkabuUrl({
            pathString: path,
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'save_condition',
            campaign: '/stock/search',
          });
        }
      };
    },
    axiosOptions() {
      return {
        headers: {
          'ContentType': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch('stockSearchInput/getSaveCondition');
      if (!this.isRecommendAvailable) {
        this.changeTab('saveCondition');
      }
    },
    /**
     * タブ切り替え
     * @param {string} page
     */
    changeTab(page) {
      this.$store.commit('stockSearchInput/setSavaConditionsPage', page);
    },
    /**
     * 保存条件のページを前に
     */
    prev() {
      if (this.hasPrev) {
        this.pageStartNumber -= this.perPage;
        window.scrollTo(0, 0);
      }
    },
    /**
     * 保存条件のページを次に
     */
    next() {
      if (this.hasNext) {
        this.pageStartNumber += this.perPage;
        window.scrollTo(0, 0);
      }
    },
    first() {
      if (this.hasPrev) {
        this.pageStartNumber = 1;
        window.scrollTo(0, 0);
      }
    },
    last() {
      if (!this.hasNext) {
        return;
      }
      const num = Math.floor(this.listCount / 10) * 10;
      this.pageStartNumber = num === this.listCount ? num - this.perPage + 1 : num + 1;

      window.scrollTo(0, 0);
    },
    /**
     * 保存条件を更新する
     * @param {Object} item 更新する保存条件
     */
    editCondition(item) {
      const body = document.querySelector('body');
      const offsetY = `-${window.pageYOffset}px`;
      body.classList.add('ui-drawer-scroll-stop');
      body.style.top = offsetY;
      this.$store.commit('stockSearchInput/setSavaConditionsModalMode', 'edit');
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataId', item.id);
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataName', item.name);
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataCondition', item.condition);
      this.$store.commit('stockSearchInput/setSavaConditionsEditDataUrl', item.url);
      this.$store.commit('stockSearchInput/setSavaConditionsModalShow', true);
    },

    /**
     * 条件を削除する
     * @param {Object} item 削除する保存条件
     */
    async deleteCondition(id) {
      this.disabled = true;
      const message = '保存条件を削除します。よろしいですか？';

      if (!window.confirm(message)) {
        this.disabled = false;
        return;
      }
      const status = await axios
        .delete(`/stock/search/saved_condition/${id}.json`, this.axiosOptions)
        .then((response) => response.status)
        .catch((err) => err.response.status);

      this.disabled = false;
      if (status < 200 || status >= 300) {
        window.alert('削除できませんでした。再度お試しください。');
        return;
      }
      this.$store.dispatch('stockSearchInput/getSaveCondition');
    },
  },
};
</script>
