/** @class */
class CurrentPrice {
  /**
   * 初期化処理
   * @note: 右ナビ閲覧履歴に必要な項目だけ定義している
   *
   * @param {Object} params
   * @constructor
   */
  constructor(params) {
    this.financialItemCode = params.financial_item.code;
    this.financialItemName = params.financial_item.name;
    this.formatedPrice = params.d_price;
    this.formatedChange = params.d_change;
    this.change = params.change;
  }
}
export default CurrentPrice;
