/**
 * 手数料比較用証券会社モデル
 *
 * @property {number} price 株価
 * @property {number} unit 購入株数
 * @property {number} rank ランキング順位
 * @property {string} diffWithTop 最安からの差額
 * @property {Object} security 証券会社
 * @property {number} id 証券会社ID
 * @property {string} name 証券会社名
 * @property {Object} eval_points 証券会社評価ポイント {title: string, description: string}
 * @property {number} fee 手数料
 * @property {number} unitedPrice 購入株数分の株価
 * @property {number} purchasePrice 購入金額
 * @property {string} url 比較ページの証券会社URL
 * @property {string} redirectSecuritiesUrl 証券会社サイトへのリダイレクトURL
 * @property {string} logo 証券会社ロゴ画像URL
 * @property {string} radar レーダーチャート画像URL
 */
class FeeRankingSecurity {
  /**
   * @param {Object} ranking 手数料ランキングモデル(from MK.Comparison.Fee)
   * @param {number} financialItem 金融アイテム
   * @param {number} unit 購入株数
   * @constructor
   */
  constructor(ranking, financialItem, unit = 0) {
    this._ranking = ranking;
    this._financialItem = financialItem;
    this.unit = unit !== 0 ? parseInt(unit) : financialItem.unit;
  }

  get price() {
    return this._financialItem.price;
  }

  get rank() {
    return this._ranking.number;
  }

  get diffWithTop() {
    const difference = this._ranking.diff_with_top;
    if (typeof(difference) === 'undefined') {
      return '';
    } else if (difference === 0) {
      return '(最安)';
    } else {
      return `(+${difference.toLocaleString()}円)`;
    }
  }

  get security() {
    return this._ranking.securities_company || {};
  }

  get id() {
    return this.security.id;
  }

  get name() {
    return this.security.name;
  }

  get evalPoints() {
    return this.security.eval_points;
  }

  get fee() {
    return this._ranking.fee || 0;
  }

  get unitedPrice() {
    return this.price * this.unit;
  }

  get purchasePrice() {
    return this.unitedPrice + this.fee;
  }

  get url() {
    return `//minkabu.jp/hikaku/company_info/${this._character}.html${this._queryParameter}`;
  }

  get redirectSecuritiesUrl() {
    return `//minkabu.jp/hikaku/redirect/${this._character}.html?utm_source=minkabu&utm_medium=stock_fee&utm_campaign=redirect_20181109`;
  }

  get logo() {
    return `//assets.minkabu.jp/images/fee/securities-company-logo/v1/logo_${this._character}.gif`;
  }

  get radar() {
    return `//assets.minkabu.jp/images/fee/securities-company-radar/v1/radar_${this._character}.png`;
  }

  /**
   * 証券会社比較ページのURLのためのクエリパラメータを返す
   * @return {string} 証券会社比較ページのURL用クエリパラメータ
   */
  get _queryParameter() {
    const locationPath = window.location.pathname;
    if (locationPath.match(/\/stock\/.+\/fee/) || locationPath.match(/^\/$/)) {
      return "?utm_source=minkabu&utm_medium=stock_fee&utm_campaign=fee_tab_20170421";
    } else if (locationPath.match(/\/stock\/.+\/yutai/)) {
      return "?utm_source=minkabu&utm_medium=stock_yutai&utm_campaign=yutai_tab_20170421";
    } else if (locationPath.match(/\/yutai\/column\/.+/)) {
      return "?utm_source=minkabu&utm_medium=yutai_fee&utm_campaign=yutai_201904";
    } else {
      return '';
    }
  }

  /**
   * 証券会社文字列を返す
   * @return {string} 証券会社識別子
   */
  get _character() {
    const nameMatrix = {
      '1': 'livestar',
      '2': 'gmo',
      '3': 'sbi',
      '4': 'matsui',
      '5': 'rakuten',
      '6': 'kabucom',
      '7': 'monex',
      '8': 'okasan',
      '9': 'dmm',
      '10': 'tachibana'
    };
    return nameMatrix[this.id] || 'unknown';
  }
}

export default FeeRankingSecurity;
