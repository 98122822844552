import UserLoginStatus from '../utils/UserLoginStatus';

/**
 * ログイン状態に関するMixin
 *
 * @vue-computed {boolean} isLoggedIn ログイン状態なら真
 * @vue-computed {boolean} isGuest ログインしていない状態なら真
 */
const LoginStatusMixin = {
  computed: {
    isLoggedIn() {
      return UserLoginStatus.isLoggedIn();
    },
    isGuest() {
      return !UserLoginStatus.isLoggedIn();
    },
  },
};
export default LoginStatusMixin;
