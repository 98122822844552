import DividendPayLockLink from './DividendPayLockLink.vue';
import NameModal from '../../modules/Modal/NameModal.vue';
import CampaignBanner from '../../modules/Banner/CampaignBanner.vue';

/**
 * 配当ページの有料部分ルートコンポーネント
 *
 * @module DividendPayLock
 */

const DividendPayLock = {
  el: '#v-dividend-pay-lock',
  components: {
    DividendPayLockLink,
    NameModal,
    CampaignBanner,
  },
};

export default DividendPayLock;
