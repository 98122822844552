<template src="./PickTooltip.html" />
<script>
/**
 * PickTooltip
 *
 * @vue-props {Object} pick 予想投稿情報
 * @module pages/StockAnalysis/NewsTooltip
 */
export default {
  name: 'PickTooltip',
  props: {
    pick: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    /**
     * 買い予想か否か
     * @return {Boolean}
     */
    isBuy() {
      return this.pick.position === 0;
    },
    /**
     * 金融アイテムコード
     * @return {string}
     */
    code() {
      return this.pick.stockCode;
    },
    /**
     * 予想投稿ID
     * @return {integer}
     */
    pickId() {
      return this.pick.pickId;
    },
    /**
     * 予想を投稿した会員のID
     * @return {integer}
     */
    userId() {
      return this.pick.userId;
    },
    /**
     * 予想期間の文字列
     * @return {String}
     */
    term() {
      switch (this.pick.period) {
        case 1:
          return '短期';
        case 2:
          return '中期';
        case 3:
          return '長期';

        default:
          return '---';
      }
    },
    /**
     * 予想期間レンジの文字列
     * @return {String}
     */
    termRange() {
      switch (this.pick.period) {
        case 1:
          return '(数分〜数日)';
        case 2:
          return '(数週間〜数ヶ月)';
        case 3:
          return '(数ヶ月〜数年)';

        default:
          return '---';
      }
    },
    /**
     * 予想理由の文字列(rawHTML)
     * @return {String}
     */
    reason() {
      switch (this.pick.reason) {
        case 1:
          return '業績<div class="fsm">(会社計画の修正)</div>';
        case 2:
          return 'チャート';
        case 3:
          return '配当';
        case 4:
          return 'イベント<div class="fsm">(増資・合併／買収・分割等)</div>';
        case 5:
          return 'その他';
        case 7:
          return '個人投資家の予想';
        default:
          return '---';
      }
    },
    /**
     * CSS
     * @return {Object}
     */
    styleObject() {
      const left = Math.ceil(this.pick.plotX + 40);
      const top = Math.ceil(this.pick.plotY + 0);
      return {
        top: `${top}px`,
        left: `${left}px`,
      };
    },
  },
  methods: {
    /**
     * ツールチップにマウスポインタが入った時
     */
    mouseEnter(e) {
      this.$store.commit('showPickTooltip');
    },
    /**
     * ツールチップからマウスポインタが離れた時
     */
    mouseLeave(e) {
      this.$store.commit('hidePickTooltip');
    },
  },
};
</script>
