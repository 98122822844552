import DPSDetailsChartVue from './DPSDetailsChart.vue';

/**
 * 個別銘柄の配当ページの配当額推移チャートのルートコンポーネント
 * @module Dividends/DPSDetailsChart
 */
const DPSDetailsChart = {
  el: '#v-dps-details-chart',
  components: {
    dpsDetailsChart: DPSDetailsChartVue,
  },
};

export default DPSDetailsChart;
