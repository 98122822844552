import FavoriteButtonFloating from './FavoriteButtonFloating.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';
import ReportModal from '../Modal/ReportModal.vue';
import ReportButtonFloating from './ReportButtonFloating.vue';

/**
 * 個別銘柄 ルートコンポーネント
 *
 * @vue-components {FavoriteButtonFloating} FavoriteButtonFloating お気に入りボタン
 * @vue-components {FavoriteSignUpModal} FavoriteSignUpModal お気に入りボタン用の会員登録誘導モーダル
 * @property {boolean} showModal モーダルを表示するかどうか
 * @module Stocks
 */
const Stocks = {
  el: '#v-stocks',
  components: {
    FavoriteButtonFloating,
    FavoriteSignUpModal,
    ReportButtonFloating,
    ReportModal,
  },
};
export default Stocks;
