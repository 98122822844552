import MkApolloProvider from '../../../utils/MkApolloProvider';
import PeerStocksOfInterest from './PeerStocksOfInterest.vue';

/**
 * 比較対象における注目企業一覧のテーブルルートコンポーネント
 *
 * @module StockResearch
 */

const StockResearch = {
  el: '#v-stock-research',
  apolloProvider: MkApolloProvider,
  components: {
    PeerStocksOfInterest,
  },
};

export default StockResearch;
