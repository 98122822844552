<template src="./PremiumModal.html" />
<script>
/**
 * 銘柄スクリーニング プレミアム誘導モーダルコンポーネント
 * @module StockSearch/modules/modal/PremiumModal
 */

export default {
  name: 'PremiumModal',
  props: {
    page: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    isVisible() {
      return this.$store.getters['premiumModal/isVisible'];
    },
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
  },
  mounted() {
    window.addEventListener('popstate', this.hide);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.hide);
  },
  methods: {
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      this.$store.commit('premiumModal/hide');
    },
  },
};
</script>
