<template src="./FavoriteButtonPopular.html" />
<script>
import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';

/**
 * お気に入りボタンコンポーネント
 * @vue-components {FavoriteButtonHeart} FavoriteButtonHeart お気に入りボタン（共通）
 * @module Discover/modules/FavoriteButtonHeart
 * @property {String} financialItemCode 銘柄コード
 */
export default {
  name: 'FavoriteButtonPopular',
  components: {
    FavoriteButtonHeart,
  },
  props: {
    code: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      financialItemCode: '0',
    };
  },
  computed: {
    isFinancialItemFavored() {
      return this.financialItemCode
        ? this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode)
        : false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.favoriteButton) {
        this.financialItemCode = this.$refs.favoriteButton.financialItemCode;
      }
    });
  },
  methods: {
    /**
     * モーダルの表示
     */
    async open() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
</script>
