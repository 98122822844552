<template src="./LatestPriceMovementChart.html"></template>
<script>
import {Chart} from 'highcharts-vue';

/**
 * 最新の値動きチャートコンポーネント
 *
 * @vue-prop { Array<number> } chartElements
 * @module LatestPriceMovementChart
 */
export default {
  name: 'LatestPriceMovementChart',
  props: {
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          pinchType: false,
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [2, 0, 2, 0],
          style: {
            overflow: 'visible'
          },
          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          min: Math.min.apply(null, this.chartElements['price']),
          max: Math.max.apply(null, this.chartElements['price']),
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          // 謎の横線を消すために追加
          gridLineColor: 'transparent',
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          hideDelay: 0,
          shared: true,
          padding: 0,
          positioner: function (w, h, point) {
            return { x: point.plotX - w / 2, y: point.plotY - h };
          }
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        },
        series: [{
          data: this.chartElements['price'],
          pointStart: 1
        }]
      }
    };
  },
  components: {
    highcharts: Chart
  }
};
</script>
