import PayoutRatioDistributionChartVue from './PayoutRatioDistributionChart.vue';

/**
 * 個別銘柄の配当ページの全市場配当性向分布チャートのルートコンポーネント
 * @module Dividends/AllStockPayoutRatioChart
 */
const PayoutRatioDistributionChart = {
  el: '#v-payout-ratio-distribution-chart',
  components: {
    PayoutRatioDistributionChart: PayoutRatioDistributionChartVue,
  },
};

export default PayoutRatioDistributionChart;
