<template src="./ChartComponent.html" />

<script>
import { Chart } from 'highcharts-vue';

/**
 * 銘柄検索 個別銘柄サマリーモーダル チャートコンポーネント
 * @vue-prop {String} code 銘柄コード
 * @module StockSearch/modules/stockSummaryModal/body/chart/ChartComponent
 */
export default {
  name: 'ChartComponent',
  components: {
    highcharts: Chart,
  },
  computed: {
    summaryChartData() {
      return this.$store.getters['stockSearchInput/summaryData'].chartData;
    },
    chartData() {
      return this.summaryChartData.dates.map((value, index) => [
        Date.parse(value),
        this.summaryChartData.closes[index] !== null ? Number(this.summaryChartData.closes[index]) : null,
      ]);
    },
    volumeData() {
      return this.summaryChartData.dates.map((value, index) => [
        Date.parse(value),
        this.summaryChartData.volumes[index] !== null ? Number(this.summaryChartData.volumes[index]) : null,
      ]);
    },
    maxPrice() {
      return this.chartData.map((elem) => elem[1]).reduce((a, b) => Math.max(a, b));
    },
    minPrice() {
      return this.chartData
        .map((elem) => elem[1])
        .filter((v) => v)
        .reduce((a, b) => Math.min(a, b));
    },
    chartOptions() {
      return {
        chart: {
          backgroundColor: '#F8FAFC',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            marker: {
              enabled: false,
            },
            fillOpacity: 0.25,
          },
        },
        title: {
          text: null,
        },
        time: {
          useUTC: false,
          timezone: 'Asia/Tokyo',
        },
        tooltip: {
          xDateFormat: '%Y/%m/%d',
          shadow: false,
          shared: true,
          useHTML: true,
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            tooltip: {
              // データを本物にする際に以下のコメントアウトを外す想定
              // valueDecimals: this.$store.getters['stockModalStore/decimalDigit'](this.code),
              valueDecimals: 1, // 銘柄により変える実装をする
              pointFormat:
                '<span style="display: inline-block; vertical-align: -2px; margin-top: 0.25rem; margin-right: 0.25rem; width: 1em; height: 1em; border-radius: 50%; background-color: {series.color}"></span>{series.name}:{point.y}<br>',
            },
          },
          {
            type: 'column',
            name: '出来高',
            data: this.volumeData,
            color: '#075985',
            yAxis: 1,
            tooltip: {
              pointFormat:
                '<span style="display: inline-block; vertical-align: -2px; margin-right: 0.25rem; width: 1em; height: 1em; background-color: {series.color}"></span>{series.name}:{point.y}',
            },
          },
        ],
        xAxis: {
          labels: {
            style: {
              fontSize: 10,
            },
          },
          type: 'datetime',
          tickInterval: 2 * 30 * 24 * 3600 * 1000, // 目盛り間隔を2か月単位に設定
          tickLength: 0,
          dateTimeLabelFormats: {
            month: '%y/%m',
          },
          crosshair: true,
        },
        yAxis: [
          {
            min: this.minPrice, // 値がつかないと最安が0になってしまいチャートが見辛くなる為、nullを除いた最安値を最小値として設定
            max: this.maxPrice, // チャートに余計な余白を作らない為、株価の最高値を最大値として設定
            tickAmount: 5,
            opposite: true,
            top: '5%',
            height: '75%',
            resize: {
              enabled: true,
            },
            labels: {
              style: {
                fontSize: 10,
              },
              format: '{value:,.0f}',
            },
            title: {
              text: '',
            },
            crosshair: true,
          },
          {
            min: 0,
            tickAmount: 0,
            opposite: true,
            top: '85%',
            height: '15%',
            labels: {
              enabled: false,
            },
            title: {
              text: '',
            },
            crosshair: true,
          },
        ],
      };
    },
  },
};
</script>
