<template src="./CorporateActionsWeeklyCalendar.html"></template>
<script>
import dayjs from 'dayjs';
import { QUERY_CORPORATE_ACTIONS_WEEKLY_CALENDAR } from '../../../queries/CorporateActionsWeeklyCalendarQuery';

/**
 * トップページのコーポレートアクション導線用カレンダーコンポーネント
 * @module CorporateActions/CorporateActionsWeeklyCalendar
 */
export default {
  name: 'CorporateActionsWeeklyCalendar',
  data() {
    return {
      actionNames: {
        ipo: 'IPO/新規上場',
        split: '株式分割',
        delisting: '上場廃止',
        reverseSplit: '株式併合',
      },
      actionAbbrNames: {
        ipo: '新規',
        split: '分割',
        delisting: '廃止',
        reverseSplit: '併合',
      },
      actionType: 'ipo', // default
      today: dayjs(),
      baseDate: null, // カレンダー表示基準日
      corporateActionsWeeklyCalendar: {
        days: [],
        actions: [],
        calendarPeriod: {
          from: null,
          to: null,
        },
      },
    };
  },
  computed: {
    actionName() {
      return this.actionNames[this.actionType];
    },
    actionAbbrName() {
      return this.actionAbbrNames[this.actionType];
    },
    days() {
      return this.corporateActionsWeeklyCalendar.days;
    },
    actions() {
      return this.corporateActionsWeeklyCalendar.actions;
    },
    isCurrentWeek() {
      return this.baseDate && this.baseDate.isSame(this.today);
    },
    calendarDays() {
      return this.days.map((day) => {
        const action = this.actions.find((action) => action.date == day.date);
        const dayjsDate = dayjs(day.date);
        return {
          day: dayjsDate.date(),
          date: dayjsDate,
          color: this.color(dayjsDate, day.isOpen),
          isOpen: day.isOpen,
          isToday: dayjsDate.isSame(this.today, 'day'),
          count: action ? action.count : 0,
          href: this.href(this.actionType, dayjsDate),
        };
      });
    },
    /**
     * カレンダー表示期間の開始日を含む週の日曜日
     */
    calendarPeriodFrom() {
      const from = this.corporateActionsWeeklyCalendar.calendarPeriod.from;
      return from ? dayjs(from).startOf('week') : null;
    },
    /**
     * カレンダー表示期間の終了日を含む週の土曜日
     */
    calendarPeriodTo() {
      const to = this.corporateActionsWeeklyCalendar.calendarPeriod.to;
      return to ? dayjs(to).endOf('week') : null;
    },
    /**
     * 前の週が存在するか
     * 現在のカレンダー表示基準日の前週がカレンダー表示期間の開始日(日曜日)より後ならtrue
     */
    hasPrevWeek() {
      return this.baseDate && this.calendarPeriodFrom && this.baseDate.subtract(1, 'week').isAfter(this.calendarPeriodFrom);
    },
    /**
     * 次の週が存在するか
     * 現在のカレンダー表示基準日の翌週がカレンダー表示期間の終了日(土曜日)より前ならtrue
     */
    hasNextWeek() {
      return this.baseDate && this.calendarPeriodTo && this.baseDate.add(1, 'week').isBefore(this.calendarPeriodTo);
    },
    weekFrom() {
      return this.calendarDays.length > 0 ? this.calendarDays[0].date.format('MM/DD') : '--/--';
    },
    weekTo() {
      return this.calendarDays.length > 0 ? this.calendarDays[this.calendarDays.length - 1].date.format('MM/DD') : '--/--';
    },
    btnText() {
      switch(this.actionType) {
        case 'ipo':
          return 'IPO・新規上場スケジュール';
        case 'split':
          return '株式分割予定銘柄一覧';
        case 'delisting':
          return '上場廃止予定銘柄一覧';
        case 'reverseSplit':
          return '株式併合予定銘柄一覧';
      }
    },
    btnLink() {
      switch(this.actionType) {
        case 'ipo':
          return '/ipo';
        case 'split':
          return '/stock/split';
        case 'delisting':
          return '/stock/delisting';
        case 'reverseSplit':
          return '/stock/reverse_split';
      }
    },
  },
  apollo: {
    corporateActionsWeeklyCalendar: {
      query: QUERY_CORPORATE_ACTIONS_WEEKLY_CALENDAR,
      variables() {
        return {
          action: this.actionType,
          date: this.baseDate,
        };
      }
    }
  },
  mounted() {
    this.initCalendar();
    const dummy = document.querySelector('[data-js-id="corporate-actions-weekly-calendar-dummy"]');
    if (dummy) dummy.remove();
  },
  methods: {
    /**
     * カレンダーの初期化
     */
    initCalendar() {
      this.baseDate = this.today;
    },
    /**
     * 前の週の情報を表示
     */
    prevWeek() {
      this.baseDate = this.baseDate.subtract(1, 'week');
    },
    /**
     * 次の週の情報を表示
     */
    nextWeek() {
      this.baseDate = this.baseDate.add(1, 'week');
    },
    /**
     * 日付の色を返す
     * 土曜なら青、日曜なら赤、それ以外の日にisOpen==falseなら赤
     */
    color(date, isOpen) {
      if (date.day() == 6) {
        return 'text-sky-600';
      } else if (!isOpen || date.day() == 0) {
        return 'text-red-500';
      } else {
        return 'text-slate-500';
      }
    },
    /**
     * 各件数のリンク
     * IPOは月別、それ以外は日別のURLを返す
     * @param {string} action
     * @param {dayjs} date
     * @return {string}
     */
    href(action, date) {
      const YYYYMMDD = date.format('YYYYMMDD');
      switch(action) {
        case 'ipo':
          return `/ipo?month=${date.format('YYYYMM')}`;
        case 'split':
          return `/stock/split?date=${YYYYMMDD}`;
        case 'delisting':
          return `/stock/delisting?date=${YYYYMMDD}`;
        case 'reverseSplit':
          return `/stock/reverse_split?date=${YYYYMMDD}`;
      }
    },
    /**
     * アクションの切り替え
     * タブが画面内に収まるようにスクロール
     * @param {string} action
     * @return {void}
     */
    changeAction(action) {
      this.actionType = action;
      const tab = document.querySelector(`[data-action-type="${action}"]`);
      if (tab) tab.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    /**
     * アクションが現在のアクションかどうか
     * @param {string} action
     * @return {boolean}
     */
    isCurrentAction(action){
      return action == this.actionType;
    },
  }
};
</script>
