import FavoriteFinancialItemsStore from './Common/FavoriteFinancialItemsStore';

/**
 * ワイドコンテンツヘッダー ルートストア
 *
 * @module MinkabuWideContentHeaderStore
 */
const MinkabuWideContentHeaderStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore
  }
};
export default MinkabuWideContentHeaderStore;
