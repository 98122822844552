/** @class */
class MkIntradayStockChart {
  /**
   * 日中足チャートで表示する二日分の日中足チャートデータ
   *
   * @param {String} currentDayChart 最新営業日の日中足チャートデータ
   * @param {String} priorDayChart 前営業日の日中足チャートデータ
   * @param {String} priorLastDay 前々営業日 (YYYY-mm-dd)
   * @constructor
   */
  constructor(currentDayChart, priorDayChart, priorLastDay) {
    this.currentDayChart = currentDayChart;
    this.priorDayChart = priorDayChart;
    this.priorLastDay = priorLastDay;
  }

  /**
   * 日中足株価チャート(二日分)
   * @returns {Array<Array>}
   */
  chartData() {
    const priorDayChartData =
      this.priorDayChart.priceSize() === 1
        ? this.convertToOnepointChartData(this.priorDayChart.chartData())
        : this.priorDayChart.chartData();
    const currentDayChartData =
      this.currentDayChart.priceSize() === 1
        ? this.convertToOnepointChartData(this.currentDayChart.chartData())
        : this.currentDayChart.chartData();
    const partitions = this.buildPartitionItems(priorDayChartData, 15);
    return priorDayChartData.concat(partitions, currentDayChartData);
  }

  /**
   * 日中足データを1点描画用のデータに変換
   * chartDataには有効な株価データが1つのみ入っている前提
   * @returns {Array<Array>}
   */
  convertToOnepointChartData(chartData) {
    let items = [];
    for (let i = 0; i < chartData.length; i++) {
      let price = chartData[i][1];
      if (price) {
        items.push({ x: chartData[i][0], y: price, marker: { symbol: 'triangle', radius: 4, enabled: true } });
      } else {
        items.push(chartData[i]);
      }
    }
    return items;
  }

  /**
   * 前日と当日のチャートの間に入れるパーティションデータを生成
   * @returns {Array<Array>}
   */
  buildPartitionItems(chartData, minutes) {
    let items = [];
    const lastTime = this.lastTime(chartData);
    for (let i = 0; i < minutes; i++) {
      let addTime = (i + 1) * 1000 * 60;
      items.push([lastTime + addTime, null]);
    }
    return items;
  }

  /**
   * chartDataの最終時刻を取得
   * @returns {Integer}
   */
  lastTime(chartData) {
    let lastItem = chartData[chartData.length - 1];
    return lastItem.x || lastItem[0];
  }

  /**
   * 前日終値用チャート
   * @returns {Array<Array>}
   */
  currentLastCloseChartData() {
    let currentDayChartData = this.currentDayChart.chartData();
    const currentLastClose = this.currentDayChart.lastPrice;

    return currentDayChartData.map((chartData) => {
      chartData[1] = currentLastClose;
      return chartData;
    });
  }

  /**
   * 前々日終値用チャート
   * @returns {Array<Array>}
   */
  priorLastCloseChartData() {
    let priorDayChartData = this.priorDayChart.chartData();
    const priorLastClose = this.priorDayChart.lastPrice;

    return priorDayChartData.map((chartData) => {
      chartData[1] = priorLastClose;
      return chartData;
    });
  }

  /**
   * 当日の終値時刻
   * @returns {Date}
   */
  currentClosedAt() {
    return this.currentDayChart.closedAt();
  }

  /**
   * 全ての株価
   * @returns {Array<Number>}
   */
  allPrices() {
    let arr = [];
    arr.push(...this.priorDayChart.prices.map((price) => price[1]));
    arr.push(...this.currentDayChart.prices.map((price) => price[1]));
    if (this.priorDayChart.lastPrice) {
      arr.push(this.priorDayChart.lastPrice);
    }
    if (this.currentDayChart.lastPrice) {
      arr.push(this.currentDayChart.lastPrice);
    }
    return arr;
  }
}
export default MkIntradayStockChart;
