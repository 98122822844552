import dayjs from 'dayjs';

/** @class */
class FxChartPrice {
  constructor(time, close) {
    this.time = time;
    this.close = close;
  }
}
class HistoricalFxChart {
  /**
   * 日中足
   *
   * @param {Object} data
   * @note {
   *         items: [
   *                  [String, float],
   *                  [],...
   *                ]
   *         updated_at: String,
   *         currency_pair: String
   *       }
   * @constructor
   */
  constructor(data) {
    this.prices = data.items.map((p) => {
      return new FxChartPrice(dayjs(p[0]).valueOf(), p[1]);
    });
  }

  /**
   * チャート用に整形した日中足のデータ
   * @param {string} barType 日足の型(monthly/weekly/daily)
   * @returns {Array<Array>}
   */
  chartData(barType) {
    switch (barType) {
      case 'monthly':
        return this._monthlyChartData();
      case 'weekly':
        return this._weeklyChartData();
      case 'daily':
      default:
        return this._dailyChartData();
    }
  }

  /**
   * チャートラベル用最終日付データ
   * @return {String}
   */
  lastDateData() {
    return dayjs(this._dailyPrices()[this._dailyPrices().length - 1].time).format('YYYY-MM-DD');
  }

  _dailyPrices() {
    return this.prices;
  }

  _weeklyPrices() {
    if (!this.weeklyPrices) {
      let pricesMap = {};
      this._dailyPrices().forEach((dailyPrice) => {
        const key = dayjs(dailyPrice.time).day(1).format('YYYY-MM-DD'); // その週を特定できる1日
        if (pricesMap[key]) {
          pricesMap[key].push(dailyPrice);
        } else {
          pricesMap[key] = [dailyPrice];
        }
      });
      this.weeklyPrices = this._toPrices(pricesMap);
    }
    return this.weeklyPrices;
  }

  _monthlyPrices() {
    if (!this.monthlyPrices) {
      let pricesMap = {};
      this._dailyPrices().forEach((dailyPrice) => {
        const key = dayjs(dailyPrice.time).format('YYYY-MM');
        if (pricesMap[key]) {
          pricesMap[key].push(dailyPrice);
        } else {
          pricesMap[key] = [dailyPrice];
        }
      });
      this.monthlyPrices = this._toPrices(pricesMap);
    }
    return this.monthlyPrices;
  }

  /**
   * 特定のグルーピングをされた株価オブジェクトのグループの中の一番日付が新しいものの配列
   * 配列を日付順でソート
   */
  _toPrices(pricesMap) {
    let prices = [];
    for (const k in pricesMap) {
      const rangedPrices = pricesMap[k];
      const lastPrice = rangedPrices[rangedPrices.length - 1]; // pricesMap[key]は日時でソート済の前提
      prices.push(lastPrice);
    }
    return prices.sort((a, b) => a.time - b.time);
  }

  _dailyChartData() {
    return this._dailyPrices().map((e) => [e.time, e.close]);
  }

  _weeklyChartData() {
    return this._weeklyPrices().map((e) => [e.time, e.close]);
  }

  _monthlyChartData() {
    return this._monthlyPrices().map((e) => [e.time, e.close]);
  }
}
export default HistoricalFxChart;
