<template src="./ChartComponent.html" />

<script>
import { Chart } from 'highcharts-vue';

/**
 * お気に入り銘柄 チャート
 * @vue-prop {String} code 銘柄コード
 * @module Mypage/modules/chart/AccordionChart
 */
export default {
  name: 'ChartComponent',
  components: {
    highcharts: Chart,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartData() {
      return this.$store.getters['favoriteFinancialItemDetails/chartData'](this.code);
    },
    isShowChart() {
      // 値が一度もついてない時（上場前とか）に false になる想定
      return this.chartData.map((elem) => elem[1]).some((elem) => elem);
    },
    chartOptions() {
      const marginTop = this.isSp ? 8 : undefined;
      const marginBottom = this.isSp ? 20 : 24;

      return {
        chart: {
          backgroundColor: null,
          type: 'area',
          marginTop,
          marginBottom,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                enabled: false,
              },
            },
            marker: {
              enabled: false,
            },
            fillOpacity: 0.25,
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver',
          },
        },
        series: [
          {
            name: '株価',
            data: this.chartData,
            tooltip: {
              valueDecimals: this.$store.getters['favoriteFinancialItemDetails/decimalDigit'](this.code),
            },
          },
        ],
        type: 'area', // チャート領域を青く塗りつぶす設定
        title: {
          text: null,
        },
        time: {
          useUTC: false,
          timezone: 'Asia/Tokyo',
        },
        tooltip: {
          xDateFormat: '%Y/%m/%d',
        },
        xAxis: {
          labels: {
            style: {
              fontSize: 10,
            },
          },
          type: 'datetime',
          tickInterval: 2 * 30 * 24 * 3600 * 1000, // 目盛り間隔を2か月単位に設定
          tickLength: 0,
          dateTimeLabelFormats: {
            month: '%y/%m',
          },
        },
        yAxis: {
          min: Math.min(...this.chartData.map((elem) => elem[1]).filter((v) => v)), // nullを除いた最安値を最小値として設定
          max: Math.max(...this.chartData.map((elem) => elem[1])), // 株価の最高値を最大値として設定
          tickAmount: 4,
          opposite: true,
          labels: {
            style: {
              fontSize: 10,
            },
            x: 2,
            format: '{value:,.0f}',
          },
          title: {
            text: null,
          },
        },
      };
    },
  },
};
</script>
