import KeywordNewsListComponent from './KeywordNewsList.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';


/*
 * キーワードニュース一覧ルートコンポーネント
 */
const KeywordNewsList = {
  el: '#v-keyword-news-list',
  apolloProvider: MkApolloProvider,
  components: {
    KeywordNewsList: KeywordNewsListComponent,
  },
  methods: {
  },
};

export default KeywordNewsList;
