<template src="./FavoriteCount.html"></template>
<script>
/**
 * お気に入り数 コンポーネント
 * @vue-prop {string} financialItemCode 金融アイテムコード
 * @vue-prop {number} favoriteTotalCount お気に入り総数
 * @vue-computed {boolean} isFinancialItemFavored お気に入り登録している金融アイテムなら真
 * @module Common/FavoriteCount
 * @description
 *   お気に入り状態では決め打ちでfavoriteCount+1（FavoriteCount.htmlでしています）
 *   お気に入り登録した時点からお気に入り数の集計までの間の違和感を感じさせないための措置です
 *   違和感の例: お気に入り数0の金融アイテムをお気に入り登録した後リロードしたら0のまま
 */
export default {
  name: 'FavoriteCount',
  props: {
    financialItemCode: {
      type: String,
      required: true
    },
    favoriteTotalCount: {
      type: Number,
      required: true,
      validator: function (value) {
        return value >= 0;
      }
    }
  },
  computed: {
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode);
    }
  },
};
</script>
