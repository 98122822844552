<template src="./FavoriteButtonFloating.html" />
<script>
import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';
import FavoriteButtonHeartWithCount from './FavoriteButtonHeartWithCount.vue';
import MkIntersectionObserver from '../../../utils/MkIntersectionObserver';

/**
 * 個別銘柄フローティングお気に入りボタンコンポーネント
 * @vue-components {FavoriteButtonHeart} FavoriteButtonHeart お気に入りボタン
 * @module Stocks/FavoriteButtonFloating
 * @property {String} financialItemCode 銘柄コード
 */
export default {
  name: 'FavoriteButtonFloating',
  components: {
    FavoriteButtonHeart,
    FavoriteButtonHeartWithCount,
  },
  props: {
    code: {
      type: String,
      default: '',
      required: true,
    },
    favoriteCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      financialItemCode: null,
      /**
       * お気に入りボタンの表示状態
       * @type {boolean}
       */
      isShow: false,
    };
  },
  computed: {
    isFinancialItemFavored() {
      return this.financialItemCode
        ? this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode)
        : false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.favoriteButton) {
        this.financialItemCode = this.$refs.favoriteButton.financialItemCode;
      }
    });
    this.handleNotIntersection();
  },
  methods: {
    /**
     * モーダルの表示
     */
    async open() {
      this.$store.commit('favoriteSignUpModal/show');
    },
    /**
     * 監視対象の要素が表示されてない場合にお気に入りボタンを表示する
     * @returns {void}
     */
    handleNotIntersection() {
      const callback = (entries, observer) => {
        if (!entries[0].isIntersecting) {
          this.isShow = true;
          observer.disconnect();
        }
      };

      const observer = new MkIntersectionObserver(callback);
      const targetElement = document.getElementById('breadcrumbs');
      observer.observe(targetElement);
    },
  },
};
</script>
