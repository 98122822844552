import LatestPriceMovementChart from './Common/LatestPriceMovementChart.vue';
import FavoriteButtonHeart from './Common/FavoriteButtonHeart.vue';
import FavoriteCount from './Common/FavoriteCount.vue';
import FavoriteButtonWithCount from './Common/FavoriteButtonWithCount.vue';
import BaseModal from './Common/BaseModal.vue';
import TopSlider from './Discover/modules/TopSlider.vue';

/**
 * ワイドコンテンツヘッダー ルートコンポーネント
 *
 * @vue-components {LatestPriceMovementChart} latestPriceMovementChart 最新の値動きチャート
 * @vue-components {FavoriteButtonHeart} FavoriteButtonHeart お気に入りハートボタン
 * @vue-components {FavoriteCount} FavoriteCount お気に入り数
 * @vue-components {FavoriteButtonWithCount} FavoriteButtonWithCount お気に入り数付きお気に入りボタン
 * @vue-components {BaseModal} BaseModal モーダル表示
 * @vue-components {TopSlider} TopSlider おすすめ銘柄コンテンツスライダー
 * @property {boolean} showModal モーダルを表示するかどうか
 * @property {boolean} isTailwindPage Tailwindでスタイルを適用しているページかどうか
 * @property {array} classArrayTemplate divタグに付けるclassを持つ配列のベース
 * @module MinkabuWideContentHeader
 */
const MinkabuWideContentHeader = {
  el: '#v-minkabu-wide-content-header',
  components: {
    LatestPriceMovementChart: LatestPriceMovementChart,
    FavoriteButtonHeart: FavoriteButtonHeart,
    FavoriteCount: FavoriteCount,
    FavoriteButtonWithCount: FavoriteButtonWithCount,
    BaseModal: BaseModal,
    TopSlider,
  },
  data() {
    return {
      // 初期化
      showModal: false,
      isTailwindPage: true,
      classArrayTemplate: [
        [
          'relative',
          'mt-0.5',
          'text-neutral-500',
          'block',
          'w-40',
          'pt-2.5',
          'bg-white',
          'border',
          'border-gray-200',
          'border-t-0',
          'rounded-b-lg',
          'shadow-md',
          'cursor-pointer',
        ],
        ['flex', 'justify-center', 'ui-favorite-button-click', 'ui-favorite-text-active'],
        ['relative', 'text-xss', 'text-center', 'w-7.5'],
        [
          'absolute',
          '-top-2.5',
          'right-0',
          'left-0',
          'mx-auto',
          'ui-favorite-button',
          'ui-favorite-button-normal',
          'ui-favorite-button-active',
        ],
        ['pt-3', 'leading-4'],
        [],
        ['text-xs', 'pt-0.5'],
      ],
    };
  },
};
export default MinkabuWideContentHeader;
