/**
 * minkabu.jpの外部URLを返すMixinクラス
 *
 */
const ExternalUrlMixin = {
  methods: {
    /**
     * 投信の個別ページのURLを返す
     * ex) /fund/03311187
     *
     * @param {string} code
     * @return {string}
     */
    itfFundUrl(code) {
      return `https://itf.minkabu.jp/fund/${code}`;
    },
  },
};
export default ExternalUrlMixin;
