<template src="./SettlementChartQuarterlyHistoriesLineUpSideways.html"></template>
<script>
import RoundUtil from '../../utils/RoundUtil';
import {Chart} from 'highcharts-vue';

/*
 * 四半期推移チャート(売上高・営業利益・純利益)のPC版横並び用コンポーネント
 *
 * @vue-prop {Settlements::Index::QuarterlyHistories::(Sales|OperatingIncome|NetIncome)Charts::ViewModel} chartElements
 * @module SettlementChartQuarterlyHistoriesLineUpSideways
 */
export default {
  name: 'SettlementChartQuarterlyHistoriesLineUpSideways',
  props: {
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          backgroundColor: '#ffffff',
          margin: [10, 0, 30, 58],
          height: 140
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [
            '1Q',
            '2Q',
            '3Q',
            '通期'
          ]
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return RoundUtil.roundJapaneseUnit(this.value, 1);
            },
            style: {
              fontSize: '9px'
            }
          },
          tickPositioner: function () {
            const dataAbsMax = Math.max(Math.abs(this.dataMin), Math.abs(this.dataMax));

            const first = dataAbsMax.toString(10).slice(0, 1);
            const digit = dataAbsMax.toString(10).replace(/\d/g, 0).slice(2);
            let refVal = parseInt(first + 5 + digit);
            if ( this.dataMax < 0 ) {
              return [-1.4, -0.5, 0].map(function (n){
                return refVal * n;
              });
            } else if ( this.dataMin < 0 ) {
              return [-1.4, -0.5, 0, 0.5, 1.4].map(function (n){
                return refVal * n;
              });
            } else {
              return [0, 0.4, 0.8, 1.4].map(function (n){
                return refVal * n;
              });
            }
          }
        },
        tooltip: {
          style: {
            fontSize: '10px'
          },
          formatter: function() {
            return `${this.series.name}: <b>${RoundUtil.roundJapaneseUnit(this.point.y, 1)}</b>`;
          }
        },
        series: [
          {
            name: '前々期実績',
            data: this.revertRoundedValues(this.chartElements['two_years_ago_results']),
            color: '#bbcad4',
            pointPlacement: -0.26
          },
          {
            name: '前期実績',
            data: this.revertRoundedValues(this.chartElements['a_year_ago_results']),
            color: '#95a5b1'
          },
          {
            name: '今期実績',
            data: this.revertRoundedValues(this.chartElements['current_results']),
            boderColor: '#cccccc',
            color: '#0096fa',
            pointPlacement: 0.26
          },
          {
            name: '最新会社予想',
            data: this.revertRoundedValues(this.chartElements['latest_projections']),
            pointPlacement: 0.26,
            dashStyle: 'dotonepointfive',
            borderColor: '#666666',
            borderWidth: 1.6,
            color: 'rgba(225, 225, 225, .3)'
          },
          {
            type: 'scatter',
            name: '当初会社予想',
            data: this.revertRoundedValues(this.chartElements['initial_projections']),
            pointPlacement: 0.26,
            color: '#00defa',
            marker: {
              radius: 3
            }
          },
          {
            type: 'scatter',
            name: 'アナリスト予想',
            data: this.revertRoundedValues(this.chartElements['analyst_projections']),
            pointPlacement: 0.26,
            color: '#FC9F02',
            marker: {
              symbol: 'diamond',
              radius: 4
            }
          }
        ],
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 10,
            pointPadding: -0.1,
            groupPadding: 0,
            grouping: false,
            shadow: false,
            animation: false
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  },
  methods: {
    /**
     * 配列の要素の値を100万掛けて返す
     * @param {Array<integer|null>} list
     * @return {Array<integer|null>}
     */
    revertRoundedValues: function(list) {
      return list === [] ? list : list.map(function(val) { return RoundUtil.revertRoundedMillion(val); });
    }
  }
};
</script>
