/** @class */
class ITFBaseValue {
  /**
   * 投信基準価額モデル
   * @note: 右ナビ閲覧履歴に必要な項目だけ定義している
   *
   * @param {Object} params
   * @constructor
   */
  constructor(params) {
    this.fundCode = params.fundCode;
    this.fundName = params.fundName;
    this.price = params.price;
    this.change = params.change;
  }
}
export default ITFBaseValue;
