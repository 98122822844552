<template src="./PerPbrChart.html"></template>
<script>
import { Chart } from 'highcharts-vue';
/**
 * 比較対象におけるPER/PBRチャートコンポーネント
 * @module Pages/StockResearch/PerPbrChart
 */
export default {
  name: 'PerPbrChart',
  components: {
    highcharts: Chart,
  },
  props: {
    /**
     * 現在表示している銘柄
     * @type {Object}
     */
    currentStock: {
      type: Object,
      required: true,
    },
    /**
     * 比較対象の銘柄のリスト
     * @type {Array}
     */
    peerStocks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * そのページの銘柄のチャート用データ
     * @return {Array}
     */
    dataCurrentStock() {
      return [
        {
          x: this.currentStock.pbr,
          y: this.currentStock.per,
          z: 1.5,
          name: this.currentStock.nameAbbr,
          code: this.currentStock.code,
          color: '#dc2626',
        },
      ];
    },
    /**
     * 表示可能な銘柄のチャート用データ
     * @return {Array}
     */
    dataPeerStocks() {
      if (!this.peerStocks) return [];

      return this.peerStocks
        .filter((e) => {
          return e.financialItemValuations.per && e.financialItemValuations.pbr;
        })
        .map((e) => {
          return {
            x: e.financialItemValuations.pbr,
            y: e.financialItemValuations.per,
            z: 1.0,
            name: e.financialItem.nameAbbr,
            code: e.financialItem.code,
            color: '#4572A7',
          };
        });
    },
    /**
     * チャートを表示するか否か
     * @return {Boolean}
     */
    isDisplayChart() {
      return !!(this.currentStock.per && this.currentStock.pbr && this.dataPeerStocks.length > 0);
    },
    /**
     * 表示可能な銘柄のPERの配列
     * @return {Array}
     */
    dataPer() {
      return this.dataPeerStocks
        .map((e) => e.y)
        .filter((e) => e)
        .concat(this.currentStock.per);
    },
    /**
     * 表示可能な銘柄のPERの平均値
     * @return {Number}
     */
    perAvg() {
      return this.dataPer.reduce((a, b) => a + b) / this.dataPer.length;
    },
    /**
     * 表示可能な銘柄のPERの最大値
     * @return {Number}
     */
    perMax() {
      return Math.max(...this.dataPer);
    },
    /**
     * 表示可能な銘柄のPERの最小値
     * @return {Number}
     */
    perMin() {
      return Math.min(...this.dataPer);
    },
    /**
     * y軸の最大値
     * @return {Number}
     */
    yAxisMax() {
      const diff = this.perMax - this.perAvg;
      return this.perMax + Math.abs(diff) * 0.8;
    },
    /**
     * y軸の最小値
     * @return {Number}
     */
    yAxisMin() {
      const diff = this.perAvg - this.perMin;
      return this.perMin - Math.abs(diff) * 0.8;
    },
    /**
     * Y軸のラベルの幅
     * @return {Number}
     */
    yAxisLabelWidth() {
      const yAxisMaxWords = (Math.floor(this.yAxisMax * 10) / 10).toString().length;
      const yAxisMinWords = (Math.floor(this.yAxisMin * 10) / 10).toString().length;
      const pitch = 7.25;
      const words = yAxisMaxWords >= yAxisMinWords ? yAxisMaxWords : yAxisMinWords;

      if (words <= 3) {
        return pitch * 4;
      } else {
        return pitch * words;
      }
    },
    /**
     * 表示可能な銘柄のPBRの配列
     * @return {Array}
     */
    dataPbr() {
      return this.dataPeerStocks
        .map((e) => e.x)
        .filter((e) => e)
        .concat(this.currentStock.pbr);
    },
    /**
     * 表示可能な銘柄のPBRの平均値
     * @return {Number}
     */
    pbrAvg() {
      return this.dataPbr.reduce((a, b) => a + b) / this.dataPbr.length;
    },
    /**
     * 表示可能な銘柄のPBRの最大値
     * @return {Number}
     */
    pbrMax() {
      return Math.max(...this.dataPbr);
    },
    /**
     * 表示可能な銘柄のPBRの最小値
     * @return {Number}
     */
    pbrMin() {
      return Math.min(...this.dataPbr);
    },
    /**
     * x軸の最大値
     * @return {Number}
     */
    xAxisMax() {
      const diff = this.pbrMax - this.pbrAvg;
      return this.pbrMax + Math.abs(diff) * 0.3;
    },
    /**
     * x軸の最小値
     * @return {Number}
     */
    xAxisMin() {
      const diff = this.pbrAvg - this.pbrMin;
      return this.pbrMin - Math.abs(diff) * 0.3;
    },
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          type: 'bubble',
          plotBorderWidth: 0,
          zoomType: 'xy',
          height: '240px',
          margin: [20, 0, 35, vueThis.yAxisLabelWidth],
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          gridLineWidth: 1,
          tickInterval: vueThis.xAxisMax / 3,
          title: {
            useHTML: true,
            text: '<span class="text-xss text-slate-500">PBR(倍)</span>',
            align: 'high',
            offset: 0,
            y: 18,
          },
          labels: {
            formatter: function () {
              return vueThis.numFormat(this.value, 1);
            },
            style: {
              fontSize: 12,
            },
            y: 15,
          },
          endOnTick: false,
          max: vueThis.xAxisMax,
          min: vueThis.xAxisMin,
          tickWidth: 0,
          lineWidth: 0,
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          showFirstLabel: true,
          tickInterval: vueThis.yAxisMax / 3,
          title: {
            useHTML: true,
            text: '<span class="text-xss text-slate-500">PER(倍)</span>',
            align: 'high',
            offset: -10,
            rotation: 0,
            y: -10,
          },
          labels: {
            align: 'right',
            x: 0,
            formatter: function () {
              return vueThis.numFormat(this.value, 1);
            },
            style: {
              fontSize: 12,
            },
          },
          max: vueThis.yAxisMax,
          min: vueThis.yAxisMin,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            return `<h3>${this.point.name} (${this.point.code})</h3>
            <p>PER: <span>${vueThis.numFormat(this.point.y, 2)}倍</span></p>
            <p>PBR: <span>${vueThis.numFormat(this.point.x, 2)}倍</span></p>`;
          },
          followPointer: true,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              format: '{point.name}',
            },
          },
          item: {
            marker: {
              symbol: 'diamond',
            },
          },
        },
        series: [
          {
            data: vueThis.dataCurrentStock,
            maxSize: '22px',
            minSize: '20px',
          },
          {
            data: vueThis.dataPeerStocks,
            maxSize: '18px',
            minSize: '16px',
          },
        ],
      };
    },
  },
};
</script>
