<template src="./DeviationRateChart.html"></template>
<script>
import {Chart} from 'highcharts-vue';

/*
 * 個別銘柄チャートページの乖離率チャートコンポーネント
 *
 * @vue-prop {ChartViewModel} chartElements
 * @module DeviationRateChart
 */

export default {
  name: 'DeviationRateChart',
  props: {
    chartElements: {
      type: Object,
      default: () => {},
      required: true
    },
    baseValueName: {
      type: String,
      default: '',
      required: true
    }
  },
  mounted: function() {
    const lists = document.getElementsByClassName('period');
    lists[1].classList.add("cur");
    this.updateCharts('6month');
  },
  components: {
    highcharts: Chart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        legend: {
          align: 'center',
          itemStyle: {
            fontWeight: 'normal'
          },
          itemHoverStyle: {
            color: '#0070bf'
          },
          vertivalAlign: 'bottom'
        },
        series: [
          {
            type: 'line',
            name: '市場価格',
            data: this.chartElements['prices'],
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
          },
          {
            type: 'line',
            name: this.baseValueName,
            data: this.chartElements['base_values'],
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
          },
          {
            type: 'line',
            name: '乖離率',
            data: this.chartElements['deviation_rates'],
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
          }
        ],
        xAxis: {
          categories: this.chartElements['dates'],
          labels: {
            enabled: true,
            formatter: function () {
              return `${this.value.replace(/ (.*)/, '')}`;
            }
          },
          tickPositions: [],
          title: {
            enabled: false
          }
        },
        yAxis: [
          {
            labels: {
              formatter: function() {
                return `${Number(this.value).toLocaleString(undefined, { maximumFractionDigits: 0 })}円`;
              }
            },
            title: {
              enabled: false
            }
          }, {
            labels: {
              formatter: function() {
                return this.value.toFixed(1);
              }
            },
            title: {
              enabled: false
            },
            opposite: true
          }
        ],
        tooltip: {
          formatter: function() {
            let pricePoint = '---';
            let baseValuePoint = '---';
            let deviationRatePoint = '---';
            let baseValueName = '';

            // nullの場合はpointsにデータが渡って来ないため、基準価額以外nullの場合は配列の長さが１となる
            if (this.points.length === 1) {
              baseValuePoint = Number(this.points[0].point.y).toLocaleString(undefined, { maximumFractionDigits: 0 })
              baseValueName = this.points[0].series.name
            } else {
              pricePoint = Number(this.points[0].point.y).toLocaleString(undefined, { maximumFractionDigits: 0 })
              baseValuePoint = Number(this.points[1].point.y).toLocaleString(undefined, { maximumFractionDigits: 0 })
              deviationRatePoint = this.points[2].point.y.toFixed(2)
              baseValueName = this.points[1].series.name
            }
            return `${this.x}
              <br><span style="font-size: 15px; color: #7cb5ec">●</span>市場価格: <b>${pricePoint}円</b>
              <br><span style="font-size: 15px; color: #434348">●</span>${baseValueName}: <b>${baseValuePoint}円</b>
              <br><span style="font-size: 15px; color: #90ed7d">●</span>乖離率: <b>${deviationRatePoint}</b>
              `;
          },
          shared: true
        },
        plotOptions: {
          line: {
            marker: { enabled: false }
          },
          series: {
            events: {
              legendItemClick: function(e) {
                e.preventDefault();
              }
            }
          }
        },
        credits: {
          enabled: false
        },
      }
    };
  },
  methods: {
    updateCharts: function(period) {
      let date = new Date();
      const lists = document.getElementsByClassName('period');

      if(period === '1year') {
        date.setFullYear(date.getFullYear() - 1);
        const lastYearDate = date.getTime();
        updateChartData(lastYearDate, 365, this.chartElements, this.chartOptions, this.baseValueName);
        lists[0].classList.remove("cur");
        lists[1].classList.remove("cur");
        lists[2].classList.add("cur");
      } else if (period == '6month') {
        date.setMonth(date.getMonth() - 6);
        const sixMonthAgoDate = date.getTime();
        updateChartData(sixMonthAgoDate, 180, this.chartElements, this.chartOptions, this.baseValueName);
        lists[0].classList.remove("cur");
        lists[1].classList.add("cur");
        lists[2].classList.remove("cur");
      }else if (period == '1month') {
        date.setMonth(date.getMonth() - 1);
        const lastMonthDate = date.getTime();
        updateChartData(lastMonthDate, 30, this.chartElements, this.chartOptions, this.baseValueName);
        lists[0].classList.add("cur");
        lists[1].classList.remove("cur");
        lists[2].classList.remove("cur");
      }
    }
  }
};

function updateChartData(comparisonDate, term, elements, options, baseValueName) {
  let index = 0;
  for (let [i, v] of Object.entries(elements['dates'])) {
    let periodDate = new Date(v.slice(0, -4));
    if (periodDate.getTime() > comparisonDate && elements['prices'][i] !== null) {
      index = i;
      break;
    }
  }
  options.series[0].data = elements['prices'].slice(index);
  options.series[1].data = elements['base_values'].slice(index);
  options.series[2].data = elements['deviation_rates'].slice(index);
  const dates = elements['dates'].slice(index);
  options.xAxis.categories = dates;
  options.xAxis.tickPositions = [0, Math.round((dates.length / 2)), (dates.length - 1)];
  options.series[1].name = baseValueName;
}
</script>
