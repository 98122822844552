import BaseInputStore from '../Base/BaseInputStore';
import NewsSearchResultStore from './NewsSearchResultStore';
import NewsSearchResource from '../../resources/NewsSearchResource';
import BaseXHRStore from "../Base/BaseXHRStore";

/**
 * @property {string} apiCallStatus ニュース検索API検索状態
 * @property {NewsSearchResource} apiResource ニュース検索APIクラス
 * @module NewsSearch/NewsSearchStore
 */
const NewsSearchStore = {
  state: {
    apiResource: new NewsSearchResource()
  },
  getters: {
    /* @returns {string} 入力文字列 */
    inputValue(state) {
      return state.input.value;
    },
    /* @returns {BaseCollection}  ニュース検索結果のコレクション */
    resultCollection(state) {
      return state.result.collection;
    },
    /* @returns {Object}  ニュース検索結果ニュース一覧 */
    resultArticles(state) {
      return state.result.collection.items;
    },
    /* @returns {boolean} ニュース検索結果があるか */
    hasResult(state) {
      return state.result.collection.items.length > 0;
    },
    /* @returns {boolean} ニュースAPIがCALLされ終わったか */
    isApiCallDone(state) {
      return state.xhr.requestStatus == 'done';
    }
  },
  actions: {
    /**
     * ニュース検索の入力状態を初期化する
     *
     * @param {ActionContext} vuexContext
     */
    initInput(context) {
      context.commit('xhr/requestStatus', 'yet');
      context.commit('input/value', '');
    },
    /**
     * ニュース検索API
     *
     * @param {ActionContext} vuexContext
     * @param {Object} values { val: 入力文字列, channel: 配信元(channel)name }
     * @return {Promise<void>}
     */
    async searchNews(context, values) {
      let collection = {};
      if(values['channel'] === '') {
        collection = await context.state.apiResource.get({ q: values['val'] });
      } else {
        collection = await context.state.apiResource.get({ q: values['val'], channel: values['channel'] });
      }
      context.commit('input/value', values['val']);
      context.commit('result/collection', collection);
      context.commit('xhr/requestStatus', 'done');
    }
  },
  modules: {
    input: BaseInputStore,
    result: NewsSearchResultStore,
    xhr: BaseXHRStore
  }
};
export default NewsSearchStore;
