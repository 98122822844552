import gql from 'graphql-tag';

export const FRAGMENT_PAGE_INFO = gql`
  fragment pageInfo on PageInfo {
    offsetValue
    totalPage
    currentPage
    totalCount
    count
  }
`;
