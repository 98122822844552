<template src="./FollowButton.html"></template>
<script>
/**
 * フォローボタンコンポーネント
 * @module Follows/FollowButton
 * @vue-prop {Number} followeeId フォロー/アンフォローされる会員ID
 * @vue-prop {Array} followingIds フォローしている会員IDのリスト
 * @vue-prop {String} loginUrl 非ログイン時に遷移するURL
 */
export default {
  props: {
    followeeId: {
      type: Number,
      required: true,
    },
    followingIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    loginUrl: {
      type: String,
      required: false,
      default: '/login',
    },
  },
  mounted() {
    this.$store.commit('followButton/followingIds', this.followingIds);
  },
  computed: {
    /**
     * フォローしているかどうか
     * @return {boolean}
     */
    isFollowing() {
      return this.$store.getters['followButton/isFollowing'](this.followeeId);
    },
    /**
     * フォローボタンのテキスト
     * @return {string}
     */
    buttonText() {
      return this.isFollowing ? 'フォロー中' : 'フォロー';
    },
  },
  methods: {
    /**
     * フォロー/アンフォローを切り替える
     */
    toggleFollow() {
      if (this.isFollowing) {
        return this.$store.dispatch('followButton/unfollow', this.followeeId);
      } else {
        return this.$store.dispatch('followButton/follow', this.followeeId);
      }
    },
  },
};
</script>
