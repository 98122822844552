/**
 * 株主優待ランキングに関するMixinクラス
 *
 */
const YutaiRankingMixin = {
  methods: {
    /**
     * 権利確定月表示用
     *
     * @param {Array<string>} months 権利確定月(数字のみ)
     * @return {string} 表示用の文字列
     */
    cutoffMonths(months) {
      if (months.length === 12) return '随時';

      return months.map((month) => `${month}月`).join(',');
    },
  },
};
export default YutaiRankingMixin;
