<template src="./NewsComponent.html" />
<script>
/**
 * 銘柄検索 個別銘柄サマリーモーダル ニュースコンポーネント
 * @module StockSearch/modules/StockSummaryModal/body/news/NewsComponent
 */
export default {
  name: 'NewsComponent',
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
  },
  methods: {
    /**
     * シグナルのパターンごとにクラス名とテキストを返す
     * @param {string} title
     * @return {Array}
     */
    signalPattern(title) {
      const tgt = title.split('_to_');
      if (tgt.length !== 2) {
        return false;
      }
      let res = [];
      for (let item of tgt) {
        if (item === 'none') {
          res.push({ classObj: 'bg-minkabuPicksNone', text: '対象外' });
        } else if (item === 'buy') {
          res.push({ classObj: 'bg-minkabuPicksBuy', text: '買い' });
        } else if (item === 'sell') {
          res.push({ classObj: 'bg-minkabuPicksSell', text: '売り' });
        } else {
          return false;
        }
      }
      return res;
    },
  },
};
</script>
