import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteFinancialItemDetails from './modules/FavoriteFinancialItemDetails';

/**
 * マイページ ルートストア
 *
 * @module MyPage/MyPageStore
 */
const MyPageStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteFinancialItemDetails: FavoriteFinancialItemDetails,
  },
};
export default MyPageStore;
