/**
 * 数値フォーマットに関するMixin
 */
const NumberFormatMixin = {
  methods: {
    /**
     * 値を整形して返却する
     *
     * @param {number} number 値
     * @param {number} precision 小数点以下桁数
     * @returns {string} 整形済み値文字列
     */
    numFormat(number, precision = 0) {
      if (number === void 0 || number === null) {
        return '---';
      }

      if (number === 0) {
        if (precision === 0) {
          return '0';
        } else {
          return `0.${'0'.repeat(precision)}`;
        }
      }

      const decimal = number.toString().split('.');
      const preDecimal = new Intl.NumberFormat('ja-JP').format(decimal[0]);
      if (precision === 0) {
        return preDecimal;
      } else {
        const postDecimal = `${decimal[1] || 0}${'0'.repeat(precision)}`.substring(0, precision);
        return `${preDecimal}.${postDecimal}`;
      }
    },
    /**
     * 値を整形して返却する
     * 値がFalsyの場合、'---'を返す
     *
     * @param {number} number 値
     * @param {number} precision 小数点以下桁数
     * @returns {string} 整形済み値文字列
     */
    numFormatWithNone(number, precision = 0) {
      if (!number) {
        return '---';
      }

      const decimal = number.toString().split('.');
      const preDecimal = new Intl.NumberFormat('ja-JP').format(decimal[0]);
      if (precision === 0) {
        return preDecimal;
      } else {
        const postDecimal = `${decimal[1] || 0}${'0'.repeat(precision)}`.substring(0, precision);
        return `${preDecimal}.${postDecimal}`;
      }
    },
    /**
     * 引数が正の値の場合は'+'を返却する
     * @param {number} number
     * @return {string} 正の符号
     */
    plusSign(number) {
      if (number > 0) {
        return '+';
      } else {
        return '';
      }
    },
    /**
     * 値を整形後、正の値の場合は符号を付けて返却する
     *
     * @param {number} number 値
     * @param {number} precision 小数点以下桁数
     * @returns {string} 整形済み値文字列
     */
    numFormatWithPlusSign(number, precision = 0) {
      return `${this.plusSign(number)}${this.numFormat(number, precision)}`;
    },
    /**
     * 金額を10,000で除算して万単位にする（100,000 -> 10.0）
     * 小数点以下は一桁、切り捨て
     * @param {number} price 金額
     * @return {string} 整形済み文字列
     */
    roundPriceByTenThousand(price) {
      let roundedPrice = price * 0.0001;
      return `${Math.floor(roundedPrice * 10) / 10}`;
    },
  },
};
export default NumberFormatMixin;
