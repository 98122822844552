/**
 * XMLHttpRequestの状態
 *
 * @property {string} requestStatus リクエスト状態 [yet, done]
 * @module BaseXhrStore
 */
const BaseXHRStore = {
  namespaced: true,
  state: {
    requestStatus: 'yet'
  },
  mutations: {
    requestStatus(state, val) {
      state.requestStatus = val;
    }
  }
};
export default BaseXHRStore;
