/**
 * 名前モーダルストア
 * 同一ページ内で複数モーダルを出し分けするためnameを指定する
 * @module Modal/NameModalStore
 */
const NameModalStore = {
  namespaced: true,
  state: {
    isVisible: { adHide: false, premiumRanking: false },
  },
  getters: {
    /* @returns {boolean} モーダルが表示されるなら真 */
    isVisible(state) {
      return state.isVisible;
    },
  },
  mutations: {
    /**
     * モーダル表示
     * @returns {void}
     */
    show(state, name) {
      state.isVisible[name] = true;
    },
    /**
     * モーダル非表示
     * @returns {void}
     */
    hide(state, name) {
      state.isVisible[name] = false;
    },
  },
};
export default NameModalStore;
