/**
 * クエリパラメタを生成する
 * @param {this} _this
 * @param {String} view 表示ページ名（検索条件： '', 検索結果：'result'）
 * @param {Boolean} paginationOnly 件数取得のみは true
 * @return {String}
 */
const generateQueryParams = ({ _this, view = '', paginationOnly = false }) => {
  let params = '?';
  if (view) {
    params += `view=${view}`;
  }

  if (paginationOnly) {
    // マッチ件数のみ欲しい場合
    params += 'pagination_only=1';
  } else {
    params += `&page=${_this.$store.getters['stockSearchInput/page']}`;
    params += `&sort_key=${_this.$store.getters['stockSearchInput/sortKey']}`;
    params += `&order=${_this.$store.getters['stockSearchInput/order']}`;
  }
  // おすすめ条件経由の場合
  if (_this.$store.getters['stockSearchInput/recommend']) {
    params += `&recommend=${_this.$store.getters['stockSearchInput/recommend']}`;
  }

  // 初期値
  const defaultValues = _this.$store.getters['stockSearchInput/default'];

  // 有効な条件
  const criteria = _this.$store.getters['stockSearchInput/criteria'].reduce((acc, key) => {
    if (_this.$store.getters['stockSearchInput/list'](key)) {
      acc[key] = _this.$store.getters['stockSearchInput/list'](key);
    }
    return acc;
  }, {});

  // queryParamsを作成
  for (const [key, val] of Object.entries(criteria)) {
    if (criteria[key].value) {
      // スライダーの場合
      /**
       * 最小が最小値以下なら min、最大が最大以上なら max 、不正値ならデフォルト値、それ以外なら実数を入れる
       *
       * 例：最小値が 1、最大値が 100 の場合
       *  [1, 100] => [min, max]
       *  [0, 101] => [min, max]
       *  [1, 90] => [min, 90]
       *  [10, 100] => [10, max]
       *  [0, 0] => [min, 1]
       *  [200, 200] => [100, max]
       *  ['ABC, 'DEF'] => [min, max]
       */
      criteria[key].value.forEach((el, i) => {
        const hasNumber = /^[+,-]?([1-9]\d*|0)(\.\d+)?$/.test(val.value[i]);

        if (el <= defaultValues[key].range[0] || (i === 0 && !hasNumber)) {
          if (i === 0) {
            params += `&${key}[0]=min`;
          } else {
            params += `&${key}[1]=${defaultValues[key].value[0]}`;
          }
        } else if (el >= defaultValues[key].range[1] || (i === 1 && !hasNumber)) {
          if (i === 1) {
            params += `&${key}[1]=max`;
          } else {
            params += `&${key}[0]=${defaultValues[key].value[1]}`;
          }
        } else {
          params += `&${key}[${i}]=${val.value[i]}`;
        }
      });
    } else if (criteria[key].checked) {
      // チェックボックスの場合
      for (const check of criteria[key].checked) {
        // 優待有無が未チェックの場合は追加しない
        if (key === 'yutai_exist' && check === '1') {
          params += `&${key}=${check}`;
          // 業種コードが4桁以外の場合は追加しない
        } else if (!(key == 'industry_sectors' && check.length !== 4)) {
          params += `&${key}[]=${check}`;
        }
      }
    }
  }

  // UTMパラメータを処理
  params += _appendUtmParams();

  return params;
};

/**
 * はじめてのアクセス時にだけURLにUTMパラメータを追加するための関数
 * @return {String}
 * @private
 */
const _appendUtmParams = () => {
  let utmParams = '';
  if (window.history.length <= 1) {
    const currentUrlObj = new URL(window.location.href);
    currentUrlObj.searchParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams += `&${key}=${value}`;
      }
    });
  }

  return utmParams;
};

export default generateQueryParams;
