<template src="./IPOTop.html"></template>

<script>
import IPOCalendar from './IPOCalendars/IPOCalendar.vue';
import IPOUpcoming from './IPOUpcoming/IPOUpcoming.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';

/**
 * IPOトップコンポーネント
 * @vue-components {IPOCalendar} ipoCalendar IPOカレンダーコンポーネント
 * @vue-components {IPOUpcoming} ipoUpcoming 今後上場予定のIPO一覧
 * @vue-components {favoriteSignUpModal} FavoriteSignUpModal お気に入りボタン用の会員登録誘導モーダル
 * @vue-data {string} current 現在のタブ(ipoCalendar, ipoUpcoming)
 *
 * @module IPOCalendars/IPOCalendar
 */
export default {
  name: 'IPOTop',
  components: {
    ipoCalendar: IPOCalendar,
    ipoUpcoming: IPOUpcoming,
    favoriteSignUpModal: FavoriteSignUpModal,
  },
  props: {
    /**
     * @vue-props {String} 今日の日付
     */
    todayString: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * 初期表示年
     * @return {Number}
     */
    defaultYear: {
      type: Number,
      require: true,
      default: null,
    },
    /**
     * 初期表示月
     * @return {Number}
     */
    defaultMonth: {
      type: Number,
      require: true,
      default: null,
    },
    /**
     * 会員登録用URL
     * @return {String}
     */
    signUpUrl: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * ログインURL
     * @return {String}
     */
    loginUrl: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * プレミアムLPへのURL
     */
    premiumLpUrl: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * キャンペーンURL
     * @return {String}
     */
    campaignUrl: {
      type: String,
      require: false,
      default: null,
    },
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      current: 'ipoCalendar',
    };
  },
  computed: {
    /**
     * IPOカレンダーを表示していれば真
     * @return {boolean}
     */
    isIpoCalendar() {
      return this.current === 'ipoCalendar';
    },
    /**
     * 今後上場予定のIPO一覧を表示していれば真
     * @return {boolean}
     */
    isIpoUpcoming() {
      return this.current === 'ipoUpcoming';
    },
    /**
     * MonthPickerが表示されていれば真
     * @return {boolean}
     */
    isVisibleMonthPicker() {
      return this.$store.getters['ipoCalendar/isVisibleMonthPicker'];
    },
  },
  methods: {
    /**
     * タブ切替え処理
     * @returns {void}
     */
    change(tabName) {
      this.current = tabName;
    },
  },
};
</script>
