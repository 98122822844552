<template src="./IPOUpcoming.html"></template>

<script>
import dayjs from 'dayjs';
import FavoriteButtonHeart from '../../../Common/FavoriteButtonHeart.vue';
import { QUERY_IPO_UPCOMING_ITEMS as IPO_UPCOMING_ITEMS } from '../../../../queries/IPOUpcomingItemsQuery';

/**
 * 今後上場予定のIPO一覧コンポーネント
 * @vue-components {FavoriteButtonHeart} favoriteButtonHeart お気に入りボタン
 * @vue-data {string} displayType 表示タイプ(list, detail)
 * @vue-data {Array<Object>} ipoUpcomingItems 今後上場予定のIPO一覧
 * @vue-apollo {ipoUpcomingItems} ipoUpcomingItems 今後上場予定のIPO一覧

 * @module IPOUpcoming/IPOUpcoming
 */
export default {
  name: 'IPOUpcoming',
  components: {
    favoriteButtonHeart: FavoriteButtonHeart,
  },
  data() {
    return {
      displayType: 'list',
      ipoUpcomingItems: null,
    };
  },
  apollo: {
    ipoUpcomingItems: {
      query: IPO_UPCOMING_ITEMS,
    },
  },
  computed: {
    /**
     * 詳細表示なら真
     * @return {Boolean}
     */
    isDetail() {
      return this.displayType === 'detail';
    },
    /**
     * リスト表示なら真
     * @return {Boolean}
     */
    isList() {
      return this.displayType === 'list';
    },
    /**
     * ipoUpcomingItemsの各IPO情報に当日がBB期間内かの属性を追加した配列
     * @return {Array<Object>}
     */
    decoratedIpoUpcomingItems() {
      if (!this.ipoUpcomingItems) {
        return [];
      }

      return this.ipoUpcomingItems.filter((ipoUpcomingItem) => {
        let isBeforeBB = false;
        let isAfterBB = false;
        let isBetweenBB = false;

        switch (this.bookBuildingPeriodStatus(ipoUpcomingItem)) {
          case 'before':
            isBeforeBB = true;
            break;
          case 'after':
            isAfterBB = true;
            break;
          default:
            isBetweenBB = true;
        }
        return Object.assign(ipoUpcomingItem, { isBeforeBB, isAfterBB, isBetweenBB });
      });
    },
    /**
     * 今年
     * @return {Integer}
     */
    defaultYear() {
      const now = new Date();
      return now.getFullYear();
    },
    /**
     * 今年のIPO一覧ページのURL
     * @return {string}
     */
    ipoShowUrl() {
      return `/ipo/${this.defaultYear}`;
    },
  },
  methods: {
    /**
     * BB期間のステータス文字列
     *
     * @param {Object} ipoUpcomingItem
     * @return {string}
     */
    bookBuildingPeriodStatus(ipoUpcomingItem) {
      const now = dayjs();
      if (now.isBefore(dayjs(ipoUpcomingItem.bookBuildingPeriodFrom), 'day')) {
        return 'before';
      } else if (now.isAfter(dayjs(ipoUpcomingItem.bookBuildingPeriodTo), 'day')) {
        return 'after';
      } else {
        return 'between';
      }
    },
    /**
     * BB期間に応じた申込みに関するテキスト
     * @param {Object} decoratedIpoUpcomingItem
     * @return {string}
     */
    bookBuildingPeriodMessage(decoratedIpoUpcomingItem) {
      if (decoratedIpoUpcomingItem.isBeforeBB) {
        return 'まもなく申込';
      } else if (decoratedIpoUpcomingItem.isAfterBB) {
        return '申込期間終了';
      } else {
        return '申込期間中';
      }
    },
    /**
     * リスト表示切り替え
     * @return {void}
     */
    displayList() {
      this.displayType = 'list';
    },
    /**
     * 詳細表示切り替え
     * @return {void}
     */
    displayDetail() {
      this.displayType = 'detail';
    },
    /**
     * お気に入りボタン押下時のモーダル表示（未ログイン状態）
     * @return {void}
     */
    showModal() {
      this.$store.commit('favoriteSignUpModal/show');
    },
    /**
     * 仮条件の上限値・下限値があれば真
     * @return {boolean}
     */
    hasProvisionalConditionPrice(ipoUpcomingItem) {
      return !!(ipoUpcomingItem.lowerLimitOfProvisionalCondition && ipoUpcomingItem.upperLimitOfProvisionalCondition);
    },
    /**
     * 公開価格があれば真
     * @return {boolean}
     */
    hasOpenPrice(ipoUpcomingItem) {
      return ipoUpcomingItem.openPrice !== null;
    },
  },
};
</script>
