/**
 * みんかぶのURLを返すMixinクラス
 *
 */
const UrlMixin = {
  methods: {
    /**
     * 個別銘柄トップのURLを返す
     * ex) /stock/4436
     *
     * @param {string} code
     * @return {string}
     */
    stockUrl(code) {
      return `/stock/${code}`;
    },
    /**
     * 個別銘柄の優待ページのURLを返す
     * ex) /stock/4436/yutai
     *
     * @param {string} code
     * @return {string}
     */
    stockYutaiUrl(code) {
      return `/stock/${code}/yutai`;
    },
    /**
     * 個別銘柄の決算ページのURLを返す
     * ex) /stock/4436/settlement
     *
     * @param {string} code
     * @return {string}
     */
    stockSettlementUrl(code) {
      return `/stock/${code}/settlement`;
    },
    /**
     * 個別銘柄の配当ページのURLを返す
     * ex) /stock/4436/dividend
     *
     * @param {string} code
     * @return {string}
     */
    stockDividendUrl(code) {
      return `/stock/${code}/dividend`;
    },
    /**
     * 個別銘柄の株価予想ページのURLを返す
     * ex) /stock/4436/analysis
     *
     * @param {string} code
     * @return {string}
     */
    stockAnalysisUrl(code) {
      return `/stock/${code}/analysis`;
    },
    /**
     * 金融アイテムランキングのURLを返す
     * ex) /financial_item_ranking/rise
     *
     * @param {string} name ランキング種別
     * @return {string}
     */
    financialItemRankingUrl(name) {
      return `/financial_item_ranking/${name}`;
    },

    /**
     * 銘柄IPOページのパス
     * @param {string} code
     * @return {string}
     */
    stockIpoUrl(code) {
      return `/stock/${code}/ipo`;
    },

    /**
     * 銘柄ニュースページのパス
     * @param {string} code
     * @param {integer} newsId
     *
     * @return {string}
     */
    stockNewsUrl(code, newsId) {
      return `/stock/${code}/news/${newsId}`;
    },

    /**
     * ニュースページのパス
     * @param {integer} newsId
     *
     * @return {string}
     */
    newsUrl(newsId) {
      return `/news/${newsId}`;
    },

    /**
     * ニュースページのパス（特定の単位で絞り込む場合）
     * @param {String} section
     * @param {String} name
     *
     * @return {string}
     */
    newsUrlBySectionWithName(section, name) {
      return `/news/search?${section}=${name}`;
    },

    /**
     * ニュース配信元ページのパス
     * @param {String} name
     * @return {string}
     */
    newsUrlByChannel(name) {
      return `/news/channel/${name}`;
    },

    /**
     * ニュースタグページのパス
     * @param {String} name
     * @return {string}
     */
    newsUrlByTag(name) {
      return `/news/tag/${name}`;
    },

    /**
     * 予想投稿のURL
     * @param {string} code
     * @param {integer} pickId
     * @return {String}
     */
    stockPickUrl(code, pickId) {
      return `/stock/${code}/pick/${pickId}`;
    },
    /**
     * 予想ユーザーへのURL
     * @param {integer} userId
     * @return {String}
     */
    userProfileUrl(userId) {
      return `/user/profile/${userId}`;
    },
  },
};
export default UrlMixin;
