/**
 * Date用フィルター
 *
 * @property {Array<string>} Vue.filterに登録するメソッド名
 * @module filters/DateFilter
 */
class DateFilter {
  constructor() {
    this.exportMethods = ['stringDateTime', 'unixTime', 'stringDateTimeAbbr'];
  }
  /**
   * yyyy/mm/dd hh:mm にして返却
   *
   * @params {Date} date Date型の値
   * @return {string} 変換済みの文字列
   */
  stringDateTime(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hour}:${minutes}`;
  }

  /**
   * Unixtimeにして返却
   *
   * @params {Date} date Date型の値
   * @return {number} 変換済みUnixtime
   */
  unixTime(date) {
    return Math.round(date.getTime() / 1000);
  }

  /**
   * 日付をmm/dd hh:mm にして返却する
   *
   * @params {Date} date Date型の値
   * @return {string} 変換済みの文字列
   */
  stringDateTimeAbbr(date) {
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${month}/${day} ${hour}:${minutes}`;
  }
}

export default DateFilter;
