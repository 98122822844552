/**
 * URL生成クラス
 */
class UrlGenerateUtil {
  /**
   * @param {string} pathString パス
   * @param {string} currentUrl 現在のURL
   * @param {string} source ソース
   * @param {string} medium メディア
   * @param {string} campaign キャンペーン
   * @returns {string} idみんかぶURLを返す
   */
  static idMinkabuUrl({ pathString, currentUrl, source, medium, campaign } = {}) {
    const hostname = window.location.hostname;
    let url = '';
    if (hostname.includes('dev')) {
      url = 'https://dev-id.minkabu.jp';
    } else if (hostname.includes('stg')) {
      url = 'https://stg-id.minkabu.jp';
    } else {
      url = 'https://id.minkabu.jp';
    }
    const path = pathString ? `/${pathString}` : '';
    const callBackUrl = currentUrl ? `?callback_url=${encodeURIComponent(this.removeUtmParameters(currentUrl))}` : '';
    const utmSource = source ? `&utm_source=${encodeURIComponent(source)}` : '';
    const utmMedium = medium ? `&utm_medium=${encodeURIComponent(medium)}` : '';
    const utmCampaign = campaign ? `&utm_campaign=${encodeURIComponent(campaign)}` : '';
    return `${url}${path}${callBackUrl}${utmSource}${utmMedium}${utmCampaign}`;
  }
  /**
   * @param {string} pathString パス
   * @param {string} currentUrl 現在のURL
   * @param {string} source ソース
   * @param {string} medium メディア
   * @param {string} campaign キャンペーン
   * @returns {string} idみんかぶURLを返す
   */
  static premiumPath({ currentUrl, source, medium, campaign } = {}) {
    const callBackUrl = currentUrl ? `?callback_url=${encodeURIComponent(this.removeUtmParameters(currentUrl))}` : '';
    const utmSource = source ? `&utm_source=${encodeURIComponent(source)}` : '';
    const utmMedium = medium ? `&utm_medium=${encodeURIComponent(medium)}` : '';
    const utmCampaign = campaign ? `&utm_campaign=${encodeURIComponent(campaign)}` : '';
    return `/lp/premium${callBackUrl}${utmSource}${utmMedium}${utmCampaign}`;
  }

  /**
   * UTMパラメータを削除する関数(コールバックURLからUTMパラメータを削除するために使用する想定)
   * @param {string} url URL
   * @returns {string} UTMパラメータを削除したURL
   * @private
   */
  static removeUtmParameters(url) {
    let result = url.replace(/&?utm_[^&]+/g, '');

    result = result.replace(/&&+/g, '&');
    result = result.replace(/\?&/, '?');
    result = result.replace(/[?&]$/, '');

    return result;
  }
}
export default UrlGenerateUtil;
