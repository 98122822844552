import gql from 'graphql-tag';

export const QUERY_CORPORATE_ACTIONS_CALENDAR = gql`
query corporateActionsCalendar($action: String!, $year: Int!, $month: Int!) {
  corporateActionsCalendar(action: $action, year: $year, month: $month) {
    weeks {
      days {
        date
        isOpen
        isCurrentMonth
      }
    }
    actions {
      date
      count
    }
  }
}
`;