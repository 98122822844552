<template src="./BaseLoading.html"></template>
<script>
/**
 * ローディング状態のコンポーネント
 * @property {boolean} loadingStatus ローディングの状態
 * @module BaseLoading
 */
export default {
  name: 'BaseLoading',
  computed:{
    loadingStatus() {
      return this.$store.state.BaseLoading.loadingStatus;
    },
  }
};
</script>
