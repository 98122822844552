/**
 * ページネーションの状態
 * @property {number} currentPage 現ページ番号
 */
const TailwindPaginationStore = {
  namespaced: true,
  state: {
    currentPage: 1,
  },
  getters: {
    /* @returns {string} 現ページ番号 */
    currentPage(state) {
      return state.currentPage;
    },
  },
  mutations: {
    /**
     * 現ページ番号更新
     * @param {number} newPage
     */
    currentPage(state, newPage) {
      state.currentPage = newPage;
    },
  },
  actions: {
    /**
     * 指定されたページに遷移
     * @param {number} page
     */
    clickPage({ commit }, page) {
      commit('currentPage', page);
    },
    /**
     * 前ページに遷移
     */
    prevPage({ commit, state }) {
      commit('currentPage', state.currentPage - 1);
    },
    /**
     * 前ページに遷移
     */
    nextPage({ commit, state }) {
      commit('currentPage', state.currentPage + 1);
    },
    /**
     * 現ページ番号を初期化
     */
    resetPage({ commit }) {
      commit('currentPage', 1);
    }
  },
};
export default TailwindPaginationStore;
