import IPOCalendarFinancialItemRow from './IPOCalendarFinancialItemRow';
import dayjs from 'dayjs';

/**
 * IPOカレンダービルダー
 */
class IPOCalendarBuilder {
  /**
   * 初期化処理
   * @param {number} calendarYear カレンダーの年
   * @param {number} calendarMonth カレンダーの月
   */
  constructor(calendarYear, calendarMonth) {
    this.lastDay = dayjs(`${calendarYear}-${calendarMonth}`).endOf('month').date();
    this.headerCount = 31;
    this.calendarYear = calendarYear;
    this.calendarMonth = calendarMonth;
    this.thisDate = dayjs().toDate();
  }

  /**
   * 金融アイテムカレンダー行情報
   *
   * @param {string} name
   * @param {string} code
   * @param {boolean} hasFinancialItem
   * @param {Object} f11mCalendar
   * @param {string} f11mCalendar.provisionalConditionFilingDate
   * @param {string} f11mCalendar.bookBuildingPeriodFrom
   * @param {string} f11mCalendar.bookBuildingPeriodTo
   * @param {string} f11mCalendar.subscriptionPriceDeterminationDate
   * @param {string} f11mCalendar.subscriptionPeriodFrom
   * @param {string} f11mCalendar.subscriptionPeriodTo
   * @param {string} f11mCalendar.listingDate
   * @returns {IPOCalendarFinancialItemRow}
   */
  buildRow(name, code, hasFinancialItem, f11mCalendar) {
    let row = new IPOCalendarFinancialItemRow(
      name,
      code,
      hasFinancialItem,
      this.headerCount,
      this.lastDay,
      f11mCalendar.ipoCanceled
    );

    const provisionalConditionFilingDate = new Date(f11mCalendar.provisionalConditionFilingDate);
    if (this._isCalendarMonth(provisionalConditionFilingDate)) {
      row.addCell(provisionalConditionFilingDate.getDate(), 1, '仮条件');
    }

    const bookBuildingPeriodFrom = new Date(f11mCalendar.bookBuildingPeriodFrom);
    const bookBuildingPeriodTo = new Date(f11mCalendar.bookBuildingPeriodTo);
    const bbSpan = this._calcSpan(bookBuildingPeriodFrom, bookBuildingPeriodTo);
    if (bbSpan) {
      row.addCell(bbSpan.day, bbSpan.colspan, 'ＢＢ期間');
    }

    const subscriptionPriceDeterminationDate = new Date(f11mCalendar.subscriptionPriceDeterminationDate);
    if (this._isCalendarMonth(subscriptionPriceDeterminationDate)) {
      row.addCell(subscriptionPriceDeterminationDate.getDate(), 1, '公開価格');
    }

    const subscriptionPeriodFrom = new Date(f11mCalendar.subscriptionPeriodFrom);
    const subscriptionPeriodTo = new Date(f11mCalendar.subscriptionPeriodTo);
    const subscriptionSpan = this._calcSpan(subscriptionPeriodFrom, subscriptionPeriodTo);
    if (subscriptionSpan) {
      row.addCell(subscriptionSpan.day, subscriptionSpan.colspan, '申込期間');
    }

    const listingDate = new Date(f11mCalendar.listingDate);
    if (this._isCalendarMonth(listingDate)) {
      row.addCell(listingDate.getDate(), 1, '上場');
    }

    return row;
  }

  /**
   * IPOカレンダーの日付ヘッダ情報
   *
   * @param {Array<number>} holidays
   * @returns {Array<Object>}
   */
  buildHeader(holidays) {
    let date;
    let wday;
    let isToday;
    let isSaturday;
    let isHoliday;
    const days = [...Array(this.headerCount).keys()].map((i) => ++i);
    days.splice(this.lastDay, this.headerCount - this.lastDay, ...new Array(this.headerCount - this.lastDay).fill(''));

    return days.map((day) => {
      if (!day) {
        return { day: null, wday: null, isToday: false, isSaturday: false, isHoliday: false };
      }

      date = dayjs(`${this.calendarYear}-${this.calendarMonth}-${day}`);
      wday = date.day();
      isToday = false;
      isSaturday = false;
      isHoliday = false;

      if (date.isSame(dayjs(this.thisDate), 'day')) {
        isToday = true;
      } else if (wday === 6) {
        isSaturday = true;
      } else if (wday === 0 || holidays.includes(day)) {
        isHoliday = true;
      }

      return { day, wday, isToday, isSaturday, isHoliday };
    });
  }

  /**
   * IPOカレンダー当日ハイライト行の情報
   *
   * @returns {Array<boolean>}
   */
  buildToday() {
    let cells = new Array(this.headerCount).fill(false);
    if (this._isCalendarMonth(this.thisDate)) {
      cells.splice(this.thisDate.getDate() - 1, 1, true);
      return cells;
    } else {
      return cells;
    }
  }

  _calcSpan(fromDate, toDate) {
    let fromDay = null;
    let toDay = null;

    if (this._isCalendarMonth(fromDate)) {
      if (this._isCalendarMonth(toDate)) {
        fromDay = fromDate.getDate();
        toDay = toDate.getDate();
      } else {
        fromDay = fromDate.getDate();
        toDay = this.lastDay;
      }
    } else {
      if (this._isCalendarMonth(toDate)) {
        fromDay = 1;
        toDay = toDate.getDate();
      }
    }

    if (fromDay === null && toDay === null) {
      return null;
    } else {
      return {
        day: fromDay,
        colspan: toDay - fromDay + 1,
      };
    }
  }

  _isCalendarMonth(date) {
    const calendarMonthYear = dayjs(`${this.calendarYear}-${this.calendarMonth}`);
    return dayjs(date).isSame(calendarMonthYear, 'month');
  }
}

export default IPOCalendarBuilder;
