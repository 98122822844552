<template src="./SideNavigationFinancialItemRanking.html" />

<script>
import { ContentLoader } from 'vue-content-loader';
import {
  QUERY_FINANCIAL_ITEM_RANKINGS_BUY_PICKS_RISES as F11M_RANKINGS_BUY_PICKS_RISES,
  QUERY_FINANCIAL_ITEM_RANKINGS_SELL_PICKS_RISES as F11M_RANKINGS_SELL_PICKS_RISES,
  QUERY_FINANCIAL_ITEM_RANKINGS_RISES as F11M_RANKINGS_RISES,
  QUERY_FINANCIAL_ITEM_RANKINGS_FALLS as F11M_RANKINGS_FALLS,
} from '../../../queries/FinancialItemRankingsQuery';
/*
 * 右ナビ金融アイテムランキングコンポーネント
 * @vue-props {string} rankingTitle ランキングタイトル
 * @vue-data {tabPosition} activeTab 現在選択されているタブの種別
 * @module SideNavigation/SideNavigationFinancialItemRanking
 */
export default {
  name: 'SideNavigationFinancialItemRanking',
  components: {
    contentLoader: ContentLoader,
  },
  props: {
    rankingTitle: {
      type: String,
      required: true,
    },
    isTailwind: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rankingLoad: false,
      activeTab: 'left',
      financialItemRankingsBuyPicksRises: [],
      financialItemRankingsSellPicksRises: [],
      financialItemRankingsRises: [],
      financialItemRankingsFalls: [],
    };
  },
  apollo: {
    financialItemRankingsBuyPicksRises: {
      query: F11M_RANKINGS_BUY_PICKS_RISES,
      skip() {
        return true;
      },
    },
    financialItemRankingsSellPicksRises: {
      query: F11M_RANKINGS_SELL_PICKS_RISES,
      skip() {
        return true;
      },
    },
    financialItemRankingsRises: {
      query: F11M_RANKINGS_RISES,
      skip() {
        return true;
      },
    },
    financialItemRankingsFalls: {
      query: F11M_RANKINGS_FALLS,
      skip() {
        return true;
      },
    },
  },
  computed: {
    /**
     * 左タブが選択されていれば真
     * @returns {boolean}
     */
    isLeft() {
      return this.activeTab === 'left';
    },
    /**
     * 右タブが選択されていれば真
     * @returns {boolean}
     */
    isRight() {
      return this.activeTab === 'right';
    },
    /**
     * 現在選択されているタブのランキング
     * @returns {Object}
     */
    currentRanking() {
      switch (this.currentRankingName) {
        case '買い予想数上昇':
          return this.financialItemRankingsBuyPicksRises;
        case '売り予想数上昇':
          return this.financialItemRankingsSellPicksRises;
        case '株価値上がり':
          return this.financialItemRankingsRises;
        case '株価値下がり':
          return this.financialItemRankingsFalls;
        default:
          return null;
      }
    },
    /**
     * 現在選択されているタブのランキングURL
     * @returns {string}
     */
    currentRankingUrl() {
      let rankingUrl;
      switch (this.currentRankingName) {
        case '買い予想数上昇':
          rankingUrl = this.financialItemRankingUrl('buy_picks_rise');
          break;
        case '売り予想数上昇':
          rankingUrl = this.financialItemRankingUrl('sell_picks_rise');
          break;
        case '株価値上がり':
          rankingUrl = this.financialItemRankingUrl('rise');
          break;
        case '株価値下がり':
          rankingUrl = this.financialItemRankingUrl('fall');
          break;
        default:
          return null;
      }
      return `${rankingUrl}`;
    },
    /**
     * 現在選択されているタブのランキング名称
     * @returns {string}
     */
    currentRankingName() {
      return this.isLeft ? this.leftRankingName : this.rightRankingName;
    },
    /**
     * 左タブのランキング名称
     * @returns {string}
     */
    leftRankingName() {
      switch (this.rankingTitle) {
        case '売買予想ランキング':
          return '買い予想数上昇';
        case '株価値上がり・値下がりランキング':
          return '株価値上がり';
        default:
          return null;
      }
    },
    /**
     * 右タブのランキング名称
     * @returns {string}
     */
    rightRankingName() {
      switch (this.rankingTitle) {
        case '売買予想ランキング':
          return '売り予想数上昇';
        case '株価値上がり・値下がりランキング':
          return '株価値下がり';
        default:
          return null;
      }
    },
    /**
     * 計測用のutm_campaignパラメータ名
     * @returns {string}
     */
    utmCampaign() {
      switch (this.rankingTitle) {
        case '売買予想ランキング':
          return 'trading_forecast';
        case '株価値上がり・値下がりランキング':
          return 'price_up_down';
        default:
          return null;
      }
    },
    /**
     * シャドースケルトンの表示判定
     * @returns {Boolean}
     */
    displayShadowSkeleton() {
      return this.$apollo.loading || !this.rankingLoad;
    },
  },
  mounted() {
    this.tabChangeLeft();
  },
  methods: {
    /**
     * タブ変更(左)
     * @returns {void}
     */
    tabChangeLeft() {
      this.activeTab = 'left';
      switch (this.rankingTitle) {
        case '売買予想ランキング':
          this.$apollo.queries.financialItemRankingsBuyPicksRises.skip = false;
          break;
        case '株価値上がり・値下がりランキング':
          this.$apollo.queries.financialItemRankingsRises.skip = false;
          break;
      }
      this.rankingLoad = true;
    },
    /**
     * タブ変更(右)
     * @returns {void}
     */
    tabChangeRight() {
      this.activeTab = 'right';
      switch (this.rankingTitle) {
        case '売買予想ランキング':
          this.$apollo.queries.financialItemRankingsSellPicksRises.skip = false;
          break;
        case '株価値上がり・値下がりランキング':
          this.$apollo.queries.financialItemRankingsFalls.skip = false;
          break;
      }
      this.rankingLoad = true;
    },
  },
};
</script>
