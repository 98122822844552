<template src="./ShareholderBenefitColumnYield.html" />

<script>
/**
 * 利回り描画コンポーネント
 *
 * @property {Number} YieldValue [data] 利回り率
 * @module ShareholderBenefitColumn/ShareholderBenefitColumnYield
 */
export default {
  name: 'ShareholderBenefitColumnYield',
  props: {
    yieldValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    isYieldValueZero() {
      return this.yieldValue == 0;
    },
  }
};
</script>
