<template src="./SettlementChartYearlyComparison.html"></template>
<script>
import RoundUtil from '../../utils/RoundUtil';
import {Chart} from 'highcharts-vue';

/*
 * 年度ごとの比較チャートコンポーネント
 *
 * @vue-prop {Settlements::Index::YearlyComparisons::(Sales|OperatingIncome|OrdinaryIncome|NetIncome)Charts::ViewModel} chartElements
 * @module SettlementChartYearlyComparison
 */
export default {
  name: 'SettlementChartYearlyComparison',
  props: {
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          backgroundColor: '#ffffff',
          height: 155
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.chartElements['fiscal_periods'],
          labels: {
            rotation: -35,
            style: {
              fontSize: 8,
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return RoundUtil.roundJapaneseUnit(this.value, 1);
            },
            style: {
              fontSize: '9px'
            }
          },
          tickPositioner: function () {
            const dataAbsMax = Math.max(Math.abs(this.dataMin), Math.abs(this.dataMax));

            const first = dataAbsMax.toString(10).slice(0, 1);
            const digit = dataAbsMax.toString(10).replace(/\d/g, 0).slice(2);
            let refVal = parseInt(first + 5 + digit);
            if ( this.dataMax < 0 ) {
              return [-1.2, -0.5, 0].map(function (n){
                return refVal * n;
              });
            } else if ( this.dataMin < 0 ) {
              return [-1.2, -0.5, 0, 0.5, 1, 1.5].map(function (n){
                return refVal * n;
              });
            } else {
              return [0, 0.5, 1, 1.5].map(function (n){
                return refVal * n;
              });
            }
          }
        },
        tooltip: {
          formatter: function() {
            return `<small>${this.series.name === '' ? this.x : this.series.name}</small>: <b>${RoundUtil.roundJapaneseUnit(this.point.y, 1)}</b>`;
          }
        },
        series: [
          {
            showInLegend: false,
            name: '',
            data: this.revertRoundedValues(this.chartElements[this.chartElements['chart_type']])
          },
          {
            type: 'scatter',
            name: 'アナリスト予想',
            data: this.revertRoundedValues([
              null,
              null,
              null,
              null,
              this.chartElements['analyst_projection'][this.chartElements['chart_type']]
            ]),
            color: '#FC9F02',
            marker: {
              symbol: 'diamond',
              radius: 5
            }
          }
        ],
        credits: {
          enabled: false
        },
        colors: [
          '#95a5b1',
          '#95a5b1',
          '#95a5b1',
          '#95a5b1',
          '#0096fa'
        ],
        legend: {
          align: 'center',
          verticalAlign: 'top',
          x: 18,
          y: -12,
          floating: true,
          itemStyle: {
            'fontWeight': 'normal',
            'fontSize': '9px'
          },
          enabled: this.legendEnabled()
        },
        plotOptions: {
          column: {
            pointWidth: 20,
            pointPadding: -0.1,
            groupPadding: 0,
            grouping: false,
            shadow: false,
            animation: false,
            colorByPoint: true
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  },
  methods: {
    /**
     * アナリスト予想の凡例を使うチャートなら真を返す
     * @return  {boolean}
     */
    legendEnabled() {
      if ((this.chartElements['chart_type'] == 'sales' || this.chartElements['chart_type'] == 'net_income') && this.chartElements['analyst_projection'][this.chartElements['chart_type']]) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 配列の要素の値を100万掛けて返す
     * @param {Array<integer|null>} list
     * @return {Array<integer|null>}
     */
    revertRoundedValues: function(list) {
      return list === [] ? list : list.map(function(val) { return RoundUtil.revertRoundedMillion(val); });
    }
  }
};
</script>
