/** Class StringUtil */
class StringUtil {

  /**
   * テキスト内の特定の文字列をspan.highlightのタグで囲む
   *
   * @param {string} text テキスト
   * @param {string} string ハイライト対象文字
   * @return {string} ハイライト済みテキスト
   */
  static highlightWithJapanese(text, string) {
    text = StringUtil.highlight_if_match(text, string, 'zen2han');
    text = StringUtil.highlight_if_match(text, string, 'han2zen_kana');
    text = StringUtil.highlight_if_match(text, string, 'han2zen');
    return StringUtil.highlight(text, string);
  }

  /**
   * テキスト内の特定の文字列をspan.highlightのタグで囲む
   *
   * @param {string} text テキスト
   * @param {string} string ハイライト対象文字
   * @return {string} 変換後テキスト
   */
  static highlight(text, string) {
    if (string === '') { return text; }
    return text.replace(string, function (s) {
      return `<span class="highlight">${s}</span>`;
    });
  }

  /**
   * マッチ条件に合った際にのみ
   * テキスト内の特定の文字列をspan.highlightのタグで囲む
   *
   * @param {string} text テキスト
   * @param {string} string ハイライト対象文字
   * @param {string} type 変換タイプ
   * @return {string} 変換後テキスト(マッチ条件に合わなければ変換しない)
   */
  static highlight_if_match(text, string, type) {
    let replacement = StringUtil.toReplacement(string, type);
    if (string === replacement) {
      return text;
    } else {
      return StringUtil.highlight(text, replacement);
    }
  }

  /**
   * 変換タイプに応じてテキストを変換する
   *
   * @param {string} string 文字列
   * @param {string} type 変換タイプ
   * @return {string} 変換後文字列
   */
  static toReplacement(string, type) {
    switch (type) {
    case 'han2zen_kana':
      return StringUtil.HankanaToZenkana(string);
    case 'han2zen':
      return StringUtil.HanToZen(string);
    case 'zen2han':
      return StringUtil.ZenToHan(string);
    }
    return string;
  }

  /**
   * 半角英数記号 -> 全角英数記号
   *
   * @param {string} string 文字列
   * @return {string} 変換後文字列
   */
  static HanToZen(string) {
    return StringUtil._ShiftCharCode(string, 'han2zen');
  }

  /**
   * 全角英数記号 -> 半角英数記号
   *
   * @param {string} string 文字列
   * @return {string} 変換後文字列
   */
  static ZenToHan(string) {
    return StringUtil._ShiftCharCode(string, 'zen2han');
  }

  /**
   * 文字コードを変換タイプに応じてシフトする
   *
   * @param {string} string 文字列
   * @param {string} type 変換タイプ
   * @return {string} 変換後文字列
   */
  static _ShiftCharCode(string, type) {
    const regexp = {
      han2zen: /[0-9a-zA-Z!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]/g,
      zen2han: /[０-９ａ-ｚＡ-Ｚ！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～]/g
    };
    const shiftLength = 0xFEE0;
    return string.replace(regexp[type], (s) => {
      switch (type) {
      case 'han2zen':
        return String.fromCharCode(s.charCodeAt(0) + shiftLength);
      case 'zen2han':
        return String.fromCharCode(s.charCodeAt(0) - shiftLength);
      }
    });
  }

  /**
   * 半角カタカナ -> 全角カタカナ
   *
   * @param {string} string 文字列
   * @return {string} 変換後文字列
   */
  static HankanaToZenkana(string) {
    const kanaMap =
      { 'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        'ｰ': 'ー', '｢': '「', '｣': '」', '｡': '。', '､': '、', '･': '・', ' ': '　'};
    const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return string.replace(reg, function (match) { return kanaMap[match]; }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
  }
}

export default StringUtil;
