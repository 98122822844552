<template src="./YutaiTopRankingsYield.html" />

<script>
import FavoriteButton from '../../Common/FavoriteButton.vue';

export default {
  name: 'YutaiTopRankingsYield',
  components: {
    favoriteButton: FavoriteButton,
  },
  props: {
    ranking_type: {
      type: String,
      require: true,
    },
    ranking: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * ランキング順位 CSSクラス
     * @param {integer} rank
     * @returns {string}
     */
    makeClassForBgrank(rank) {
      return `bgrank${rank}`;
    },
    /**
     * ランキング画像ALT文言
     * @param {Object} ranking
     * @returns {string}
     */
    imageAlt(ranking) {
      return `${ranking.financialItem.name}の株主優待`;
    },
    /**
     * ランキング利回り
     * @param {Object} ranking
     * @returns {number}
     */
    currentYield(ranking) {
      switch (this.ranking_type) {
        case 'yutai':
          return ranking.yutaiYield;
        case 'dividend':
          return ranking.dividendYield;
        case 'total':
          return ranking.totalYield;
      }
    },
    /**
     * ランキング利回りのラベル
     * @returns {string}
     */
    currentYieldLable() {
      switch (this.ranking_type) {
        case 'yutai':
          return '優待利回り';
        case 'dividend':
          return '配当利回り';
        case 'total':
          return '配当+優待';
      }
    },
  },
};
</script>
