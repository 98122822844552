<template src="./YutaiSearchYieldRangeSlider.html" />
<script>
import VueSlider from 'vue-slider-component';
/**
 * 株主優待検索ボックス利回り設定用スライダー
 *
 * @vue-props {string} propYieldType 利回り種別 (yutai/haito/yutai_haito)
 * @vue-props {number} propMinValue 最小値(引数)
 * @vue-props {number} propMaxValue 最大値(引数)
 * @vue-props {boolean} propUnlimitedValue 上限なしなら真(引数)
 * @vue-data {string} yieldType 選択中利回り種別
 * @vue-data {Object} yieldTypeOptions 利回り種別を設定するオプション
 * @vue-data {Object} vueSliderOptions レンジバーに渡す設定オプション
 * @vue-data {Array.<number>} rangeValues レンジバーの値
 * @vue-data {boolean} unlimitedValue 上限なしなら真
 * @vue-data {boolean} isValidateError フォームvalidateがinvalidなら真
 * @vue-computed {number} minValue 最小値
 * @vue-computed {number} maxValue 最大値
 * @vue-computed {boolean} isUnlimitedValue 上限なしなら真
 * @module Yutai/YutaiSearchYieldRangeSlider
 */
export default {
  name: 'YutaiSearchYieldRangeSlider',
  components: {
    vueSlider: VueSlider
  },
  props: {
    propYieldType: { type: String, default: 'yutai' },
    propMinValue: { type: Number, default: null },
    propMaxValue: { type: Number, default: null },
    propUnlimitedValue: { type: Boolean, default: false }
  },
  data() {
    return {
      yieldType: 'yutai',
      yieldTypeOptions: [
        { text: '株主優待利回り', value: 'yutai'},
        { text: '配当利回り', value: 'haito'},
        { text: '配当＋株主優待利回り', value: 'yutai_haito'}
      ],
      vueSliderOptions: {
        height: 10,
        min: 0,
        max: 100,
        speed: 0.3,
        tooltip: false
      },
      rangeValues: [null, null],
      unlimitedValue: false,
      isValidateError: false
    };
  },
  computed: {
    minValue: {
      get() {
        return this.rangeValues[0];
      },
      /**
       * 入力値をIntegerに変換して最小値に設定する
       *
       * @param {string} inputValue
       */
      set(inputValue) {
        const inputMinValue = this.parseInputValue(inputValue)
        if (inputMinValue !== null) {
          this.setMinValue(inputMinValue);
        } else {
          // 数値以外の値が入った場合は0に変換する
          this.setMinValue(0);
        }
      }
    },
    maxValue: {
      get() {
        return this.rangeValues[1];
      },
      /**
       * 入力値をIntegerに変換して最大値に設定する
       *
       * @param {string} inputValue
       */
      set(inputValue) {
        const inputMaxValue = this.parseInputValue(inputValue)
        if (inputMaxValue !== null) {
          this.setMaxValue(inputMaxValue);
        } else {
          // 数値以外の値が入った場合は0に変換する
          this.setMaxValue(0);
        }
      }
    },
    isUnlimitedValue: {
      get() {
        return this.unlimitedValue;
      },
      /**
       * 入力値をunlimitedValueに設定する
       * trueの場合は最大値をスライダー最大値に設定する
       *
       * @param {string} inputValue
       */
      set(inputValue) {
        this.unlimitedValue = inputValue;
        if (inputValue) { this.setMaxValue(this.vueSliderOptions.max); }
      }
    }
  },
  watch: {
    /**
     * 上限値がレンジバーの最大値以下の場合「上限なし」のチェックボックスを外す
     *
     * @param {Array.<integer>} currentValues 変更値
     * @param {Array.<integer>} previousValues 変更前値
     */
    rangeValues(currentValues, previousValues) {
      if (currentValues !== previousValues) {
        this.validateValues();
        if (this.maxValue < this.vueSliderOptions.max) {
          this.isUnlimitedValue = false;
        }
      }
    },
    /**
     * isValidateErrorが真の時にはフォームのSubmitができないようにする
     * @todo 将来的には検索フォームのStoreを作成して、そちらをセットする様にした方が良い
     *
     * @param {boolean} currentValue 変更値
     */
    isValidateError(currentValue) {
      const searchSubmit = document.querySelector('#search_submit');
      if (currentValue) {
        searchSubmit.style.pointerEvents = 'none';
        searchSubmit.classList.add('theme_disabled');
      } else {
        searchSubmit.style.pointerEvents = '';
        searchSubmit.classList.remove('theme_disabled');
      }
    }
  },
  created() {
    if( this.propMinValue > 0 ) { this.setMinValue(this.propMinValue); }
    if( this.propMaxValue > 0 ) { this.setMaxValue(this.propMaxValue); }
    if( ['yutai', 'haito', 'yutai_haito'].includes(this.propYieldType) ) {
      this.yieldType = this.propYieldType;
    } else {
      this.yieldType = 'yutai';
    }
    this.isUnlimitedValue = this.propUnlimitedValue;
  },
  methods: {
    /**
     * 入力値をintegerに変換して返却する、数値以外の場合はnullを返す
     * @param {string} inputValue 入力値
     * @returns {null|number}
     */
    parseInputValue(inputValue) {
      const pattern = new RegExp("[0-9][0-9]{0,2}");
      const matches = pattern.exec(inputValue);
      if (matches) {
        return parseInt(matches[0]);
      } else {
        return null;
      }
    },
    /**
     * 最小値が最大値より大きければisValidateErrorが真
     */
    validateValues() {
      this.isValidateError = this.minValue > this.maxValue;
    },
    /**
     * min値を$setする
     * @param {number} num
     */
    setMinValue(num) {
      this.$set(this.rangeValues, 0, num);
    },
    /**
     * max値を$setする
     * @param {number} num
     */
    setMaxValue(num) {
      this.$set(this.rangeValues, 1, num);
    }
  }
};
</script>
