<template src="./ReportButtonFloating.html"></template>

<script>
import MkIntersectionObserver from '../../../utils/MkIntersectionObserver';

/**
 * 個別銘柄フローティングレポートボタンコンポーネント
 * @module modules/Stocks/ReportButtonFloating
 */
export default {
  name: 'ReportButtonFloating',
  props: {
    /**
     * 金融アイテムコード
     * @type {string}
     */
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * レポートボタンの表示状態
       * @type {boolean}
       */
      isShow: false,
    };
  },
  computed: {
    /**
     * レポート銘柄登録済みかどうか
     * @returns {boolean}
     */
    isReported() {
      return this.$store.getters['reportFinancialItems/isReported'](this.code);
    },
    /**
     * レポート銘柄登録数が最大かどうか
     * @returns {boolean}
     */
    isFull() {
      return this.$store.getters['reportFinancialItems/isFull'];
    },
    /**
     * ボタン計測用のタグ
     * @returns {string}
     */
    toggleGoogleTag() {
      return this.isReported ? 'report_remove' : 'report_add';
    },
  },
  created() {
    this.$store.dispatch('reportFinancialItems/init');
  },
  mounted() {
    this.handleNotIntersection();
  },
  methods: {
    /**
     * モーダルを表示する
     * @returns {boolean}
     */
    show() {
      if (this.isLoggedIn) {
        if (this.isReported) {
          this.$store.commit('reportModal/showReported');
        } else {
          if (this.isFull) {
            this.$store.commit('reportModal/showFull');
          } else {
            this.$store.commit('reportModal/showReportable');
          }
        }
      } else {
        this.$store.commit('reportModal/showSignUp');
      }
      return false;
    },
    /**
     * 監視対象の要素が表示されてない場合にレポートボタンを表示する
     * @returns {void}
     */
    handleNotIntersection() {
      const callback = (entries, observer) => {
        if (!entries[0].isIntersecting) {
          this.isShow = true;
          observer.disconnect();
        }
      };

      const observer = new MkIntersectionObserver(callback);
      const targetElement = document.getElementById('breadcrumbs');
      observer.observe(targetElement);
    },
  },
};
</script>
