<template src="./AnalysisComponent.html" />
<script>
import { numberWithDelimiter } from '../../../../utils/formatNumber.js';
/**
 * 銘柄検索 個別銘柄サマリーモーダル 基本情報コンポーネント
 * @module StockSearch/modules/StockSummaryModal/header/analysis/AnalysisComponent
 */
export default {
  name: 'AnalysisComponent',
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    latestTradePrice() {
      return numberWithDelimiter(this.summaryData.latestTradePrice, 1).split('.');
    },
    minkabuTargetPrice() {
      return numberWithDelimiter(this.summaryData.minkabuTargetPrice, 0);
    },

    /**
     * 目標株価のクラス名
     * @returns {String}
     */
    minkabuRatingClass() {
      return ['bg-slate-400', 'ui-stock-search-label-2', 'ui-stock-search-label-4'];
    },
    /**
     * アナリスト予想のクラス名
     * @returns {String}
     */
    analystRatingClass() {
      return [
        'bg-slate-400',
        'ui-stock-search-label-1',
        'ui-stock-search-label-2',
        'ui-stock-search-label-3',
        'ui-stock-search-label-4',
        'ui-stock-search-label-5',
      ];
    },
    /**
     * 株価診断のクラス名
     */
    researchRatingClass() {
      return [
        'bg-slate-400',
        'ui-stock-search-label-4',
        'ui-stock-search-label-2',
        'ui-stock-search-label-3',
        'bg-slate-400',
      ];
    },
    /**
     * 個人予想のクラス名
     */
    userExpectationRatingClass() {
      return ['bg-slate-400', 'ui-stock-search-label-2', 'ui-stock-search-label-4'];
    },
  },
};
</script>
