import gql from 'graphql-tag';

export const FRAGMENT_LATEST_TRADE_WITH_CHANGE = gql`
  fragment latestTradeWithChange on LatestTradeWithChange {
    price
    priceDecimalDigit
    priceTime
    change
    changeRatio
  }
`;
