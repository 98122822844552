import gql from 'graphql-tag';

export const QUERY_PEER_COMPANIES_DIVIDEND_INFO = gql`
  query peerCompaniesDividendInfo($code: String!) {
    peerCompaniesDividendInfo(code: $code) {
      financialItem {
        code
        nameAbbr
      }
      settlement {
        quarterlyResults {
          settlementEvaluation
        }
      }
      dividend {
        dividendYield
        projection {
          dps
        }
        payoutRatio
      }
    }
  }
`;
