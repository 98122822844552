import MkApolloProvider from '../../../utils/MkApolloProvider';
import IPOTopComponent from './IPOTop.vue';

/**
 * IPOトップページ ルートコンポーネント
 *
 * @vue-components {IPOTopComponent} ipoTop IPOトップページコンポーネント
 * @module IPOTop
 */
const IPOTop = {
  el: '#v-ipo-top',
  apolloProvider: MkApolloProvider,
  components: {
    ipoTop: IPOTopComponent,
  },
  mounted() {
    document.querySelector('[data-js-id="ipo-top-dummy"]').remove();
  },
};
export default IPOTop;
