/**
 * 入力文字列の状態
 *
 * @property {string} value 入力文字列
 * @module BaseInputStore
 */
const BaseInputStore = {
  namespaced: true,
  state: {
    value: ''
  },
  mutations: {
    value(state, val) {
      state.value = val;
    }
  }
};
export default BaseInputStore;
