import FinancialItemRankingPayLockLink from './FinancialItemRankingPayLockLink.vue';
import FinancialItemRankingSearchBox from './FinancialItemRankingSearchBox.vue';
import NameModal from '../modules/Modal/NameModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 株式ランキング ルートコンポーネント
 * @module FinancialItemRankings
 */
const FinancialItemRankings = {
  el: '#v-financial-item-rankings',
  components: {
    FinancialItemRankingPayLockLink,
    FinancialItemRankingSearchBox,
    NameModal,
    CampaignBanner,
  },
  data() {
    return {
      isBoxVisible: false,
    };
  },
  methods: {
    /**
     * ランキング表示条件の表示/非表示を設定する
     * @param bool
     */
    setBoxVisible(bool) {
      this.isBoxVisible = bool;
    },
  },
};
export default FinancialItemRankings;
