<template src="./YutaiRankingListMini.html" />
<script>
import YutaiRankingRow from './YutaiRankingRow.vue';
import YutaiRankingResource from '../../resources/YutaiRankingResource';

/**
 * 株主優待ランキング一覧表示(3件)
 *
 * @vue-props {String} resourceName ランキングリソース名
 * @vue-data {Array.<YutaiRanking>} rankings ランキング一覧
 * @vue-data {Array.<integer>} months 月タブ表示用の月一覧
 * @vue-data {boolean} isLoading ローディング中なら真
 * @vue-data {YutaiRankingResource} api 株主優待ランキング取得API
 * @vue-computed {boolean} isEmpty 取得したランキングが一件もなければ真
 * @module Yutai/YutaiRankingListMini
 */
export default {
  name: 'YutaiRankingListMini',
  components: {
    yutaiRankingRow: YutaiRankingRow,
  },
  props: {
    resourceName: {
      type: String,
      default: 'total',
    },
  },
  data() {
    return {
      rankings: [],
      currentMonth: 0,
      months: Array.from({ length: 13 }, (_, i) => i),
      isLoading: true,
      api: new YutaiRankingResource(),
    };
  },
  created() {
    this.loadYutaiRankings(0);
  },
  computed: {
    isEmpty() {
      return this.rankings.length === 0;
    },
  },
  methods: {
    /**
     * 月タブ クリック後、その月の優待情報を取得。
     */
    async loadYutaiRankings(month) {
      this.isLoading = true;

      const rankings = await this.api.getYutaiRankings(this.resourceName, month);
      this.rankings = rankings;
      this.currentMonth = month;

      this.isLoading = false;
    },
  },
};
</script>
