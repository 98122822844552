<template src="./AccordionButtons.html" />
<script>
import FavoriteButton from '../../buttons/FavoriteButton.vue';
/**
 * お気に入り銘柄 アコーディオン ボタン類
 * @module Mypage/modules/accordion/buttons/AccordionButtons
 */
export default {
  name: 'AccordionButtons',
  components: {
    FavoriteButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
