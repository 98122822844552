<template src="./SettlementChartCashFlow.html"></template>
<script>
import RoundUtil from '../../utils/RoundUtil';
import VariantUtil from '../../utils/VariantUtil';
import {Chart} from 'highcharts-vue';

/*
 * キャッシュフローチャートコンポーンネント
 *
 * @vue-prop {Settlements::Index::CashFlowCharts::ViewModel} chartElements
 * @module SettlementChartCashFlow
 */

export default {
  name: 'SettlementChartCashFlow',
  props: {
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          backgroundColor: '#ffffff',
          height: 200,
          margin: [38, 0, this.readBottomMargin(), 70]
        },
        title: {
          text: ''
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          y: -16,
          floating: true
        },
        xAxis: {
          categories: [
            '営業CF',
            '投資CF',
            '財務CF',
            'フリーCF'
          ],
          plotBands: [{
            color: '#fdf5e1',
            from: 2.5,
            to: 3.5
          }],
          plotLines: [
            {
              color: '#7f93b5',
              value: -0.5,
              width: 2
            },
            {
              color: '#7f93b5',
              value: 0.5,
              width: 2
            },
            {
              color: '#7f93b5',
              value: 1.5,
              width: 2
            },
            {
              color: '#7f93b5',
              value: 2.5,
              width: 3.5
            }
          ],
          labels: {
            style: {
              fontSize: '16px'
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return RoundUtil.roundJapaneseUnit(this.value, 1);
            }
          },
          tickPositioner: function () {
            const dataMax = Math.max(Math.abs(this.dataMin), Math.abs(this.dataMax));

            // Y軸に表示する数値を切りの良い数値にする
            const first = dataMax.toString(10).slice(0, 1);
            const digit = dataMax.toString(10).replace(/\d/g, 0).slice(2);
            let refVal = parseInt(first + '5' + digit);
            return [-1.4, -0.5, 0, 0.5, 1.4].map(function (n){
              return Math.round(refVal * n);
            });
          }
        },
        tooltip: {
          formatter: function() {
            return `${this.series.name}: <b>${RoundUtil.roundJapaneseUnit(this.point.y, 1)}</b>`;
          }
        },
        series: [
          {
            name: this.chartElements['fiscal_periods'][0],
            color: '#bbcad4',
            data: [
              this.revertRounded(this.chartElements['operating_cash_flows'][0]),
              this.revertRounded(this.chartElements['investing_cash_flows'][0]),
              this.revertRounded(this.chartElements['financing_cash_flows'][0]),
              this.revertRounded(this.chartElements['free_cash_flows'][0])
            ],
            showInLegend: this.legendViewable(this.chartElements['fiscal_periods'][0])
          },
          {
            name: this.chartElements['fiscal_periods'][1],
            color: '#95a5b1',
            data: [
              this.revertRounded(this.chartElements['operating_cash_flows'][1]),
              this.revertRounded(this.chartElements['investing_cash_flows'][1]),
              this.revertRounded(this.chartElements['financing_cash_flows'][1]),
              this.revertRounded(this.chartElements['free_cash_flows'][1])
            ],
            showInLegend: this.legendViewable(this.chartElements['fiscal_periods'][1])
          },
          {
            name: this.chartElements['fiscal_periods'][2],
            color: '#7c8a95',
            data: [
              this.revertRounded(this.chartElements['operating_cash_flows'][2]),
              this.revertRounded(this.chartElements['investing_cash_flows'][2]),
              this.revertRounded(this.chartElements['financing_cash_flows'][2]),
              this.revertRounded(this.chartElements['free_cash_flows'][2])
            ],
            showInLegend: this.legendViewable(this.chartElements['fiscal_periods'][2])
          },
          {
            name: this.chartElements['fiscal_periods'][3],
            color: '#576475',
            data: [
              this.revertRounded(this.chartElements['operating_cash_flows'][3]),
              this.revertRounded(this.chartElements['investing_cash_flows'][3]),
              this.revertRounded(this.chartElements['financing_cash_flows'][3]),
              this.revertRounded(this.chartElements['free_cash_flows'][3])
            ],
            showInLegend: this.legendViewable(this.chartElements['fiscal_periods'][3])
          },
          {
            name: this.chartElements['fiscal_periods'][4],
            color: '#0096fa',
            data: [
              this.revertRounded(this.chartElements['operating_cash_flows'][4]),
              this.revertRounded(this.chartElements['investing_cash_flows'][4]),
              this.revertRounded(this.chartElements['financing_cash_flows'][4]),
              this.revertRounded(this.chartElements['free_cash_flows'][4])
            ],
            showInLegend: this.legendViewable(this.chartElements['fiscal_periods'][4])
          }
        ],
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointWidth: this.readPointWidth(),
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  },
  methods: {
    /**
     * 環境ごとのチャートの幅を返す
     * @return {integer}
     */
    readPointWidth: function() {
      return VariantUtil.isSp() ? 10 : 15;
    },
    /**
     * 環境ごとのチャート下のマージンを返す
     * @return {integer}
     */
    readBottomMargin: function() {
      return VariantUtil.isSp() ? 70 : 30;
    },
    /**
     * 凡例を表示するか返す
     * @return {boolean}
     */
    legendViewable: function(value) {
      return value != null;
    },
    /**
     * 引数の値を100万掛けて返す
     * @param {integer} value
     * @return {integer}
     */
    revertRounded: function(value) {
      return RoundUtil.revertRoundedMillion(value);
    }
  }
};
</script>
