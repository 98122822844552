/**
 * 桁区切り
 * @param {Number} num 数値
 * @param {Number} digits 小数点以下の桁数
 */
const numberWithDelimiter = (num, digits = 0) => {
  if (num === null) {
    return '---';
  }
  return Number(num).toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
};
/**
 * 百万単位
 * @param {Number} num 数値
 * @param {Number} digits 小数点以下の桁数
 */
const roundHundred = (num) => {
  return Number(num) / 1000000;
};

export { numberWithDelimiter, roundHundred };
