<template src="./AccordionInfo.html" />
<script>
import { analysisRating, changeRatio } from '../../../utils/htmlClassName.js';
import { priceInteger, hasDecimal, priceDecimal } from '../../../utils/stockPrice.js';
/**
 * お気に入り銘柄 アコーディオン 株価情報
 * @module Mypage/FavoriteFinancialItems
 */
export default {
  name: 'AccordionInfo',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * 売買予想のHTMLクラス名を返す
     * @return {string}
     */
    analysisRatingClass() {
      return analysisRating(this.item.analysisRating.status);
    },
    /**
     * 前日比のHTMLクラス名を返す
     * @return {string}
     */
    changeRatioClass() {
      return changeRatio(parseFloat(this.item.changeRatio));
    },
    /**
     * 目標株価を返す
     * @return {string}
     */
    targetPrice() {
      return this.$store.getters['favoriteFinancialItemDetails/detailsTargetPrice'](this.item.code);
    },
    /**
     * 最新取引日時を返す（当日の場合は時刻、それ以外は日付）
     * @return {string}
     */
    priceTime() {
      return this.formatPriceTime(new Date(this.item.priceTime.datetime));
    },
    /**
     * 株価の整数部を返す
     * @return {string}
     */
    priceInteger() {
      return priceInteger(this.item.price);
    },
    /**
     * 株価に小数部があれば真
     * @return {string}
     */
    hasDecimal() {
      return hasDecimal(this.item.price);
    },
    /**
     * 株価に小数部があれば小数、そうでなければ空文字を返す
     * @return {string}
     */
    priceDecimal() {
      return priceDecimal(this.item.price);
    },
  },
};
</script>
