<template src="./StockNewsSourceSearchInput.html"/>
<script>
export default {
  name: 'NewsSourceSearchInput',
  data() {
    return {
      source: '0',
      options: [
        { text: '全て', value: '0' },
        { text: 'ニュース', value: '1' },
        { text: '適時開示', value: '2' }
      ]
    }
  },
  watch: {
    // この関数は source が変わるごとに実行されます。
    source: function () {
      this.$store.dispatch('searchNews', { source: this.source });
    }
  }
}
</script>
