<template src="./PeerCompaniesInfo.html"></template>
<script>
import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';
import MkIntersectionObserver from '../../../utils/MkIntersectionObserver';
import { QUERY_INDIVIDUAL_FINANCIAL_ITEM_PEER_COMPANIES_INFO as INDIVIDUAL_F11M_PEER_COMPANIES_INFO } from '../../../queries/IndividualFinancialItemPeerCompaniesInfoQuery';

/**
 * 個別銘柄 他社比較
 *
 * @module modules/Stocks/PeerCompaniesInfo
 */
export default {
  name: 'PeerCompaniesInfo',
  components: {
    FavoriteButtonHeart,
  },
  props: {
    /**
     * 金融アイテムコード
     *  @return {String}
     */
    f11mCode: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * 業種コード
     * @return {String}
     */
    industrySectorCode: {
      type: String,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      individualFinancialItemsPeerCompaniesInfo: [],
    };
  },
  apollo: {
    individualFinancialItemsPeerCompaniesInfo: {
      query: INDIVIDUAL_F11M_PEER_COMPANIES_INFO,
      skip() {
        return true;
      },
      variables() {
        return {
          f11mCode: this.f11mCode,
        };
      },
    },
  },
  computed: {
    /**
     * 注目比率(業種内での当該銘柄が占める30日間お気に入り増加数の割合)が百分率になるようフォーマット
     * @return {String} ex) 12.3
     * @param {Float} attentionRatioInIndustrySector ex) 0.1234
     */
    formatAttentionRatioInIndustrySector() {
      return (attentionRatioInIndustrySector) => {
        return this.numFormat(attentionRatioInIndustrySector * 100, 1);
      };
    },
    /**
     * 決算情報があるか否か
     * @return {Boolean}
     * @param {Object} settlement
     */
    hasSettlement() {
      return (settlement) => {
        return !!settlement;
      };
    },
    /**
     * 業績評価があるか否か
     * @return {Boolean}
     * @param {Object} settlement
     */
    hasSettlementEvaluation() {
      return (settlement) => {
        return (
          this.hasSettlement(settlement) &&
          !!(settlement.quarterlyResults.length > 0 && settlement.quarterlyResults[0].settlementEvaluation)
        );
      };
    },
    /**
     * 業績評価を表す名前
     * @return {String}
     * @param  {Object} settlement
     */
    settlementEvaluationName() {
      return (settlement) => {
        return this.hasSettlementEvaluation(settlement)
          ? this.settlementEvaluationNameWithIconUrl(settlement.quarterlyResults[0].settlementEvaluation).name
          : '---';
      };
    },
    /**
     * 業績評価のアイコンURL
     * @return {String}
     * @param  {Object} settlement
     */
    settlementEvaluationIconSrc() {
      return (settlement) => {
        return this.hasSettlementEvaluation(settlement)
          ? this.settlementEvaluationNameWithIconUrl(settlement.quarterlyResults[0].settlementEvaluation).iconUrl
          : '';
      };
    },
    /**
     * 総合予想があるか否か
     * @return {Boolean}
     * @param {Object} minkabuAnalysis
     */
    hasAnalysis() {
      return (minkabuAnalysis) => {
        return !!minkabuAnalysis;
      };
    },
    /**
     * 有効な総合予想の評価があるか否か
     * @return {Boolean}
     * @param {Object} minkabuAnalysis
     */
    hasAnalysisRating() {
      return (minkabuAnalysis) => {
        return this.hasAnalysis(minkabuAnalysis) && !!this.analysisRating(minkabuAnalysis.rating);
      };
    },
    /**
     * 総合予想の評価を表す名前
     * @return {String}
     * @param {String} rating
     */
    analysisRating() {
      return (rating) => {
        const ratingMap = {
          buy: '買',
          sell: '売',
        };
        return ratingMap[rating];
      };
    },
    /**
     * 配当情報があるか否か
     * @return {Boolean}
     * @param {Object} dividend
     */
    hasDividend() {
      return (dividend) => {
        return !!dividend;
      };
    },
    /**
     * 配当利回りがあるか否か
     * @return {Boolean}
     * @param {Object} dividend
     */
    hasDividendYield() {
      return (dividend) => {
        return this.hasDividend(dividend) && dividend.dividendYield !== null;
      };
    },
    /**
     * 配当利回りのクラス
     * @return {String}
     * @param {Object} dividend
     */
    hasDividendYieldClass() {
      return (dividend) => {
        return this.hasDividendYield(dividend) ? 'text-right' : 'text-center';
      };
    },
    /**
     * 配当利回り
     * @return {String}
     * @param {String} dividendYield
     */
    formatDividendYield() {
      return (dividendYield) => {
        return this.numFormat(dividendYield, 2);
      };
    },
    /**
     * おすすめタグがあるか否か
     * @return {Boolean}
     * @param {Array} recommendedTags
     */
    hasRecommendedTags() {
      return (recommendedTags) => {
        return recommendedTags.length > 0;
      };
    },
    /**
     * おすすめタグ名を表す名前
     * @return {String}
     * @param {String} recommendedTag
     */
    recommendedTagName() {
      return (recommendedTag) => {
        const tagNameMap = {
          choice: '本日期待',
          dividend: '高配当',
          nisa: 'NISA',
          pension: '割安',
          trend: '会員注目',
          yutai: '優待',
          growth: '成長',
        };

        return tagNameMap[recommendedTag];
      };
    },
    /**
     * コンポーネント自体を表示すべきか否か
     * @return {Boolean}
     */
    shouldDisplayComponent() {
      return this.individualFinancialItemsPeerCompaniesInfo.length !== 0;
    },
    /**
     * 自分のコードか否か
     * @param {String} code
     * @return {Boolean}
     */
    isMyCode() {
      return (code) => {
        return code === this.f11mCode;
      };
    },
    /**
     * 自分のコードの背景色を返す
     * @param {String} code
     * @return {String}
     */
    myCodeColor() {
      return (code) => {
        return this.isMyCode(code) ? 'bg-slate-50' : '';
      };
    },
    /**
     * お気に入り登録されているかどうか
     * @returns {boolean}
     */
    isFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.f11mCode);
    },
  },
  watch: {
    shouldDisplayComponent: {
      handler: function (newVal) {
        this.$emit('data-loaded', newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.lazyQuery();
  },
  updated() {
    if (this.isSp && this.shouldDisplayComponent) {
      this.toggleScrollMessage();
    }
  },
  methods: {
    /**
     * @note mountedされるまでpropsから引数を受け取る事ができないので、mountedでskipをfalseにして、queryを発火させている
     */
    lazyQuery() {
      this.$apollo.queries.individualFinancialItemsPeerCompaniesInfo.skip = false;
    },
    /**
     * 監視対象の要素の表示状態に応じてスクロールメッセージを表示・非表示にする
     * @returns {void}
     */
    toggleScrollMessage() {
      const callback = (entries, observer) => {
        if (entries[0].isIntersecting) {
          messageElement.firstElementChild.classList.add('hidden');
          messageElement.classList.remove('mb-2');
          messageElement.classList.add('mb-6');
          observer.disconnect();
        }
      };
      const observer = new MkIntersectionObserver(callback);
      const messageElement = document.getElementById('js-scroll-message');
      const tr = document.getElementById('js-table-header-row');
      const targetElement = tr.lastElementChild;
      observer.observe(targetElement);
    },
    /**
     * モーダルの表示
     */
    open() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
</script>
