import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM_CODE_WITH_NAME_ABBR as FRAGMENT_F11M_CODE_WITH_NAME_ABBR } from './fragments/FinancialItemFragment';
import { FRAGMENT_LATEST_TRADE_WITH_CHANGE } from './fragments/LatestTradeFragment';

const FINANCIAL_ITEM_RANKING_TYPE = `
number
financialItem {
  ...financialItemCodeWithNameAbbr
}
latestTrade {
  ...latestTradeWithChange
}
`;

export const QUERY_FINANCIAL_ITEM_RANKINGS_BUY_PICKS_RISES = gql`
  query financialItemRankingsBuyPicksRises {
    financialItemRankingsBuyPicksRises {
      ${FINANCIAL_ITEM_RANKING_TYPE}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME_ABBR}
  ${FRAGMENT_LATEST_TRADE_WITH_CHANGE}
`;

export const QUERY_FINANCIAL_ITEM_RANKINGS_SELL_PICKS_RISES = gql`
  query financialItemRankingsSellPicksRises {
    financialItemRankingsSellPicksRises {
      ${FINANCIAL_ITEM_RANKING_TYPE}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME_ABBR}
  ${FRAGMENT_LATEST_TRADE_WITH_CHANGE}
`;

export const QUERY_FINANCIAL_ITEM_RANKINGS_RISES = gql`
  query financialItemRankingsRises {
    financialItemRankingsRises {
      ${FINANCIAL_ITEM_RANKING_TYPE}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME_ABBR}
  ${FRAGMENT_LATEST_TRADE_WITH_CHANGE}
`;

export const QUERY_FINANCIAL_ITEM_RANKINGS_FALLS = gql`
  query financialItemRankingsFalls {
    financialItemRankingsFalls {
      ${FINANCIAL_ITEM_RANKING_TYPE}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME_ABBR}
  ${FRAGMENT_LATEST_TRADE_WITH_CHANGE}
`;
