import Highcharts from 'highcharts';
Highcharts.setOptions({
  lang: {
    numericSymbols: null,
    thousandsSep: ','
  }
});

// カスタムした破線のstyleを追加する
Highcharts.wrap(Highcharts.SVGElement.prototype, 'dashstyleSetter', function () {
  let value = arguments[1];
  let i;
  value = value && value.toLowerCase();
  if (value) {
    value = value
      .replace('shortdashdotdot', '3,1,1,1,1,1,')
      .replace('shortdashdot', '3,1,1,1')
      .replace('shortdot', '1,1,')
      .replace('shortdash', '3,1,')
      .replace('longdash', '8,3,')
      .replace('dottwo', '2,2') // customized
      .replace('dotonepointfive', '1.5,1.5') // customized
      .replace(/dot/g, '1,3,')
      .replace('dash', '4,3,')
      .replace(/,$/, '')
      .split(','); // ending comma

    i = value.length;
    while (i--) {
      value[i] = parseInt(value[i]) * this['stroke-width'];
    }
    value = value.join(',')
      .replace('NaN', 'none'); // #3226
    this.element.setAttribute('stroke-dasharray', value);
  }
});
