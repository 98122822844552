import FinancialItemRankingPayLockLink from './FinancialItemRankingPayLockLink.vue';
import NameModal from '../modules/Modal/NameModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 株式ランキングの有料部分ルートコンポーネント
 *
 * @module FinancialItemRankingPayLock
 */

const FinancialItemRankingPayLock = {
  el: '#v-ranking-pay-lock',
  components: {
    financialItemRankingPayLockLink: FinancialItemRankingPayLockLink,
    nameModal: NameModal,
    CampaignBanner: CampaignBanner,
  },
};

export default FinancialItemRankingPayLock;
