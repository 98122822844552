/** Class InputUtil */
class InputUtil {
  /**
   * delayMicroTime中に連続で実行された場合に最後の実行のみtrueを返す
   *
   * @param {Array} que
   * @param {integer} delayMicroTime
   * @returns {Promise<any>}
   */
  static delay(que, delayMicroTime) {
    return new Promise((resolve, reject) => {
      try {
        que.push(1);
        setTimeout(() => {
          que.pop();
          resolve(que.length === 0);
        }, delayMicroTime);
      } catch(error) {
        reject(false);
      }
    });
  }
}
export default InputUtil;
