<template src="./FavoriteSignUpModal.html"></template>
<script>
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * お気に入りボタンからの会員登録モーダルコンポーネント
 * @vue-props {string} signUpUrl 会員登録URL
 * @vue-props {string} loginUrl ログインURL
 * @vue-computed {boolean} isVisible モーダルが表示中なら真
 * @module Modal/FavoriteSignUpModal
 */
export default {
  name: 'FavoriteSignUpModal',
  components: {
    CampaignBanner,
  },
  props: {
    signUpUrl: {
      type: String,
      required: true,
    },
    loginUrl: {
      type: String,
      required: true,
    },
    /**
     * プレミアムLPへのURL
     */
    premiumLpUrl: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * キャンペーンURL
     * @type {string}
     */
    campaignUrl: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters['favoriteSignUpModal/isVisible'];
    },
  },
  methods: {
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      this.$store.commit('favoriteSignUpModal/hide');
    },
  },
};
</script>
