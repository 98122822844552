import StockSearchInput from './modules/StockSearchInput';
import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';
import PremiumModalStore from './modules/modal/PremiumModalStore';
/**
 * 銘柄検索 ルートストア
 *
 * @module StockSearch/StockSearchStore
 */
const StockSearchStore = {
  modules: {
    stockSearchInput: StockSearchInput,
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
    premiumModal: PremiumModalStore,
  },
};
export default StockSearchStore;
