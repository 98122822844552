<template src="./FavoriteFinancialItemsSearch.html" />

<script>
import { generateUrlParams, pushUrlParams } from './utils/urlParams.js';
import { loadingPage } from './utils/loadingPage.js';
/**
 * お気に入り銘柄検索コンポーネント
 * @module Mypage/FavoriteFinancialItemsSearch
 */
export default {
  name: 'FavoriteFinancialItemsSearch',
  computed: {
    /**
     * 検索フォームとVuexのバインディング
     */
    searchKeyword: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/search'].keyword;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchKeyword', value);
      },
    },
    searchNews: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/search'].news;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchNews', value);
      },
    },
    searchAnalysisRating: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/search'].analysisRating;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchAnalysisRating', value);
      },
    },
    searchFiscalEvaluation: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/search'].fiscalEvaluation;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchFiscalEvaluation', value);
      },
    },
    sortSpSelected: {
      get() {
        return this.$store.getters['favoriteFinancialItemDetails/sort'].spSelected;
      },
      set(value) {
        this.$store.commit('favoriteFinancialItemDetails/setSortSpSelected', value);
      },
    },
    /**
     * 現在ページ
     * @return {boolean}
     */
    pageCurrent() {
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current;
    },
    search() {
      return this.$store.getters['favoriteFinancialItemDetails/search'];
    },
    sort() {
      return this.$store.getters['favoriteFinancialItemDetails/sort'];
    },
  },
  methods: {
    /**
     * 絞り込み処理
     * @param {number} keyCode
     */
    async filteringItem(keyCode) {
      // 日本語変換確定のエンターキー(code: 229)で動かないようにする
      // TODO: keyCode は非推奨だが、codeでは Safari で確定エンターか判別が難しいため keyCode で実装
      if (keyCode && keyCode !== 13) {
        return;
      }

      await loadingPage({
        vm: this,
        scroll: true,
        callback: async () => {
          await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/changePage', 1);
          pushUrlParams(generateUrlParams({ page: 1, search: this.search, sort: this.sort }));
        },
      });
    },
    /**
     * 検索のリセット
     */
    async resetForm() {
      await loadingPage({
        vm: this,
        scroll: true,
        callback: async () => {
          await this.$store.dispatch('favoriteFinancialItemDetails/resetForm');
          await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/changePage', 1);
          pushUrlParams(generateUrlParams({ page: 1, search: this.search, sort: this.sort }));
        },
      });
    },
    /**
     * ソート順
     * @param {string} key
     */
    async sortOrder(key) {
      await loadingPage({
        vm: this,
        scroll: true,
        callback: async () => {
          await this.$store.dispatch('favoriteFinancialItemDetails/changeSortOrder', { key: key, isSp: this.isSp });
          await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
          await this.$store.dispatch('favoriteFinancialItemDetails/changePage', this.pageCurrent);
          pushUrlParams(generateUrlParams({ page: 1, search: this.search, sort: this.sort }));
        },
      });
    },
    /**
     * SP版の検索表示切り替え
     */
    toggleSpSearch() {
      this.$store.commit('favoriteFinancialItemDetails/toggleSpSearch');
    },
  },
};
</script>
