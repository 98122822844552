<template src="./InfoComponent.html" />
<script>
import { numberWithDelimiter, roundHundred } from '../../../../utils/formatNumber.js';
import ChartComponent from '../chart/ChartComponent.vue';
/**
 * 銘柄検索 個別銘柄サマリーモーダル 株価参考指標コンポーネント
 * @module StockSearch/modules/StockSummaryModal/body/info/InfoComponent
 */
export default {
  name: 'InfoComponent',
  components: {
    ChartComponent,
  },
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    volume() {
      return numberWithDelimiter(this.summaryData.volume, 0);
    },
    marketCapitalization() {
      return numberWithDelimiter(roundHundred(this.summaryData.marketCapitalization), 0);
    },
    yutai() {
      return this.summaryData.yutai ? this.summaryData.yutai : '---';
    },
    /**
     * チャートデータが全てnullか空の場合は false を返す
     * @return {boolean}
     */
    showChart() {
      const chartData = this.$store.getters['stockSearchInput/summaryData'].chartData;
      const closes = chartData.closes.every((value) => value === null) || chartData.closes.length === 0;
      const volumes = chartData.volumes.every((value) => value === null) || chartData.volumes.length === 0;
      return !closes && !volumes;
    },
  },
};
</script>
