<template src="./DividendPayLockLink.html"></template>
<script>
/**
 * 配当ページPayLockコンポーネント
 * @module Pages/StockDividend/DividendPayLockLink
 */
export default {
  name: 'DividendPayLockLink',
  methods: {
    /**
     * モーダルの表示
     */
    async open(name) {
      this.$store.commit('show', name);
    },
  },
};
</script>
