import CorporateActionsCalendarStore from './CorporateActionsCalendarStore';
import TailwindPaginationStore from '../Pagination/TailwindPaginationStore';

/**
 * コーポレートアクション上場廃止ルートストア
 *
 * @module CorporateActions/CorporateActionsDelistingStore
 */
const CorporateActionsDelistingStore = {
  modules: {
    corporateActionsCalendar: CorporateActionsCalendarStore,
    tailwindPagination: TailwindPaginationStore,
  },
};
export default CorporateActionsDelistingStore;
