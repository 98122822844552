import gql from 'graphql-tag';

export const QUERY_CORPORATE_ACTIONS_WEEKLY_CALENDAR = gql`
query corporateActionsWeeklyCalendar($action: String!, $date: String!) {
  corporateActionsWeeklyCalendar(action: $action, date: $date) {
    days {
      date
      isOpen
    }
    actions {
      date
      count
    }
    calendarPeriod {
      from
      to
    }
  }
}
`;
