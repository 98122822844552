import gql from 'graphql-tag';

export const QUERY_NEWS_ARTICLES_BY_KEYWORDS = gql`
  query newsArticlesByKeywords($keywords: [String!]!, $limit: Int) {
    newsArticlesByKeywords(keywords: $keywords, limit: $limit) {
      articles {
        newsId
        title
        publishedAt
        tags {
          slug
          name
        }
        categories {
          name
          label
        }
        channel {
          name
          title
        }
        author {
          name
          resource
        }
      }
    }
  }
`;
