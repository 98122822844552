<template src="./DrawerWrapper.html" />
<script>
import DrawerButtons from './buttons/DrawerButtons.vue';
import DrawerInfo from './info/DrawerInfo.vue';
import DrawerChart from '../chart/ChartComponent.vue';
import DrawerActivity from './activity/DrawerActivity.vue';
/**
 * お気に入り銘柄ドロワーコンポーネント
 * @vue-components {DrawerButtons} DrawerButtons ボタン類
 * @vue-components {DrawerInfo} DrawerInfo 株価情報
 * @module Mypage/DrawerWrapper
 * @vue-components {DrawerChart} DrawerChart チャート
 * @vue-components {DrawerActivity} DrawerActivity アクティビティ
 */
export default {
  name: 'DrawerWrapper',
  components: {
    DrawerButtons,
    DrawerInfo,
    DrawerChart,
    DrawerActivity,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * ドロワーを閉じる
     */
    hideDrawer() {
      const body = document.querySelector('body');
      const offsetY = -parseFloat(getComputedStyle(body).top);
      this.$store.dispatch('favoriteFinancialItemDetails/changeDisplayDrawer', false);

      // グローバルヘッダを表示
      const header = document.querySelector('#header_sp');
      if (header) {
        header.style.opacity = 1;
      }

      // Bodyのスクロール制御
      body.classList.remove('ui-drawer-scroll-stop');
      body.style.top = null;
      window.scrollTo(0, offsetY);
    },
  },
};
</script>
