<template src="./GuideTimeline.html"></template>
<script>
/**
 * GuideTimeline
 *
 * @module modules/Stocks/GuideTimeline
 */
export default {
  name: 'GuideTimeline',
  props: {
    /**
     * 金融アイテムコード
     *  @return {String}
     */
    f11mCode: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * 金融アイテム名
     *  @return {String}
     */
    f11mName: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * 会員登録URL
     * @type {string}
     */
    signUpUrl: {
      type: String,
      required: true,
    },
    /**
     * ログインURL
     * @type {string}
     */
    loginUrl: {
      type: String,
      required: true,
    },
    /**
     * スタイリングにTailwindを用いるか
     * @type {boolean}
     */
    isTailwind: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * リンク先URL
     * @returns {String}
     */
    guideURL() {
      return `/mypage/timelines?code=${this.f11mCode}`;
    },
    /**
     * お気に入り登録されているかどうか
     * @returns {boolean}
     */
    isFavored() {
      return this.$store.getters['isFavored'](this.f11mCode);
    },
  },
  methods: {
    /**
     * マイページのタイムラインへ遷移する
     */
    goToMypageTimeLine() {
      if (this.isFavored) {
        window.location.assign(this.guideURL);
      } else {
        const favoriteReminderMessage = this.isSp
          ? '右下のハートマークを押して、\r\nお気に入り登録後にタイムラインを見てみましょう!'
          : 'ページ上部のハートマークを押して、\r\nお気に入り登録後にタイムラインを見てみましょう!';
        alert(favoriteReminderMessage);
      }
    },
  },
};
</script>
