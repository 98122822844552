<template src="./IPOItems.html"></template>

<script>
import { ContentLoader } from 'vue-content-loader';
import TailwindPagination from '../../Pagination/TailwindPagination.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';
import IPOItem from './IPOItem.vue';
import { QUERY_IPO_ITEMS as IPO_ITEMS } from '../../../queries/IPOItemsQuery';

/**
 * 上場済みIPO一覧表示コンポーネント
 *
 * @vue-components {ContentLoader} contentLoader シャドースケルトン用
 * @vue-components {TailwindPagination} ipoPagination ページネーション
 * @vue-components {FavoriteButtonHeart} favoriteButtonHeart お気に入りボタン
 * @vue-components {FavoriteSignUpModal} favoriteSignUpModal お気に入りボタン用の会員登録誘導モーダル
 * @vue-apollo {Object} ipoItems IPO情報
 * @module pages/IPOItems/IPOItems
 */
export default {
  name: 'IPOItems',
  components: {
    contentLoader: ContentLoader,
    ipoPagination: TailwindPagination,
    favoriteSignUpModal: FavoriteSignUpModal,
    ipoItem: IPOItem,
  },
  props: {
    /**
     * 初期表示年
     * @vue-props {Number}
     */
    defaultYear: {
      type: Number,
      required: true,
      default: null,
    },
    /**
     * 会員登録用URL
     * @vue-props {String}
     */
    signUpUrl: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * ログインURL
     * @vue-props {String}
     */
    loginUrl: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * プレミアムLPへのURL
     */
    premiumLpUrl: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * キャンペーンURL
     * @return {String}
     */
    campaignUrl: {
      type: String,
      require: false,
      default: null,
    },
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      year: null,
      targetMonth: null,
      displayType: 'list',
      order_by_percentage_change: 'none',
      ipoItems: null,
    };
  },
  computed: {
    /**
     * 表示年
     * @return {Integer}
     */
    targetYear() {
      return this.year || this.defaultYear;
    },
    /**
     * 詳細表示なら真
     * @return {boolean}
     */
    isDetail() {
      return this.displayType === 'detail';
    },
    /**
     * リスト表示なら真
     * @return {boolean}
     */
    isList() {
      return this.displayType === 'list';
    },
    /**
     * 現ページ番号
     * @return {Integer}
     */
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    },
    /**
     * 総ページ数
     * @return {Integer}
     */
    totalPage() {
      if (!this.ipoItems) {
        return null;
      }
      return this.ipoItems.pageInfo.totalPage;
    },
    /**
     * IPO一覧の全件数を返す
     * @return {Integer}
     */
    totalCount() {
      if (!this.ipoItems) {
        return null;
      }
      return this.ipoItems.pageInfo.totalCount;
    },
    /**
     * IPO一覧の表示中の件数(開始)を返す
     * @return {Integer}
     */
    currentPageFrom() {
      if (!this.ipoItems) {
        return null;
      }
      return this.ipoItems.pageInfo.offsetValue + 1;
    },
    /**
     * IPO一覧の表示中の件数(終了)を返す
     * @return {Integer}
     */
    currentPageTo() {
      if (!this.ipoItems) {
        return null;
      }
      return this.ipoItems.pageInfo.offsetValue + this.ipoItems.pageInfo.count;
    },
    /**
     * Select Box用の年一覧を返す。
     * @return {Array<Integer>}
     */
    selectBoxYears() {
      const date = new Date();
      const currentYear = date.getFullYear();
      let years = [];
      for (let i = currentYear; i >= 1999; i--) {
        years.push(i);
      }
      return years;
    },
    /**
     * 騰落率のソート順が昇順か否かを返す。
     * @return {Boolean}
     */
    isOrderByPercentageChangeAsc() {
      return this.order_by_percentage_change === 'asc';
    },
    /**
     * 騰落率のソート順が降順か否かを返す。
     * @return {Boolean}
     */
    isOrderByPercentageChangeDesc() {
      return this.order_by_percentage_change === 'desc';
    },
    hasIPOCollection() {
      if (!this.ipoItems) {
        return false;
      }

      return this.ipoItems.collection.length > 0;
    },
  },
  apollo: {
    ipoItems: {
      query: IPO_ITEMS,
      variables() {
        return {
          year: this.targetYear,
          month: this.targetMonth,
          page: this.currentPage,
          order_by_percentage_change: this.order_by_percentage_change,
        };
      },
    },
  },
  mounted() {
    document.querySelectorAll('[data-dummy-ipo="true"]').forEach((e) => {
      e.remove();
    });
  },
  methods: {
    /**
     * リスト表示切り替え
     */
    displayList() {
      this.displayType = 'list';
    },
    /**
     * 詳細表示切り替え
     */
    displayDetail() {
      this.displayType = 'detail';
    },
    /**
     * 年の切り替え
     */
    selectYear(e) {
      const year = e.target.value;
      window.location.assign(`/ipo/${year}`);
    },
    /**
     * 月の切り替え
     */
    selectMonth(e) {
      const targetMonth = e.target.value;
      this.targetMonth = targetMonth === 'all' ? null : Number(targetMonth);
      this.$store.commit('tailwindPagination/currentPage', 1);
    },
    /**
     * 騰落率のソート順を変更する。
     * ページ番号は1にリセットされる。
     */
    togglePercentageChange() {
      this.$store.commit('tailwindPagination/currentPage', 1);
      switch (this.order_by_percentage_change) {
        case 'none':
          this.order_by_percentage_change = 'desc';
          break;
        case 'desc':
          this.order_by_percentage_change = 'asc';
          break;
        case 'asc':
        default:
          this.order_by_percentage_change = 'none';
          break;
      }
    },
  },
};
</script>
