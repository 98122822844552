import BaseInputStore from '../Base/BaseInputStore';
import StockNewsSearchResultStore from './StockNewsSearchResultStore';
import NewsSearchResource from '../../resources/NewsSearchResource';
import BaseXHRStore from "../Base/BaseXHRStore";

/**
 * @property {NewsSearchResource} apiResource ニュース検索APIクラス
 * @property {string} financialItemCode 銘柄コード
 * @property {string} source 絞り込み値
 * @module StockNews/StockNewsSearchStore
 */
const NewsSearchStore = {
  state: {
    apiResource: new NewsSearchResource(),
    financialItemCode: '1',
    source: '0'
  },
  mutations: {
    setFinancialItemCode (state, financialItemCode) {
      state.financialItemCode = financialItemCode;
    },
    setSource (state, source) {
      state.source = source;
    }
  },
  getters: {
    /* @returns {string} 入力文字列 */
    inputValue(state) {
      return state.input.value;
    },
    /* @returns {BaseCollection}  ニュース検索結果のコレクション */
    resultCollection(state) {
      return state.result.collection;
    },
    /* @returns {Object}  ニュース検索結果ニュース一覧 */
    resultArticles(state) {
      return state.result.collection.items;
    },
    /* @returns {boolean} ニュース検索結果があるか */
    hasResult(state) {
      return state.result.collection.items.length > 0;
    },
    /* @returns {boolean} ニュースAPIがCALLされ終わったか */
    isApiCallDone(state) {
      return state.xhr.requestStatus == 'done';
    },
    /* @return {string} 銘柄コード */
    financialItemCode(state) {
      return state.financialItemCode;
    },
    /* @return {string} 絞り込み値 */
    source(state) {
      return state.source;
    }
  },
  actions: {
    /**
     * ニュース検索API
     *
     * @param {ActionContext} vuexContext
     * @param {Object} values { val: 入力文字列, stock_code: 銘柄コード, source: 絞り込み（0: 全て, 1: ニュース, 2: 適時開示） }
     * @return {Promise<void>}
     */
    async searchNews(context, values) {
      if (values['val'] != undefined) { context.commit('input/value',  values['val']); }
      if (values['source'] != undefined) { context.commit('setSource', values['source']); }

      let collection = {};
      collection = await context.state.apiResource.get({
        q: context.state.input.value,
        stock_code: context.state.financialItemCode,
        source: context.state.source
      });

      context.commit('result/collection', collection);
      context.commit('xhr/requestStatus', 'done');
    }
  },
  modules: {
    input: BaseInputStore,
    result: StockNewsSearchResultStore,
    xhr: BaseXHRStore
  }
};
export default NewsSearchStore;
