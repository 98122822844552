<template src="./GlossaryTooltip.html" />
<script>
/**
 * 銘柄スクリーニング 用語説明ツールチップコンポーネント
 * @module StockSearch/modules/tooltip/GlossaryTooltip
 */

export default {
  name: 'GlossaryTooltip',
  props: {
    itemName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      parentHeight: 0,
      zIndex: 0,
      position: {
        top: false,
        bottom: false,
        left: false,
        right: false,
      },
      // 項目の短縮名からだと後から分からなくなりそうなので、プログラム上は使ってないけどnameキーを生やしている
      tooltipDescription: [
        {
          minimumPurchasePrice: {
            name: '最低投資金額',
            text: 'その銘柄に投資するために最低限必要な金額です。銘柄には単元株という、売買を行う際の基本株数が定められています。単元株が1の銘柄は1株から、単元株100の銘柄は100株から購入することができます。<br>[算出方法] 株価 × 単元株数',
            visible: false,
          },
        },
        {
          per: {
            name: '調整後PER',
            text: '転換社債やストックオプション（新株予約権）など、現在の発行済株式数を変動させる要素を考慮した株式数を基準に算出したものを用いて計算したPERのことを言います。<br>[算出方法] 株価 ÷ 調整後 − 株当たりの純利益',
            visible: false,
          },
        },
        {
          pbr: {
            name: 'PBR',
            text: '純資産の簿価（解散価値）に対し、株価がどの程度評価されているかを示す指標です。<br>[算出方法] 株価 ÷ 一株当たりの純資産（※一株当たりの純資産 = 純資産 ÷ 発行済み株式数）',
            visible: false,
          },
        },
        {
          psr: {
            name: 'PSR',
            text: '市場価格（時価総額）が会社の売上の何倍であるかを示す指標です。<br>[算出方法] 株価 ÷ 一株当たり売上高（※一株当たり売上高=当期売上高÷発行済み株式数）',
            visible: false,
          },
        },
        {
          roe: {
            name: 'ROE',
            text: '自己資本でどれだけ利益を稼いだかを示す指標です。数値が高いほど、株主資本が有効に使われ多くの付加価値が生み出せていることを表します。<br>[算出方法] （当期純利益 ÷ 期中平均株主資本）× 100',
            visible: false,
          },
        },
        {
          roa: {
            name: 'ROA',
            text: '株主そして債権者からの投下された資本（総資産）に対し、いかに効率よく利益を創出できたかを示す指標です。<br>[算出方法] 当期利益 ÷ 期中平均総資産 × 100',
            visible: false,
          },
        },
        {
          settlementEvaluations: {
            name: '業績評価',
            text: '売上高・経常利益・最終利益の総合的な評価をお天気で表現しています。「晴れ」に近づくほど高評価となります。<br>「晴れ」、「曇り時々晴れ」、「曇り」、「雨」、「雷」の5種類あります。',
            visible: false,
          },
        },
        {
          salesCAGR3y: {
            name: '3年平均売上高成長率',
            text: '3年間の売上高実績の成長率から1年当たりの伸び率を平均化した値です。<br>[算出方法] (3年前の売上高 ÷ 最新実績の売上高) <sup>(1 ÷ (3 - 1) - 1)</sup> × 100<br>',
            visible: false,
          },
        },
        {
          operatingIncomeMargin: {
            name: '売上高営業利益率',
            text: '売上高のうち営業利益が占める割合を表した値です。<br>[算出方法] 営業利益 ÷ 売上高 × 100',
            visible: false,
          },
        },
        {
          analystRatings: {
            name: 'アナリスト予想',
            text: 'プロの証券アナリストによる売買判断を使って絞り込めます。「強気買い」「買い」「中立」「売り」「強気売り」「対象外」の6種類が選択できます。',
            visible: false,
          },
        },
        {
          userRatings: {
            name: 'みんかぶ会員予想',
            text: 'みんかぶ会員による株価予想を使って絞り込めます。「買い」「売り」「対象外」の3種類が選択できます。',
            visible: false,
          },
        },
        {
          researchRatings: {
            name: 'AI株価診断',
            text: 'AI株価診断の結果を使って絞り込めます。「割安」「妥当」「割高」「分析中」「対象外」の5種類が選択できます。',
            visible: false,
          },
        },
        { minkabuTpSpDr: { name: '目標株価 乖離率', text: '（目標株価−株価）÷株価', visible: false } },
        { analystTpSpDr: { name: 'アナリスト予想 乖離率', text: '（アナリスト予想株価−株価）÷株価', visible: false } },
        {
          userExpectationTpSpDr: {
            name: 'みんかぶ会員予想 乖離率',
            text: '（みんかぶ会員予想株価−株価）÷株価',
            visible: false,
          },
        },
        { researchTpSpDr: { name: 'AI株価診断 乖離率', text: '（AI株価診断株価−株価）÷株価', visible: false } },
        {
          sharePriceCr1d: {
            name: '株価 騰落率（前営業日）',
            text: '（株価−前営業日株価）÷前営業日株価',
            visible: false,
          },
        },
        {
          sharePriceCr1w: { name: '株価 騰落率（1週間前）', text: '（株価−1週間前株価）÷1週間前株価', visible: false },
        },
        {
          sharePriceCr1m: { name: '株価 騰落率（1ヶ月前）', text: '（株価−1ヶ月前株価）÷1ヶ月前株価', visible: false },
        },
        { sharePriceCr1y: { name: '株価 騰落率（1年前）', text: '（株価−1年前株価）÷1年前株価', visible: false } },
        {
          minkabuTpCr1d: {
            name: '目標株価 変化率（前営業日）',
            text: '（目標株価−前営業日目標株価）÷前営業日目標株価',
            visible: false,
          },
        },
        {
          minkabuTpCr1w: {
            name: '目標株価 変化率（1週間前）',
            text: '（目標株価−1週間前目標株価）÷1週間前目標株価',
            visible: false,
          },
        },
        {
          minkabuTpCr1m: {
            name: '目標株価 変化率（1ヶ月前）',
            text: '（目標株価−1ヶ月前目標株価）÷1ヶ月前目標株価',
            visible: false,
          },
        },
        {
          minkabuTpCr1y: {
            name: '目標株価 変化率（1年前）',
            text: '（目標株価−1年前目標株価）÷1年前目標株価',
            visible: false,
          },
        },
        {
          analystTpCr1d: {
            name: 'アナリスト予想 変化率（前営業日）',
            text: '（アナリスト予想株価−前営業日アナリスト予想株価）÷前営業日アナリスト予想株価',
            visible: false,
          },
        },
        {
          analystTpCr1w: {
            name: 'アナリスト予想 変化率（1週間前）',
            text: '（アナリスト予想株価−1週間前アナリスト予想株価）÷1週間前アナリスト予想株価',
            visible: false,
          },
        },
        {
          analystTpCr1m: {
            name: 'アナリスト予想 変化率（1ヶ月前）',
            text: '（アナリスト予想株価−1ヶ月前アナリスト予想株価）÷1ヶ月前アナリスト予想株価',
            visible: false,
          },
        },
        {
          analystTpCr1y: {
            name: 'アナリスト予想 変化率（1年前）',
            text: '（アナリスト予想株価−1年前アナリスト予想株価）÷1年前アナリスト予想株価',
            visible: false,
          },
        },
        {
          userExpectationTpCr1d: {
            name: 'みんかぶ会員予想 変化率（前営業日）',
            text: '（みんかぶ会員予想株価−前営業日みんかぶ会員予想株価）÷前営業日みんかぶ会員予想株価',
            visible: false,
          },
        },
        {
          userExpectationTpCr1w: {
            name: 'みんかぶ会員予想 変化率（1週間前）',
            text: '（みんかぶ会員予想株価−1週間前みんかぶ会員予想株価）÷1週間前みんかぶ会員予想株価',
            visible: false,
          },
        },
        {
          userExpectationTpCr1m: {
            name: 'みんかぶ会員予想 変化率（1ヶ月前）',
            text: '（みんかぶ会員予想株価−1ヶ月前みんかぶ会員予想株価）÷1ヶ月前みんかぶ会員予想株価',
            visible: false,
          },
        },
        {
          userExpectationTpCr1y: {
            name: 'みんかぶ会員予想 変化率（1年前）',
            text: '（みんかぶ会員予想株価−1年前みんかぶ会員予想株価）÷1年前みんかぶ会員予想株価',
            visible: false,
          },
        },
        {
          researchTpCr1d: {
            name: 'AI株価診断 変化率（前営業日）',
            text: '（AI株価診断株価−前営業日AI株価診断株価）÷前営業日AI株価診断株価',
            visible: false,
          },
        },
        {
          researchTpCr1w: {
            name: 'AI株価診断 変化率（1週間前）',
            text: '（AI株価診断株価−1週間前AI株価診断株価）÷1週間前AI株価診断株価',
            visible: false,
          },
        },
        {
          researchTpCr1m: {
            name: 'AI株価診断 変化率（1ヶ月前）',
            text: '（AI株価診断株価−1ヶ月前AI株価診断株価）÷1ヶ月前AI株価診断株価',
            visible: false,
          },
        },
        {
          researchTpCr1y: {
            name: 'AI株価診断 変化率（1年前）',
            text: '（AI株価診断株価−1年前AI株価診断株価）÷1年前AI株価診断株価',
            visible: false,
          },
        },
      ],
    };
  },
  computed: {
    camelCaseItemName() {
      // (ex): research_tp_dr_1d => researchTpDr1d
      return this.itemName.replace(/_(.)/g, (match, firstCharAfterUnderscore) =>
        firstCharAfterUnderscore.toUpperCase()
      );
    },
    /**
     * 用語説明ツールチップが設定されていたらそのデータを返す
     * @return {object}
     */
    findTooltipDescription() {
      return this.tooltipDescription.find((description) => description[this.camelCaseItemName] !== undefined);
    },
    /**
     * 用語説明ツールチップの表示非表示の状態を返す
     * @return {boolean}
     */
    isVisible() {
      const item = this.findTooltipDescription;
      if (item) {
        return item[this.camelCaseItemName].visible;
      } else {
        return false;
      }
    },
    isTooltipTargetItem() {
      const itemNames = this.tooltipDescription.map((description) => Object.keys(description)[0]);
      return itemNames.includes(this.camelCaseItemName);
    },
    tooltipDescriptionText() {
      const item = this.findTooltipDescription;
      if (item) {
        return item[this.camelCaseItemName].text;
      } else {
        return '';
      }
    },
    activeGlossaryTooltipCount() {
      return this.$store.getters['stockSearchInput/activeGlossaryTooltipCount'];
    },
  },
  methods: {
    /**
     * ツールチップを非表示にする
     * @return {void}
     */
    hide() {
      const item = this.findTooltipDescription;
      item[this.camelCaseItemName].visible = false;
      document.removeEventListener('click', this.handleClickOutside);
      this.$store.commit('stockSearchInput/decrementActiveGlossaryTooltips');
      this.zIndex = 0;
    },
    /**
     * ツールチップを表示
     * @return {void}
     */
    open() {
      const item = this.findTooltipDescription;
      item[this.camelCaseItemName].visible = true;
      document.addEventListener('click', this.handleClickOutside);
      this.$store.commit('stockSearchInput/incrementActiveGlossaryTooltips');
      this.zIndex = this.activeGlossaryTooltipCount;
    },
    /**
     * ツールチップの表示/非表示を切り替える
     * @param {Event} event
     */
    toggle(event) {
      event.preventDefault();
      this.updateClickedPosition(event.clientX, event.clientY);
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const clickX = event.clientX;
      const clickY = event.clientY;

      this.position.top = clickY < screenHeight / 2;
      this.position.bottom = clickY >= screenHeight / 2;
      this.position.left = clickX < screenWidth / 2;
      this.position.right = clickX >= screenWidth / 2;

      if (this.isVisible) {
        this.hide();
      } else {
        this.open();
      }
    },
    /**
     * ツールチップ表示対象の要素をクリックした位置を更新する
     * @note 現状、position.left/right は使ってないけど一応更新してる
     * @param {number} clickX
     * @param {number} clickY
     */
    updateClickedPosition(clickX, clickY) {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      this.position.top = clickY < screenHeight / 2;
      this.position.bottom = clickY >= screenHeight / 2;
      this.position.left = clickX < screenWidth / 2;
      this.position.right = clickX >= screenWidth / 2;
    },
    /**
     * ツールチップを表示する要素かどうかを判定する
     * @param {HTMLElement} element
     * @return {boolean}
     */
    isOpenTooltipTarget(element) {
      // 本当はアイコンだけでいいけど、チェックボックスがevent.targetになってしまうのでチェックボックスも対象にしている（直せたら直したい）
      return element.classList.contains('fa-circle-question') || element.matches("input[type='checkbox']");
    },
    /**
     * ツールチップ以外をクリックした場合にツールチップを非表示にするためのイベントハンドラ
     * @param {Event} event
     * @return {void}
     */
    handleClickOutside(event) {
      if (!this.isOpenTooltipTarget(event.target)) {
        this.hide();
      }
    },
  },
};
</script>
