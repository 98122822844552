<template src="./ChangesInPayoutRatioChart.html"></template>

<script>
import { Chart } from 'highcharts-vue';
import VariantUtil from '../../../utils/VariantUtil';

/*
 * 個別銘柄配当ページの配当性向推移チャートコンポーネント
 *
 * @vue-prop {Hash} changesInPayoutRatio
 * @module ChangesInPayoutRatioChart
 */

export default {
  name: 'ChangesInPayoutRatioChart',
  components: {
    highcharts: Chart,
  },
  props: {
    changesInPayoutRatio: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /**
     * 配当性向推移チャートの各種設定
     * @return {Object}
     */
    chartOptions() {
      return {
        chart: {
          height: this.readHeight,
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        lang: {
          decimalPoint: '.',
          thousandsSep: ',',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: '',
          },
          offset: 1,
          lineWidth: 2,
          lineColor: '#808080',
          categories: this.categoriesData,
          labels: {
            autoRotationLimit: 40,
            style: {
              fontSize: 12,
            },
          },
        },
        yAxis: [
          {
            title: {
              text: '（%）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: -35,
              style: {
                fontSize: 12,
              },
            },
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: 35,
              style: {
                fontSize: 12,
              },
            },
            opposite: true,
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
        ],
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: '1株あたりの純利益',
            color: '#BECAD3',
            data: this.seriesColumnData,
            pointWidth: 40,
            yAxis: 1,
          },
          {
            type: 'line',
            color: '#1E3A8A',
            name: '配当性向',
            data: this.seriesLineData,
            yAxis: 0,
          },
        ],
        tooltip: {
          shared: true,
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            return this.points.reverse().reduce(function (s, p) {
              let tooltipShape = '';
              if (p.key === '配当性向') {
                tooltipShape = 'dividend-legend dividend-legend--payout-ratio';
              } else if (p.key === '1株あたりの純利益') {
                tooltipShape = 'dividend-legend dividend-legend--base';
              }
              return (
                s +
                '<br/>' +
                `<span class="${tooltipShape}"></span> ` +
                `${p.key}:${p.y.toLocaleString('ja-JP', { minimumFractionDigits: p.point.precision })}${p.point.unit}`
              );
            }, '<span style="font-size:12px">' + this.points[0].point.period);
          },
        },
      };
    },
    /**
     * 1株あたりの純利益のチャート表示用のデータを返す
     * @return {Array}
     */
    seriesColumnData() {
      return this.changesInPayoutRatio['eps_array'].map((eps, idx) => ({
        y: Math.trunc(eps * 100) / 100,
        name: '1株あたりの純利益',
        precision: 2,
        unit: '円',
        period: this.tooltipPeriod(this.changesInPayoutRatio['fiscal_periods'][idx]),
      }));
    },
    /**
     * 配当性向のチャート表示用のデータを返す
     * @return {Array}
     */
    seriesLineData() {
      return this.changesInPayoutRatio['payout_ratios'].map((payout_ratio, idx) => ({
        y: payout_ratio == undefined ? null : Math.trunc(payout_ratio * 100) / 100,
        name: '配当性向',
        precision: 2,
        unit: '%',
        period: this.tooltipPeriod(this.changesInPayoutRatio['fiscal_periods'][idx]),
        marker: { radius: 5 },
      }));
    },
    /**
     * x軸に表示するメモリの値を返す
     * @return {Array}
     */
    categoriesData() {
      return this.changesInPayoutRatio['fiscal_periods'].map((period) => period);
    },
    /**
     * SPかどうか判定して高さを返す
     * @return {Number}
     */
    readHeight() {
      return VariantUtil.isSp() ? 220 : 365;
    },
  },
  methods: {
    /**
     * ツールチップに表示する年度情報を返す
     * @params {String} period
     * @return {String}
     */
    tooltipPeriod: function (period) {
      const array = period.split('.');
      return array[0] + '年' + array[1] + '月期';
    },
  },
};
</script>
