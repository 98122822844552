/**
 * ページネーションの数値セルクラス
 * - gapはpage=0,isGap=trueと定義する
 *
 * @property {number} page ページ番号
 * @property {string} url URL
 * @property {boolean} isCurrent 現在のページかどうか
 * @property {boolean} isGap gapかどうか
 * @module BasePagination/PageCell
 */
class PageCell {
  constructor(page, currentPage, url) {
    this.page = page;
    this.url = url;
    this.isCurrent = page === currentPage;
    this.isGap = page === 0;
  }
}
export default PageCell;
