<template src="./DividendComponent.html" />
<script>
/**
 * 銘柄検索 個別銘柄サマリーモーダル 配当コンポーネント
 * @module StockSearch/modules/StockSummaryModal/body/dividend/DividendComponent
 */
export default {
  name: 'DividendComponent',
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
  },
};
</script>
