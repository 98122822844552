import gql from 'graphql-tag';

export const QUERY_INDIVIDUAL_FINANCIAL_ITEM_PEER_COMPANIES_INFO = gql`
  query individualFinancialItemsPeerCompaniesInfo($f11mCode: String!) {
    individualFinancialItemsPeerCompaniesInfo(f11mCode: $f11mCode) {
      financialItem {
        code
        nameAbbr
      }
      settlement {
        quarterlyResults {
          settlementEvaluation
        }
      }
      favorite {
        attentionRatioInIndustrySector
      }
      minkabuAnalysis {
        rating
      }
      dividend {
        dividendYield
      }
      yutai
      recommendedTags
    }
  }
`;
