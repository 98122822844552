import IntradayChart from '../../modules/StockChart/IntradayChart.vue';
import HistoricalChart from '../../modules/StockChart/HistoricalChart.vue';
import PeriodLabel from '../../modules/StockChart/PeriodLabel.vue';
import PeriodLabelTailwind from '../../modules/StockChart/PeriodLabelTailwind.vue';

/*
 * 個別銘柄トップのチャート
 * @vue-components {IntradayChart} IntradayChart 日中足チャート
 * @vue-components {HistoricalChart} HistoricalChart 履歴チャート
 * @vue-components {PeriodLabel} PeriodLabel 期間選択ラベル
 */
const StockTopChart = {
  el: '#v-stock-top-chart',
  components: {
    IntradayChart,
    HistoricalChart,
    PeriodLabel,
    PeriodLabelTailwind,
  },
  computed: {
    /**
     * 日中足チャートであれば真
     * @return {boolean}
     */
    isIntradayChart() {
      return this.$store.getters['chartTerm'] === 'today';
    },
    /**
     * 履歴チャートであれば真
     * @return {boolean}
     */
    isHistoricalChart() {
      return this.$store.getters['chartTerm'] !== 'today';
    },
  },
};

export default StockTopChart;
