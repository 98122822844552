/**
 * 株主優待モデル
 *
 * @property {string} financialItemCode 金融アイテムコード
 * @property {string} image 画像パス
 * @property {string} financialItemName 金融アイテム名
 * @property {string} summary 株主優待概要
 * @property {Array<string>} categoryNames カテゴリー名リスト
 * @property {number} minimumPurchasePrice 最低投資金額
 * @property {string} yutaiYield 優待利回り率
 * @property {Array<number>} months 権利確定月リスト
 * @property {string} financialItemLinkUrl 個別銘柄の優待ページへのリンクパス
 * @property {boolean} available 株主優待フラグ（株主優待が廃止された場合はfalse）
 * @property {string} financialItemNameWithYutai 金融アイテム名+優待文言
 */
class YutaiInfo {
  /**
   * 初期化処理
   *
   * @params {object} data
   * @constructor
   */
  constructor(data) {
    this.financialItemCode = data.financial_item_code;
    this.image = data.image;
    this.financialItemName = data.financial_item_name;
    this.summary = data.summary;
    this.categoryNames = data.category_names;
    this.minimumPurchasePrice = data.minimum_purchase_price;
    this.unitToRight = data.unit_to_right;
    this.yutaiYield = data.yutai_yield;
    this.dividendYield = data.dividend_yield;
    this.totalYield = data.total_yield;
    this.months = data.months;
    this.financialItemLinkUrl = this._financialItemLinkUrl();
    this.available = data.available;
    this.financialItemNameWithYutai = this._financialItemNameWithYutai();
  }

  _financialItemLinkUrl() {
    return `/stock/${this.financialItemCode}/yutai`;
  }

  _financialItemNameWithYutai() {
    return `${this.financialItemName.name}の株主優待`;
  }
}

export default YutaiInfo;
