import gql from 'graphql-tag';

export const FRAGMENT_YUTAI_ITEM = gql`
  fragment yutaiItem on Yutai {
    number
    code
    summary
    purchasePrice
    cutoffMonths
    imageUrl
    available
  }
`;

export const FRAGMENT_YUTAI_CATEGORY_NAME = gql`
  fragment yutaiCategoryName on YutaiCategory {
    name
  }
`;
