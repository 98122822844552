<template src="./IntradayFxChart.html" />
<script>
import dayjs from 'dayjs';
import NumberFormatMixin from '../../../mixins/NumberFormatMixin';
import IntradayFxChartResource from '../../../resources/IntradayFxChartResource.js';

const CHART_STYLE = {
  labelPosition: 12,
  labelColor: '#666',
  labelFontSize: '10px',
  tooltipBorderColor: '#666',
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 為替の日中チャートコンポーネント
 * @vue-data {IntradayFxChart} intradayFxChart
 * @module FxCharts/FxChart
 */
export default {
  props: {
    code: {
      type: String,
      required: false,
      default: 'USDJPY',
    },
    height: {
      type: String,
      required: false,
      default: '250px',
    },
  },
  data() {
    return {
      intradayFxChart: null,
    };
  },
  computed: {
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          height: vueThis.height,
          animation: false,
          margin: [26, 0, 26, 0],

          pinchType: false,
          events: {
            redraw: function (chart) {
              const x = 0;
              const y = CHART_STYLE.labelPosition;
              const color = CHART_STYLE.labelColor;
              const fontSize = CHART_STYLE.labelFontSize;
              const closedAtPos = vueThis.isPc ? 80 : 95;

              chart.target.renderer.text(`[${vueThis.code}] 6時間`, x, y).css({ color, fontSize }).add();

              chart.target.renderer
                .text(dayjs(vueThis.closedAt).format('YYYY/MM/DD HH:mm'), chart.target.chartWidth - closedAtPos, y)
                .css({ color, fontSize })
                .add();
            },
          },
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          borderColor: CHART_STYLE.tooltipBorderColor,
          xDateFormat: '%Y/%m/%d %H:%M',
          formatter: function () {
            const st = this.points.find((p) => p.series.name === '株価');
            if (!st) {
              return false;
            }

            const point = st.point;
            const dayjsPriceTime = dayjs(point.x);
            let lines = [];

            lines.push(dayjsPriceTime.format('YYYY/MM/DD HH:mm'));

            lines.push(`<b>${NumberFormatMixin.methods.numFormat(point.y, 2)}</b>`);

            return lines.join('<br />');
          },
          shared: true,
          useHTML: true,
          valueDecimals: 1,
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#4572A7',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
            },
            dataGrouping: {
              enabled: false, // 日中チャートは空のデータも描画したいのでfalseにしている
            },
          },
          line: {},
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            type: 'line',
            name: '前日終値',
            animation: false,
            data: this.lastCloseChartData,
            color: 'red',
            lineWidth: 2,
            dashStyle: 'ShortDot',
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
          },
        ],
        xAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            dateTimeLabelFormats: {
              minute: '%H:%M',
              hour: '%H',
              day: '%m/%d',
              week: '%e. %b',
              month: "%b '%y",
              year: '%Y',
            },
            tickInterval: 60 * 60 * 1000,
          },
        ],
        yAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            labels: {
              align: 'left',
              x: 0,
            },
            gridLineColor: '#ddd',
            opposite: false,
            max: this.yAxisMax,
            min: this.yAxisMin,
          },
        ],
        time: {
          useUTC: false,
        },
      };
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.intradayFxChart) {
        return [];
      }

      return this.intradayFxChart.chartData();
    },
    /**
     * 前日終値描画用データ
     * @return {Array}
     */
    lastCloseChartData() {
      if (!this.intradayFxChart) {
        return [];
      }

      return this.intradayFxChart.lastCloseChartData();
    },
    /**
     * 前日の終値
     * @return {Integer}
     */
    lastClose() {
      if (!this.intradayFxChart) {
        return null;
      }
      return this.intradayFxChart.lastClose();
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this.chartData.map((e) => e[1]).filter((e) => e), this.lastClose) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...[...this.chartData.map((e) => e[1]), this.lastClose].filter((e) => e)) * 0.9995;
    },
    /**
     * 最終株価時刻
     * @return {Date}
     */
    closedAt() {
      if (!this.intradayFxChart) return;

      return this.intradayFxChart.closedAt();
    },
  },
  mounted() {
    this.loadIntradayFxChart();
  },
  methods: {
    /**
     * 日中足チャートデータを読み込む
     * @return {void}
     */
    async loadIntradayFxChart() {
      const resource = new IntradayFxChartResource();
      const intradayFxChart = await resource.get(this.code);

      this.intradayFxChart = intradayFxChart;
    },
  },
};
</script>
