import MkApolloProvider from '../../../utils/MkApolloProvider';
import NewsItemsComponent from './NewsItems.vue';

const NewsItems = {
  el: '#v-news-items',
  apolloProvider: MkApolloProvider,
  data: { isHide: false },
  methods: {
    switchHideFlg(val) {
      this.isHide = !val;
    },
  },
  components: {
    newsItems: NewsItemsComponent,
  },
};
export default NewsItems;
