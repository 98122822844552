import ReportFinancialItemResource from '../../resources/ReportFinancialItemResource';
import UserLoginStatus from '../../utils/UserLoginStatus';

/**
 * @property {ReportFinancialItemResource} resource レポート銘柄登録APIクラス
 * @property {array} financialItemCodes レポート銘柄に登録している金融アイテムコード
 * @property {string} notificationMessage 通知メッセージ
 * @property {string} loadingState financialItemCodesのロード処理が開始されたかどうかの状態
 */
const ReportFinancialItemsStore = {
  namespaced: true,
  state: {
    resource: new ReportFinancialItemResource(),
    financialItemCodes: [],
    notificationMessage: '',
    loadingState: 'none',
  },
  getters: {
    /* *
     * 金融アイテムがレポート銘柄登録されているか
     * @params {string} code 金融アイテムコード
     * @return {boolean}
     * */
    isReported: (state) => (code) => {
      return state.financialItemCodes.indexOf(code) >= 0;
    },
    /**
     * レポート銘柄登録上限に達しているか
     * @return {boolean}
     */
    isFull: (state) => {
      return state.financialItemCodes.length >= 5;
    },
  },
  mutations: {
    addFinancialItemCode(state, financialItemCode) {
      state.financialItemCodes.push(financialItemCode);
    },
    removeFinancialItemCode(state, financialItemCode) {
      state.financialItemCodes = state.financialItemCodes.filter((code) => code !== financialItemCode);
    },
    registerLoadingState(state, loadingState) {
      state.loadingState = loadingState;
    },
    fetchFavoriteFinancialItemCode(state, response) {
      state.financialItemCodes = response;
    },
    registerNotificationMessage(state, notificationMessage) {
      state.notificationMessage = notificationMessage;
    },
  },
  actions: {
    /**
     * 初期処理
     * 複数のボタンから呼ばれても処理が並列しないようにフラグを持つ
     */
    async init({ state, dispatch, commit }) {
      if (!UserLoginStatus.isLoggedIn()) {
        return;
      }
      if (state.loadingState === 'loading') {
        return;
      }
      commit('registerLoadingState', 'loading');

      dispatch('get');
    },
    /**
     * レポート銘柄登録済み銘柄コードを取得する
     * エラーの場合は、間隔を開け再取得処理
     */
    async get({ state, dispatch, commit }) {
      const response = await state.resource.get();
      if (response === 'error') {
        setTimeout(() => dispatch('get'), 10000);
      } else {
        commit('fetchFavoriteFinancialItemCode', response);
      }
    },
    /**
     * レポート銘柄登録状態変更
     */
    async toggle({ getters, dispatch }, financialItemCode) {
      if (getters['isReported'](financialItemCode)) {
        await dispatch('destroy', financialItemCode);
      } else {
        await dispatch('create', financialItemCode);
      }
    },
    /**
     * レポート銘柄登録
     */
    async create({ commit, state }, financialItemCode) {
      const response = await state.resource.create(financialItemCode);
      if (response.status === 201) {
        commit('addFinancialItemCode', financialItemCode);
        commit('registerNotificationMessage', 'レポート銘柄に登録しました');
      } else if (response.data.message === '既にレポートメールを受け取る設定になっています。') {
        commit('registerNotificationMessage', response.data.message);
      } else if (response.data.message === 'レポートメール銘柄は5件までしか登録できません') {
        commit('registerNotificationMessage', response.data.message);
      } else {
        commit('registerNotificationMessage', 'システムエラーが発生しました。時間を置いてからもう一度試してください。');
      }
    },
    /**
     * レポート銘柄削除
     */
    async destroy({ commit, state }, financialItemCode) {
      const response = await state.resource.delete(financialItemCode);

      if (response.status === 204) {
        commit('removeFinancialItemCode', financialItemCode);
        commit('registerNotificationMessage', 'レポート銘柄を削除しました');
      } else {
        commit('registerNotificationMessage', 'システムエラーが発生しました。時間を置いてからもう一度試してください。');
      }
    },
  },
};
export default ReportFinancialItemsStore;
