import gql from 'graphql-tag';

export const QUERY_CORPORATE_ACTIONS_STOCK_TRANSFERS = gql`
query corporateActionsStockTransfers($action: String!, $year: Int!, $month: Int!, $day: Int, $page: Int) {
  corporateActionsStockTransfers(action: $action, year: $year, month: $month, day: $day, page: $page) {
    collection {
      date
      financialItem {
        code
        name
        nameAbbr
      }
      splitRatio
      latestTradeWithChange {
        price
        priceDecimalDigit
        priceTime
        change
        changeRatio
      }
    }
    pageInfo {
      offsetValue
      totalPage
      currentPage
      totalCount
      count
    }
  }
}
`;
