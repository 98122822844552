<template src="./YutaiNewsFurthersButton.html"></template>
<script>
import BaseLoading from '../Base/BaseLoading.vue';

/*
* 銘柄優待ニュース、さらに表示ボタンコンポーネント
* @property {baseLoading} baseLoading [component] ローディングコンポーネント
* @property {boolean} buttonStatus さらに表示ボタンのステータス(これ以上押せるか押せないか, trueの場合は押せる)
* @module Stock/YutaiNewsFurthersButton
*/
export default {
  name: 'YutaiNewsFurthersButton',
  components: {
    baseLoading: BaseLoading
  },
  computed: {
    buttonStatus() {
      return this.$store.state.StockYutaiNews.furthersButtonStatus;
    },
  },
  methods: {
    /*
    * さらに表示ボタン押下後、優待関連ニュース取得
    */
    async FetchYutaiNews() {
      this.$store.dispatch("BaseLoading/uploadLoadingStatus", true);
      await this.$store.dispatch('StockYutaiNews/updateYutaiNewsList');
      this.$store.dispatch("BaseLoading/uploadLoadingStatus", false);
    }
  }
};
</script>
