<template src="./ItfRiskReturnChart.html"></template>

<script>
import {Chart} from 'highcharts-vue';

/*
 * 個別銘柄ページの投信チャートコンポーネント
 *
 * @vue-prop {Hash} fundMap
 * @module ItfRiskReturnChart
 */

export default {
  name: 'ItfRiskReturnChart',
  props: {
    fundMap: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    highcharts: Chart
  },
  computed: {
    chartOptions() {
      let that = this;
      return {
        chart: {
          type: 'scatter',
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'リスク ( % )'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          title: {
            text: 'リターン ( % )'
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },

            events: {
              click(event){
                if(that.isPC){
                  window.open(`https://itf.minkabu.jp/fund/${event.point.code}`, '_blank', 'noopener');
                  return false;
                }
              }
            }
          }
        },
        tooltip: {
          useHTML: true,
          headerFormat: '',
          outside: false,
          pointFormat: '<b><a href="https://itf.minkabu.jp/fund/{point.code}" target="_blank" rel="noopener">{point.name}</a></b><br>リターン: <b>{point.y}</b><br>リスク: <b>{point.x}</b>', //
          style: {
            pointerEvents: 'auto'
          }
        },
        series: [{ data: this.seriesData }]
      };
    },
    seriesData() {
      return this.fundMap.items.map((fund) => {
        return {
          y:    fund.returns,
          x:    fund.risk,
          name: fund.name,
          code: fund.code
        };
      });
    },
    isPC() {
      return this.fundMap.platform === 'pc';
    }
  }
};
</script>
