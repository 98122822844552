import ItemForNewsListDisplay from './ItemForNewsListDisplay.vue';
import YutaiNewsFurthersButton from './YutaiNewsFurthersButton.vue';
import UpdateYutaiNewsButton from './UpdateYutaiNewsButton.vue';

/*
 * 銘柄 優待ページ優待関連ニュースのルートコンポーネント
 *
 * @vue-computed {array<StockYutaiNews>} StockYutaiNewsList 優待関連ニュース
 * @vue-computed {array<StockYutaiNews>} LatestYutaiNewsList  最新優待関連ニュース
 */
const StockYutaiNews = {
  el: '#yutai_news',
  data: {
    loading_display: true,
    yutai_news_exist: true
  },
  components: {
    itemForListDisplay: ItemForNewsListDisplay,
    yutaiNewsFurthersButton: YutaiNewsFurthersButton,
    updateYutaiNewsButton: UpdateYutaiNewsButton
  },
  /*
   * 初期処理
   * ニュースの総ページ数, 銘柄コード, さらに表示ボタン表示有無をstateにセットする
   * */
  created() {
    this.$store.dispatch("StockYutaiNews/initialProcessing");
    this.loading_display = false;
  },
  computed: {
    StockYutaiNewsList() {
      return this.$store.state.StockYutaiNews.NewsList;
    },
    LatestYutaiNewsList() {
      return this.$store.state.StockYutaiNews.LatestNewsList;
    }
  },
  watch: {
    /*
     * ニュースが取得できた場合はニュース表示領域を非表示にする
     */
    StockYutaiNewsList(val) {
      if (val.length === 0) {
        this.yutai_news_exist = false;
      }
    }
  }
};
export default StockYutaiNews;
