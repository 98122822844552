import StringUtil from '../utils/StringUtil';

/**
 * ニュースカテゴリクラス
 *
 * @property {string} url ニュースカテゴリのURL
 * @property {string} css カテゴリアイコン用のCSSクラス
 * @property {string} label カテゴリ名
 */
class NewsCategory {
  constructor(params) {
    this.url = params.url;
    this.css = params.css;
    this.label = params.label;
  }
}

/**
 * ニュースタグクラス
 *
 * @param {Object} params
 * @param {string} params.slug タグのスラッグ (例：dividend、long-term-interest）
 * @param {string} params.name タグ名（例：配当、長期金利）
 * @property {string} url タグ別ニュース一覧ページのURL (/news/tag/:slug)
 * @property {string} name タグ名
 */
class NewsTag {
  constructor(params) {
    this.url = `/news/tag/${params.slug}`;
    this.name = params.name;
  }
}

/**
 * ニュースチャンネルクラス
 *
 * @property {string} url チャンネルのURL
 * @property {string} title チェンネル名
 */
class NewsChannel {
  constructor(params) {
    this.url = params.url;
    this.title = params.title;
  }
}

/** @class */
class NewsArticle {
  /**
   * 初期化処理
   *
   * @param {Object} params
   * @constructor
   */
  constructor(params) {
    this.url = params.url;
    this.title = params.title;
    this.publishedAt = params.published_at;
    this.author = params.author;
    this.authorPath = params.author_path;
    this.isNew = params.is_new;
    this.channel = new NewsChannel(params.channel);
    this.categories = this._toCategories(params.categories);
    this.tags = this._toTags(params.tags);
    this.channelHeading = params.channel_heading;
  }

  /**
   * キーワードでハイライト処理されたtitleを返却する
   *
   * @param {string} keyword キーワード
   * @returns {string} ハイライト処理されたタイトル
   */
  highlightedTitle(keyword) {
    return StringUtil.highlightWithJapanese(this.title, keyword);
  }

  /**
   * ニュースカテゴリの配列を返却する
   *
   * @param {array<Object>} categoriesOfParam
   * @returns {array<NewsCategory>} ニュースカテゴリの配列
   * @private
   */
  _toCategories(categoriesOfParam) {
    return categoriesOfParam.map((category) => {
      return new NewsCategory(category);
    });
  }

  /**
   * ニュースタグの配列を返却する
   *
   * @param {array<Object>} tagsOfParam
   * @param {string} tagsOfParam.slug タグのスラッグ
   * @param {string} tagsOfParam.name タグ名
   * @returns {array<NewsTag>} ニュースタグの配列
   * @private
   */
  _toTags(tagsOfParam) {
    return tagsOfParam.map((tag) => {
      return new NewsTag(tag);
    });
  }
}

export default NewsArticle;
