/** @class */
class IntradayFxChart {
  /**
   * 日中為替チャート
   *
   * @param {Object} data
   * @note {
   *         last_price: { price_on: string, close: float},
   *         items: [
   *                  [string, float], // ["2023-12-26T11:13:00+09:00", 123.45]
   *                  [],...
   *                ],
   *         updated_at: string
   *       }
   * @constructor
   */
  constructor(data) {
    this.lastPrice = data.last_price;
    this.items = data.items;
  }

  /**
   * チャート用に整形した日中データ
   * @returns {Array<Array>}
   */
  chartData() {
    return this.items.map((p) => [Date.parse(p[0]), p[1]]);
  }

  /**
   * 前営業日終値
   * @return {Number}
   */
  lastClose() {
    return this.lastPrice.close;
  }

  /**
   * 終値時刻
   * @return {Date}
   */
  closedAt() {
    let time_index = 0;
    return Date.parse(this.items[this.items.length - 1][time_index]);
  }

  /**
   * 前営業日終値のチャートデータ
   * @return {Array}
   */
  lastCloseChartData() {
    return this.items.map((p) => [Date.parse(p[0]), this.lastClose()]);
  }
}
export default IntradayFxChart;
