import CookieUtil from './CookieUtil';
/**
 * ユーザがログインしているかどうかを判定するクラス
 */
class UserLoginStatus {
  /**
   * @return {bool} ログイン済みの場合はtrue
   */
  static isLoggedIn() {
    const userId = CookieUtil.get('user_id');
    return typeof userId === 'string' && userId.trim() !== '';
  }
}

export default UserLoginStatus;
