/* script */
export * from "!!babel-loader!../../../../../../../../../../../shared/node_modules/vue-loader/lib/selector?type=script&index=0!./DividendComponent.vue"
import __vue_script__ from "!!babel-loader!../../../../../../../../../../../shared/node_modules/vue-loader/lib/selector?type=script&index=0!./DividendComponent.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../../../../../../../../../shared/node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-1c43c520\",\"hasScoped\":false,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!./DividendComponent.html"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = null
/* scopeId */
var __vue_scopeId__ = null
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
