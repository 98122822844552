<template src="./StockSearchSimilarLink.html"></template>

<script>
import axios from 'axios';

/**
 * 銘柄スクリーニング動的リンク
 *
 * @module modules/Stocks/StockSearchSimilarLink
 */

const CONDITIONS = {
  free: [
    'per',
    'pbr',
    'market_capitalization',
    'sales_cagr_3y',
    'dividend_yield',
    'capital_adequacy_ratio',
    'operating_income_margin',
  ],
  paid: [
    'minkabu_ratings',
    'analyst_ratings',
    'settlement_evaluations',
    'highest_settlement_evaluation',
    'user_expectation_ratings',
  ],
};
const MAX_RANGE = { per: 100.0, pbr: 8.0 };
const MIN_RANGE = { operating_income_margin: -50.0 };

const MAPPINGS = {
  sales_cagr_3y: 'sales_cagr_3years',
  minkabu_ratings: 'minkabu_rating',
  analyst_ratings: 'analyst_rating',
  settlement_evaluations: 'settlement_evaluation',
  highest_settlement_evaluation: 'settlement_evaluation',
  user_expectation_ratings: 'user_expectation_rating',
};

export default {
  name: 'StockSearchSimilarLink',
  components: {},
  props: {
    /**
     * 銘柄スクリーニングレコード
     *  @return {Object}
     */
    record: {
      type: Object,
      require: false,
      default: null,
    },
    premium: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      enabledConditions: [],
      open: false,
      showable: false,
    };
  },
  computed: {
    recommends() {
      return this.buildRecommends();
    },
  },
  async created() {
    await this.availableConditions();
    this.filteringConditions();
    this.shuffleConditions();
  },
  methods: {
    /**
     * 開閉
     */
    toggleOpen() {
      return (this.open = !this.open);
    },
    /**
     * 表示に必要な要素をオブジェクトにまとめて配列で返す
     * @return {Array}
     */
    buildRecommends() {
      const _this = this;
      let recommendObjects = [];
      this.enabledConditions.forEach((condition) => {
        recommendObjects.push({
          name: condition,
          firstHalfText: _this.generateFirstHalfText(this.record.name, condition),
          status: _this.generateStatus(condition),
          latterHalfText: _this.generateLatterHalfText(condition),
          path: _this.buildPath(condition),
          premium: CONDITIONS.paid.includes(condition),
        });
      });
      return recommendObjects;
    },
    buildPath(condition) {
      const searchPath = `/stock/search${this.buildParams(condition)}&view=result`;
      if (CONDITIONS.free.includes(condition)) {
        return searchPath;
      } else {
        return this.premium
          ? searchPath
          : `/lp/premium?callback_url=${encodeURIComponent(
              window.location.origin + searchPath
            )}&utm_source=minkabu&utm_medium=f11m_top&utm_campaign=stock_search_similar_link`;
      }
    },
    /**
     * 表示文言の前半部分を生成する
     *  @param {String} name
     *  @param {String} condition
     *  @return {String}
     */
    generateFirstHalfText(name, condition) {
      switch (condition) {
        case 'per':
          return `${name}と同じ業種で「PER」が`;
        case 'pbr':
          return `${name}と同じ業種で「PBR」が`;
        case 'market_capitalization':
          return `${name}と同じ業種で「時価総額」が`;
        case 'sales_cagr_3y':
          return `${name}と同じ業種で「３年平均売上高成長率」が`;
        case 'dividend_yield':
          return `${name}と同じ業種で「配当利回り」が`;
        case 'capital_adequacy_ratio':
          return `${name}と同じ業種で「自己資本比率」が`;
        case 'operating_income_margin':
          return `${name}と同じ業種で「売上高営業利益率」が`;
        case 'minkabu_ratings':
          return `${name}と同じ業種で「目標株価」が`;
        case 'analyst_ratings':
          return `${name}と同じ業種で「アナリスト予想」が`;
        case 'settlement_evaluations':
          return `${name}と同じ業種で「業績評価」が`;
        case 'highest_settlement_evaluation':
          return `${name}と同じ業種で「業績評価」が`;
        case 'user_expectation_ratings':
          return `${name}と同じ業種で「会員予想」が`;
      }
    },
    /**
     * 各条件のステータスのテキストと文字色のオブジェクトを生成する
     *  @param {String} condition
     *  @return {Object}
     */
    generateStatus(condition) {
      if (['per', 'pbr'].includes(condition)) {
        return { text: '低い', color: 'bg-minkabuPicksBuy' };
      }
      if (
        [
          'market_capitalization',
          'sales_cagr_3y',
          'dividend_yield',
          'capital_adequacy_ratio',
          'operating_income_margin',
        ].includes(condition)
      ) {
        return { text: '高い', color: 'bg-minkabuRedUp' };
      }
      return this.ratingStatus(condition);
    },
    /**
     * rating系のステータスを生成する
     *  @param {String} condition
     *  @return {Object}
     */
    ratingStatus(condition) {
      switch (condition) {
        case 'minkabu_ratings':
          return this.minkabuRatingStatus();
        case 'analyst_ratings':
          return this.analystRatingStatus();
        case 'settlement_evaluations':
          return this.settlementEvaluationStatus();
        case 'highest_settlement_evaluation':
          return this.highestSettlementEvaluationStatus();
        case 'user_expectation_ratings':
          return this.userExpectationRatingStatus();
      }
    },
    /**
     * みんかぶ目標株価のステータスのテキストと文字色のオブジェクトを返す
     *  @return {Object}
     */
    minkabuRatingStatus() {
      switch (Number(this.record.minkabu_rating)) {
        case 1:
          return {
            text: '買い',
            color: 'bg-minkabuPicksBuy',
          };
        case 2:
          return {
            text: '売り',
            color: 'bg-minkabuPicksSell',
          };
        default:
          return {
            text: '対象外',
            color: 'bg-minkabuPicksNone',
          };
      }
    },
    /**
     * アナリスト予想のステータスのテキストと文字色のオブジェクトを返す
     *  @return {Object}
     */
    analystRatingStatus() {
      switch (Number(this.record.analyst_rating)) {
        case 1:
          return {
            text: '強気買い',
            color: 'bg-orange-700',
          };
        case 2:
          return { text: '買い', color: 'bg-minkabuPicksBuy' };
        case 3:
          return { text: '中立', color: 'bg-yellow-500' };
        case 4:
          return { text: '売り', color: 'bg-minkabuPicksSell' };
        case 5:
          return { text: '強気売り', color: 'bg-lime-800' };
        default:
          return { text: '対象外', color: 'bg-minkabuPicksNone' };
      }
    },
    /**
     * 業績評価のステータスのテキストと文字色のオブジェクトを返す
     *  @return {Object}
     */
    settlementEvaluationStatus() {
      switch (this.record.settlement_evaluation) {
        case 5:
          return {
            text: '晴れ',
            imgPath: 'icon_results_sun_v3.png',
          };
        case 4:
          return {
            text: '曇り時々晴',
            imgPath: 'icon_results_partly_cloudy_v3.png',
          };
        case 3:
          return {
            text: '曇り',
            imgPath: 'icon_results_cloudy_v3.png',
          };
        case 2:
          return {
            text: '雨',
            imgPath: 'icon_results_rain_v3.png',
          };
        case 1:
          return {
            text: '雷',
            imgPath: 'icon_results_thunder_v3.png',
          };
        default:
          return { text: 'なし', imgPath: '' };
      }
    },
    /**
     * 業績評価の最高値ステータスのテキストと文字色のオブジェクトを返す
     *  @return {Object}
     */
    highestSettlementEvaluationStatus() {
      return {
        text: '晴れ',
        imgPath: 'icon_results_sun_v3.png',
      };
    },
    /**
     * 会員予想のステータスのテキストと文字色のオブジェクトを返す
     *  @return {Object}
     */
    userExpectationRatingStatus() {
      switch (Number(this.record.user_expectation_rating)) {
        case 1:
          return {
            text: '買い',
            color: 'bg-minkabuPicksBuy',
          };
        case 2:
          return {
            text: '売り',
            color: 'bg-minkabuPicksSell',
          };
        default:
          return {
            text: '対象外',
            color: 'bg-minkabuPicksNone',
          };
      }
    },
    /**
     * 表示文言の後半部分を生成する
     *  @param {String} condition
     *  @return {String}
     */
    generateLatterHalfText(condition) {
      if (CONDITIONS.paid.includes(condition)) {
        return 'の銘柄を見る';
      }
      return '銘柄を見る';
    },
    /**
     * 特定の条件を除外して抽出した配列を返す
     *  @note 業績評価が最高値の場合は条件が被るため、業績評価が最高値固定としている条件を除外
     *  @return {Array}
     */
    filteringConditions() {
      if (Number(this.record.settlement_evaluation) === 5) {
        this.enabledConditions = this.enabledConditions.filter((item) => item !== 'highest_settlement_evaluation');
      }
    },
    /**
     * ランダムに入れ替えたスクリーニング条件名の配列を返す
     *  @return {Array}
     */
    shuffleConditions() {
      const enabledConditions = JSON.parse(JSON.stringify(this.enabledConditions));
      let shuffles =
        enabledConditions.length <= 1
          ? enabledConditions
          : enabledConditions.reduce((_, cur, idx) => {
              let rand = Math.floor(Math.random() * (idx + 1));
              enabledConditions[idx] = enabledConditions[rand];
              enabledConditions[rand] = cur;
              return enabledConditions;
            });
      const freeIndex = shuffles.findIndex((item) => CONDITIONS.free.includes(item));
      const firstItem = shuffles[freeIndex];
      shuffles.splice(freeIndex, 1);
      this.enabledConditions = [firstItem, ...shuffles];
      this.showable = true;
    },
    /**
     * 利用可能な条件のみを抽出して配列で返す
     *  @note 条件で選択可能な最大値・最小値を超える場合はそれぞれmax, minに丸められてしまうので使用不可として除外する
     *  @return {Array}
     */
    async availableConditions() {
      const joinConditions = [...CONDITIONS.free, ...CONDITIONS.paid];
      // 検索結果が3件以上あれば利用可能としている
      let params = `?threshold_value=3&industry_sectors[]=${this.record.industry_sector_code}`;
      joinConditions.forEach((condition) => {
        if (this.nullCheck(condition)) {
          return;
        }
        if (
          ['per', 'pbr', 'operating_income_margin', 'settlement_evaluations', 'highest_settlement_evaluation'].includes(
            condition
          )
        ) {
          if (
            (condition === 'per' && Number(this.record.per) <= Number(MAX_RANGE.per)) ||
            (condition === 'pbr' && Number(this.record.pbr) <= Number(MAX_RANGE.pbr)) ||
            (condition === 'operating_income_margin' &&
              Number(this.record.operating_income_margin) >= Number(MIN_RANGE.operating_income_margin)) ||
            (condition === 'settlement_evaluations' && Number(this.record.settlement_evaluation) !== 0)
          ) {
            params += this.makeParams(condition);
          }
        } else {
          params += this.makeParams(condition);
        }
      });
      await this.checkAvailable(params);
    },
    /**
     * 利用可能な条件をチェックしてenabledConditionsに格納する
     *  @param {String} condition
     *  @return {Array}
     */
    async checkAvailable(params) {
      const _this = this;
      return await axios
        .get(`/stock/search/available_link.json${params}`)
        .then(function (response) {
          _this.enabledConditions = response.data.available_links;
        })
        .catch(function (err) {
          // APIエラーの場合はコンソールに出力する（エラーが出ても表示に影響はないので、エラーが出ても処理は継続する）
          // eslint-disable-next-line no-consoles
          console.error(`${err.response.status}: ${err.response.data.message}`);
        });
    },
    /**
     * パラメータを生成する
     *  @param {String} condition
     *  @param {Boolean} forCheck
     *  @return {String}
     */
    buildParams(condition) {
      let params = `?industry_sectors[]=${this.record.industry_sector_code}`;
      switch (condition) {
        case 'per':
          params += this.perParams();
          break;
        case 'pbr':
          params += this.pbrParams();
          break;
        case 'market_capitalization':
          params += this.marketCapitalizationParams();
          break;
        case 'sales_cagr_3y':
          params += this.salesCagrParams();
          break;
        case 'dividend_yield':
          params += this.dividendYieldParams();
          break;
        case 'capital_adequacy_ratio':
          params += this.capitalAdequacyRatioParams();
          break;
        case 'operating_income_margin':
          params += this.operatingIncomeMarginParams();
          break;
        case 'minkabu_ratings':
          params += this.minkabuRatingsParams();
          break;
        case 'analyst_ratings':
          params += this.analystRatingsParams();
          break;
        case 'settlement_evaluations':
          params += this.settlementEvaluationsParams();
          break;
        case 'highest_settlement_evaluation':
          params += this.highestSettlementEvaluationParams();
          break;
        case 'user_expectation_ratings':
          params += this.userExpectationRatingsParams();
          break;
      }
      return params;
    },
    nullCheck(condition) {
      const adjustedCondition = MAPPINGS[condition] || condition;
      return this.record[adjustedCondition] === null;
    },
    makeParams(condition) {
      switch (condition) {
        case 'per':
          return this.perParams(false);
        case 'pbr':
          return this.pbrParams(false);
        case 'market_capitalization':
          return this.marketCapitalizationParams(false);
        case 'sales_cagr_3y':
          return this.salesCagrParams(false);
        case 'dividend_yield':
          return this.dividendYieldParams(false);
        case 'capital_adequacy_ratio':
          return this.capitalAdequacyRatioParams(false);
        case 'operating_income_margin':
          return this.operatingIncomeMarginParams(false);
        case 'minkabu_ratings':
          return this.minkabuRatingsParams();
        case 'analyst_ratings':
          return this.analystRatingsParams();
        case 'settlement_evaluations':
          return this.settlementEvaluationsParams();
        case 'highest_settlement_evaluation':
          return this.highestSettlementEvaluationParams();
        case 'user_expectation_ratings':
          return this.userExpectationRatingsParams();
      }
    },
    perParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=per&order=desc' : '';
      return (params += `&per[0]=min&per[1]=${this.formatNumber(this.record.per, 'ceil').toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}`);
    },
    pbrParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=pbr&order=desc' : '';
      return (params += `&pbr[0]=min&pbr[1]=${this.formatNumber(this.record.pbr, 'ceil').toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}`);
    },
    marketCapitalizationParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=market_capitalization&order=desc' : '';
      return (params += `&market_capitalization[0]=${this.formatNumber(
        this.record.market_capitalization / 100000000
      )}&market_capitalization[1]=max`);
    },
    salesCagrParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=sales_cagr_3y&order=desc' : '';
      return (params += `&sales_cagr_3y[0]=${this.formatNumber(this.record.sales_cagr_3years).toLocaleString(
        undefined,
        { minimumFractionDigits: 1, maximumFractionDigits: 1 }
      )}&sales_cagr_3y[1]=max`);
    },
    dividendYieldParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=dividend_yield&order=desc' : '';
      return (params += `&dividend_yield[0]=${this.formatNumber(this.record.dividend_yield).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}&dividend_yield[1]=max`);
    },
    capitalAdequacyRatioParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=capital_adequacy_ratio&order=desc' : '';
      return (params += `&capital_adequacy_ratio[0]=${this.formatNumber(
        this.record.capital_adequacy_ratio
      ).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}&capital_adequacy_ratio[1]=max`);
    },
    operatingIncomeMarginParams(sortAndOrder = true) {
      let params = sortAndOrder ? '&sort_key=operating_income_margin&order=desc' : '';
      return (params += `&operating_income_margin[0]=${this.formatNumber(
        this.record.operating_income_margin
      ).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}&operating_income_margin[1]=max`);
    },
    minkabuRatingsParams() {
      return `&minkabu_ratings[]=${this.record.minkabu_rating}`;
    },
    analystRatingsParams() {
      return `&analyst_ratings[]=${this.record.analyst_rating}`;
    },
    settlementEvaluationsParams() {
      return `&settlement_evaluations[]=${this.record.settlement_evaluation}`;
    },
    highestSettlementEvaluationParams() {
      return `&settlement_evaluations[]=5`;
    },
    userExpectationRatingsParams() {
      return `&user_ratings[]=${this.record.user_expectation_rating}`;
    },
    formatNumber(value, math = 'floor') {
      if (math === 'floor') {
        return Math.floor(Number(value) * 10) / 10;
      }
      return Math.ceil(Number(value) * 10) / 10;
    },
  },
};
</script>
