import StockSearchSimilarLinkComponent from './StockSearchSimilarLink.vue';

/*
 * 銘柄スクリーニング動的リンク ルートコンポーネント
 */
const StockSearchSimilarLink = {
  el: '#v-stock-search-similar-link',
  data: {},
  methods: {},
  components: {
    StockSearchSimilarLink: StockSearchSimilarLinkComponent,
  },
};

export default StockSearchSimilarLink;
