import YutaiNews from './modules/StockYutaiNews';
import BaseLoading from '../Base/BaseLoading';

const StockYutaiNewsStore = {
  modules: {
    StockYutaiNews: YutaiNews,
    BaseLoading: BaseLoading
  }
};

export default StockYutaiNewsStore;
