import dayjs from 'dayjs';

/**
 * 日付フォーマットに関するMixin
 */
const DateFormatMixin = {
  methods: {
    /**
     * 引数が当日と等しい場合は時間、そうでない場合は日付でフォーマットして返却する
     * @param {Date} priceTime
     * @returns {string} formatされた文字列
     */
    formatPriceTime(priceTime) {
      const dayjsPriceTime = dayjs(priceTime);
      if (dayjs().isSame(dayjsPriceTime, 'day')) {
        return dayjsPriceTime.format('HH:mm');
      } else {
        return dayjsPriceTime.format('MM/DD');
      }
    },
    /**
     * javascriptのwdayに対応する曜日
     *
     * @param {number} wday
     * @returns {string}
     */
    jaWeekDay(wday) {
      const jaWdays = ['日', '月', '火', '水', '木', '金', '土'];
      return jaWdays[wday];
    },
    /**
     * 日付をmm/dd にして返却する
     *
     * @params {string} 日付
     * @return {string} 変換済みの文字列
     */
    formatDate(stringDate) {
      return dayjs(stringDate).format('MM/DD');
    },
    /**
     * 日付をmm/dd hh:mm にして返却する
     *
     * @params {string} 日付
     * @return {string} 変換済みの文字列
     */
    formatDateToMMDDHHmm(stringDate) {
      return dayjs(stringDate).format('MM/DD HH:mm');
    },
    /**
     * 日付をhh:mm にして返却する
     *
     * @params {string} 日付
     * @return {string} 変換済みの文字列
     */
    formatDateToHHmm(stringDate) {
      return dayjs(stringDate).format('HH:mm');
    },
    /**
     * 日付をYYYY MM/DD にして返却する
     *
     * @params {string} 日付
     * @return {string} 変換済みの文字列
     */
    formatDateToYYYYMMDD(stringDate) {
      return dayjs(stringDate).format('YYYY MM/DD');
    },
  },
};
export default DateFormatMixin;
