/**
 * URLパラメタをに「?」を追加して返す
 * @return {String}
 */
const UrlParamsToString = () => '?' + new URLSearchParams(window.location.search).toString();

/**
 * URLパラメタを生成する
 * @param {number} page
 * @param {object} search
 * @param {object} sort
 * @param {array} accordion
 * @return {String}
 */
export const generateUrlParams = ({ page, search, sort, accordion = '' }) => {
  const param =
    `?page=${page}` +
    `&keyword=${search.keyword}` +
    `&news=${search.news}` +
    `&analysisRating=${search.analysisRating.join('_')}` +
    `&fiscalEvaluation=${search.fiscalEvaluation.join('_')}` +
    `&key=${sort.key}&asc=${sort.asc}`;

  if (accordion) {
    return `${param}&open=${accordion.join('_')}`;
  }
  return param;
};

/**
 * ブラウザヒストリーにURLをプッシュする
 * @param {string} params
 */
export const pushUrlParams = (params) => {
  if (params === UrlParamsToString()) {
    return;
  }
  history.pushState(null, null, params);
};

/**
 * ブラウザヒストリーのURLを置換する
 * @param {string} params
 */
export const replaceUrlParams = (params) => {
  if (params === UrlParamsToString()) {
    return;
  }
  history.replaceState(null, null, params);
};
