<template src="./SummaryInfo.html"></template>

<script>
import { ContentLoader } from 'vue-content-loader';
import { QUERY_INDIVIDUAL_FINANCIAL_ITEM_SUMMARY_INFO as INDIVIDUAL_F11M_SUMMARY_INFO } from '../../../queries/IndividualFinancialItemSummaryInfoQuery';

/**
 * 個別銘柄サマリー情報
 *
 * @module modules/Stocks/SummaryInfo
 */
export default {
  name: 'SummaryInfo',
  components: {
    ContentLoader,
  },
  props: {
    /**
     * 金融アイテムコード
     *  @return {String}
     */
    f11mCode: {
      type: String,
      require: true,
      default: null,
    },
    /**
     * 金融アイテム名（短縮名）
     *  @return {String}
     */
    f11mNameAbbr: {
      type: String,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      individualFinancialItemsSummaryInfo: null,
    };
  },
  apollo: {
    individualFinancialItemsSummaryInfo: {
      query: INDIVIDUAL_F11M_SUMMARY_INFO,
      skip() {
        return true;
      },
      variables() {
        return {
          f11mCode: this.f11mCode,
        };
      },
    },
  },
  computed: {
    /**
     * 決算ページへのリンクを付けるか否か
     * @return {Boolean}
     */
    isSettlementEvaluationLinkable() {
      return !!(this.individualFinancialItemsSummaryInfo && this.individualFinancialItemsSummaryInfo.settlement);
    },
    /**
     * 業績評価があるか否か
     * @return {Boolean}
     */
    hasSettlementEvaluation() {
      return !!(
        this.isSettlementEvaluationLinkable &&
        this.individualFinancialItemsSummaryInfo.settlement.quarterlyResults.length > 0 &&
        this.individualFinancialItemsSummaryInfo.settlement.quarterlyResults[0].settlementEvaluation
      );
    },
    /**
     * 業績評価のデータ
     * @return {Object}
     */
    settlementEvaluationData() {
      if (!this.hasSettlementEvaluation) {
        return { name: '---', iconUrl: '' };
      }

      return this.settlementEvaluationNameWithIconUrl(
        this.individualFinancialItemsSummaryInfo.settlement.quarterlyResults[0].settlementEvaluation
      );
    },
    /**
     * 業績評価を表す名前
     * @return {String}
     */
    settlementEvaluationName() {
      return this.settlementEvaluationData.name;
    },
    /**
     * 業績評価のアイコンURL
     * @return {String}
     */
    settlementEvaluationIconSrc() {
      return this.settlementEvaluationData.iconUrl;
    },
    /**
     * 業績評価のリンク先URL
     * @return {String}
     */
    settlementEvaluationLinkUrl() {
      return this.hasSettlementEvaluation
        ? this.stockSettlementUrl(this.f11mCode) + '#target-from-summary-info'
        : this.stockSettlementUrl(this.f11mCode);
    },
    /**
     * 配当ページへのリンクを付けるか否か
     * @return {Boolean}
     */
    isDividendLinkable() {
      return !!(this.individualFinancialItemsSummaryInfo && this.individualFinancialItemsSummaryInfo.dividend);
    },
    /**
     * 配当状況が無配か否か
     * @return {Boolean}
     */
    isDividendStatusPassed() {
      if (!this.isDividendLinkable) {
        return false;
      }

      return this.dividendStatusName === '無配';
    },
    /**
     * 配当ページはあるけど配当予想が無ければ真
     */
    isNoneProjectionDividend() {
      if (!this.isDividendLinkable) {
        return false;
      }

      return this.dividendStatusName === '---';
    },
    /**
     * 配当状況を表す名前
     * @return {String}
     */
    dividendStatusName() {
      const dividendStatusNames = {
        consecutive_increased: '連続増配',
        increased: '増配',
        kept: '配当維持',
        decreased: '減配',
        passed: '無配',
      };
      return dividendStatusNames[this.individualFinancialItemsSummaryInfo.dividend.annualList.dividendStatus] || '---';
    },
    /**
     * 配当利回り
     * @return {String}
     */
    dividendYield() {
      return this.numFormat(this.individualFinancialItemsSummaryInfo.dividend.dividendYield, 2);
    },
    /**
     * 株主優待があるか否か
     * @return {Boolean}
     */
    hasYutai() {
      return !!(
        this.individualFinancialItemsSummaryInfo &&
        this.individualFinancialItemsSummaryInfo.yutai &&
        this.individualFinancialItemsSummaryInfo.yutai.yutaiCategories.length > 0
      );
    },
    /**
     * 優待カテゴリー名
     * @description 優待カテゴリーは1銘柄に複数存在するが、優待IDが一番小さいものを表示する仕様
     * @return {String}
     */
    yutaiCategoryName() {
      // 優待がない場合のtemplateは分けているので、ここでは常にhasYutaiがtrueの想定だけど念の為。
      if (!this.hasYutai) {
        return '---';
      }

      const minimumIdYutai = this.individualFinancialItemsSummaryInfo.yutai.yutaiCategories.reduce((prev, curr) => {
        return prev.id < curr.id ? prev : curr;
      });
      return minimumIdYutai.name;
    },
    /**
     * ロードしたデータに有効値があるか否か
     * @return {Boolean}
     */
    hasIndividualFinancialItemsSummaryInfo() {
      return (
        !!this.individualFinancialItemsSummaryInfo &&
        (this.hasSettlementEvaluation || this.hasYutai || this.isDividendLinkable)
      );
    },
  },
  watch: {
    hasIndividualFinancialItemsSummaryInfo: {
      handler: function (newVal) {
        this.$emit('data-loaded', newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.individualFinancialItemsSummaryInfoLazyQuery();
  },
  methods: {
    /**
     * @note
     *   mountedされるまでpropsから引数を受け取る事ができないので、
     *   mountedでskipをfalseにして、queryを発火させている
     */
    individualFinancialItemsSummaryInfoLazyQuery() {
      this.$apollo.queries.individualFinancialItemsSummaryInfo.skip = false;
    },
  },
};
</script>
