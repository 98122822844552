<template src="./QuarterlySwitcher.html"></template>
<script>
/*
 * @vue-prop {Settlements::Index::DiverseQuarterlies::SubtotalQuarterlies::ViewModel} subTotal
 * @vue-prop {Settlements::Index::DiverseQuarterlies::Quarterlies::ViewModel} Total
 * @vue-data {string} isDisplayed
 * @module QuarterlySwitcher
 */
export default {
  name: 'QuarterlySwitcher',
  props: {
    subTotal: {
      type: Object,
      default: () => {}
    },
    total: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      isDisplayed: 'total'
    };
  },
  methods: {
    /**
     * 矢印アイコンのcssのclass名を返す
     * @param {Settlements::Index::DiverseQuarterlies::DiffValueViewModel} item
     * @return {string}
     */
    arrowClass(item) {
      if (item.positive) {
        return 'fa-solid fa-arrow-up up';
      } else if (item.negative) {
        return 'fa-solid fa-arrow-down down';
      }
    },
    /**
     * up, downのcssのclass名を返す
     * @param {Settlements::Index::DiverseQuarterlies::DiffValueViewModel} item
     * @return {string}
     */
    valueClass(item) {
      if (item.zero) {
        return 'even';
      } else if (item.positive) {
        return 'up';
      } else if (item.negative) {
        return 'down';
      }
    },
    /**
     * 1年前の決算であれば真を返す
     * @param {string} type
     * @param {integer} year
     * @param {integer} month
     * @return {boolean}
     */
    checkYearAgoSettlement(type, year, month) {
      if (type === 'total') {
        return this.total.quarterlies[0].fiscal_period_year === (year + 1) && this.total.quarterlies[0].fiscal_period_month === month;
      } else if (type === 'subtotal') {
        return this.subTotal.quarterlies[0].fiscal_period_year === (year + 1) && this.subTotal.quarterlies[0].fiscal_period_month === month;
      } else {
        return false;
      }
    },
    /**
     * 前年比の数値と単位にhtmlタグを追加して返す
     * @param {Settlements::Index::DiverseQuarterlies::DiffValueViewModel} diff
     * @return {string}
     */
    valueHtml(diff) {
      if (diff.zero) {
        return `<span class="wsnw">±0</span><span class="fss dpib">円</span>`;
      } else {
        return `<span class="wsnw">${diff.value}</span><span class="fss dpib">${diff.unit}</span>`;
      }
    }
  }
};
</script>
