const StockChartStore = {
  namespaced: true,
  state: {
    chartTerm: 'today', // today, 3m, 6m, 1y, 3y, 5y
    visibleTooltip: false,
  },
  getters: {
    /**
     * 表示中のチャート種別
     * @return {string}
     */
    chartTerm(state) {
      return state.chartTerm;
    },
    /**
     * 履歴チャートなら真
     * @return {boolean}
     */
    isHistorical(state) {
      return state.chartTerm !== 'today';
    },
    /**
     * 表示するチャートの足の種類
     * @return {String}
     */
    barType(state) {
      const barTypes = {
        '3m': 'daily',
        '6m': 'weekly',
        '1y': 'weekly',
        '3y': 'monthly',
        '5y': 'monthly',
      };

      return barTypes[state.chartTerm] || 'daily';
    },
    /**
     * ラベルごとの足(日足、週足、月足)
     * @return {string}
     */
    barPeriod(state) {
      const labels = {
        '3m': '3ヶ月 日足',
        '6m': '6ヶ月 週足',
        '1y': '1年 週足',
        '3y': '3年 月足',
        '5y': '5年 月足',
      };

      return labels[state.chartTerm] || '日中';
    },
    /**
     * ラベルごとの期間
     * @return {string}
     */
    periodType(state) {
      const terms = {
        '3m': 'month',
        '6m': 'month',
        '1y': 'year',
        '3y': 'year',
        '5y': 'year',
      };

      return terms[state.chartTerm] || 'month';
    },
    /**
     * ラベルごとの期間
     * @return {Number}
     */
    periodTypeInYear(state) {
      const terms = {
        '3m': 3,
        '6m': 6,
        '1y': 1,
        '3y': 3,
        '5y': 5,
      };

      return terms[state.chartTerm] || 3;
    },
    /**
     * ツールチップ表示中なら真
     * @return {boolean}
     */
    visibleTooltip(state) {
      return state.visibleTooltip;
    },
  },
  mutations: {
    /**
     * 表示中のチャート種別更新
     */
    chartTerm(state, chartTerm) {
      state.chartTerm = chartTerm;
    },
    /**
     * ツールチップ表示
     */
    showTooltip(state) {
      state.visibleTooltip = true;
    },
    /**
     * ツールチップ非表示
     */
    hideTooltip(state) {
      state.visibleTooltip = false;
    },
  },
};
export default StockChartStore;
