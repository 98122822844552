<template src="./HistoricalChart.html" />
<script>
import { Chart } from 'highcharts-vue';
import StockChartResource from '../../../resources/StockChartResource';
import dayjs from 'dayjs';
import NumberFormatMixin from '../../../mixins/NumberFormatMixin';

const CHART_STYLE = {
  labelPosition: 12,
  labelColor: '#666',
  labelFontSize: '10px',
  tooltipBorderColor: '#666',
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 履歴チャートコンポーネント
 * @vue-prop {String} code
 * @vue-prop {String} height
 * @vue-data {StockChart} stockChart
 * @vue-data {Object} codeLabel
 * @vue-data {Object} lastDateLabel
 * @module StockChart/HistoricalChart
 */
export default {
  components: {
    highcharts: Chart,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    exchangeId: {
      type: Number,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '250px',
    },
  },
  data() {
    return {
      stockChart: null,
      codeLabel: null,
      lastDateLabel: null,
    };
  },
  computed: {
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          height: vueThis.height,
          margin: [26, 0, 46, 0],
          pinchType: false,
          animation: false,
          events: {
            redraw: function (chart) {
              const color = CHART_STYLE.labelColor;
              const fontSize = CHART_STYLE.labelFontSize;

              if (!vueThis.codeLabel) {
                vueThis.codeLabel = chart.target.renderer
                  .text('', 0, CHART_STYLE.labelPosition)
                  .css({ color, fontSize })
                  .add();
              }
              vueThis.codeLabel.attr({
                text: `[${vueThis.toCodeLabel(vueThis.code)}] ${vueThis.barPeriod}`,
              });

              if (!vueThis.lastDateLabel) {
                vueThis.lastDateLabel = chart.target.renderer
                  .text('', chart.target.chartWidth - 65, CHART_STYLE.labelPosition)
                  .css({ color, fontSize })
                  .add();
              }

              if (vueThis.lastDateData) {
                vueThis.lastDateLabel.attr({
                  text: dayjs(vueThis.lastDateData).format('YYYY/MM/DD'),
                });
              }
            },
          },
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        legend: {
          enabled: true,
          floating: false,
          align: 'center',
          verticalAlign: 'bottom',
          itemStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 10,
          },
          y: 20,
        },
        title: {
          text: '',
        },
        tooltip: {
          borderColor: CHART_STYLE.tooltipBorderColor,
          xDateFormat: '%Y/%m/%d %H:%M',
          formatter: function () {
            const st = this.points.find((p) => p.series.name === '株価');
            if (!st) {
              return false;
            }

            const point = st.point;
            const dayjsPriceTime = dayjs(point.x);
            let lines = [];

            lines.push(dayjsPriceTime.format('YYYY/MM/DD'));

            lines.push(`株価：${NumberFormatMixin.methods.numFormat(point.y, vueThis.tooltipDecimal(vueThis.code))}`);

            return lines.join('<br />');
          },
          shared: true,
          useHTML: true,
          valueDecimals: 1,
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#4572A7',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
            },
          },
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
            showInLegend: false,
          },
          {
            type: 'line',
            name: '25日',
            data: this.ma25,
            color: 'red',
            lineWidth: 1,
            animation: false,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
            events: {
              legendItemClick: function (e) {
                e.preventDefault(); // レジェンドのクリック無効
              },
            },
          },
          {
            type: 'line',
            name: '75日',
            data: this.ma75,
            color: 'blue',
            lineWidth: 1,
            animation: false,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
            events: {
              legendItemClick: function (e) {
                e.preventDefault(); // レジェンドのクリック無効
              },
            },
          },
        ],
        xAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            dateTimeLabelFormats: {
              day: '%m/%d',
              week: '%m/%d',
              month: '%Y/%m',
              year: '%Y',
            },
            max: this.xAxisMax,
            min: this.xAxisMin,
          },
        ],
        yAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            labels: {
              align: 'left',
              x: 0,
            },
            gridLineColor: '#ddd',
            opposite: false,
            max: this.yAxisMax,
            min: this.yAxisMin,
          },
        ],
      };
    },
    /**
     * 最終営業日（当日は含まない）
     * @return {String}
     */
    lastDateData() {
      if (!this.stockChart) return;

      return this.stockChart.lastDateData();
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.chartData(this.barType);
    },
    /**
     * 25日移動平均線
     * @return {Array}
     */
    ma25() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.movingAverage(this.barType, 25);
    },
    /**
     * 75日移動平均線
     * @return {Array}
     */
    ma75() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.movingAverage(this.barType, 75);
    },
    /**
     * 表示するチャートの足の種類
     * @return {String}
     */
    barType() {
      return this.$store.getters['barType'];
    },
    /**
     * 表示されている分のチャートデータ
     * @return {Array}
     */
    displayChartData() {
      const xMax = this.latestPriceDate;
      const xMin = this.startPriceDate;
      return this.chartData.filter((e) => {
        return xMin <= e[0] && e[0] <= xMax;
      });
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 0.9995;
    },
    /**
     * X軸の最大値
     * @return {Number}
     */
    xAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * X軸の最小値
     * @return {Number}
     */
    xAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * 最新の取引日
     * @return {Number}
     */
    latestPriceDate() {
      if (this.chartData.length === 0) return;

      return this.chartData[this.chartData.length - 1][0];
    },
    /**
     * X軸の描画開始日
     * @return {Number}
     */
    startPriceDate() {
      const strTerm = this.$store.getters['periodType'];
      const numTerm = this.$store.getters['periodTypeInYear'];

      let day = dayjs(this.latestPriceDate).subtract(numTerm, strTerm);
      return day.valueOf();
    },
    /**
     * ラベルごとの足(日足、週足、月足)
     * @return {String}
     */
    barPeriod() {
      return this.$store.getters['barPeriod'];
    },
  },
  beforeUpdate() {
    this.loadStockChart();
  },
  methods: {
    /**
     * チャートを読み込む
     * @return {void}
     */
    async loadStockChart() {
      if (this.stockChart || !this.$store.getters['isHistorical']) {
        return;
      }

      const stockChartResource = new StockChartResource();
      this.stockChart = await stockChartResource.get(this.code, this.exchangeId);
    },
    /**
     * ツールチップ用の小数点桁数
     * 指数系銘柄の場合2桁、そのほかは1桁
     * @param {String} code
     * @return {Number}
     */
    tooltipDecimal(code) {
      return this.isIndexCode(code) ? 2 : 1;
    },
    /**
     * 銘柄コードを表示用のラベルに変換
     * 例:"100000018"->"Nikkei225"
     * @param {String} code
     * @return {String}
     */
    toCodeLabel(code) {
      switch (code) {
        case '100000018':
          return 'Nikkei225';
        case 'KSISU1000':
          return 'TOPIX';
        default:
          return code;
      }
    },
  },
};
</script>
