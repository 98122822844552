/**
 * 株式ランキングTOPのSPサイト用表示切り替えルートコンポーネント
 *
 * @property {String} current_ranking [data] 表示するランキング名
 * @module FinancialItemRanking
 */

const FinancialItemRankingTop = {
  el: '#v_ranking_top',
  data: {
    currentType: 'rise'
  },
  methods: {
    switchCurrentType: function (type) {
      this.currentType = type;
    },
    isCurrent: function (type) {
      return type === this.currentType;
    }
  }
};

export default FinancialItemRankingTop;
