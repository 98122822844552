import ChangesInDPSChartVue from './ChangesInDPSChart.vue';

/**
 * 個別銘柄の配当ページの年間1株配当額推移チャートのルートコンポーネント
 * @module Dividends/ChangesInDPSChart
 */
const ChangesInDPSChart = {
  el: '#v-changes-in-dps-chart',
  components: {
    changesInDpsChart: ChangesInDPSChartVue,
  },
};

export default ChangesInDPSChart;
