<template src="./UpdateYutaiNewsButton.html"></template>
<script>
import BaseLoading from '../Base/BaseLoading.vue';

/*
* 銘柄優待ニュース、更新ボタンコンポーネント
* @property {baseLoading} baseLoading [component] ローディングコンポーネント
*/
export default {
  name: 'UpdateYutaiNewsButton',
  components: {
    baseLoading: BaseLoading
  },
  methods: {
    /*
    * 更新ボタン押下後、優待関連ニュースを取得
    */  
    async updateYutaiNews() {
      this.$store.dispatch("BaseLoading/uploadLoadingStatus", true);
      await this.$store.dispatch('StockYutaiNews/latestYutaiNewsList');
      this.$store.dispatch("BaseLoading/uploadLoadingStatus", false);  
    }
  }
};
</script>
