import StockTopThemeComponent from './StockTopTheme.vue';

/*
 * 個別銘柄トップ 関連テーマ
 *
 */
const StockTopTheme = {
  el: '#v-stock-top-theme',
  components: {
    StockTopTheme: StockTopThemeComponent,
  },
};

export default StockTopTheme;
