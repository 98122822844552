<template src="./SearchCriteria.html" />
<script>
import GlossaryTooltip from './modules/tooltip/GlossaryTooltip.vue';
import generateQueryParams from './utils/generateQueryParams';
import PremiumTooltip from './modules/tooltip/PremiumTooltip.vue';
import _isEqual from 'lodash/isEqual';

/**
 * 銘柄検索 検索条件コンポーネント
 * @module StockSearch/SearchCriteria
 */
export default {
  name: 'SearchCriteria',
  components: {
    'glossary-tooltip': GlossaryTooltip,
    'premium-tooltip': PremiumTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    // プレミアム限定条件かどうか
    premium: {
      type: Boolean,
      required: false,
      default: false,
    },
    loginUrl: {
      type: String,
      required: false,
      default: null,
    },
    ui: {
      type: String,
      required: false,
      default: 'slider',
    },
    preset: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    listValue() {
      return (val) => {
        const item = this.$store.getters['stockSearchInput/list'](this.name);
        if (item && val) {
          return _isEqual(val, JSON.parse(JSON.stringify(item.value)));
        }
        return [];
      };
    },
    itemName() {
      if (this.name === 'sales_cagr_3y') {
        return 'salesCAGR3y';
      }
      return this.name.replace(/_([a-z])/g, (_, v) => v.toUpperCase());
    },
  },
  created() {
    if (this.premium && !this.$store.getters['stockSearchInput/premium']) {
      this.disabled = true;
    }
  },
  methods: {
    /**
     * 条件削除
     */
    deleteItem() {
      this.$store.commit('stockSearchInput/setCriteria', { name: this.name });
    },
    /**
     * 条件をクリア
     */
    async resetItem() {
      if (this.ui === 'slider') {
        const item = this.$store.getters['stockSearchInput/default'][this.name].range;
        this.$store.commit('stockSearchInput/setListValue', { name: this.name, value: item });
      } else if (this.ui === 'checkbox') {
        this.$store.commit('stockSearchInput/setListChecked', { name: this.name, checked: [] });
      }
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: true })
      );
    },
    /**
     * 条件を表示するか
     */
    hasShow(name) {
      return this.$store.getters['stockSearchInput/criteria'].includes(name);
    },

    /**
     * GA4カウント用にプリセット名を作る
     */
    generatePresetEventName(name) {
      return `preset_${name}`;
    },

    async changePreset(i) {
      this.$store.commit('stockSearchInput/setListValue', {
        name: this.name,
        value: this.preset[i].value,
      });
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: true })
      );
    },
  },
};
</script>
