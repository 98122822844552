/**
 * 共通Styleクラスを取得するためのクラス
 *
 */
const StyleMixin = {
  methods: {
    /**
     * up, downのcssのclass名を返す
     * @param {string} value
     * @return {string}
     */
    valueClassFromString(value) {
      if (value === '---') {
        return 'even';
      }

      const numberValue = Number(value.replace(',', ''));
      return this.valueClassFromNumber(numberValue);
    },
    /**
     * up, downのcssのclass名を返す
     * @param {Number} value
     * @return {string}
     */
    valueClassFromNumber(value) {
      if (value === 0) {
        return 'even';
      } else if (value > 0) {
        return 'up';
      } else if (value < 0) {
        return 'down';
      } else {
        return 'even';
      }
    },
    /**
     * up, downのtailwindcssのclass名を返す
     * @param {Number} value
     * @return {string}
     */
    valueTwClassFromNumber(value) {
      if (value === 0) {
        return 'text-minkabuGrayEven';
      } else if (value > 0) {
        return 'text-minkabuRedUp';
      } else if (value < 0) {
        return 'text-minkabuBlueDown';
      } else {
        return 'text-minkabuGrayEven';
      }
    },
    /**
     * 優待カテゴリ名から対応するアイコン名と色のクラスをセットで返す
     * アイコンはFontAwesome、色はtailwindcssのクラス名
     *
     * @param {string} categoryName
     * @return {string}
     */
    yutaiIconClassWithColor(categoryName) {
      const yutaiIconClasses = {
        '食料品': 'fa-bowl-chopsticks-noodles text-orange-500',
        '食事券': 'fa-utensils text-slate-600',
        '金券': 'fa-ticket text-amber-500',
        'カタログギフト': 'fa-book-open text-rose-700',
        '交通・旅行': 'fa-suitcase-rolling text-amber-700',
        '暮らし': 'fa-house-user text-stone-500',
        'ポイントサービス': 'fa-circle-p text-orange-500',
        '美容・ファッション': 'fa-shirt text-rose-700',
        '教養・娯楽': 'fa-graduation-cap text-amber-700',
        'スポーツ': 'fa-person-running text-green-600',
        '保育・介護・医療': 'fa-suitcase-medical text-sky-600',
        '金融サービス': 'fa-piggy-bank text-slate-600',
        'カレンダー': 'fa-calendar-range text-stone-500',
        '長期保有特典': 'fa-hand-holding-box text-rose-700',
        '社会貢献': 'fa-hands-holding-hear text-orange-500',
        'プレミアム優待倶楽部': 'fa-ribbon text-slate-600',
      };

      return yutaiIconClasses[categoryName] || '';
    },
    /**
     * 配当状況から対応するアイコン名と色のクラスをセットで返す
     * アイコンはFontAwesome、色はtailwindcssのクラス名
     *
     * @param {string} dividendStatus
     * @return {string}
     */
    dividendStatusIconClassWithColor(dividendStatus) {
      const dividendStatusIconClasses = {
        連続増配: 'fa-angles-up text-red-600',
        増配: 'fa-angles-up text-red-500',
        配当維持: 'fa-angles-right text-amber-500',
        減配: 'fa-angles-down text-indigo-500',
      };

      return dividendStatusIconClasses[dividendStatus] || '';
    },

    /**
     * 株価診断の評価から対応する色クラスを返す
     * @param {string} rating
     * @returns {string}
     */
    researchRatingClass(rating) {
      const ratingClasses = {
        buy: 'bg-minkabuPicksBuy',
        unknown: 'bg-yellow-500',
        sell: 'bg-minkabuPicksSell',
      };
      return ratingClasses[rating] || '';
    },
    /**
     * 総合予想・個人予想の評価から対応する色クラスを返す
     * @param {string} rating
     * @returns {string}
     */
    analysisRatingClass(rating) {
      const ratingClasses = {
        buy: 'bg-minkabuPicksBuy',
        sell: 'bg-minkabuPicksSell',
      };

      return ratingClasses[rating] || '';
    },
    /**
     * アナリスト予想の評価から対応する色クラスを返す
     * @param {string} rating
     * @returns {string}
     */
    analystConsensusRatingClass(rating) {
      const ratingClasses = {
        strong_buy: 'bg-orange-700',
        buy: 'bg-minkabuPicksBuy',
        hold: 'bg-yellow-500',
        sell: 'bg-minkabuPicksSell',
        strong_sell: 'bg-lime-800',
        unknown: 'bg-yellow-500',
      };

      return ratingClasses[rating] || '';
    },
  },
};
export default StyleMixin;
