<template src="./IntradayChart.html" />
<script>
import { Chart } from 'highcharts-vue';
import MkIntradayStockChartBuilder from '../../../models/StockCharts/MkIntradayStockChartBuilder.js';
import StockChartTooltip from './StockChartTooltip.vue';
import dayjs from 'dayjs';

const CHART_STYLE = {
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 株価の日中足チャートのコンポーネント(SP版)
 *
 * @module StockChartSp/IntradayChart
 */
export default {
  components: {
    highcharts: Chart,
    StockChartTooltip,
  },
  props: {
    /**
     * 金融アイテムコード
     * @type {String}
     */
    code: {
      type: String,
      required: true,
    },
    /**
     * 市場ID
     * @type {Number}
     */
    exchangeId: {
      type: Number,
      required: true,
    },
    /**
     * 遅延ロードするかどうか
     * @type {Boolean}
     */
    isLazyLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      /**
       * 日中足チャートデータ
       * @type {MkIntradayStockChart}
       */
      intradayStockChart: null,
      /**
       * プロットの情報
       * @type {Object}
       */
      plotData: {},
      legendWidth: undefined,
    };
  },
  computed: {
    /**
     * ツールチップを表示するかどうか
     * @return {Boolean}
     */
    isVisible() {
      return this.$store.getters['visibleTooltip'];
    },
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          events: {
            /**
             * @note xAxis.tickPositionsを指定すると
             * xAxis.overflow: 'justify' が効かなくなるので
             * X軸左端のラベルをチャート描画エリア内に収めるために処理を追加した
             */
            render() {
              const xAxis = this.xAxis[0];
              const tick = Object.values(xAxis.ticks)[0];
              if (tick) {
                tick.label.translate(tick.label.getBBox().width / 2, 0);
              }
            },
          },
          height: '200px',
          animation: false,
          margin: [30, vueThis.yAxisLabelWidth, 46, 1],
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        legend: {
          enabled: true,
          floating: false,
          align: 'left',
          verticalAlign: 'bottom',
          itemStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 9,
          },
          x: -16,
          y: 22,
          width: this.legendWidth,
        },
        title: {
          text: '',
        },
        tooltip: {
          formatter: function () {
            const st = this.points.find((p) => p.series.name === '株価');
            if (st) {
              const price = this.y;
              const priceTime = this.x;
              const plotX = this.points[0].point.plotX;
              vueThis.chartClick({ price, priceTime, plotX });
            } else {
              vueThis.hideTooltip();
            }
            return false;
          },
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#64748b',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
              fillColor: '#3B82F6',
            },
            dataGrouping: {
              enabled: false, // 日中チャートは空のデータも描画したいのでfalseにしている
            },
          },
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
            showInLegend: false,
          },
          {
            type: 'line',
            name: vueThis.priorLastClosePriceLegend,
            animation: false,
            data: this.priorLastCloseChartData,
            color: '#2563EB',
            lineWidth: 2,
            dashStyle: 'ShortDot',
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
          },
          {
            type: 'line',
            name: vueThis.currentLastClosePriceLegend,
            animation: false,
            data: this.currentLastCloseChartData,
            color: '#EF4444',
            lineWidth: 2,
            dashStyle: 'ShortDot',
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
          },
        ],
        xAxis: [
          {
            type: 'datetime',
            tickPositions: vueThis.tickPositions,
            tickLength: 4,
            showLastLabel: false,
            crosshair: {
              dashStyle: 'Dash',
            },
            labels: {
              formatter: function () {
                const time = dayjs(this.value);

                if (time.hour() === 9) {
                  return time.format('M/D');
                } else {
                  return time.format('HH:mm');
                }
              },
              step: 3,
            },
          },
        ],
        yAxis: [
          {
            showFirstLabel: true,
            showLastLabel: true,
            gridLineColor: '#ddd',
            opposite: true,
            max: this.yAxisMax,
            min: this.yAxisMin,
            offset: vueThis.yAxisLabelWidth,
            minTickInterval: 1,
            labels: {
              format: '{value:,.0f}',
              y: 4,
            },
          },
        ],
        time: {
          timezone: 'Asia/Tokyo',
          useUTC: false,
        },
      };
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.intradayStockChart) {
        return [];
      }

      return this.intradayStockChart.chartData();
    },
    /**
     * 前日終値描画用データ
     * @return {Array}
     */
    currentLastCloseChartData() {
      if (!this.intradayStockChart) {
        return [];
      }

      return this.intradayStockChart.currentLastCloseChartData();
    },
    /**
     * 前々日終値描画用データ
     * @return {Array}
     */
    priorLastCloseChartData() {
      if (!this.intradayStockChart) {
        return [];
      }
      return this.intradayStockChart.priorLastCloseChartData();
    },
    /**
     * 前日の終値
     * @return {Integer}
     */
    currentLastClose() {
      if (!this.intradayStockChart) {
        return null;
      }
      return this.intradayStockChart.currentLastCloseChartData()[0][1];
    },
    /**
     * 前々日の終値
     * @return {Integer}
     */
    priorLastClose() {
      if (!this.intradayStockChart) {
        return null;
      }
      return this.intradayStockChart.priorLastCloseChartData()[0][1];
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this._ensurePrices) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...this._ensurePrices) * 0.9995;
    },
    /**
     * 最大値、最小値を計算する処理の共通部分
     * @private
     * @return {Array}
     */
    _ensurePrices() {
      if (!this.intradayStockChart) {
        return [];
      }
      return this.intradayStockChart.allPrices();
    },
    /**
     * 最終株価時刻
     * @return {Date}
     */
    currentClosedAt() {
      if (!this.intradayStockChart) return;

      return this.intradayStockChart.currentClosedAt();
    },
    /**
     * Y軸のラベルの幅
     * @return {Number}
     */
    yAxisLabelWidth() {
      const pitch = 7.25;
      const margin = 4;
      const pitchDelimiter = 2.65;
      const words = parseInt(this.yAxisMax).toString().length;

      if (words <= 3) {
        return pitch * words + margin;
      } else {
        return pitch * words + margin + pitchDelimiter;
      }
    },
    /**
     * 各日付の9時から15時までの1時間刻みの時刻を返す
     * ただし、1日目は14時まで
     */
    tickPositions() {
      if (!this.intradayStockChart) return [];

      let positions = [];
      const priorDay = dayjs(this.intradayStockChart.priorDayChart.dateString);
      const currentDay = dayjs(this.intradayStockChart.currentDayChart.dateString);

      for (let i = 9; i <= 14; i++) {
        positions.push(priorDay.hour(i).valueOf());
      }
      for (let i = 9; i <= 15; i++) {
        positions.push(currentDay.hour(i).valueOf());
      }

      return positions;
    },
    /**
     * 前日終値チャートの凡例
     */
    priorLastClosePriceLegend() {
      if (!this.intradayStockChart) return '前々日終値';
      const priorLastDay = dayjs(this.intradayStockChart.priorLastDay).format('M/D');
      return `${priorLastDay}終値：${this.numFormat(this.priorLastClose, this.tooltipDecimal(this.code))}`;
    },
    /**
     *  当日終値チャートの凡例
     */
    currentLastClosePriceLegend() {
      if (!this.intradayStockChart) return '前日終値';
      const priorDay = dayjs(this.intradayStockChart.priorDayChart.dateString).format('M/D');
      return `${priorDay}終値：${this.numFormat(this.currentLastClose, this.tooltipDecimal(this.code))}`;
    },
  },
  mounted() {
    this.loadIntradayStockChart();
    this.setLegendWidth();
  },
  methods: {
    /**
     * 2日分チャートを読み込む
     * @note PSI対応でスクロールイベント発火時に1度だけ処理されるようにしている
     * @return {void}
     */
    async loadIntradayStockChart() {
      const intradayStockChartBuilder = new MkIntradayStockChartBuilder();
      const intradayStockChart = await intradayStockChartBuilder.build(this.code, this.exchangeId);

      if (this.isLazyLoad) {
        this.lazyLoadByScrollOnce(() => {
          this.intradayStockChart = intradayStockChart;
        });
      } else {
        this.intradayStockChart = intradayStockChart;
      }
    },
    /**
     * ツールチップ用の小数点桁数
     * 指数系銘柄の場合2桁、そのほかは1桁
     * @TODO: このメソッドはMixinで共通化する
     * @param {String} code
     * @return {Number}
     */
    tooltipDecimal(code) {
      return this.isIndexCode(code) ? 2 : 1;
    },
    /**
     * チャートクリック時にツールチップを表示する
     * @param {void}
     */
    chartClick(plotData) {
      this.$store.commit('showTooltip');
      this.plotData = plotData;
    },
    /**
     * ツールチップを非表示にする
     * @param {void}
     */
    hideTooltip() {
      this.$store.commit('hideTooltip');
    },
    /**
     * 凡例の幅を設定する
     * @param {void}
     */
    setLegendWidth() {
      this.legendWidth = window.innerWidth >= 375 ? 311 : void 0;
    },
  },
};
</script>
