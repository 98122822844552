/**
 * Cookieを扱うクラス
 */
class CookieUtil {
  /**
   * CookieをHashオブジェクトに変換
   * @return {hash}
   */
  static convertCookieToObject() {
    const cookieItems = document.cookie.split(';');
    const obj = {};
    cookieItems.forEach((item) => {
      let elem = item.split('=');
      const key = elem[0].trim();
      const val = decodeURIComponent(elem[1]);
      obj[key] = val;
    });

    return obj;
  }

  /**
   * 指定されたキーのCookieの値を返す
   * @param {string} key Cookieのキー
   * @return {string} Cookieの値
   */
  static get(key) {
    const cookieObj = this.convertCookieToObject();
    return cookieObj[key];
  }
}
export default CookieUtil;
