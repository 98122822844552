<template src="./ShareholderBenefitColumnFeeWithUnit.html"></template>

<script>
import FeeRankingSecurity from '../../models/FeeRankingSecurity';
import SecuritiesCompanyTable from './SecuritiesCompanyTable.vue';
import ShareholderBenefitColumnYield from './ShareholderBenefitColumnYield.vue';

/**
 * 指定した購入株数での手数料比較表示ルートコンポーネント
 *
 * @vue-prop {Boolean} isNext 翌月フラグ { true: 翌月用ネット証券手数料比較htmlを描画 / false: 当月用ネット証券手数料比較htmlを描画 }
 * @vue-prop {Object} shareholderBenefit おすすめ株主優待コラムページに必要な株主優待情報
 * @vue-data {Array.<FeeRankingSecurity>} securitiesCompanies 証券会社手数料比較ランキング
 * @vue-computed {number} unitedPrice 株価 x 株主優待権利獲得単位
 * @vue-computed {boolean} isLoaded securitiesCompaniesが読込済みなら真
 * @vue-computed {Mk.Comparison.Fee} mkComparisonFee 手数料比較コンテンツライブラリ
 * @vue-computed {number} purchasePrice 最低投資金額
 * @vue-computed {Date} priceTime 最低投資金額算出時
 * @vue-computed {FeeRankingSecurity} topSecurity 手数料最安の証券会社
 * @vue-computed {Object} financialItem ページで表示されている金融アイテム
 * @module ShareholderBenefitColumn/ShareholderBenefitColumnFeeWithUnit
 */
export default {
  name: 'ShareholderBenefitColumnFeeWithUnit',
  components: {
    securitiesCompanyTable: SecuritiesCompanyTable,
    shareholderBenefitColumnYield: ShareholderBenefitColumnYield
  },
  props: {
    isNext: {
      type: Boolean,
      default: false
    },
    shareholderBenefit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      securitiesCompanies: []
    };
  },
  computed: {
    unitedPrice() {
      return this.financialItem.price * this.shareholderBenefit.unit_to_right;
    },
    // 読込開始〜読込終了までのエラー防止メソッド
    isLoaded() {
      return this.securitiesCompanies.length >= 3;
    },
    mkComparisonFee() {
      return window.MK.Comparison.Fee;
    },
    purchasePrice() {
      if (this.isLoaded) {
        return this.topSecurity.purchasePrice;
      } else {
        return 0;
      }
    },
    priceTime() {
      return new Date(this.financialItem.price_time);
    },
    topSecurity() {
      return this.securitiesCompanies[0];
    },
    financialItem() {
      return this.mkComparisonFee.getStock(`#${this.shareholderBenefit.financial_item_code}`);
    }
  },
  created() {
    this.updateSecurities(this.shareholderBenefit.unit_to_right);
  },
  mounted(){
    this.hiddenIcons(".loading_disp");
  },
  methods: {
    /**
     * 指定された株数に応じた手数料比較ランキングを更新する
     *
     * @param {number} unitValue 株主優待発生株数(最低購入株数)
     */
    updateSecurities(unitValue) {
      this.mkComparisonFee.Ranking.get(this.unitedPrice, this.financialItem.stock_exchange_id, (rankings) => {
        this.securitiesCompanies = rankings.map((ranking) => {
          return new FeeRankingSecurity(ranking, this.financialItem, unitValue);
        }).slice(0, 3);
      });
    },
    /**
     * 対象Classを非表示にする
     *
     * @param {string} classValue 対象Class
     */
    hiddenIcons(classValue) {
      const targetClasses = document.querySelectorAll(classValue);
      targetClasses.forEach((targetClass) => {
        targetClass.style.display = 'none';
      });
    }
  }
};
</script>
