import StockCalendarResource from '../../resources/StockCalendarResource.js';
import IntradayStockChartResource from '../../resources/IntradayStockChartResource.js';
import MkIntradayStockChart from './MkIntradayStockChart';

/**
 *
 */
class MkIntradayStockChartBuilder {
  /**
   * 日中足チャートモデルを生成する
   * @param {String} code 金融アイテムコード
   * @param {Number} exchangeId 取引市場ID
   * @note 今日の取引の仕様によって、8:30を境に日中足の取得日時を変えている
   * - 営業日カレンダー取得
   * - 株価データ取得
   */
  async build(code, exchangeId) {
    const stockCalendarResource = new StockCalendarResource();
    const stockCalendar = await stockCalendarResource.get();

    const currentDay = stockCalendar.currentDay('08:30');
    const priorDay = stockCalendar.priorDay('08:30');
    const priorLastDay = stockCalendar.priorLastDay('08:30');

    const currentCoefficient = stockCalendar.coefficientByCodeAndDate(code, currentDay);
    const priorCoefficient = stockCalendar.coefficientByCodeAndDate(code, priorDay);

    const intradayStockChartResource = new IntradayStockChartResource();
    const currentDayChart = await intradayStockChartResource.get(code, exchangeId, currentDay, currentCoefficient);
    const priorDayChart = await intradayStockChartResource.get(code, exchangeId, priorDay, priorCoefficient);

    return new MkIntradayStockChart(currentDayChart, priorDayChart, priorLastDay);
  }
}

export default MkIntradayStockChartBuilder;
