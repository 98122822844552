/**
 * IPOカレンダーコンポーネントストア
 * @property {Number} calendarYear カレンダーの年
 * @property {Number} calendarMonth カレンダーの月
 * @property {Object} calendarPeriod カレンダー表示期間(from/to)
 * @property {boolean} isVisibleMonthPicker MonthPickerが表示されているか
 * @property {Number} pickerYear MonthPickerが表示している年
 * @module IPOTop/IPOCalendarStore
 */
const IPOCalendarStore = {
  namespaced: true,
  state: {
    calendarYear: null,
    calendarMonth: null,
    calendarPeriod: null,
    isVisibleMonthPicker: false,
    pickerYear: null,
  },
  getters: {
    /**
     * @returns {Number}
     */
    calendarYear(state) {
      return state.calendarYear;
    },
    /**
     * @returns {Number}
     */
    calendarMonth(state) {
      return state.calendarMonth;
    },
    /**
     * @returns {boolean}
     */
    isVisibleMonthPicker(state) {
      return state.isVisibleMonthPicker;
    },
    /**
     * カレンダー表示可能期間開始日
     * @returns {Date}
     */
    calendarPeriodFrom(state) {
      if (!state.calendarPeriod) {
        return null;
      }
      return new Date(state.calendarPeriod.periodFrom);
    },
    /**
     * カレンダー表示可能期間終了日
     * @returns {Date}
     */
    calendarPeriodTo(state) {
      if (!state.calendarPeriod) {
        return null;
      }
      return new Date(state.calendarPeriod.periodTo);
    },
    /**
     * 前月が表示可能なら真
     * @returns {boolean}
     */
    hasPrevMonth(state, getters) {
      if (!state.calendarPeriod) {
        return false;
      }

      const fromYear = getters.calendarPeriodFrom.getFullYear();
      const fromMonth = getters.calendarPeriodFrom.getMonth() + 1;
      return fromYear * 12 + fromMonth < state.calendarYear * 12 + state.calendarMonth;
    },
    /**
     * 翌月が表示可能なら真
     * @returns {boolean}
     */
    hasNextMonth(state, getters) {
      if (!state.calendarPeriod) {
        return false;
      }

      const toYear = getters.calendarPeriodTo.getFullYear();
      const toMonth = getters.calendarPeriodTo.getMonth() + 1;
      return toYear * 12 + toMonth > state.calendarYear * 12 + state.calendarMonth;
    },
    /**
     * @returns {Number}
     */
    pickerYear(state) {
      return state.pickerYear;
    },
  },
  mutations: {
    calendarYear(state, newYear) {
      state.calendarYear = newYear;
    },
    calendarMonth(state, newMonth) {
      state.calendarMonth = newMonth;
    },
    isVisibleMonthPicker(state, visible) {
      state.isVisibleMonthPicker = visible;
    },
    calendarPeriod(state, calendarPeriod) {
      state.calendarPeriod = calendarPeriod;
    },
    pickerYear(state, newYear) {
      state.pickerYear = newYear;
    },
  },
  actions: {
    /**
     * MonthPickerを表示する
     */
    showMonthPicker({ state }) {
      state.isVisibleMonthPicker = true;
    },
    /**
     * MonthPickerを隠す
     */
    hideMonthPicker({ state }) {
      state.isVisibleMonthPicker = false;
    },
    /**
     * MonthPickerの表示を切り替える
     */
    toggleMonthPicker({ state }) {
      state.isVisibleMonthPicker = !state.isVisibleMonthPicker;
    },
    /**
     * 前月のカレンダーを表示する
     */
    prevMonth({ state }) {
      if (state.calendarMonth > 1) {
        state.calendarMonth = state.calendarMonth - 1;
      } else {
        state.calendarMonth = 12;
        state.calendarYear = state.calendarYear - 1;
      }
    },
    /**
     * 次月のカレンダーを表示する
     */
    nextMonth({ state }) {
      if (state.calendarMonth < 12) {
        state.calendarMonth = state.calendarMonth + 1;
      } else {
        state.calendarMonth = 1;
        state.calendarYear = state.calendarYear + 1;
      }
    },
    /**
     * カレンダー内に当日の日付が含まれている場合
     * カレンダーの日付表示開始位置が当日になる様にする
     */
    stickyScroll() {
      const ipoCal = document.getElementById('ipo-calendar-table');
      const ipoCalToday = document.getElementById('ipo-calendar-today');
      const ipoCalF11mTitle = document.getElementById('ipo-calendar-f11m-title');

      if (ipoCalToday) {
        ipoCal.scrollTo(ipoCalToday.offsetLeft - ipoCalF11mTitle.clientWidth, 0);
      }
    },
    /**
     * カレンダーの日付表示開始位置が1日になる様にする
     */
    cancelStickyScroll() {
      const ipoCal = document.getElementById('ipo-calendar-table');

      ipoCal.scrollTo(0, 0);
    },
  },
};
export default IPOCalendarStore;
