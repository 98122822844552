import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';

/**
 * 銘柄一覧
 *
 * @module FinancialItemList/FinancialItemListStore
 */
const FinancialItemListStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
  },
};
export default FinancialItemListStore;
