/**
 * 数値用フィルター
 *
 * @property {Array<string>} Vue.filterに登録するメソッド名
 * @module filters/NumberFilter
 */
class NumberFilter {
  constructor() {
    this.exportMethods = ['numFormat', 'numFormatWithZero', 'roundPriceByTenThousand', 'plusSign'];
  }
  /**
   * 値を整形して返却します。
   *
   * @param {number} number 値
   * @param {number} precision 小数点以下桁数
   * @returns {string} 整形済み値文字列
   */
  numFormat(number, precision = 0) {
    if (number === 0 || number === undefined || number === null) {
      return '---';
    }
    if (number) {
      const decimal = number.toString().split('.');
      const preDecimal = new Intl.NumberFormat('ja-JP').format(decimal[0]);
      if (precision === 0) {
        return preDecimal;
      } else {
        const postDecimal = `${decimal[1] || 0}${'0'.repeat(precision)}`.substr(0, precision);
        return `${preDecimal}.${postDecimal}`;
      }
    } else {
      return '';
    }
  }

  /**
   * 値を整形して返却します。
   * 値が0の時は0を返す
   *
   * @param {number} number 値
   * @param {number} precision 小数点以下桁数
   * @returns {string} 整形済み値文字列
   */
  numFormatWithZero(number, precision = 0) {
    if (number === undefined || number === null) {
      return '---';
    }
    if (number === 0) {
      if (precision === 0) {
        return '0';
      } else {
        return `0.${'0'.repeat(precision)}`;
      }
    }

    if (number) {
      const decimal = number.toString().split('.');
      const preDecimal = new Intl.NumberFormat('ja-JP').format(decimal[0]);
      if (precision === 0) {
        return preDecimal;
      } else {
        const postDecimal = `${decimal[1] || 0}${'0'.repeat(precision)}`.substr(0, precision);
        return `${preDecimal}.${postDecimal}`;
      }
    } else {
      return '';
    }
  }

  /**
   * 金額を10,000で除算して万単位にする（100,000 -> 10.0）
   * 小数点以下は一桁、切り捨て
   * @param {number} price 金額
   * @return {string} 整形済み文字列
   */
  roundPriceByTenThousand(price) {
    let roundedPrice = price * 0.0001;
    return `${Math.floor(roundedPrice * 10) / 10}`;
  }

  /**
   * 引数が正の値の場合は'+'を返却する
   * @param {number} number
   * @return {string} 正の符号
   */
  plusSign(number) {
    if (number > 0) {
      return '+';
    }
  }
}

export default NumberFilter;
