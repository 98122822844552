import FollowButton from "../Follows/FollowButton.vue";

/*
 * 会員ヘッダー ルートコンポーネント
 *
 */
const MemberHeader = {
  el: '#v-member-header',
  components: {
    FollowButton,
  },
};
export default MemberHeader;
