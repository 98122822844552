import PeriodLabel from '../../modules/StockChart/PeriodLabel.vue';
import IntradayChart from '../../modules/StockChart/IntradayChart.vue';
import HistoricalChart from '../../modules/StockChart/HistoricalChart.vue';
import IntradayFxChart from '../../modules/FxChart/IntradayFxChart.vue';
import HistoricalFxChart from '../../modules/FxChart/HistoricalFxChart.vue';

/**
 * みんかぶトップページ用ルートコンポーネント
 * @vue-components {PeriodLabel} PeriodLabel 期間選択ラベル
 * @vue-components {IntradayChart} IntradayChart 日中足チャート
 * @vue-components {HistoricalChart} HistoricalChart 履歴チャート
 * @vue-components {IntradayFxChart} IntradayFxChart 日中足チャート（為替）
 * @vue-components {HistoricalFxChart} HistoricalFxChart 履歴チャート（為替）
 * @module Top/Top
 */
const Top = {
  el: '#v-minkabu-top',
  components: {
    PeriodLabel,
    IntradayChart,
    HistoricalChart,
    IntradayFxChart,
    HistoricalFxChart,
  },
  computed: {
    /**
     * 日中足チャートであれば真
     * @return {boolean}
     */
    isIntradayChart() {
      return this.$store.getters['chartTerm'] === 'today';
    },
    /**
     * 履歴チャートであれば真
     * @return {boolean}
     */
    isHistoricalChart() {
      return this.$store.getters['chartTerm'] !== 'today';
    },
  },
};
export default Top;
