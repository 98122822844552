import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM as FRAGMENT_F11M } from './fragments/FinancialItemFragment';
import { FRAGMENT_MINKABU_ANALYSIS } from './fragments/MinkabuAnalysisFragment';
import { FRAGMENT_FINANCIAL_ITEM_VALUATIONS } from './fragments/FinancialItemValuationFragment';

const PEER_STOCKS_OF_INTEREST = `
  financialItem {
    ...financialItem
  }
  minkabuAnalysis {
    ...minkabuAnalysis
  }
  financialItemValuations {
    ...financialItemValuations
  }
`;

/**
 * 日本株全体の注目銘柄を取得するクエリ
 * @param {integer} exchange_section_id
 */
export const QUERY_PEER_STOCKS_OF_INTEREST_IN_WHOLE_SHARE = gql`
  query queryPeerStocksOfInterestInWholeShare {
    peerStocksOfInterestInWholeShare {
      ${PEER_STOCKS_OF_INTEREST}
    }
  }
  ${FRAGMENT_F11M}
  ${FRAGMENT_MINKABU_ANALYSIS}
  ${FRAGMENT_FINANCIAL_ITEM_VALUATIONS}
`;
