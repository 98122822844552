<template src="./SaveModal.html" />
<script>
/**
 * 銘柄スクリーニング 条件保存モーダル
 * @module StockSearch/modules/save/SaveModal
 */
import axios from 'axios';
import UrlGenerateUtil from '../../../../../utils/UrlGenerateUtil';

export default {
  name: 'SaveModal',
  data() {
    return {
      disabled: false,
      success: false,
      err: false,
      focus: false,
      timestamp: null,
    };
  },
  computed: {
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },

    /**
     * 登録上限を超えているか
     * @returns {boolean}
     */
    limitOver() {
      if (this.isLoggedIn && this.itemsRemaining > 0) {
        return false;
      }
      return true;
    },
    /**
     * モーダルの見出しを返す
     * @returns {string}
     */
    heading() {
      if (this.isGuest) {
        return '会員登録が必要です';
      }
      if (this.limitOver && this.mode === 'create') {
        if (this.isPremiumUser) {
          return '検索条件が上限まで保存されています';
        }
        return 'みんかぶプレミアム会員なら「100件」まで条件を保存できます';
      }
      return '検索条件を保存';
    },
    /**
     * 登録可能数を返す
     * @returns {number}
     */
    itemsRemaining() {
      const max = this.isPremiumUser ? 100 : 3;
      return max - this.saveConditions.list.length;
    },
    saveConditions() {
      return this.$store.getters['stockSearchInput/saveConditions'];
    },
    show() {
      return this.saveConditions.modal.show;
    },
    id() {
      return this.saveConditions.editData.id;
    },
    url() {
      return this.saveConditions.editData.url;
    },
    mode() {
      return this.saveConditions.modal.mode;
    },
    condition() {
      return this.saveConditions.editData.condition;
    },
    nameCount() {
      return this.name.length;
    },
    name: {
      get() {
        return this.saveConditions.editData.name;
      },
      set(v) {
        this.$store.commit('stockSearchInput/setSavaConditionsEditDataName', v);
      },
    },
    /**
     * プレミアム登録とログインのコールバックURLを返す
     */
    callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        if (path === 'premium_lp') {
          return UrlGenerateUtil.premiumPath({
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'save_condition',
            campaign: '/stock/search',
          });
        } else {
          return UrlGenerateUtil.idMinkabuUrl({
            pathString: path,
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: 'save_condition',
            campaign: '/stock/search',
          });
        }
      };
    },
    axiosOptions() {
      return {
        headers: {
          'ContentType': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      };
    },
  },
  watch: {
    show() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.timestamp = new Date().toLocaleString('ja-JP');
      this.$store.dispatch('stockSearchInput/getSaveCondition');
    },
    /**
     * 名前が空ならタイムスタンプを入れる
     */
    checkName() {
      this.name = this.name.length > 0 ? this.name : this.timestamp;
    },
    /**
     * 4バイト文字を削除する
     * @param {string} str
     * @returns {string}
     */
    remove4byte(str) {
      return Array.from(str)
        .filter((s) => new Blob([s]).size < 4)
        .join('');
    },
    /**
     * モードによって保存処理を分岐する
     */
    async save() {
      this.err = false;
      this.checkName();
      if (this.saveConditions.modal.mode === 'create') {
        await this.create();
      } else if (this.saveConditions.modal.mode === 'edit') {
        await this.edit();
      } else {
        return;
      }

      this.disabled = false;

      if (!this.err) {
        this.success = true;
        setTimeout(() => {
          if (!this.success) {
            return;
          }
          this.hide();
        }, 2000);
      }
    },
    /**
     * Ajaxのステータスコードが2xx以外ならエラー
     * @param {number} status
     */
    checkStatusCode(status) {
      if (status < 200 || status >= 300) {
        this.err = true;
      }
    },

    /**
     * 条件を新規保存する
     */
    async create() {
      this.disabled = true;
      const params = {
        name: this.remove4byte(this.name),
        url: this.url,
        condition: this.condition,
      };

      this.checkStatusCode(
        await axios
          .post(`/stock/search/saved_condition`, params, this.axiosOptions)
          .then((response) => response.status)
          .catch((err) => err.response.status)
      );
    },
    /**
     * 条件を上書き保存する
     */
    async edit() {
      this.disabled = true;
      const params = {
        name: this.remove4byte(this.name),
      };

      this.checkStatusCode(
        await axios
          .patch(`/stock/search/saved_condition/${this.id}.json`, params, this.axiosOptions)
          .then((response) => response.status)
          .catch((err) => err.response.status)
      );
    },
    /**
     * 条件編集・削除画面に遷移する
     */
    saveConditionPage() {
      this.hide();
      window.scrollTo(0, 0);
      this.$store.commit('stockSearchInput/setView', '');
      this.$store.commit('stockSearchInput/setSavaConditionsPage', 'saveCondition');
    },
    /**
     * モーダルを非表示にする
     */
    hide() {
      if (this.focus) {
        // 入力欄にフォーカスが当たっている場合はモーダルを閉じない
        return;
      }

      const body = document.querySelector('body');
      const offsetY = -parseFloat(getComputedStyle(body).top);
      body.classList.remove('ui-drawer-scroll-stop');
      body.style.top = null;
      window.scrollTo(0, offsetY);
      this.$store.commit('stockSearchInput/setSavaConditionsModalShow', false);
      this.err = false;
      this.success = false;
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
  },
};
</script>
