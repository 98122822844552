<template src="./SettlementChartQuarterlyHistories.html"></template>
<script>
import RoundUtil from '../../utils/RoundUtil';
import {Chart} from 'highcharts-vue';

/*
 * 四半期推移チャートコンポーネント
 *
 * @vue-prop {Settlements::Index::QuarterlyHistories::(Sales|OperatingIncome|OrdinaryIncome|NetIncome)Charts::ViewModel} chartElements
 * @module SettlementChartQuarterlyHistories
 */
export default {
  name: 'SettlementChartQuarterlyHistories',
  props: {
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: 200
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [
            '1Q',
            '2Q',
            '3Q',
            '通期'
          ]
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return RoundUtil.roundJapaneseUnit(this.value, 1);
            }
          },
          minorTickInterval: 'auto'
        },
        tooltip: {
          formatter: function() {
            return `<small>${this.x}</small><br>${this.series.name}: <b>${RoundUtil.roundJapaneseUnit(this.point.y, 1)}</b>`;
          }
        },
        series: [
          {
            name: '前々期実績',
            data: this.revertRoundedValues(this.chartElements['two_years_ago_results']),
            color: '#bbcad4',
            pointPlacement: -0.20
          },
          {
            name: '前期実績',
            data: this.revertRoundedValues(this.chartElements['a_year_ago_results']),
            color: '#95a5b1'
          },
          {
            name: '今期実績',
            data: this.revertRoundedValues(this.chartElements['current_results']),
            boderColor: '#cccccc',
            color: '#0096fa',
            pointPlacement: 0.22
          },
          {
            name: '最新会社予想',
            data: this.revertRoundedValues(this.chartElements['latest_projections']),
            pointPlacement: 0.22,
            dashStyle: 'dottwo',
            borderColor: '#666666',
            borderWidth: 1.6,
            pointWidth: 20,
            color: 'rgba(225, 225, 225, .3)'
          },
          {
            type: 'scatter',
            name: '当初会社予想',
            data: this.revertRoundedValues(this.chartElements['initial_projections']),
            pointPlacement: 0.22,
            color: '#00defa',
            marker: {
              radius: 5
            }
          },
          {
            type: 'scatter',
            name: 'アナリスト予想',
            data: this.revertRoundedValues(this.chartElements['analyst_projections']),
            pointPlacement: 0.22,
            color: '#FC9F02',
            marker: {
              symbol: 'diamond',
              radius: 6
            }
          }
        ],
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 20,
            pointPadding: -0.1,
            groupPadding: 0,
            grouping: false,
            shadow: false,
            animation: false
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  },
  methods: {
    /**
     * 配列の要素の値を100万掛けて返す
     * @param {Array<integer|null>} list
     * @return {Array<integer|null>}
     */
    revertRoundedValues: function(list) {
      return list === [] ? list : list.map(function(val) { return RoundUtil.revertRoundedMillion(val); });
    }
  }
};
</script>
