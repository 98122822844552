<template src="./LowestSecurity.html" />

<script>
import FeeRankingSecurity from '../../models/FeeRankingSecurity';
/**
 * 手数料最安の証券会社の紹介コンポーネント
 *
 * @vue-prop {FeeRankingSecurity} security 手数料最安の証券会社
 * @module Fee/LowestSecurity
 */
export default {
  name: 'LowestSecurity',
  props: {
    security:{
      type: FeeRankingSecurity,
      required: true
    }
  }
};
</script>
