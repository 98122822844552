<template src="./YutaiSearchCheckboxes.html" />
<script>
/**
 * 株主優待検索ボックス用チェックボックスコンポーネント
 *
 * @vue-props {Array.<string>} propIds 選択済みの項目ID
 * @vue-props {Array.<Array>} propMasterMaps [string: 項目名, string: 項目ID]の配列
 * @vue-props {boolean} propIsAllChecked ページ表示時点ですべて選択するなら真
 * @vue-props {string} inputName フォーム送信時のname
 * @vue-props {string} inputAllName フォーム送信時の「すべて選択する」のname
 * @vue-data {Array.<string>} ids 現在選択中の項目ID
 * @vue-data {boolean} isCheckedAll すべて選択するなら真
 * @module Yutai/YutaiSearchCheckboxes
 */
export default {
  name: 'YutaiSearchCheckboxes',
  props: {
    propIds: { type: Array, default: () => [] },
    propMasterMaps: { type: Array, default: () => [] },
    propIsAllChecked: { type: Boolean, default: false },
    inputName: { type: String, require: true },
    inputAllName: { type: String, require: true }
  },
  data() {
    return {
      ids: [],
      isCheckedAll: false
    };
  },
  created() {
    this.ids = this.propIds || [];
    this.isCheckedAll = this.propIsAllChecked;
  },
  methods: {
    /**
     * 項目IDが現在選択中の項目IDに含まれていれば真
     * @param {string} id 項目ID
     * @returns {boolean}
     */
    isChecked(id) {
      return this.ids.includes(id);
    },
    /**
     * isChecked(項目ID)がtrueなら
     * 現在選択中の項目ID群から取り除いて、すべて選択するを偽にする。
     * isChecked(項目ID)がfalseなら
     * 現在選択中の項目IDに項目IDを追加する
     * @param {string} id 項目ID
     */
    toggle(id) {
      if (this.isChecked(id)) {
        this.ids = this.ids.filter(i => i !== id);
        this.isCheckedAll = false;
      } else {
        this.ids.push(id);
      }
    },
    /**
     * すべて選択するの真偽を入れ替える
     * 入れ替えてすべてを選択するが真なら全項目を現在選択中の項目IDに追加する
     * 入れ替えてすべてを選択するが偽なら現在選択中の項目IDを空配列にする
     */
    toggleAll() {
      this.isCheckedAll = !this.isCheckedAll;
      if (this.isCheckedAll === true) {
        this.ids = this.propMasterMaps.map(pair => { return pair[1]; });
      } else {
        this.ids = [];
      }
    }
  }
};
</script>
