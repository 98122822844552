<template src="./FavoriteButtonBox.html"></template>
<script>
/**
 * お気に入りボックスボタン コンポーネント
 * @vue-prop {string} financialItemCode 銘柄コード
 * @vue-prop {number} favoriteTotalCount お気に入り総数
 * @vue-computed {boolean} isFinancialItemFavored お気に入り登録している銘柄なら真
 * @module Common/FavoriteButtonBox
 */
export default {
  name: 'FavoriteButtonBox',
  props: {
    financialItemCode: {
      type: String,
      required: true
    },
    favoriteTotalCount: {
      type: Number,
      required: true,
      validator: function (value) {
        return value >= 0;
      }
    }
  },
  computed: {
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode);
    }
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    /**
     * 1. 金融アイテムをお気に入り登録または削除
     * 2. 1が完了したらお知らせボックスを表示(5秒間)
     */
    async toggle() {
      await this.$store.dispatch('favoriteFinancialItems/toggle', this.financialItemCode);
      await this.$store.dispatch('notificationBox/show', this.$store.state.favoriteFinancialItems.notificationMessage);
    }
  }
};
</script>
