<template src="./NameModal.html"></template>
<script>
/**
 * 名前モーダルコンポーネント
 * @vue-props {string} name モーダル名
 * @vue-computed {boolean} isVisible モーダルが表示中なら真
 * @module Modal/NameModal
 */
export default {
  name: 'NameModal',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters['isVisible'][this.name];
    },
  },
  methods: {
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      this.$store.commit('hide', this.name);
    },
  },
};
</script>
