<template src="./AdHideLink.html"></template>
<script>
/**
 * 広告非表示リンクコンポーネント
 * @module AdHide/AdHideLink
 */
export default {
  name: 'AdHideLink',
  props: {
    /**
     * ad9101で使用するかどうか
     */
    isAd9101: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isAd9101Loaded: false,
    };
  },
  computed: {
    /**
     * 広告非表示ボタンの表示・非表示
     * @type {boolean}
     */
    isShowAdHideBtn() {
      return this.isAd9101 && this.isSp && this.isAd9101Loaded;
    },
    /**
     * 特に小さい画面かどうか(iPhone5など、iPhone 6/7/8より小さいサイズを想定)
     * @return {boolean}
     */
    isSmallScreen() {
      return window.innerWidth < 360;
    },
  },
  mounted() {
    if (this.isAd9101) {
      // Vue.jsインスタンスのthisを変数に格納
      const vueThis = this;

      // 9101広告が読み込まれたらフラグを立てる
      googletag.cmd.push(function () {
        googletag.pubads().addEventListener('slotRenderEnded', function (event) {
          vueThis.setAdLoaded(event.slot.getSlotElementId());
        });
      });
    }
  },
  methods: {
    /**
     * モーダルの表示
     */
    async open(name) {
      this.$store.commit('show', name);
    },
    /**
     * Ad9101のロード済みを判定
     */
    setAdLoaded(adId) {
      if (adId.includes('9101')) {
        this.isAd9101Loaded = true;
      }
    },
  },
};
</script>
