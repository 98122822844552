/**
 * HighCharts拡張
 * - series.ignoreWhenScaling = true に設定すると、該当のデータはY軸の計算対象から外れる
 * - Y軸のスケールが各項目10%上下に拡張される
 *
 * @param {Highcharts}
 */
const HighchartsIgnoreWhenScaling = function (H) {
  const PADDING_RATE = 0.1;

  let extremes = {};
  let yPadding = 0;
  let ignoreWhenScaling = false;

  H.wrap(H.Series.prototype, 'getExtremes', function (proceed) {
    ignoreWhenScaling = this.options.ignoreWhenScaling;
    if (ignoreWhenScaling) {
      return extremes;
    } else {
      extremes = proceed.apply(this, [].slice.call(arguments, 1));
      if (extremes.dataMax === undefined) return extremes;

      yPadding = (extremes.dataMax - extremes.dataMin) * PADDING_RATE;
      extremes.dataMax += yPadding;
      extremes.dataMin -= yPadding;
      return extremes;
    }
  });
};
export default HighchartsIgnoreWhenScaling;
