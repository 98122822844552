import SideNavigationHistoriesComponent from './SideNavigationHistories.vue';

/*
 * 右ナビ 閲覧履歴 ルートコンポーネント
 *
 */
const SideNavigationHistories = {
  el: '#side-navigation-histories',
  data: {
    loadingDisplay: true,
  },
  components: {
    sideNavigationHistories: SideNavigationHistoriesComponent,
  },
};
export default SideNavigationHistories;
