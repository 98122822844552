<template src="./AccordionSection.html" />
<script>
/**
 * 銘柄検索 アコーディオンコンポーネント
 * @module StockSearch/modules/accordion/AccordionSection
 */
export default {
  name: 'CriteriaAccordion',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    /**
     * 開閉
     */
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>
