<template src="./YutaiTopRankings.html" />

<script>
import YutaiTopRankingsPopular from './YutaiTopRankingsPopular.vue';
import YutaiTopRankingsYield from './YutaiTopRankingsYield.vue';

import { ContentLoader } from 'vue-content-loader';
import {
  QUERY_YUTAI_RANKINGS_POPULAR as YUTAI_RANKINGS_POPULAR,
  QUERY_YUTAI_RANKINGS_YUTAI_YIELD as YUTAI_RANKINGS_YUTAI_YIELD,
  QUERY_YUTAI_RANKINGS_DIVIDEND_YIELD as YUTAI_RANKINGS_DIVIDEND_YIELD,
  QUERY_YUTAI_RANKINGS_TOTAL_YIELD as YUTAI_RANKINGS_TOTAL_YIELD,
} from '../../../queries/YutaiTopRankingsQuery';
import FavoriteButton from '../../Common/FavoriteButton.vue';
/**
 * 株主優待トップのランキング表示コンポーネント
 *
 * @vue-data {string} 現在のランキングを切り替える為に利用
 * @vue-apollo {Object} yutaiRankingsPopular 株主優待人気ランキング
 * @vue-apollo {Object} yutaiRankingsYutaiYield 株主優待利回りランキング
 * @vue-apollo {Object} yutaiRankingsDividendYield 配当利回りランキング
 * @vue-apollo {Object} yutaiRankingsTotalYield 配当＋株主優待利回りランキング
 * @module Yutai/YutaiSearchYieldRangeSlider
 */
export default {
  name: 'YutaiTopRankings',
  components: {
    yutaiTopRankingsPopular: YutaiTopRankingsPopular,
    yutaiTopRankingsYield: YutaiTopRankingsYield,
    contentLoader: ContentLoader,
    favoriteButton: FavoriteButton,
  },
  data() {
    return {
      current: 'popular',
      yutaiRankingsPopular: () => {},
      yutaiRankingsYutaiYield: () => {},
      yutaiRankingsDividendYield: () => {},
      yutaiRankingsTotalYield: () => {},
    };
  },
  apollo: {
    yutaiRankingsPopular: {
      query: YUTAI_RANKINGS_POPULAR,
    },
    yutaiRankingsYutaiYield: {
      query: YUTAI_RANKINGS_YUTAI_YIELD,
      skip() {
        return true;
      },
    },
    yutaiRankingsDividendYield: {
      query: YUTAI_RANKINGS_DIVIDEND_YIELD,
      skip() {
        return true;
      },
    },
    yutaiRankingsTotalYield: {
      query: YUTAI_RANKINGS_TOTAL_YIELD,
      skip() {
        return true;
      },
    },
  },
  computed: {
    /**
     * 株主優待人気ランキングなら真
     */
    isPopular() {
      return this.current === 'popular';
    },
    /**
     * 株主優待利回りランキングなら真
     */
    isYutaiYield() {
      return this.current === 'yutai';
    },
    /**
     * 配当利回りランキングなら真
     */
    isDividendYield() {
      return this.current === 'dividend';
    },
    /**
     * 配当＋株主優待利回りランキングなら真
     */
    isTotalYield() {
      return this.current === 'total';
    },
    /**
     * 現在のランキングオブジェクト
     */
    currentRankings() {
      if (this.isYutaiYield) {
        return this.yutaiRankingsYutaiYield;
      } else if (this.isDividendYield) {
        return this.yutaiRankingsDividendYield;
      } else if (this.isTotalYield) {
        return this.yutaiRankingsTotalYield;
      } else {
        return this.yutaiRankingsPopular;
      }
    },
    /**
     * 現在のランキング種別
     */
    currentRankingType() {
      if (this.isYutaiYield) {
        return 'yutai';
      } else if (this.isDividendYield) {
        return 'dividend';
      } else if (this.isTotalYield) {
        return 'total';
      } else {
        return '';
      }
    },
    /**
     * 現在のランキングURL
     */
    currentRankingUrl() {
      if (this.isYutaiYield) {
        return '/yutai/yutai_ranking';
      } else if (this.isDividendYield) {
        return '/yutai/haito_ranking';
      } else if (this.isTotalYield) {
        return '/yutai/yutai_haito_ranking';
      } else {
        return '/yutai/popular_ranking';
      }
    },
    /**
     * 現在のランキング名
     */
    currentRankingName() {
      if (this.isYutaiYield) {
        return '株主優待利回りランキング';
      } else if (this.isDividendYield) {
        return '配当利回りランキング';
      } else if (this.isTotalYield) {
        return '配当＋株主優待利回りランキング';
      } else {
        return '株主優待人気ランキング';
      }
    },
  },
  methods: {
    /**
     * タブ切替え処理
     * @returns {void}
     */
    change(tabName) {
      this.current = tabName;

      switch (tabName) {
        case 'yutai':
          this.$apollo.queries.yutaiRankingsYutaiYield.skip = false;
          break;
        case 'dividend':
          this.$apollo.queries.yutaiRankingsDividendYield.skip = false;
          break;
        case 'total':
          this.$apollo.queries.yutaiRankingsTotalYield.skip = false;
          break;
      }
    },
  },
};
</script>
