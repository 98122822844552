<template src="./FavoriteButtonHeart.html"></template>
<script>
import UserLoginStatus from '../../utils/UserLoginStatus';
/**
 * お気に入りハートボタン コンポーネント
 * @vue-prop {string} financialItemCode 銘柄コード
 * @vue-computed {boolean} isUserLoginStatus ユーザがログイン状態なら真
 * @vue-computed {boolean} isFinancialItemFavored お気に入り登録している銘柄なら真
 * @module Common/FavoriteButtonHeart
 */
export default {
  name: 'FavoriteButtonHeart',
  props: {
    financialItemCode: {
      type: String,
      required: true
    },
    dataGtmClickLabelPrefix: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isUserLoginStatus() {
      return UserLoginStatus.isLoggedIn();
    },
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode);
    },
    dataGtmClickLabel() {
      const baseLabel = this.isFinancialItemFavored ? 'favorite_remove' : 'favorite_add';
      return this.dataGtmClickLabelPrefix ? `${this.dataGtmClickLabelPrefix}${baseLabel}` : baseLabel;
    },
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    /**
     * 1. ユーザが未ログイン時はログイン画面へ遷移させる
     * 2. 金融アイテムをお気に入り登録または削除
     */
    async toggle() {
      if (this.isUserLoginStatus) {
        await this.$store.dispatch('favoriteFinancialItems/toggle', this.financialItemCode);
      } else {
        return location.assign(`/signin?return_to=${window.location.href}`);
      }
    }
  }
};
</script>
