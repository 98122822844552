import FinancialItemRankingSearchBox from './FinancialItemRankingSearchBox.vue';

/**
 * ランキング表示条件設定ルートコンポーネント
 *
 * @vue-components {FinancialItemRankingSearch} FinancialItemRankingSearch
 * @vue-data {boolean} isVisible 表示条件設定ボックスの表示状態
 * @module FinancialItemRankingSearch
 */
const FinancialItemRankingSearch = {
  el: "#v_financial_item_ranking_search",
  components: {
    FinancialItemRankingSearchBox: FinancialItemRankingSearchBox
  },
  data() {
    return {
      isBoxVisible: false
    };
  },
  methods: {
    /**
     * ランキング表示条件の表示/非表示を設定する
     * @param bool
     */
    setBoxVisible(bool) {
      this.isBoxVisible = bool;
    }
  }
};

export default FinancialItemRankingSearch;
