<template src="./RelationshipPercentagesGraph.html"></template>
<script>
import { Chart } from 'highcharts-vue';

/*
 * テーマ関連度グラフコンポーネント
 *
 * @module Themes/RelationshipPercentagesGraph
 */
export default {
  components: {
    highcharts: Chart,
  },
  props: {
    /**
     * 金融アイテムコード
     * @type {String}
     */
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          type: 'bar',
          height: 15,
          width: 50,
          margin: [0, 0, 0, 0],
          animation: false,
          backgroundColor: 'transparent',
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 200,
              easing: 'easeInOutSine',
            },
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 15,
            stacking: 'percent',
          },
        },
        series: [
          {
            data: [100 - vueThis.value],
            color: 'transparent',
          },
          {
            data: [vueThis.value],
            color: '#4294F3',
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
