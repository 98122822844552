/**
 * 共通的に使用するkeyとvalueを保持するMixin
 *
 */
const KeyValueMixin = {
  methods: {
    /**
     * 業績評価を表すkeyに対応する名前とURLをセットで返す
     *
     * @param {Integer} key
     * @return {Object}
     */
    settlementEvaluationNameWithIconUrl(key) {
      const settlementEvaluations = {
        5: { name: '晴れ', iconUrl: 'https://assets.minkabu.jp/images/icon/icon_results_sun_v2.svg' },
        4: { name: '曇り時々晴れ', iconUrl: 'https://assets.minkabu.jp/images/icon/icon_results_partly_cloudy_v2.svg' },
        3: { name: '曇り', iconUrl: 'https://assets.minkabu.jp/images/icon/icon_results_cloudy_v2.svg' },
        2: { name: '雨', iconUrl: 'https://assets.minkabu.jp/images/icon/icon_results_rain_v2.svg' },
        1: { name: '雷', iconUrl: 'https://assets.minkabu.jp/images/icon/icon_results_thunder_v2.svg' },
      };

      return settlementEvaluations[key] || { name: '---', iconUrl: '' };
    },
    /**
     * 業績評価推移(前期→今期)を表すkeyに対応する名前とURLをセットで返す
     * @note 前期、今期を表すkeyが渡ってくる前提
     * @param {Integer} key
     * @return {Object}
     */
    settlementEvaluationTrendsNameWithIconUrl(latest, previous) {
      const weatherName = ['雷', '雨', '曇り', '曇り時々晴れ', '晴れ'];
      const weatherEnName = ['thunder', 'rain', 'cloudy', 'partlyCloudy', 'sun'];

      if (weatherName[previous - 1] && weatherName[latest - 1]) {
        const iconName = `${weatherName[previous - 1]}→${weatherName[latest - 1]}`;
        const iconUrl = `https://assets.minkabu.jp/images/icon/sp_${weatherEnName[previous - 1]}_to_${
          weatherEnName[latest - 1]
        }.png`;
        return { name: iconName, iconUrl: iconUrl };
      }

      return { name: '---', iconUrl: '' };
    },
    /**
     * ニュースに紐づくタグのslugに対応するnameを返す
     * @note SEO的な事情等で表示するタグを変更したい場合に使われる想定
     * @param {String} slug
     * @return {String}
     */
    formatTagName(slug) {
      const tags = {
        'nikkei-average': '日経平均株価の見通し材料',
      };
      return tags[slug];
    },
  },
};
export default KeyValueMixin;
