import BaseResource from './BaseResource';
import StockChart from '../models/StockCharts/HistoricalStockChart';

/* *
 * 日足API
 * */
class StockChartResource extends BaseResource {
  _url(code, exchange_id) {
    const hostname = this._jsonHostname();
    return `${hostname}/daily_bar/${code}_${exchange_id}.json`;
  }
  /*
   * 日足データを取得する
   * @return {StockChart} 日足モデル
   */
  async get(code, exchange_id) {
    const url = this._url(code, exchange_id);
    return await this.client
      .get(url)
      .then((response) => {
        return this._toStockChart(response.data);
      })
      .catch(() => {
        return 'error';
      });
  }

  _toStockChart(data) {
    return new StockChart(data);
  }
}
export default StockChartResource;
