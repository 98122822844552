<template src="./SettlementChartProfitability.html"></template>
<script>
import {Chart} from 'highcharts-vue'

/*
 * 収益性チャート（ROA, ROE）コンポーネント
 *
 * @vue-prop {Settlements::Index::ProfitabilityCharts::ViewModel} chartElements
 * @module SettlementChartProfitability
 */
export default {
  name: 'SettlementChartProfitability',
  props: {
    chartElements: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          backgroundColor: '#ffffff',
          alignTicks: false,
          height: 244
        },
        title: {
          text: ''
        },
        legend: {
          backgroundColor: '#FFFFFF',
          align: 'center',
          verticalAlign: 'top'
        },
        xAxis: {
          categories: this.chartElements['fiscal_periods'],
          labels: {
            style: {
              fontSize: '11px'
            }
          }
        },
        yAxis: [{
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontSize: '11px'
            }
          }
        }, {
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontSize: '11px'
            }
          },
          opposite: true
        }],
        tooltip: {
          headerFormat: '<small>{point.x}</small><br>',
          pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        series: [
          {
            name: 'ROA',
            data: this.chartElements['roa'],
            color: '#00b9cc',
            yAxis: 0
          },
          {
            name: 'ROE',
            data: this.chartElements['roe'],
            color: '#f7c000',
            yAxis: 1
          }
        ],
        credits: {
          enabled: false
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 1
        },
        plotOptions: {
          line: {
            grouping: false
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  }
};
</script>
