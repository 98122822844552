<template src="./DrawerButtons.html" />
<script>
import FavoriteButton from '../../buttons/FavoriteButton.vue';
/**
 * お気に入り銘柄 ドロワー ボタン類
 * @module Mypage/modules/drawer/buttons/DrawerButtons
 */
export default {
  name: 'DrawerButtons',
  components: {
    FavoriteButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
