<template src="./PremiumTooltip.html" />
<script>
/**
 * 銘柄スクリーニング プレミアム会員誘導用 ツールチップコンポーネント
 * @module StockSearch/modules/tooltip/PremiumTooltip
 */

import UrlGenerateUtil from '../../../../../utils/UrlGenerateUtil';

export default {
  name: 'PremiumTooltip',
  data() {
    return {
      uid: this._uid,
      showPremiumTooltip: 'hidden',
    };
  },
  computed: {
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    /**
     * プレミアム登録とログインのコールバックURLを返す
     */
    callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        if (path === 'premium_lp') {
          return UrlGenerateUtil.premiumPath({
            currentUrl: window.location.href,
            source: 'shoken_minkabu',
            medium: 'tooltip',
            campaign: '/stock/search',
          });
        } else {
          return UrlGenerateUtil.idMinkabuUrl({
            pathString: path,
            currentUrl: window.location.href,
            source: 'shoken_minkabu',
            medium: 'tooltip',
            campaign: '/stock/search',
          });
        }
      };
    },
    zIndex() {
      return this.$store.getters['stockSearchInput/activeGlossaryTooltipCount'] + 10;
    },
  },
  methods: {
    /**
     * プレミアムツールチップを開閉する
     */
    premiumTooltipToggleOpen(event) {
      if (this.showPremiumTooltip === 'hidden') {
        document.addEventListener('click', this.closePremiumTooltip, true);
        this.openPremiumTooltip(event);
      } else {
        this.closePremiumTooltip(event);
      }
    },

    /**
     * プレミアムツールチップを表示する
     * @param {object} event
     */
    openPremiumTooltip(event) {
      if (this.isPremiumUser) {
        return;
      }

      // クリックした位置が画面中央より下にある場合は上に表示する
      const rect = event.target.getBoundingClientRect();
      const y = rect.top + rect.height / 2;
      if (y > window.innerHeight / 2) {
        this.showPremiumTooltip = 'top';
        return;
      }
      this.showPremiumTooltip = 'bottom';
    },
    /**
     * プレミアムツールチップを非表示にする
     * @param {object} event
     */
    closePremiumTooltip(event) {
      if (event.target.closest(`[data-uid="${this.uid}"]`)) {
        event.stopPropagation();
      }
      this.showPremiumTooltip = 'hidden';
      document.removeEventListener('click', this.closePremiumTooltip, true);
    },
  },
};
</script>
