import StockYutaiNewsResource from '../../../resources/StockYutaiNewsResource';
/*
 * @property {array<StockYutaiNews>} NewsList 優待関連ニュースのリスト
 * @property {array<StockYutaiNews>} LatestNewsList 最新優待関連ニュースのリスト（更新ボタン用）
 * @property {string} nextPage 次ページ数
 * @property {string} maxPage 最大ページ数
 * @property {string} stockCode 銘柄コード
 * @property {boolean} furthersButtonStatus さらに表示ボタンの状態(true: display, false: hidden)
 * @property {StockYutaiNewsResource} apiResource 銘柄優待ニュース取得APIクラス
 * */
const state = {
  NewsList: [],
  LatestNewsList: [],
  nextPage: 1,
  maxPage: null,
  stockCode: null,
  furthersButtonStatus: false,
  apiResource: new StockYutaiNewsResource()
};

const mutations = {
  setNewsList (state, data) {
    state.NewsList = state.NewsList.concat(data);
  },
  setLatestNewsList (state, data) {
    state.LatestNewsList = state.LatestNewsList.concat(data);
  },
  setFurthersButtonStatus (state, status) {
    state.furthersButtonStatus = status;
  },
  setMaxPage (state, page) {
    state.maxPage = page;
  },
  setStockCode (state, code) {
    state.stockCode = code;
  },
  incrementPage (state) {
    state.nextPage += 1;
  }
};

const actions = {
  /*
   * さらに表示ボタン押下後、優待関連ニュースのデータを取得
   */
  async updateYutaiNewsList ({ commit, state }) {
    commit('setFurthersButtonStatus', false);
    const response = await state.apiResource.get({
      params: {
        stock_code: state.stockCode,
        page: state.nextPage,
        threshold_limiter: "off"
      }
    });
    if (state.nextPage < state.maxPage) commit('setFurthersButtonStatus', true);
    commit('setNewsList', response.articles);
    commit('incrementPage');
  },

  /*
   * 初期化処理
   * ニュースの総ページ数, 銘柄コード, さらに表示ボタン表示有無 のセット
   */
  async initialProcessing ({ state, commit }) {
    commit('setStockCode', window.document.getElementById("stock_code").attributes.stock_code.value);

    const response = await state.apiResource.get({
      params: {
        stock_code: state.stockCode,
        page: 1,
        threshold_limiter: "off"
      }
    });

    commit('setNewsList', response.articles);
    commit('setMaxPage', Number(response.maxPage));

    /*
     * 総ページが0か1の時はさらに表示ボタンは表示しない
     */
    if (state.maxPage === 0 ) {
      commit('setFurthersButtonStatus', false);
    } else if (state.maxPage === 1) {
      commit('setFurthersButtonStatus', false);
    } else {
      commit('setFurthersButtonStatus', true);
      commit('incrementPage');
    }
  },

  /*
   * 更新ボタン押下後、優待関連ニュースのデータ取得
   * */
  async latestYutaiNewsList ({ commit, state }) {

    const datetime = document.querySelector('.date span') ? document.querySelector('.date span').getAttribute('data-datetime-second') : Math.round(new Date().getTime()/1000);

    const response = await state.apiResource.get({
      params: {
        stock_code: state.stockCode,
        latest_activated_time: datetime,
        threshold_limiter: "off"
      }
    });
    commit('setLatestNewsList', response.articles);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
