import FollowResource from '../../../resources/FollowResource';

const FollowButtonStore = {
  namespaced: true,
  state: {
    resource: new FollowResource(),
    followingIds: [],
  },
  getters: {
    /**
     * フォローしているかどうか
     * @return {Boolean}
     */
    isFollowing: (state) => (userId) => {
      return state.followingIds.indexOf(userId) >= 0;
    },
  },
  mutations: {
    /**
     * フォローしている会員のIDのリスト
     * @param {} state 
     * @param {Array<Number>} followingIds 
     */
    followingIds(state, followingIds) {
      state.followingIds = followingIds;
    },
    /**
     * 会員IDをフォローリストに追加する
     * @param {Number} followeeId 
     */
    addFollowingId(state, followeeId) {
      state.followingIds.push(followeeId);
    },
    /**
     * 会員IDをフォローリストから削除する
     * @param {Number} followeeId 
     */
    removeFollowingId(state, followeeId) {
      state.followingIds = state.followingIds.filter((id) => id !== followeeId);
    },
  },
  actions: {
    /**
     * フォローする
     * @param {Number} followeeId 
     */
    async follow({ state, commit }, followeeId) {
      commit('addFollowingId', followeeId);
      await state.resource.follow(followeeId);
    },
    /**
     * アンフォローする
     * @param {Number} followeeId 
     */
    async unfollow({ state, commit }, followeeId) {
      commit('removeFollowingId', followeeId);
      await state.resource.unfollow(followeeId);
    },
  },
};
export default FollowButtonStore;
