<template src="./SignUpModal.html" />
<script>
/**
 * 銘柄スクリーニング ログインモーダルコンポーネント
 * @module StockSearch/modules/modal/SignUpModal
 */

export default {
  name: 'SignUpModal',
  props: {},
  data() {
    return {};
  },
  computed: {
    isVisible() {
      return this.$store.getters['favoriteSignUpModal/isVisible'];
    },
  },
  mounted() {
    window.addEventListener('popstate', this.hide);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.hide);
  },
  methods: {
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      this.$store.commit('favoriteSignUpModal/hide');
    },
  },
};
</script>
