import BaseResource from './BaseResource';
import NewsSearchUrl from './NewsSearchUrl';
import NewsCollection from '../models/BaseCollection';
import NewsArticle from '../models/NewsArticle';

/**
 * ニュース検索API
 *
 * @property {NewsSearchUrl} url ニュース検索URLオブジェクト
 */
class NewsSearchResource extends BaseResource {

  constructor(params = {}) {
    super(params);
    this.url = new NewsSearchUrl(params['location']);
  }

  /**
   * APIをコールして非同期にコールバックメソッドを実行する
   *
   * @param {Object} params リクエストパラメータ
   * @param {Object} axiosOptions テスト用のOption
   */
  async get(params, axiosOptions = {}) {
    const response = await this._clientGet(params, axiosOptions);
    const mergedData = Object.assign(response.data, {'url': this.url.toString(params)});
    return this._toNewsCollection(mergedData);
  }

  /**
   * APIコール
   * @param {Object} params リクエストパラメータ
   * @param {Object} axiosOptions テスト用のOption
   * @return {Axios}
   * @private
   */
  _clientGet(params, axiosOptions) {
    return this.client.get(this.url.toString(params, this.format), axiosOptions);
  }

  /**
   * APIの戻り値をコレクションオブジェクトにして返却する
   *
   * @param {Object} data Api戻り値にurlを拡張したオブジェクト
   * @return {BaseCollection} ニュースのコレクションオブジェクト
   * @private
   */
  _toNewsCollection(data) {
    return new NewsCollection({
      header: data.header,
      items: this._toNewsArticles(data.items),
      url: data.url
    });
  }

  /**
   * ニュース記事の配列から
   * NewsArticleオブジェクトの配列を返却する
   *
   * @param {Object} items ニュース記事の配列
   * @return {Array<NewsArticle>}
   * @private
   */
  _toNewsArticles(items) {
    if (items.length === 0) {
      return [];
    }
    return items.map(item => new NewsArticle(item));
  }
}
export default NewsSearchResource;
