<template src="./TailwindPagination.html" />
<script>
import PageCell from './PageCell';

/**
 * Tailwind用ページネーションコンポーネント
 * @note 前提：stores/Pagination/TailwindPaginationStoreを利用する
 *
 * @vue-props {number} totalPage 総ページ数
 * @module TailwindPagination
 */
export default {
  name: 'TailwindPagination',
  props: {
    totalPage: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  computed: {
    /**
     * 現在のページ番号
     * @return {number}
     */
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    },
    /**
     * 前ページがあるか
     * @return {boolean}
     */
    hasPrevPage() {
      return this.currentPage > 1;
    },
    /**
     * 次ページがあるか
     * @return {boolean}
     */
    hasNextPage() {
      return this.totalPage > this.currentPage;
    },
    /**
     * 現在ページセルの前後に出すページセルの数
     * @return {number}
     */
    cellMargin() {
      if (this.isSp) {
        switch (this.currentPage) {
          case 1:
            return 3;
          case 2:
            return 2;
          default:
            return 1;
        }
      } else {
        return 3;
      }
    },
    /**
     * ページセル開始番号
     * @return {number}
     */
    pageCellFrom() {
      const _pageCellFrom = this.currentPage - this.cellMargin;
      return _pageCellFrom > 0 ? _pageCellFrom : 1;
    },
    /**
     * ページセル終了番号
     * @return {number}
     */
    pageCellTo() {
      const _pageCellTo = this.currentPage + this.cellMargin;
      return _pageCellTo < this.totalPage ? _pageCellTo : this.totalPage;
    },
    /**
     * ページネーションのセルの配列
     * @return {Array<PageCell>}
     */
    pageCells() {
      let cells = [];
      // 1ページ目は常に表示される
      if (this.pageCellFrom > 1) {
        cells.push(new PageCell(1, false, false));
      }

      // gapの挿入
      if (this.pageCellFrom > 2) {
        cells.push(new PageCell(0, false, true));
      }

      // ページ番号セルの挿入
      for (let i = this.pageCellFrom; i <= this.pageCellTo; i++) {
        cells.push(new PageCell(i, this.currentPage === i, false));
      }

      // gapの挿入
      if (this.pageCellTo < this.totalPage) {
        cells.push(new PageCell(0, false, true));
      }
      return cells;
    },
  },
  methods: {
    /**
     * 指定されたページに遷移
     * @param {number} page
     */
    clickPage(page) {
      this.$store.dispatch('tailwindPagination/clickPage', page);
      this.scrollToTop();
    },
    /**
     * 前ページに遷移
     */
    prevPage() {
      this.$store.dispatch('tailwindPagination/prevPage');
      this.scrollToTop();
    },
    /**
     * 次ページに遷移
     */
    nextPage() {
      this.$store.dispatch('tailwindPagination/nextPage');
      this.scrollToTop();
    },
    /**
     * 指定した要素までスクロール
     * SP版はヘッダー分のオフセットがある
     */
    scrollToTop() {
      const elem = document.querySelector("[data-pagination-top='true']");
      if (elem) {
        let scrollPosY = elem.offsetTop;
        if (this.isSp) scrollPosY -= 60;
        window.scrollTo({ top: scrollPosY, behavior: 'smooth' });
      }
    },
    /**
     * ページ毎のref属性名
     * @param {Number} page
     * @return {String}
     */
    formatRef(page) {
      return `tailwindPaginate${page}`;
    },
  },
};
</script>
