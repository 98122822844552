import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM_NAME as FRAGMENT_F11M_NAME } from './fragments/FinancialItemFragment';
import { FRAGMENT_YUTAI_ITEM, FRAGMENT_YUTAI_CATEGORY_NAME } from './fragments/YutaiFragment';

const toYutaiTopRankingType = (rankingType) => {
  if (rankingType !== 'popular') {
    return `
      ...yutaiItem
      ${rankingType}Yield
      yutaiCategories {
      ...yutaiCategoryName
      }
      financialItem {
      ...financialItemName
      }
    `;
  }

  return `
    ...yutaiItem
    yutaiYield
    dividendYield
    totalYield
    unitToRight
    yutaiCategories {
    ...yutaiCategoryName
    }
    financialItem {
    ...financialItemName
    }
  `;
};

export const QUERY_YUTAI_RANKINGS_POPULAR = gql`
  query yutaiRankingsPopular {
    yutaiRankingsPopular {
      ${toYutaiTopRankingType('popular')}
    }
  }
  ${FRAGMENT_YUTAI_ITEM}
  ${FRAGMENT_YUTAI_CATEGORY_NAME}
  ${FRAGMENT_F11M_NAME}
`;

export const QUERY_YUTAI_RANKINGS_YUTAI_YIELD = gql`
  query yutaiRankingsYutaiYield {
    yutaiRankingsYutaiYield {
      ${toYutaiTopRankingType('yutai')}
    }
  }
  ${FRAGMENT_YUTAI_ITEM}
  ${FRAGMENT_YUTAI_CATEGORY_NAME}
  ${FRAGMENT_F11M_NAME}
`;

export const QUERY_YUTAI_RANKINGS_DIVIDEND_YIELD = gql`
  query yutaiRankingsDividendYield {
    yutaiRankingsDividendYield {
      ${toYutaiTopRankingType('dividend')}
    }
  }
  ${FRAGMENT_YUTAI_ITEM}
  ${FRAGMENT_YUTAI_CATEGORY_NAME}
  ${FRAGMENT_F11M_NAME}
`;

export const QUERY_YUTAI_RANKINGS_TOTAL_YIELD = gql`
  query yutaiRankingsTotalYield {
    yutaiRankingsTotalYield {
      ${toYutaiTopRankingType('total')}
    }
  }
  ${FRAGMENT_YUTAI_ITEM}
  ${FRAGMENT_YUTAI_CATEGORY_NAME}
  ${FRAGMENT_F11M_NAME}
`;
