import SummaryInfoComponent from './SummaryInfo.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';

/*
 * 個別銘柄 サマリー情報ルートコンポーネント
 */
const SummaryInfo = {
  el: '#v-stock-summary-info',
  apolloProvider: MkApolloProvider,
  data: { isHide: false },
  methods: {
    switchHideFlg(val) {
      this.isHide = !val;
    },
  },
  components: {
    SummaryInfo: SummaryInfoComponent,
  },
};

export default SummaryInfo;
