// mixin
import VariantMixin from '../mixins/VariantMixin';
import StyleMixin from '../mixins/StyleMixin';
import UrlMixin from '../mixins/UrlMixin';
import ExternalUrlMixin from '../mixins/ExternalUrlMixin';
import LoginStatusMixin from '../mixins/LoginStatusMixin';
import NumberFormatMixin from '../mixins/NumberFormatMixin';
import DateFormatMixin from '../mixins/DateFormatMixin';
import YutaiRankingMixin from '../mixins/YutaiRankingMixin';
import LazyLoadMixin from '../mixins/LazyLoadMixin';
import CodeClassificationMixin from '../mixins/CodeClassificationMixin';
import KeyValueMixin from '../mixins/KeyValueMixin';
// Vue Filter
import NumberFilter from '../filters/NumberFilter';
import VariantFilter from '../filters/VariantFilter';
import DateFilter from '../filters/DateFilter';
import YutaiRankingFilter from '../filters/YutaiRankingFilter';
import StringDateFilter from '../filters/StringDateFilter';
// Vue Component
import NewsSearch from '../components/NewsSearch/NewsSearch';
import NewsSearchStore from '../stores/NewsSearch/NewsSearchStore';
import Fee from '../components/Fee/Fee';
import FinancialItemRankingTop from '../components/FinancialItemRanking/FinancialItemRankingTop';
import FinancialItemRankingSearch from '../components/FinancialItemRanking/FinancialItemRankingSearch';
import FinancialItemRankingPayLock from '../components/FinancialItemRanking/FinancialItemRankingPayLock';
import SettlementPack from '../components/Settlements/SettlementPack';
import ShareholderBenefitRanking from '../components/ShareholderBenefits/ShareholderBenefitRanking';
import ShareholderBenefitRankingStore from '../stores/ShareholderBenefits/Ranking/ShareholderBenefitRankingStore';
import ShareholderBenefitSearch from '../components/ShareholderBenefits/ShareholderBenefitSearch';
import ShareholderBenefitSearchStore from '../stores/ShareholderBenefits/Search/ShareholderBenefitSearchStore';
import ShareholderBenefitColumn from '../components/ShareholderBenefitColumn/ShareholderBenefitColumn';
import YutaiNews from '../components/Stock/YutaiNews';
import YutaiNewsStore from '../stores/Stock/YutaiNewsStore';
import Stocks from '../components/modules/Stocks/Stocks';
import StocksStore from '../stores/modules/Stocks/StocksStore';
import StockNews from '../components/StockNews/StockNews';
import StockNewsSearchStore from '../stores/StockNews/StockNewsSearchStore';
import YutaiApp from '../components/Yutai/YutaiApp';
import YutaiAppStore from '../stores/Yutai/YutaiAppStore';
import YutaiTop from '../components/pages/YutaiTop/YutaiTop';
import YutaiSearchOrLinks from '../components/Yutai/YutaiSearchOrLinks';
import YutaiMultiRankingBox from '../components/Yutai/YutaiMultiRankingBox';
import YutaiSearchBox from '../components/Yutai/YutaiSearchBox';
import ItfRiskReturnChart from '../components/Itf/ItfRiskReturnChart';
import Discover from '../components/Discover/Discover';
import DiscoverStore from '../stores/Discover/DiscoverStore';
import MyPage from '../components/MyPage/MyPage';
import FinancialItemList from '../components/pages/FinancialItemList/FinancialItemList';
import FinancialItemListStore from '../stores/FinancialItemList/FinancialItemListStore';
import MyPageStore from '../stores/MyPage/MyPageStore';
import MinkabuWideContentHeader from '../components/MinkabuWideContentHeader';
import MinkabuWideContentHeaderStore from '../stores/MinkabuWideContentHeaderStore';
import AdHide from '../components/modules/AdHide/AdHide';
import NameModalStore from '../stores/modules/Modal/NameModalStore';
import AnalystConsensus from '../components/AnalystConsensus/AnalystConsensus';
import DeviationRateChart from '../components/DeviationRates/DeviationRateChart';
import IndividualFinancialItemHeader from '../components/IndividualFinancialItemHeader';
import IndividualFinancialItemHeaderStore from '../stores/IndividualFinancialItemHeaderStore';
import IPOTop from '../components/pages/IPOTop/IPOTop';
import IPOTopStore from '../stores/IPOTop/IPOTopStore';
import IPOItems from '../components/pages/IPOItems/IPOItems';
import IPOItemsStore from '../stores/IPOItems/IPOItemsStore';
import ChangesInDPSChart from '../components/pages/Dividends/ChangesInDPSChart';
import SideNavigationHistories from '../components/modules/SideNavigation/SideNavigationHistories';
import SideNavigationFinancialItemRankings from '../components/modules/SideNavigation/SideNavigationFinancialItemRankings';
import StockSearch from '../components/pages/StockSearch/StockSearch';
import StockSearchStore from '../stores/StockSearch/StockSearchStore.js';
import StockAnalysis from '../components/pages/StockAnalysis/StockAnalysis';
import StockAnalysisStore from '../stores/pages/StockAnalysis/StockAnalysisStore';
import PayoutRatioDistributionChart from '../components/pages/Dividends/PayoutRatioDistributionChart';
import DPSDetailsChart from '../components/pages/Dividends/DPSDetailsChart';
import ChangesInPayoutRatio from '../components/pages/Dividends/ChangesInPayoutRatioChart';
import DividendYieldChart from '../components/pages/Dividends/DividendYieldChart';
import QuarterlyDPSDetailsChart from '../components/pages/Dividends/QuarterlyDPSDetailsChart';
import DividendPayLock from '../components/pages/StockDividends/DividendPayLock';
import StockTopChart from '../components/pages/StockTop/StockTopChart';
import StockTopChartSp from '../components/pages/StockTop/StockTopChartSp';
import StockChartSpStore from '../stores/modules/StockChartSp/StockChartStore';
import StockChartStore from '../stores/modules/StockChart/StockChartStore';
import Top from '../components/pages/Top/Top';
import StockChart from '../components/pages/StockCharts/StockChart';
import StockTopTheme from '../components/pages/StockTop/StockTopTheme';
import GuideTimeline from '../components/modules/Stocks/GuideTimeline';
import FavoriteFinancialItemsStore from '../stores/Common/FavoriteFinancialItemsStore.js';
import Theme from '../components/pages/Themes/Theme.js';
import ThemeStore from '../stores/Themes/ThemeStore.js';
import SummaryInfo from '../components/modules/Stocks/SummaryInfo';
import StockSearchSimilarLink from '../components/modules/Stocks/StockSearchSimilarLink';
import PeerCompaniesInfo from '../components/modules/Stocks/PeerCompaniesInfo';
import PeerCompaniesDividendInfo from '../components/pages/Dividends/PeerCompaniesDividendInfo';
import StockResearch from '../components/pages/StockResearch/StockResearch.js';
import NewsItems from '../components/pages/News/NewsItems.js';
import KeywordNewsList from '../components/modules/News/KeywordNewsList.js';
import NewsItemsStore from '../stores/News/NewsItemsStore';
import MemberHeader from '../components/modules/MemberHeader/MemberHeader.js';
import MemberHeaderStore from '../stores/modules/MemberHeader/MemberHeaderStore.js';
import CorporateActionsWeeklyCalendar from '../components/pages/CorporateActions/CorporateActionsWeeklyCalendar.js';
import CorporateActionsStockTransfer from '../components/pages/CorporateActions/CorporateActionsStockTransfer.js';
import CorporateActionsDelisting from '../components/pages/CorporateActions/CorporateActionsDelisting.js';
import CorporateActionsStockTransferStore  from '../stores/CorporateActions/CorporateActionsStockTransferStore';
import CorporateActionsDelistingStore from '../stores/CorporateActions/CorporateActionsDelistingStore';
import FinancialItemRankings from '../components/FinancialItemRanking/FinancialItemRankings';

import { vueMount, vueFilter, vueMixin, vuePlugins } from '../utils/AppUtil';

import Highcharts from './HighchartsAddOption';

document.addEventListener('DOMContentLoaded', function () {
  // vue plugins
  vuePlugins();

  // setup global mixin
  vueMixin(VariantMixin);
  vueMixin(StyleMixin);
  vueMixin(UrlMixin);
  vueMixin(ExternalUrlMixin);
  vueMixin(LoginStatusMixin);
  vueMixin(NumberFormatMixin);
  vueMixin(DateFormatMixin);
  vueMixin(YutaiRankingMixin);
  vueMixin(LazyLoadMixin);
  vueMixin(CodeClassificationMixin);
  vueMixin(KeyValueMixin);

  // setup the Vue Filters
  // @deprecated Vue3では非推奨なので、computed or methodsへ処理を移行すること
  vueFilter(NumberFilter);
  vueFilter(VariantFilter);
  vueFilter(DateFilter);
  vueFilter(YutaiRankingFilter);
  vueFilter(StringDateFilter);

  // mount Vue Root Components
  vueMount(NewsSearch, NewsSearchStore);
  vueMount(Fee);
  vueMount(SettlementPack);
  vueMount(FinancialItemRankingSearch);
  vueMount(FinancialItemRankingTop);
  vueMount(FinancialItemRankingPayLock, NameModalStore);
  vueMount(ShareholderBenefitRanking, ShareholderBenefitRankingStore);
  vueMount(ShareholderBenefitSearch, ShareholderBenefitSearchStore);
  vueMount(ShareholderBenefitColumn, YutaiAppStore);
  vueMount(YutaiNews, YutaiNewsStore);
  vueMount(StockNews, StockNewsSearchStore);
  vueMount(Stocks, StocksStore);
  vueMount(YutaiApp, YutaiAppStore);
  vueMount(YutaiTop, YutaiAppStore);
  vueMount(YutaiSearchOrLinks);
  vueMount(YutaiMultiRankingBox, YutaiAppStore);
  vueMount(YutaiSearchBox);
  vueMount(ItfRiskReturnChart);
  vueMount(Discover, DiscoverStore);
  vueMount(MyPage, MyPageStore);
  vueMount(MinkabuWideContentHeader, MinkabuWideContentHeaderStore);
  vueMount(AdHide, NameModalStore);
  vueMount(AnalystConsensus);
  vueMount(DeviationRateChart);
  vueMount(IndividualFinancialItemHeader, IndividualFinancialItemHeaderStore);
  vueMount(SideNavigationFinancialItemRankings);
  vueMount(IPOTop, IPOTopStore);
  vueMount(IPOItems, IPOItemsStore);
  vueMount(ChangesInDPSChart);
  vueMount(PayoutRatioDistributionChart);
  vueMount(DividendYieldChart);
  vueMount(SideNavigationHistories);
  vueMount(StockSearch, StockSearchStore);
  vueMount(DPSDetailsChart);
  vueMount(ChangesInPayoutRatio);
  vueMount(StockAnalysis, StockAnalysisStore);
  vueMount(DividendPayLock, NameModalStore);
  vueMount(QuarterlyDPSDetailsChart);
  vueMount(Top, StockChartStore);
  vueMount(StockChart, StockChartStore);
  vueMount(StockTopChart, StockChartStore);
  vueMount(StockTopChartSp, StockChartSpStore);
  vueMount(StockTopTheme);
  vueMount(GuideTimeline, FavoriteFinancialItemsStore);
  vueMount(Theme, ThemeStore);
  vueMount(SummaryInfo);
  vueMount(StockSearchSimilarLink);
  vueMount(PeerCompaniesInfo, StocksStore);
  vueMount(PeerCompaniesDividendInfo, StocksStore);
  vueMount(StockResearch);
  vueMount(NewsItems, NewsItemsStore);
  vueMount(KeywordNewsList);
  vueMount(MemberHeader, MemberHeaderStore);
  vueMount(FinancialItemList, FinancialItemListStore);
  vueMount(CorporateActionsWeeklyCalendar);
  vueMount(CorporateActionsStockTransfer, CorporateActionsStockTransferStore);
  vueMount(CorporateActionsDelisting, CorporateActionsDelistingStore);
  vueMount(FinancialItemRankings, NameModalStore);
});
