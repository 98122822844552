import AdHideLink from './AdHideLink.vue';
import NameModal from '../Modal/NameModal.vue';
import CampaignBanner from '../../modules/Banner/CampaignBanner.vue';

/**
 * 広告非表示のルートコンポーネント
 * @vue-components {AdHideLink} AdHideLink 広告非表示リンク
 * @vue-components {NameModal} NameModal 名前モーダル
 * @vue-components {CampaignBanner} CampaignBanner キャンペーンバナー
 */
const AdHide = {
  el: '#v-ad-hide',
  components: {
    AdHideLink,
    NameModal,
    CampaignBanner,
  },
};
export default AdHide;
