/**
 * ページ左下に出現するお知らせボックスの状態
 * @property {boolean} visible お知らせボックスの状態[true: 表示, false: 表示しない]
 * @property {string} message お知らせボックスに表示するメッセージ
 * @property {number} timerID お知らせボックスのsetTimeoutID
 */
const NotificationBox = {
  namespaced: true,
  state: {
    visible: false,
    message: '',
    timerID: 0
  },
  actions: {
    /**
     * お知らせボックス表示アクション
     * @param message 表示メッセージ
     * @returns {Promise<void>}
     */
    async show({ state }, message) {
      clearTimeout(state.timerID);
      state.message = message;
      state.visible = true;
      state.timerID = setTimeout(() => {
        state.visible = false;
      }, 5000);
    }
  }
};
export default NotificationBox;
