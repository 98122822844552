import BaseResource from './BaseResource';
import CurrentPrice from '../models/CurrentPrice';

/**
 * 現在値API
 */
class CurrentPriceResource extends BaseResource {
  /*
   * 現在値のリストを取得する
   * @param {array<String>}
   * @return {array<CurrentPrice>}
   */
  async get(codes) {
    return await this.client
      .get('/current_price.json', { params: { codes: codes.join(',') } })
      .then((response) => this._toCurrentPrices(response.data.items))
      .catch(() => {
        return 'error';
      });
  }

  _toCurrentPrices(items) {
    if (items.length === 0) {
      return [];
    }
    return items.map((item) => this._toCurrentPrice(item));
  }

  _toCurrentPrice(item) {
    return new CurrentPrice(item);
  }
}
export default CurrentPriceResource;
