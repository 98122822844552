<template src="./IntradayChart.html" />
<script>
import { Chart } from 'highcharts-vue';
import MkIntradayStockChartBuilder from '../../../models/StockCharts/MkIntradayStockChartBuilder.js';
import dayjs from 'dayjs';
import NumberFormatMixin from '../../../mixins/NumberFormatMixin';

const CHART_STYLE = {
  labelPosition: 12,
  labelColor: '#666',
  labelFontSize: '10px',
  tooltipBorderColor: '#666',
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 株価の日中足チャートのコンポーネント
 * @vue-data {MkIntraStockChart} intradayStockChart
 * @module StockChart/IntradayChart
 */
export default {
  components: {
    highcharts: Chart,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    exchangeId: {
      type: Number,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '250px',
    },
    isLazyLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      intradayStockChart: null,
    };
  },
  computed: {
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          height: vueThis.height,
          animation: false,
          margin: [26, 5, 26, 5],
          pinchType: false,
          events: {
            redraw: function (chart) {
              const x = 0;
              const y = CHART_STYLE.labelPosition;
              const color = CHART_STYLE.labelColor;
              const fontSize = CHART_STYLE.labelFontSize;
              const closedAtPos = vueThis.isPc ? 80 : 95;

              chart.target.renderer
                .text(`[${vueThis.toCodeLabel(vueThis.code)}] 日中`, x, y)
                .css({ color, fontSize })
                .add();

              chart.target.renderer
                .text(
                  dayjs(vueThis.currentClosedAt).format('YYYY/MM/DD HH:mm'),
                  chart.target.chartWidth - closedAtPos,
                  y
                )
                .css({ color, fontSize })
                .add();
            },
          },
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          borderColor: CHART_STYLE.tooltipBorderColor,
          xDateFormat: '%Y/%m/%d %H:%M',
          formatter: function () {
            const st = this.points.find((p) => p.series.name === '株価');
            if (!st) {
              return false;
            }

            const point = st.point;
            const dayjsPriceTime = dayjs(point.x);
            let lines = [];

            lines.push(dayjsPriceTime.format('YYYY/MM/DD HH:mm'));

            lines.push(`<b>${NumberFormatMixin.methods.numFormat(point.y, vueThis.tooltipDecimal(vueThis.code))}</b>`);

            return lines.join('<br />');
          },
          shared: true,
          useHTML: true,
          valueDecimals: 1,
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#4572A7',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
              enabled: false,
            },
            dataGrouping: {
              enabled: false, // 日中チャートは空のデータも描画したいのでfalseにしている
            },
          },
          line: {},
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            type: 'line',
            name: '前日終値',
            animation: false,
            data: this.currentLastCloseChartData,
            color: 'red',
            lineWidth: 2,
            dashStyle: 'ShortDot',
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
          },
          {
            type: 'line',
            name: '前々日終値',
            animation: false,
            data: this.priorLastCloseChartData,
            color: 'red',
            lineWidth: 2,
            dashStyle: 'ShortDot',
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
          },
        ],
        xAxis: [
          {
            type: 'datetime',
            tickPixelInterval: 50,
            showFirstLabel: true,
            showLastLabel: false,
            dateTimeLabelFormats: {
              minute: { main: '%H:%M' },
              hour: { main: '%H' },
              day: { main: '<b>%m/%d</b>' },
            },
          },
        ],
        yAxis: [
          {
            showFirstLabel: true,
            showLastLabel: false,
            labels: {
              align: 'left',
              x: 0,
            },
            gridLineColor: '#ddd',
            opposite: false,
            max: this.yAxisMax,
            min: this.yAxisMin,
          },
        ],
        time: {
          timezone: 'Asia/Tokyo',
          useUTC: false,
        },
      };
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.intradayStockChart) {
        return [];
      }

      return this.intradayStockChart.chartData();
    },
    /**
     * 前日終値描画用データ
     * @return {Array}
     */
    currentLastCloseChartData() {
      if (!this.intradayStockChart) {
        return [];
      }

      return this.intradayStockChart.currentLastCloseChartData();
    },
    /**
     * 前々日終値描画用データ
     * @return {Array}
     */
    priorLastCloseChartData() {
      if (!this.intradayStockChart) {
        return [];
      }
      return this.intradayStockChart.priorLastCloseChartData();
    },
    /**
     * 前日の終値
     * @return {Integer}
     */
    currentLastClose() {
      if (!this.intradayStockChart) {
        return null;
      }
      return this.intradayStockChart.currentLastCloseChartData()[0][1];
    },
    /**
     * 前々日の終値
     * @return {Integer}
     */
    priorLastClose() {
      if (!this.intradayStockChart) {
        return null;
      }
      return this.intradayStockChart.priorLastCloseChartData()[0][1];
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this._ensurePrices) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...this._ensurePrices) * 0.9995;
    },
    /**
     * 最大値、最小値を計算する処理の共通部分
     * @private
     * @return {Array}
     */
    _ensurePrices() {
      if (!this.intradayStockChart) {
        return [];
      }
      return this.intradayStockChart.allPrices();
    },
    /**
     * 最終株価時刻
     * @return {Date}
     */
    currentClosedAt() {
      if (!this.intradayStockChart) return;

      return this.intradayStockChart.currentClosedAt();
    },
  },
  mounted() {
    this.loadIntradayStockChart();
  },
  methods: {
    /**
     * 2日分チャートを読み込む
     * @note PSI対応でスクロールイベント発火時に1度だけ処理されるようにしている
     * @return {void}
     */
    async loadIntradayStockChart() {
      const intradayStockChartBuilder = new MkIntradayStockChartBuilder();
      const intradayStockChart = await intradayStockChartBuilder.build(this.code, this.exchangeId);

      if (this.isLazyLoad) {
        this.lazyLoadByScrollOnce(() => {
          this.intradayStockChart = intradayStockChart;
        });
      } else {
        this.intradayStockChart = intradayStockChart;
      }
    },
    /**
     * ツールチップ用の小数点桁数
     * 指数系銘柄の場合2桁、そのほかは1桁
     * @param {String} code
     * @return {Number}
     */
    tooltipDecimal(code) {
      return this.isIndexCode(code) ? 2 : 1;
    },
    /**
     * 銘柄コードを表示用のラベルに変換
     * 例:"100000018"->"Nikkei225"
     * @param {String} code
     * @return {String}
     */
    toCodeLabel(code) {
      switch (code) {
        case '100000018':
          return 'Nikkei225';
        case 'KSISU1000':
          return 'TOPIX';
        default:
          return code;
      }
    },
  },
};
</script>
