import FollowButtonStore from "../Follows/FollowButtonStore";
/**
 * 会員ヘッダー ルートストア
 *
 * @module MemberHeader/MemberHeader
 */
const MemberHeaderStore = {
  modules: {
    followButton: FollowButtonStore,
  },
};
export default MemberHeaderStore;
