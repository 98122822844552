<template src="./StockChartTooltip.html" />
<script>
import dayjs from 'dayjs';
export default {
  name: 'StockChartTooltip',
  props: {
    code: {
      type: String,
      required: true,
    },
    plotData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      styleObject: { left: '0px', top: '5px' },
    };
  },
  computed: {
    /**
     * 株価
     * @return {Number}
     */
    price() {
      return this.plotData.price;
    },
    /**
     * 株価取引時刻
     * @return {String}
     */
    priceTime() {
      const time = this.plotData.priceTime;
      if (this.$store.getters['isHistorical']) {
        return dayjs(time).format('YYYY/MM/DD');
      } else {
        return dayjs(time).format('MM/DD HH:mm');
      }
    },
    /**
     * ツールチップ用の小数点桁数
     * 指数系銘柄の場合2桁、そのほかは1桁
     * @param {String} code
     * @return {Number}
     */
    decimalDigit() {
      return this.isIndexCode(this.code) ? 2 : 1;
    },
  },
  watch: {
    plotData: {
      handler() {
        this.updateLeftPosition();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * ツールチップ表示位置の更新
     * @return {void}
     */
    updateLeftPosition() {
      if (!this.$refs.stockTooltip) return;

      let left = '0px';
      const areaPadding = 58;
      const innerWidth = window.innerWidth - areaPadding;
      const clientWidth = this.$refs.stockTooltip.clientWidth;
      const mouseX = this.plotData.plotX;

      if (mouseX < clientWidth / 2) {
        // 左端
        left = '0px';
      } else if (mouseX + clientWidth / 2 > innerWidth) {
        // 右端
        left = `${innerWidth - clientWidth}px`;
      } else {
        left = `${mouseX - clientWidth / 2}px`;
      }

      this.styleObject.left = left;
    },
  },
};
</script>
