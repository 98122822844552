/**
 * 売買予想のHTMLクラス名を返す
 * @param {number} status
 * @return {string}
 */
export const analysisRating = (status) => {
  if (status === 1) {
    return 'bg-minkabuPicksBuy';
  } else if (status === 2) {
    return 'bg-minkabuPicksSell';
  }
  return 'bg-minkabuPicksNone';
};

/**
 * 前日比のHTMLクラス名を返す
 * @param {string} ratio
 * @return {string}
 */
export const changeRatio = (ratio) => {
  if (parseFloat(ratio) > 0) {
    return 'text-minkabuRedUp';
  } else if (parseFloat(ratio) < 0) {
    return 'text-minkabuBlueDown';
  }
  return 'text-neutral-500';
};
