<template src="./FavoriteButton.html"></template>
<script>
import UserLoginStatus from '../../utils/UserLoginStatus';
import exportEventLabel from '../../utils/exportEventLabel';
/**
 * 優待ランキング お気に入りボタンコンポーネント
 * @vue-prop {String} financialItemCode 銘柄コード
 * @vue-computed {Boolean} isFinancialItemFavored お気に入り登録している銘柄なら真
 * @module Common/FavoriteButton
 */
export default {
  name: 'FavoriteButton',
  props: {
    financialItemCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.financialItemCode);
    },
    eventLabel() {
      return exportEventLabel({
        isUserLoginStatus: this.isLoggedIn,
        isFinancialItemFavored: this.isFinancialItemFavored,
      });
    },
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    /**
     * 1. ユーザが未ログイン時はログイン画面へ遷移させる
     * 2. 金融アイテムをお気に入り登録または削除
     * 3. 2が完了したらお知らせボックスを表示(5秒間)
     */
    async toggle() {
      if (this.isLoggedIn) {
        await this.$store.dispatch('favoriteFinancialItems/toggle', this.financialItemCode);
        await this.$store.dispatch(
          'notificationBox/show',
          this.$store.getters['favoriteFinancialItems/notificationMessage']
        );
      } else {
        this.$store.commit('favoriteSignUpModal/show');
        try {
          this.$emit('show-modal-event');
        } catch {} // 移行が終わる迄エラーは握り潰す
      }
    },
  },
};
</script>
