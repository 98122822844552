import ShareholderBenefitColumnFeeWithUnit from './ShareholderBenefitColumnFeeWithUnit.vue';
import FavoriteButton from '../Common/FavoriteButton.vue';
import NotificationBox from '../Common/NotificationBox.vue';
import FavoriteSignUpModal from '../Modal/FavoriteSignUpModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * おすすめ株主優待コラムページのルートコンポーネント
 * @vue-components {ShareholderBenefitColumnFeeWithUnit} shareholderBenefitColumnFeeWithUnit 指定した購入株数での手数料比較コンポーネント
 * @vue-components {FavoriteButton} favoriteButton お気に入り登録ボタンコンポーネント
 * @vue-components {NotificationBox} NotificationBox お知らせボックス
 * @vue-components {FavoriteSignUpModal} FavoriteSignUpModal お気に入りボタン用の会員登録誘導モーダル
 */
const ShareholderBenefitColumn = {
  el: '#shareholder_benefit_column',
  components: {
    shareholderBenefitColumnFeeWithUnit: ShareholderBenefitColumnFeeWithUnit,
    favoriteButton: FavoriteButton,
    notificationBox: NotificationBox,
    favoriteSignUpModal: FavoriteSignUpModal,
    CampaignBanner,
  },
};

export default ShareholderBenefitColumn;
