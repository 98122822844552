<template src="./FinancialItemRankingSearchBox.html"></template>
<script>
import FinancialItemRankingForm from './FinancialItemRankingForm';

/*
 * @vue-prop {Object} formElements
 * @vue-data {string} exchangeNameId 市場コード
 * @vue-data {string} sortOrder 表示順
 * @vue-data {string} industrySectorCode 業種コード
 * @vue-computed {FinancialItemRankingForm} rankingForm formElementsから作られた株式ランキングフォーム
 * @vue-computed {Object} currentParams 選択された絞込条件
 * @module FinancialItemRankingSearchBox
 */
export default {
  name: 'FinancialItemRankingSearchBox',
  props: {
    formElements: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      exchangeNameId: null,
      sortOrder: null,
      industrySectorCode: null
    };
  },
  computed: {
    rankingForm() {
      return new FinancialItemRankingForm(this.formElements);
    },
    currentParams() {
      const params = {
        exchange: this.exchangeNameId,
        order: this.sortOrder,
        industry: this.industrySectorCode
      };
      Object.keys(params).forEach(k => { if (!params[k]) { delete params[k]; }});
      return params;
    }
  },
  /**
   * フォーム初期値設定
   */
  mounted() {
    this.exchangeNameId = this.formElements.exchangeNameId;
    this.sortOrder = this.formElements.sortOrder;
    this.industrySectorCode = this.formElements.industrySectorCode;
  },
  methods: {
    /**
     * 選択された絞込条件で遷移する
     */
    submitRankingForm() {
      this.rankingForm.submit(this.currentParams);
    },
    /**
     * 検索ボックスを閉じる
     */
    closeBox() {
      this.$emit('set-box-visible', false);
    }
  }
};
</script>
