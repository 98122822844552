import FavoriteButtonBox from './Common/FavoriteButtonBox.vue';
import ReportButtonBox from './Common/ReportButtonBox.vue';
import SecuritiesCompaniesButton from './Stock/SecuritiesCompaniesButton.vue';
import BaseModal from './Common/BaseModal.vue';
import NotificationBox from './Common/NotificationBox.vue';
import CampaignBanner from './modules/Banner/CampaignBanner.vue';

/**
 * 個別銘柄ページのヘッダールートコンポーネント
 * @vue-components {FavoriteButtonBox} favoriteButtonBox お気に入りボタン
 * @vue-components {reportButtonBox} reportButtonBox レポート銘柄ボタン
 * @vue-components {securitiesCompaniesButton} securitiesCompaniesButton 証券会社ボタン
 * @vue-components {NotificationBox} notificationBox お知らせボックス
 * @vue-components {BaseModal} baseModal モーダル表示
 * @vue-components {CampaignBanner} campaignBanner キャンペーン期間限定のバナー
 * @module IndividualFinancialItemHeader
 */
const IndividualFinancialItemHeader = {
  el: '#v-individual-financial-item-header',
  data() {
    return {
      showModal: false,
      showButtons: false,
    };
  },
  components: {
    favoriteButtonBox: FavoriteButtonBox,
    reportButtonBox: ReportButtonBox,
    securitiesCompaniesButton: SecuritiesCompaniesButton,
    notificationBox: NotificationBox,
    baseModal: BaseModal,
    campaignBanner: CampaignBanner,
  },
  mounted() {
    // ViewとVue読込順序の関係で初期表示が崩れちゃうのでその対応
    // モーダルを表示
    Array.from(document.querySelectorAll('#loading_hidden'), (elm) => elm.classList.remove('dpn'));
    // ダミー証券会社ボタンを非表示
    Array.from(document.querySelectorAll('#securities_button_dummy'), (elm) => elm.classList.add('dpn'));
    this.showButtons = true;
  },
  methods: {
    /**
     * モーダル表示イベント
     * @returns {boolean} closeイベントが発火するとshowModalはfalseとなる
     */
    showModalEvent: function () {
      this.showModal = true;
    },
  },
};

export default IndividualFinancialItemHeader;
