<template src="./FavoriteButton.html" />

<script>
import exportEventLabel from '../../../../utils/exportEventLabel.js';
/**
 * お気に入り銘柄 お気に入りボタン
 * @module Mypage/modules/buttons/FavoriteButton
 */
export default {
  name: 'FavoriteButton',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.code);
    },
    eventLabel() {
      return exportEventLabel({
        isUserLoginStatus: true,
        isFinancialItemFavored: this.isFinancialItemFavored,
      });
    },
  },
  methods: {
    async toggle() {
      await this.$store.dispatch('favoriteFinancialItems/toggle', this.code);
    },
  },
};
</script>
