<template src="./StockTopTheme.html"></template>
<script>
/**
 * StockTopTheme
 *
 * @module pages/StockTop/StockTopTheme
 */
export default {
  name: 'StockTopTheme',
  props: {
    /**
     * 関連テーマ
     *  @return {Array}
     */
    themes: {
      type: Array,
      require: true,
      default() {
        return [];
      },
    },
    /**
     * 初期に表示する関連テーマ数
     *  @return {Number}
     */
    firstViewLength: {
      type: Number,
      require: false,
      default: 8,
    },
  },
  data() {
    return {
      /**
       * 省略された関連テーマを全て表示している場合は真
       * @return {Boolean}
       */
      isAll: false,
    };
  },
  computed: {
    /**
     * さらに表示ボタンを表示する場合は真
     * @return {Boolean}
     */
    moreDisplayable() {
      return !this.isAll && this.themes.length > this.firstViewLength;
    },
  },
  methods: {
    /**
     * 表示対象を関連テーマ全体に変更
     * @return {void}
     */
    showAll() {
      const ssr = document.getElementById('ssr-stock-top-theme');
      if (ssr) {
        ssr.style.display = 'none';
      }
      this.isAll = true;
    },
  },
};
</script>
