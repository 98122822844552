import QuarterlyDPSDetailsChartVue from './QuarterlyDPSDetailsChart.vue';

/**
 * 個別銘柄の配当ページの配当額推移(四半期配当)チャートのルートコンポーネント
 * @module Dividends/QuarterlyDPSDetailsChart
 */
const QuarterlyDPSDetailsChart = {
  el: '#v-quarterly-dps-details-chart',
  components: {
    quarterlyDpsDetailsChart: QuarterlyDPSDetailsChartVue,
  },
};

export default QuarterlyDPSDetailsChart;
