import gql from 'graphql-tag';
import { FRAGMENT_PAGE_INFO } from './fragments/PageInfoFragment';

export const QUERY_NEWS_ITEMS = gql`
  query newsArticlesByTags($tags: [String!]!, $page: Int, $limit: Int) {
    newsArticlesByTags(tags: $tags, page: $page, limit: $limit) {
      collection {
        newsId
        title
        publishedAt
        tags {
          slug
          name
        }
        categories {
          name
          label
        }
        channel {
          name
          title
        }
        author {
          name
          resource
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${FRAGMENT_PAGE_INFO}
`;
