/** Class RoundUtil */
class RoundUtil {
  /**
   * 受け取った値が存在する場合は百万を掛けて返す
   *
   * @param {Integer, null} value
   * @returns {Integer, null}
   */
  static revertRoundedMillion(value) {
    return value == null ? value : value * 1000000;
  }

  /**
   * 受け取った値を兆・億・万に丸めて指定された小数点以下の桁数を追加して返す
   *
   * @param {Integer} val
   * @param {Integer} precision
   * @return {String}
   */
  static roundJapaneseUnit(value, precision) {
    let val = '';
    let ope = '';
    if(value < 0) { ope = '-'; }
    value = Math.abs(value);

    if(value === 0) {
      val = 0;
    } else if (value < 100000000 && value >= 10000) {
      val = RoundUtil.roundTenThousand(value, precision);
    } else if (value < 1000000000000 && value >= 100000000) {
      val = RoundUtil.roundOneHundredMillion(value, precision);
    } else if (value >= 1000000000000) {
      val = RoundUtil.roundTrillion(value, precision);
    } else {
      val = value.toFixed(precision);
    }
    return `${ope}${val}`;
  }

  /**
   * 受け取った値を万に丸めて、指定された小数点以下の桁数を追加して返す
   *
   * @param {Integer} value
   * @param {Integer} precision
   * @return {String}
   */
  static roundTenThousand(value, precision) {
    return `${(value / 10000).toFixed(precision)}万`;
  }

  /**
   * 受け取った値を億に丸めて、指定された小数点以下の桁数を追加して返す
   *
   * @param {Integer} value
   * @param {Integer} precision
   * @return {String}
   */
  static roundOneHundredMillion(value, precision) {
    return `${(value / 100000000).toFixed(precision)}億`;
  }

  /**
   * 受け取った値を兆に丸めて、指定された小数点以下の桁数を追加して返す
   *
   * @param {Integer} value
   * @param {Integer} precision
   * @return {String}
   */
  static roundTrillion(value, precision) {
    return `${(value / 1000000000000).toFixed(precision)}兆`;
  }
}
export default RoundUtil;
