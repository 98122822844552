<template src="./SettlementScheduleMail.html" />
<script>
import axios from 'axios';
/**
 * お気に入り銘柄 決算発表予定日メールの受信設定コンポーネント
 * @module Mypage/modules/SettlementScheduleMail
 */
export default {
  name: 'SettlementMail',

  data() {
    return {
      receivable: false,
    };
  },
  created() {
    this.getReceivable();
  },
  methods: {
    /**
     * 受信可否設定を取得
     */
    getReceivable() {
      const _this = this;
      axios
        .get('/mypage/favorites/notice_settings')
        .then(function (res) {
          _this.receivable = Boolean(res.data.receivable);
        })
        .catch(function (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    /**
     * 受信可否を設定
     */
    putReceivable() {
      const _this = this;
      axios
        .put('/mypage/favorites/notice_settings', { receivable: Number(!this.receivable) })
        .then(function (res) {
          _this.receivable = Boolean(res.data.receivable);
        })
        .catch(function (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    /**
     * 確認ダイアログを表示
     */
    toggleReceivable() {
      if (!this.receivable) {
        this.putReceivable();
        return;
      }
      const message =
        'お気に入り銘柄に関する「重要なお知らせ」を含む全てのメールが送信されなくなります。\nよろしいですか？';
      const res = window.confirm(message);
      if (res) {
        this.putReceivable();
      }
    },
  },
};
</script>
