<template src="./SideNavigationITFHistories.html" />

<script>
/**
 * 右ナビ 投信閲覧履歴コンポーネント
 * @vue-data {Array<Object>} histories 投信閲覧履歴オブジェクト
 * @module modules/SideNavigation/SideNavigationFinancialItemHistories
 */
export default {
  name: 'SideNavigationITFHistories',
  props: {
    histories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * 閲覧履歴が存在すれば真
     * @return {Boolean}
     */
    hasHistory() {
      if (!this.histories.length) return false;

      return this.histories.length > 0;
    },
  },
  methods: {
    /**
     * 計測用のタグを追加したITF個別銘柄ページのURL
     * @return {String}
     */
    customizedItfFundUrl(code) {
      return `${this.itfFundUrl(code)}?utm_source=minkabu&utm_medium=sidenavi&utm_campaign=recently_viewed`;
    },
  },
};
</script>
