<template src="./BasePagination.html" />
<script>
import PageCell from './PageCell';

/**
 * ページネーションコンポーネント
 *
 * @vue-prop {BaseCollection} collection コレクションクラス
 * @vue-computed {number} currentPage 現在のページ番号
 * @vue-computed {number} totalPage 総ページ数
 * @vue-computed {boolean} hasPrevPage 前ページがあるか
 * @vue-computed {boolean} hasNextPage 次ページがあるか
 * @vue-computed {string} prevUrl 次ページのURL
 * @vue-computed {string} nextUrl 前ページのURL
 * @vue-computed {Object} urlObj URL生成用のオブジェクト
 * @vue-computed {Array<PageCell>} pageCells ページネーションのセルの配列
 * @vue-computed {Array<integer>} pageRange ページネーションで表示するページ番号の配列
 * @module BasePagination
 */
export default {
  name: 'BasePagination',
  props: {
    collection: {
      type: Object,
    },
  },
  computed: {
    currentPage() {
      return this.collection.currentPage;
    },
    totalPage() {
      return this.collection.totalPage;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.totalPage > this.currentPage;
    },
    prevUrl() {
      return this.pageUrl(this.currentPage - 1);
    },
    nextUrl() {
      return this.pageUrl(this.currentPage + 1);
    },
    urlObj() {
      return new URL(this.collection.url);
    },
    pageCells() {
      let cells = [];
      this.pageRange.forEach((page) => {
        cells.push(new PageCell(page, this.currentPage, this.pageUrl(page)));
      });
      return cells;
    },
    pageRange() {
      let rangeBegin = this.currentPage - 3;
      let rangeEnd = this.currentPage + 3;
      rangeEnd = rangeEnd > this.totalPage ? this.totalPage : rangeEnd;
      let range = [];

      if (rangeBegin > 2) {
        range.push(0); // gapを入れる
      }

      for (let i = rangeBegin; i <= rangeEnd; i++) {
        if (i > 0) {
          range.push(i);
        }
      }

      if (rangeEnd < this.totalPage) {
        range.push(0); // gapを入れる
      }
      return range;
    },
  },
  methods: {
    /**
     * 指定したページ番号のページネションURL
     * @param {number} page
     * @returns {string} URL
     */
    pageUrl(page) {
      this.urlObj.searchParams.set('page', page);
      return this.urlObj.toString();
    },
  },
};
</script>
