import axios from 'axios';
/**
 * 表示項目カスタマイズのデフォルト値
 */
const CUSTOMIZE_DEFAULT = [
  'financialItemName', // 銘柄名
  'latestTradePrice', // 株価
  'sharePriceCr1d',
  'sharePriceCr1w',
  'sharePriceCr1m',
  'sharePriceCr1y',
  'minkabuTargetPrice', // 目標株価
  'analystTargetPrice', // アナリスト予想
  'userExpectationTargetPrice', // みんかぶ会員予想
  'researchTargetPrice', // AI株価診断
  'buyPickTotal',
  'sellPickTotal',
  'volume',
  'vroc',
  'marketCapitalization',
  'per',
  'pbr',
  'psr',
  'roa',
  'roe',
  'bps',
  'settlementEvaluations',
  'sales',
  'salesCAGR3y',
  'operatingIncome',
  'operatingIncomeMargin',
  'ordinaryIncome',
  'netIncome',
  'dps',
  'dividendYield',
  'consecutiveIncreasedDividendCount',
  'payoutRatio',
  'yutaiExist',
  'yutaiYield',
  'eps',
  'netAssets',
  'totalAssets',
  'capital',
  'industrySectors',
  'capitalAdequacyRatio',
  'listingDate',
  'minkabuTpSpDr',
  'analystTpSpDr',
  'userExpectationTpSpDr',
  'researchTpSpDr',
  'minkabuTpCr1d',
  'minkabuTpCr1w',
  'minkabuTpCr1m',
  'minkabuTpCr1y',
  'analystTpCr1d',
  'analystTpCr1w',
  'analystTpCr1m',
  'analystTpCr1y',
  'userExpectationTpCr1d',
  'userExpectationTpCr1w',
  'userExpectationTpCr1m',
  'userExpectationTpCr1y',
  'researchTpCr1d',
  'researchTpCr1w',
  'researchTpCr1m',
  'researchTpCr1y',
  'minimumPurchasePrice',
];

/**
 * プレミアムのみで表示する項目
 */
const CUSTOMIZE_PREMIUM = [
  'minkabuTpSpDr',
  'analystTpSpDr',
  'userExpectationTpSpDr',
  'researchTpSpDr',
  'minkabuTpCr1d',
  'minkabuTpCr1w',
  'minkabuTpCr1y',
  'analystTpCr1d',
  'analystTpCr1w',
  'analystTpCr1y',
  'userExpectationTpCr1d',
  'userExpectationTpCr1w',
  'userExpectationTpCr1y',
  'researchTpCr1d',
  'researchTpCr1w',
  'researchTpCr1y',
];

Object.freeze(CUSTOMIZE_DEFAULT);

const state = {
  default: {},
  list: {},
  criteria: [],
  result: {},
  view: '',
  page: 1,
  sortKey: 'favorite_count',
  order: 'desc',
  total: 0,
  checkedCustomize: CUSTOMIZE_DEFAULT,
  isCriteriaAvailable: {},
  showCustomize: false,
  showCriteriaModal: false,
  premium: false,
  cls: true,
  statusCode: 200,
  fullscreen: false,
  activeGlossaryTooltipCount: 0,
  recommend: null,
  summary: {
    data: {},
    show: false,
    code: '',
    codeList: [],
    error: false,
  },
  saveConditions: {
    list: [],
    modal: {
      show: false,
      mode: 'create',
    },
    editData: {
      id: '',
      name: '',
      condition: '',
      url: '',
    },
    page: 'recommends',
  },
};

const getters = {
  listAll(state) {
    return state.list;
  },
  list: (state) => (name) => {
    return state.list[name];
  },
  listChecked: (state) => (name) => {
    if (!state.list[name]) {
      return undefined;
    }
    return state.list[name].checked;
  },
  default(state) {
    return state.default;
  },
  checkedCustomize(state) {
    return state.checkedCustomize;
  },
  isCriteriaAvailable(state) {
    return state.isCriteriaAvailable;
  },
  defaultCustomize() {
    return CUSTOMIZE_DEFAULT;
  },
  showCustomize(state) {
    return state.showCustomize;
  },
  premium(state) {
    return state.premium;
  },
  criteria(state) {
    return state.criteria;
  },
  result(state) {
    return state.result;
  },
  view(state) {
    return state.view;
  },
  page(state) {
    return state.page;
  },
  sortKey(state) {
    return state.sortKey;
  },
  order(state) {
    return state.order;
  },
  total(state) {
    return state.total;
  },
  cls(state) {
    return state.cls;
  },
  statusCode(state) {
    return state.statusCode;
  },
  fullscreen(state) {
    return state.fullscreen;
  },
  showCriteriaModal(state) {
    return state.showCriteriaModal;
  },
  activeGlossaryTooltipCount(state) {
    return state.activeGlossaryTooltipCount;
  },
  summaryData(state) {
    return state.summary.data;
  },
  summaryShow(state) {
    return state.summary.show;
  },
  summaryCode(state) {
    return state.summary.code;
  },
  summaryCodeList(state) {
    return state.summary.codeList;
  },
  /**
   * サマリーAPIのエラー状態を返す
   * @returns {boolean} エラーの場合はtrue
   */
  summaryError(state) {
    return state.summary.error;
  },
  /**
   * 表示中のおすすめ条件を返す
   * @returns {string}
   */
  recommend(state) {
    return state.recommend;
  },
  /**
   * 条件保存のデータ一式を返す
   * @returns {Object}
   */
  saveConditions(state) {
    return state.saveConditions;
  },
};

const mutations = {
  setListAll(state, data) {
    state.list = data;
  },
  setList(state, data) {
    state.list = Object.assign({}, state.list, { [data.name]: data });
  },
  setListValue(state, { name, value }) {
    state.list[name].value = value;
  },
  setListChecked(state, { name, checked = [] }) {
    state.list[name].checked = checked;
  },
  setDefault(state, data) {
    state.default = Object.assign({}, state.default, { [data.name]: data });
  },
  /**
   * デフォルト＋指定した検索条件を検索結果の表示項目としてセット
   */
  setDefaultCheckedCustomize(state) {
    // 検索条件をキャメルケースに変換
    const criteria = state.criteria.map((c) => {
      if (c === 'sales_cagr_3y') {
        return 'salesCAGR3y';
      }
      return c.replace(/_([0-9a-z])/g, (_, v) => v.toUpperCase());
    });

    // 検索条件の「みんかぶ予想」にチェックが入っている場合の処理
    const ratingsTypes = [
      {
        name: 'analyst_ratings',
        mapping: 'analystTargetPrice',
      },
      {
        name: 'user_ratings',
        mapping: 'userExpectationTargetPrice',
      },
      {
        name: 'research_ratings',
        mapping: 'researchTargetPrice',
      },
    ];

    ratingsTypes.forEach((type) => {
      if (state.list[type.name] && state.list[type.name]['checked'].length > 0) {
        criteria.push(type.mapping);
      }
    });

    // デフォルト＋指定した検索条件のうち、表示項目として存在するものを抽出
    let defaultItems = [
      'financialItemName',
      'latestTradePrice',
      'sharePriceCr1d',
      'minkabuTargetPrice',
      ...criteria,
    ].filter((item) => {
      // プレミアム会員でない場合はプレミアムのみ表示の項目を除外
      if (!state.premium && CUSTOMIZE_PREMIUM.includes(item)) {
        return;
      }
      return CUSTOMIZE_DEFAULT.includes(item);
    });

    // 重複項目を削除
    defaultItems = defaultItems.filter((item, idx) => defaultItems.indexOf(item) === idx);

    // 指定順に並び替えてからセット
    state.checkedCustomize = defaultItems.sort((x, y) => {
      return CUSTOMIZE_DEFAULT.indexOf(x) - CUSTOMIZE_DEFAULT.indexOf(y);
    });
  },
  setCheckedCustomize(state, data) {
    state.checkedCustomize = data;
  },
  setShowCustomize(state, data) {
    state.showCustomize = data;
  },
  setCriteriaAvailable(state, data) {
    state.isCriteriaAvailable = data;
  },
  setPremium(state, data) {
    state.premium = data;
  },
  setCriteria(state, { name }) {
    if (state.criteria.includes(name)) {
      state.criteria = state.criteria.filter((el) => el !== name);
    } else {
      state.criteria.push(name);
    }
  },
  setCriteriaAll(state, data) {
    state.criteria = data;
  },
  setResult(state, data) {
    state.result = data;
  },
  setView(state, data) {
    state.view = data;
  },
  setPage(state, data) {
    state.page = data;
  },
  setSortKey(state, data) {
    state.sortKey = data;
  },
  setOrder(state, data) {
    state.order = data;
  },
  setTotal(state, data) {
    state.total = data;
  },
  setCls(state, data) {
    state.cls = data;
  },
  setStatusCode(state, data) {
    state.statusCode = data;
  },
  toggleFullscreen(state) {
    state.fullscreen = !state.fullscreen;
  },
  setShowCriteriaModal(state, data) {
    state.showCriteriaModal = data;
  },
  setActiveGlossaryTooltips(state, data) {
    state.activeGlossaryTooltipCount = data;
  },
  incrementActiveGlossaryTooltips(state) {
    state.activeGlossaryTooltipCount++;
  },
  decrementActiveGlossaryTooltips(state) {
    state.activeGlossaryTooltipCount--;
  },
  setSummaryData(state, data) {
    state.summary.data = data;
  },
  setSummaryShow(state, data) {
    state.summary.show = data;
  },
  setSummaryCode(state, data) {
    state.summary.code = data;
  },
  setSummaryCodeList(state, data) {
    state.summary.codeList = data;
  },
  setSummaryError(state, data) {
    state.summary.error = data;
  },
  setRecommend(state, data) {
    state.recommend = data;
  },
  setSaveConditionsList(state, data) {
    state.saveConditions.list = data;
  },
  setSavaConditionsModalShow(state, data) {
    state.saveConditions.modal.show = data;
  },
  setSavaConditionsModalMode(state, data) {
    state.saveConditions.modal.mode = data;
  },
  setSavaConditionsEditDataId(state, data) {
    state.saveConditions.editData.id = data;
  },
  setSavaConditionsEditDataName(state, data) {
    state.saveConditions.editData.name = data;
  },
  setSavaConditionsEditDataCondition(state, data) {
    state.saveConditions.editData.condition = data;
  },
  setSavaConditionsEditDataUrl(state, data) {
    state.saveConditions.editData.url = data;
  },
  setSavaConditionsPage(state, data) {
    state.saveConditions.page = data;
  },
};

const actions = {
  /**
   * 検索結果を取得
   * @param {String} params クエリパラメータ
   */
  async getResult({ commit }, params) {
    commit('setCls', true);
    await axios
      .get(`/stock/search.json${params}`)
      .then(function (response) {
        commit('setResult', response.data);
        commit('setTotal', response.data.pagination.totalCount);
        commit('setCls', false);
        commit('setStatusCode', response.status);
      })
      .catch(function (err) {
        commit('setCls', false);
        commit('setStatusCode', err.response.status);
        // eslint-disable-next-line no-console
        console.error(`${err.response.status}: ${err.response.data.message}`);
      });
  },
  /**
   * サマリーを取得
   */
  async getSummary({ state, commit }) {
    commit('setSummaryError', false);
    await axios
      .get(`/stock/search/summary/${state.summary.code}.json`)
      .then(function (response) {
        commit('setSummaryData', response.data);
        commit('setPremium', response.data.charged);
      })
      .catch(function (err) {
        commit('setSummaryError', true);
        // eslint-disable-next-line no-console
        console.error(`${err.response.status}: ${err.response.data.message}`);
      });
  },
  /**
   * 保存条件を取得
   */
  async getSaveCondition({ commit }) {
    await axios
      .get(`/stock/search/saved_condition.json`)
      .then(function (response) {
        commit('setSaveConditionsList', response.data);
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(`${err.response.status}: ${err.response.data.message}`);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
