import gql from 'graphql-tag';

export const QUERY_INDIVIDUAL_FINANCIAL_ITEM_SUMMARY_INFO = gql`
  query individualFinancialItemsSummaryInfo($f11mCode: String!) {
    individualFinancialItemsSummaryInfo(f11mCode: $f11mCode) {
      settlement {
        quarterlyResults {
          settlementEvaluation
        }
      }
      dividend {
        annualList {
          dividendStatus
        }
        dividendYield
      }
      yutai {
        yutaiCategories {
          id
          name
        }
      }
    }
  }
`;
