<template src="./ReportModal.html"></template>
<script>
import CampaignBanner from '../../modules/Banner/CampaignBanner.vue';

/**
 * お気に入りボタンからの会員登録モーダルコンポーネント
 *
 * @module modules/Modal/ReportModal
 */
export default {
  name: 'ReportModal',
  components: {
    CampaignBanner,
  },
  props: {
    /**
     * 金融アイテムコード
     * @type {string}
     */
    code: {
      type: String,
      required: true,
    },
    /**
     * 会員登録URL
     * @type {string}
     */
    signUpUrl: {
      type: String,
      required: true,
    },
    /**
     * ログインURL
     * @type {string}
     */
    loginUrl: {
      type: String,
      required: true,
    },
    /**
     * キャンペーンURL
     * @type {string}
     */
    campaignUrl: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod: {
      type: Boolean,
      required: true,
    },
    /**
     * プレミアムLPURL
     * @type {string}
     */
    premiumLpUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    /**
     * モーダルが表示中なら真
     * @returns {boolean}
     */
    isVisible() {
      return this.$store.getters['reportModal/isVisible'];
    },
    /**
     * 会員登録モーダルが表示中なら真
     * @returns {boolean}
     */
    isSignUpVisible() {
      return this.$store.getters['reportModal/isSignUpVisible'];
    },
    /**
     * レポート済みモーダルが表示中なら真
     * @returns {boolean}
     */
    isReportedVisible() {
      return this.$store.getters['reportModal/isReportedVisible'];
    },
    /**
     * レポート登録件数上限モーダルが表示中なら真
     * @returns {boolean}
     */
    isFullVisible() {
      return this.$store.getters['reportModal/isFullVisible'];
    },
    /**
     * レポート可能モーダルが表示中なら真
     * @returns {boolean}
     */
    isReportableVisible() {
      return this.$store.getters['reportModal/isReportableVisible'];
    },
  },
  methods: {
    /**
     * モーダルを非表示にする
     * @returns {void}
     */
    hide() {
      this.$store.commit('reportModal/hide');
    },
    /**
     * レポート銘柄に追加する
     * @returns {void}
     */
    async add() {
      await this.$store.dispatch('reportFinancialItems/create', this.code);
      this.$store.commit('reportModal/hide');
    },
    /**
     * レポート銘柄から削除する
     * @returns {void}
     */
    async remove() {
      await this.$store.dispatch('reportFinancialItems/destroy', this.code);
      this.$store.commit('reportModal/hide');
    },
  },
};
</script>
