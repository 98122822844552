<template src="./SideNavigationDividendYieldRanking.html"></template>

<script>
import { ContentLoader } from 'vue-content-loader';
import { QUERY_FINANCIAL_ITEM_RANKINGS_DIVIDEND_YIELDS as F11M_RANKINGS_DIVIDEND_YIELDS } from '../../../queries/DividendYieldRankingsQuery';

/*
 * 右ナビ配当利回りランキングコンポーネント
 * @vue-props {Boolean} isTailwind スタイリングにTailwindを用いるか否か
 * @module SideNavigation/SideNavigationDividendYieldRanking
 */
export default {
  name: 'SideNavigationDividendYieldRanking',
  components: {
    contentLoader: ContentLoader,
  },
  props: {
    isTailwind: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rankingLoad: false,
      financialItemRankingsDividendYields: [],
    };
  },
  apollo: {
    financialItemRankingsDividendYields: {
      query: F11M_RANKINGS_DIVIDEND_YIELDS,
      skip() {
        return true;
      },
    },
  },
  computed: {
    /**
     * シャドースケルトンの表示判定
     * @returns {Boolean}
     */
    displayShadowSkeleton() {
      return this.$apollo.loading || !this.rankingLoad;
    },
  },
  mounted() {
    this.$apollo.queries.financialItemRankingsDividendYields.skip = false;
    this.rankingLoad = true;
  },
};
</script>
