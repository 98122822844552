import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';
import IPOCalendarStore from './IPOCalendarStore';

/**
 * IPOトップコンテンツルートストア
 *
 * @module IPOTop/IPOTopStore
 */
const IPOTopStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
    ipoCalendar: IPOCalendarStore,
  },
};
export default IPOTopStore;
