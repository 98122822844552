<template src="./ShareholderBenefitSearchOrder.html"></template>

<script>
/**
 * 並び替えプルダウン コンポーネント
 * @vue-data {String} [selected=''] - プルダウンのselected値
 * @vue-data {Array} [yieldOrders=[{ text: '並び替え', value: '' }, { text: '最低投資金額 高い順', value: 'unit_price_desc' }, { text: '最低投資金額 低い順', value: 'unit_price_asc' }]] - プルダウンのリスト
 */
export default {
  name: 'ShareholderBenefitSearchOrder',
  data() {
    return {
      selected: '',
      yieldOrders: [
        { text: '並び替え', value: '' },
        { text: '最低投資金額 高い順', value: 'unit_price_desc' },
        { text: '最低投資金額 低い順', value: 'unit_price_asc' }
      ]
    };
  },
  created() {
    this.orderPullDownInitializes(this);
  },
  methods: {
    /**
     * 並び替えプルダウンで選択されたorderでランキングを表示する
     * @return  {Location} 並び替えURLを生成しロードする
     */
    yieldOrderIssues() {
      let url = new URL(window.location);
      url.searchParams.set('order', this.selected);
      window.location.assign(url.toString());
    },
    /**
     * 並び替えプルダウンのselectedとプルダウンのリストをセットする
     * @param {VueComponent} component - 並び替えプルダウン コンポーネント
     */
    orderPullDownInitializes(component) {
      // @fixme document.getElementByIdで選択された並び替え順を取得しているが、他に良い方法ありそう。
      const order = document.getElementById('info_order_type').innerHTML;
      if (component._isOrderlyOrder(order)) { component.selected = order; }

      // @fixme document.getElementByIdで選択された利回りを取得しているが、他に良い方法ありそう。
      const yield_type = document.getElementById('info_yield_type').innerHTML;
      const selectOptionList = component._getSelectOptionList(yield_type);

      selectOptionList.forEach(function(element) {
        component.yieldOrders.push(element);
      }, component);
    },
    _isOrderlyOrder(order) {
      return [
        'unit_price_desc', 'unit_price_asc',
        'yutai_yield_desc', 'yutai_yield_asc',
        'dividend_yield_desc', 'dividend_yield_asc',
        'total_yield_desc', 'total_yield_asc'
      ].includes(order);
    },
    _getSelectOptionList(key) {
      let orderList = [];
      let switchbox = {};
      switchbox.yutai = function() {
        orderList.push({ text: '株主優待利回り 高い順', value: 'yutai_yield_desc' });
        orderList.push({ text: '株主優待利回り 低い順', value: 'yutai_yield_asc' });
      };
      switchbox.haito = function() {
        orderList.push({ text: '配当利回り 高い順', value: 'dividend_yield_desc' });
        orderList.push({ text: '配当利回り 低い順', value: 'dividend_yield_asc' });
      };
      switchbox.yutai_haito = function() {
        orderList.push({ text: '配当＋株主優待利回り 高い順', value: 'total_yield_desc' });
        orderList.push({ text: '配当＋株主優待利回り 低い順', value: 'total_yield_asc' });
      };

      if(key!==undefined && key in switchbox){
        const func = switchbox[key];
        func();
      } else {
        orderList.push({ text: '株主優待利回り 高い順', value: 'yutai_yield_desc' });
        orderList.push({ text: '株主優待利回り 低い順', value: 'yutai_yield_asc' });
      }
      return orderList;
    }
  }
};
</script>
