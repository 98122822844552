<template src="./AccordionActivity.html" />
<script>
import generateSignalPatternList from '../../../utils/generateSignalPatternList';
/**
 * お気に入り銘柄 アコーディオン アクティビティ
 * @vue-prop {Object} item
 * @module Mypage/Accordion/AccordionActivity
 */

export default {
  name: 'AccordionActivity',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activities() {
      return this.$store.getters['favoriteFinancialItemDetails/detailsActivities'](this.item.code);
    },
  },
  methods: {
    /**
     * シグナルのパターンごとにクラス名とテキストを返す
     * @param {string} title
     * @return {Array}
     */
    signalPattern(title) {
      return generateSignalPatternList(title);
    },
  },
};
</script>
