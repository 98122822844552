/*
 * 銘柄優待ニュースモデル
 *
 *  @property {string} code 銘柄コード
 *  @property {number} id 優待ニュースID
 *  @property {string} stockNewsIcon ニュースの種別アイコン
 *  @property {string} title 優待ニュースタイトル
 *  @property {string} channelTitle ニュース配信元
 *  @property {string} relativePublishedTime 配信からの経過日時
 *  @property {Date} publishedDate 配信日をDate型にしたもの
 *  @property {string} bodySizeOverClass 記事div装飾クラス名
 *  @property {string} truncateBody トランケートされた記事
 *  @property {string} stockNewsUrl ニュース記事URL
 */
class StockYutaiNews {
  /*
   * 初期化処理
   *
   * @params {object}.data
   * @constructor
   */
  constructor(data) {
    this.code = data.stock_code;
    this.id = data.article_id;
    this.stockNewsIcon = data.l_stock_news_icon;
    this.title = data.title;
    this.channelTitle = data.d_channel_title;
    this.relativePublishedTime = data.d_relative_published_time;
    this.publishedDate = new Date(data.published_at);
    this.bodySizeOverClass = data.body_size_over;
    this.truncateBody = data.truncate_body;
    this.stockNewsUrl = this._stockNewsUrl();
  }

  _stockNewsUrl() {
    return `/stock/${this.code}/news/${this.id}`;
  }
}

export default StockYutaiNews;
