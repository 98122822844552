import JamChart from './JamChart.vue';

/**
 * 個別銘柄株価予想タブ ルートコンポーネント
 * @vue-components {jamChart} JamChart
 * @module pages/StockAnalysis/StockAnalysis
 */
const StockAnalysis = {
  el: '#v-stock-analysis',
  components: {
    jamChart: JamChart,
  },
};
export default StockAnalysis;
