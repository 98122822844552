import ChangesInPayoutRatioChartVue from './ChangesInPayoutRatioChart.vue';

/**
 * 個別銘柄の配当ページの配当性向推移チャートのルートコンポーネント
 * @module Dividends/ChangesInPayoutRatioChart
 */
const ChangesInPayoutRatioChart = {
  el: '#v-changes-in-payout-ratio-chart',
  components: {
    changesInPayoutRatioChart: ChangesInPayoutRatioChartVue,
  },
};

export default ChangesInPayoutRatioChart;
