import ShareholderBenefitSearchOrder from './ShareholderBenefitSearchOrder.vue';
import FavoriteButton from '../Common/FavoriteButton.vue';
import NotificationBox from '../Common/NotificationBox.vue';
import VariantUtil from '../../utils/VariantUtil';
import BaseModal from '../Common/BaseModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 優待検索ページのルートコンポーネント
 * @vue-data {Boolean} showModal モーダルを表示するかどうか
 * @vue-components {FavoriteButton} favoriteButton お気に入り登録ボタンコンポーネント
 * @vue-components {NotificationBox} notificationBox お知らせBoxコンポーネント
 * @vue-components {BaseModal} baseModal 未ログイン時に表示するモーダルコンポーネント
 * @vue-event {Promise} getFavoritesFinancialItems お気に入り登録済み銘柄コードリスト取得
 */

const Search = {
  el: '#yutai_search',
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    /**
     * モーダル表示イベント
     * @returns {boolean} closeイベントが発火するとshowModalはfalseとなる
     */
    showModalEvent: function () {
      this.showModal = true;
    },
  },
  get components() {
    if (VariantUtil.isSp()) {
      return {
        shareholderBenefitSearchOrder: ShareholderBenefitSearchOrder,
        favoriteButton: FavoriteButton,
        notificationBox: NotificationBox,
        baseModal: BaseModal,
        CampaignBanner,
      };
    } else {
      return {
        shareholderBenefitSearchOrder: ShareholderBenefitSearchOrder,
        favoriteButton: FavoriteButton,
        baseModal: BaseModal,
        CampaignBanner,
      };
    }
  },
};
export default Search;
