<template src="./NewsSearchResult.html" />
<script>
import BasePagination from '../Base/BasePagination/BasePagination.vue';

/**
 * ニュース検索絞込結果コンポーネント
 *
 * @vue-computed {BaseCollection} newsCollection 取得したニュースのCollectionオブジェクト
 * @vue-computed {Array<NewsArticle>} newsArticles 取得したニュース記事の配列
 * @vue-computed {boolean} isApiCallDone APIコールしたかどうか
 * @vue-computed {string} inputValue APIコールしたかどうか
 * @vue-computed {boolean} noneResult 検索結果が1件も無いかどうか
 * @vue-computed {Object} newsSearchSsr SSRで表示されているニュース検索画面の初期DOM
 * @module NewsSearch/NewsSearchResult
 */
export default {
  name: 'NewsSearchResult',
  components: {
    NewsSearchPagination: BasePagination,
  },
  computed: {
    newsCollection() {
      return this.$store.getters.resultCollection;
    },
    newsArticles() {
      return this.$store.getters.resultArticles;
    },
    isApiCallDone() {
      return this.$store.getters.isApiCallDone;
    },
    inputValue() {
      return this.$store.getters.inputValue;
    },
    noneResult() {
      return !this.$store.getters.hasResult;
    },
    newsSearchSsr() {
      return window.document.getElementById('v-news-search-ssr');
    },
    /**
     * バッジ表示させる項目があるか否か
     * @return {Boolean}
     */
    hasBadgeItems() {
      return ({ categories, tags }) => {
        if (!categories && !tags) {
          return false;
        }
        if (Array.isArray(categories) && categories.length === 0 && Array.isArray(tags) && tags.length === 0) {
          return false;
        }
        return true;
      };
    },
  },
  watch: {
    /**
     * isApiCallDoneのウォッチャー
     * ServerSideRenderingの表示を制御する
     *
     * @param {string} val 検索キー
     */
    isApiCallDone(val) {
      if (!this.newsSearchSsr) {
        return false;
      }
      this.newsSearchSsr.style.display = val ? 'none' : 'block';
    },
  },
};
</script>
