import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';

/**
 * おすすめ銘柄 ルートストア
 *
 * @module Discover/DiscoverStore
 */
const DiscoverStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
  },
};
export default DiscoverStore;
