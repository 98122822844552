<template src="./StockSearch.html" />
<script>
import generateQueryParams from './utils/generateQueryParams';
import UrlGenerateUtil from '../../../utils/UrlGenerateUtil';
import saveConditions from './modules/save/SaveConditions.vue';
import saveModal from './modules/save/SaveModal.vue';

/**
 * 銘柄検索コンポーネント
 * @module StockSearch/StockSearch
 */
export default {
  name: 'StockSearch',
  components: {
    saveConditions,
    saveModal,
  },
  props: {
    premium: {
      type: Boolean,
      required: false,
      default: false,
    },
    isShow: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
  },
  computed: {
    result() {
      return this.$store.getters['stockSearchInput/result'];
    },
    criteria() {
      return this.$store.getters['stockSearchInput/criteria'];
    },
    total() {
      return this.$store.getters['stockSearchInput/total'].toLocaleString();
    },
    view() {
      return this.$store.getters['stockSearchInput/view'];
    },
    page() {
      return Number(this.$store.getters['stockSearchInput/page']);
    },
    listAll() {
      return this.$store.getters['stockSearchInput/listAll'];
    },
    /**
     * ログインボタン、会員登録ボタンのリンクを書き換える
     * @note StockSearch.vue管理外にあるリンクに対しての処理
     */
    rewriteCallbackUrl() {
      /**
       * @param {object} link link要素
       * @param {string} pathString 遷移先
       */
      return (link, pathString) => {
        if (!link) {
          return;
        }
        return link.setAttribute(
          'href',
          UrlGenerateUtil.idMinkabuUrl({
            pathString: pathString,
            currentUrl: window.location.href,
            source: 'minkabu',
            medium: pathString,
            campaign: '/stock/search',
          })
        );
      };
    },
  },

  mounted() {
    // ブラウザのページバック・プレビューイベント
    window.addEventListener('popstate', () => {
      const { sort_key, order, view, page, ...queryObj } = Object.fromEntries(
        new URLSearchParams(window.location.search).entries()
      );
      this.onSearch(page || 1, false);
    });

    this.$store.commit('stockSearchInput/setDefaultCheckedCustomize');
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.onSearch(this.page));
  },
  async created() {
    const headerTitle = document.querySelector('#header_sp .js_header_title');
    if (headerTitle) {
      headerTitle.innerHTML = '銘柄スクリーニング<br>（条件検索）';
    }
    this.hideDock();

    const { sort_key, order, view, page, recommend, ...queryObj } = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    this.initSearchStore({ sort_key, order, view, page, recommend, queryObj });

    // 検索結果ページの場合は検索する
    if (view === 'result') {
      this.$nextTick(() => {
        this.onSearch(page || 1, false);
      });
    }

    // プレミアムかストアに登録
    this.$store.commit('stockSearchInput/setPremium', this.premium);
  },
  watch: {
    criteria(val, old) {
      this.criteriaReplaceBrowserHistory();
    },
    listAll(val, old) {
      this.criteriaReplaceBrowserHistory();
    },
    view(val, old) {
      this.setRecommend(val);
      //銘スクページ内かつvue管理外の要素を取得してコールバックURLにクエリパラメータをつけて書き換える
      this.rewriteCallbackUrl(document.querySelector('#v-stock-search .login-link'), 'login');
      this.rewriteCallbackUrl(document.getElementById('bn_login'), 'login');
      this.rewriteCallbackUrl(document.getElementById('bn_reg'), 'sign_up');
    },
  },
  methods: {
    /**
     * queryParams から初期条件をStoreにセットする
     */
    initSearchStore(query = { sort_key: null, order: null, view: null, page: null, recommend: null, queryObj: {} }) {
      this.$store.commit('stockSearchInput/setCriteriaAvailable', this.isShow);
      // プロパティで分割
      if (query.sort_key) {
        this.$store.commit('stockSearchInput/setSortKey', query.sort_key);
      }
      if (query.order) {
        this.$store.commit('stockSearchInput/setOrder', query.order);
      }
      if (query.view) {
        this.$store.commit('stockSearchInput/setView', query.view);
      }
      if (query.page) {
        this.$store.commit('stockSearchInput/setPage', query.page);
      }
      if (query.recommend) {
        this.$store.commit('stockSearchInput/setRecommend', query.recommend);
      }

      const criteria = Array.from(
        new Set(
          Object.keys(query.queryObj).map((el) => {
            return el.replace(/\[\d?\]/, '');
          })
        )
      ).filter((v) => {
        if (this.isShow[this.toUpperCase(v)] === false) {
          return false;
        }
        return true;
      });

      // 有効な検索条件をセット: queryParams のキーから添字を削除して Store に保存
      this.$store.commit('stockSearchInput/setCriteriaAll', [...new Set(criteria)]);
    },
    /**
     * クエリパラメタから表示するページを設定する
     */
    setCurrentPage() {
      const headerTitle = document.querySelector('#header_sp .js_header_title');
      const breadcrumb = document.querySelector('#breadcrumbs > li:last-child > span');

      window.scrollTo(0, 0);
      const searchParams = new URLSearchParams(window.location.search);
      this.hideDock();
      if (searchParams.has('view') && searchParams.get('view') === 'result') {
        this.$store.commit('stockSearchInput/setView', 'result');

        if (headerTitle && breadcrumb) {
          headerTitle.innerHTML = '銘柄スクリーニング<br>検索結果';
          breadcrumb.innerHTML = '銘柄スクリーニング　検索結果';
        }
        return;
      }
      this.$store.commit('stockSearchInput/setView', '');
      if (headerTitle && breadcrumb) {
        headerTitle.innerHTML = '銘柄スクリーニング<br>（条件検索）';
        breadcrumb.innerHTML = '銘柄スクリーニング（条件検索）';
      }
    },
    /**
     * 検索
     */
    async onSearch(page = 1, historyPush = true) {
      this.$store.commit('stockSearchInput/setPage', page);
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: false })
      );
      if (historyPush) {
        history.pushState(null, null, generateQueryParams({ _this: this, view: 'result', paginationOnly: false }));
      }
      this.setCurrentPage();
      this.$store.commit('stockSearchInput/setDefaultCheckedCustomize');
    },
    /**
     * 条件リセット
     */
    onReset() {
      window.location.href = '/stock/search';
    },
    /**
     * 共通フッタを非表示
     */
    hideDock() {
      const dock = document.querySelector('.js_c_dock');
      if (dock) {
        dock.style.display = 'none';
      }
    },
    /**
     * 検索条件を変更するとブラウザの履歴を書き換える
     */
    async criteriaReplaceBrowserHistory() {
      const view = this.view === 'result' ? 'result' : null;
      if (!view) {
        await this.$store.dispatch(
          'stockSearchInput/getResult',
          generateQueryParams({ _this: this, view: null, paginationOnly: true })
        );
      }
      //ログインで遷移した後も検索で設定した値を保持するためにログインのリンクの要素を取得してコールバックURLにクエリパラメータをつけて書き換える
      history.replaceState(null, null, generateQueryParams({ _this: this, view: view, paginationOnly: false }));
    },
    /**
     * 条件追加モーダルを表示
     */
    showCriteriaModal() {
      const body = document.querySelector('body');
      const offsetY = `-${window.pageYOffset}px`;
      body.classList.add('ui-drawer-scroll-stop');
      body.style.top = offsetY;
      this.$store.commit('stockSearchInput/setShowCriteriaModal', true);
    },
    /**
     * スネークからキャメルに変換
     * @param {String} c
     */
    toUpperCase(c) {
      if (c === 'sales_cagr_3y') {
        return 'salesCAGR3y';
      }
      return c.replace(/_([0-9a-z])/g, (_, v) => v.toUpperCase());
    },
    /**
     * おすすめ条件をセット
     * @param {String} view
     */
    setRecommend(view = null) {
      if (view === 'result') {
        // おすすめ条件経由で検索結果ページに遷移した場合はStoreにセットする
        this.$store.commit(
          'stockSearchInput/setRecommend',
          new URLSearchParams(window.location.search).get('recommend')
        );
      } else {
        // 検索条件ページの場合はおすすめ条件をStoreから削除する
        this.$store.commit('stockSearchInput/setRecommend', null);
      }
    },
  },
};
</script>
