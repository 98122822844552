import BaseResource from './BaseResource';

/* *
 * お気に入り金融アイテムAPI
 * */
class FavoriteFinancialItemResource extends BaseResource {
  /*
   * お気に入り登録済み金融アイテムコードリストAPIをコールし
   * お気に入り登録済み金融アイテムコードリストを取得
   * @return {array} 金融アイテムコード
   */
  async get() {
    const url = '/users/favorite_stock';
    return await this.client.get(url)
      .then(response => response.data.stocks.map(sc => sc.code))
      .catch(() => { return 'error'; });
  }

  /*
   * お気に入り登録APIをコールしてお気に入り金融アイテムに追加
   * @param {string} financialItemCode 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async create(financialItemCode) {
    return await this.client.post('/users/favorite_stock', { id: financialItemCode });
  }

  /*
   * お気に入り解除APIをコールしてお気に入り金融アイテムから削除
   * @param {string} financialItemCode 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async delete(financialItemCode) {
    return await this.client.delete(`/users/favorite_stock/${financialItemCode}`);
  }
}
export default FavoriteFinancialItemResource;
