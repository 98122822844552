import VariantUtil from '../utils/VariantUtil';

/**
 * 共通component要素をmixinするためのオブジェクト
 *
 * @vue-computed {boolean} isSp VariantがSPなら真
 * @vue-computed {boolean} isPc VariantがSPでなければ真
 */
const VariantMixin = {
  computed: {
    isSp() {
      return VariantUtil.isSp();
    },
    isPc() {
      return !VariantUtil.isSp();
    }
  }
};
export default VariantMixin;

