<template src="./PeerCompaniesDividendInfo.html"></template>
<script>
import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';
import MkIntersectionObserver from '../../../utils/MkIntersectionObserver';
import { QUERY_PEER_COMPANIES_DIVIDEND_INFO as PEER_COMPANIES_DIVIDEND_INFO } from '../../../queries/PeerCompaniesDividendInfoQuery';

/**
 * 個別銘柄 配当他社比較
 *
 * @module pages/Dividends/PeerCompaniesDividendInfo
 */
export default {
  name: 'PeerCompaniesDividendInfo',
  components: {
    FavoriteButtonHeart,
  },
  props: {
    /**
     * 金融アイテムコード
     *  @return {String}
     */
    code: {
      type: String,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      peerCompaniesDividendInfo: [],
    };
  },
  apollo: {
    peerCompaniesDividendInfo: {
      query: PEER_COMPANIES_DIVIDEND_INFO,
      skip() {
        return true;
      },
      variables() {
        return {
          code: this.code,
        };
      },
    },
  },
  computed: {
    /**
     * 業績評価があるか否か
     * @return {Boolean}
     * @param {Object} settlement
     */
    hasSettlementEvaluation() {
      return (settlement) => {
        return (
          !!settlement &&
          !!(settlement.quarterlyResults.length > 0 && settlement.quarterlyResults[0].settlementEvaluation)
        );
      };
    },
    /**
     * 業績評価を表す名前
     * @return {String}
     * @param  {Object} settlement
     */
    settlementEvaluationName() {
      return (settlement) => {
        return this.hasSettlementEvaluation(settlement)
          ? this.settlementEvaluationNameWithIconUrl(settlement.quarterlyResults[0].settlementEvaluation).name
          : '---';
      };
    },
    /**
     * 業績評価のアイコンURL
     * @return {String}
     * @param  {Object} settlement
     */
    settlementEvaluationIconSrc() {
      return (settlement) => {
        return this.hasSettlementEvaluation(settlement)
          ? this.settlementEvaluationNameWithIconUrl(settlement.quarterlyResults[0].settlementEvaluation).iconUrl
          : '';
      };
    },
    /**
     * 配当利回りがあるか否か
     * @return {Boolean}
     * @param {Object} dividend
     */
    hasDividendYield() {
      return (dividend) => {
        return !!dividend && dividend.dividendYield !== null;
      };
    },
    /**
     * 配当利回りのクラス
     * @return {String}
     * @param {Object} dividend
     */
    dividendYieldClass() {
      return (dividend) => {
        return this.hasDividendYield(dividend) ? 'text-right' : 'text-center';
      };
    },
    /**
     * 配当性向があるか否か
     * @return {Boolean}
     * @param {Object} dividend
     */
    hasPayoutRatio() {
      return (dividend) => {
        return !!dividend && dividend.payoutRatio !== null;
      };
    },
    /**
     * 配当性向のクラス
     * @return {String}
     * @param {Object} dividend
     */
    payoutRatioClass() {
      return (dividend) => {
        return this.hasPayoutRatio(dividend) ? 'text-right' : 'text-center';
      };
    },
    /**
     * アップダウンのCSSのクラス
     * @return {String}
     * @param {number} value
     */
    upDownClass() {
      return (value) => {
        return this.valueTwClassFromNumber(Number(value));
      };
    },
    /**
     * 値のフォーマット
     * @param {Number} value
     * @return {String}
     */
    formatValue() {
      return (value) => {
        return this.numFormat(value, 2);
      };
    },
    /**
     * 一株あたり今期配当があるか否か
     * @return {Boolean}
     * @param {Object} dividend
     */
    hasDps() {
      return (dividend) => {
        return !!dividend && dividend.projection.dps !== null;
      };
    },
    /**
     * 一株あたり今期配当のクラス
     * @return {String}
     * @param {Object} dividend
     */
    dpsClass() {
      return (dividend) => {
        return this.hasDps(dividend) ? 'text-right' : 'text-center';
      };
    },
    /**
     * コンポーネント自体を表示すべきか否か
     * @return {Boolean}
     */
    shouldDisplayComponent() {
      return this.peerCompaniesDividendInfo.length !== 0;
    },
    /**
     * 自分のコードか否か
     * @param {String} code
     * @return {Boolean}
     */
    isMyCode() {
      return (code) => {
        return code === this.code;
      };
    },
    /**
     * 自分のコードの背景色を返す
     * @param {String} code
     * @return {String}
     */
    myCodeColor() {
      return (code) => {
        return this.isMyCode(code) ? 'bg-slate-50' : '';
      };
    },
    /**
     * お気に入り登録されているかどうか
     * @returns {boolean}
     */
    isFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.code);
    },
  },
  watch: {
    shouldDisplayComponent: {
      handler: function (newVal) {
        this.$emit('data-loaded', newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.lazyQuery();
  },
  updated() {
    if (this.isSp && this.shouldDisplayComponent) {
      this.toggleScrollMessage();
    }
  },
  methods: {
    /**
     * @note mountedされるまでpropsから引数を受け取る事ができないので、mountedでskipをfalseにして、queryを発火させている
     */
    lazyQuery() {
      this.$apollo.queries.peerCompaniesDividendInfo.skip = false;
    },
    /**
     * 監視対象の要素の表示状態に応じてスクロールメッセージを表示・非表示にする
     * @returns {void}
     */
    toggleScrollMessage() {
      const callback = (entries, observer) => {
        if (entries[0].isIntersecting) {
          messageElement.firstElementChild.classList.add('hidden');
          messageElement.classList.remove('mb-2');
          messageElement.classList.add('mb-6');
          observer.disconnect();
        }
      };
      const observer = new MkIntersectionObserver(callback);
      const messageElement = document.getElementById('js-scroll-message');
      const tr = document.getElementById('js-table-header-row');
      const targetElement = tr.lastElementChild;
      observer.observe(targetElement);
    },
    /**
     * モーダルの表示
     */
    open() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
</script>
