<template src="./StockSearchChkbox.html" />
<script>
import generateQueryParams from '../../utils/generateQueryParams';
/**
 * 銘柄検索条件画面 チェックボックスコンポーネント
 * @module StockSearchChkbox/StockSearchChkbox
 */
export default {
  name: 'StockSearchChkbox',
  props: {
    name: {
      type: String,
      required: true,
    },
    column: {
      // チェックボックスUIのカラム数（1 or 2 or 3）
      // 1 -> ☑️ ラベル ↩︎
      // 2 -> ☑️ ラベル  ☑️ ラベル ↩︎
      // 3 -> ☑️ ラベル  ☑️ ラベル  ☑️ ラベル ↩︎
      type: Number,
      required: true,
      default: 1,
    },
    boxes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checked: [],
      accordion: [],
    };
  },
  computed: {
    storeChecked() {
      return this.$store.getters['stockSearchInput/listChecked'](this.name);
    },
  },
  watch: {
    storeChecked(val) {
      this.checked = val;
      if (this.checked.length === 0) {
        this.accordion = [];
      }
    },
  },
  created() {
    this.init();
  },

  methods: {
    /**
     * 初期化
     */
    init() {
      this.$store.commit('stockSearchInput/setDefault', {
        name: this.name,
        checked: this.checked,
      });
      const queryObj = new URLSearchParams(window.location.search);

      // 存在するvalueの配列を作成
      const checkValues = this.boxes
        .map((v) => {
          let result = v.value;
          if (v.children) {
            result = [...result, ...v.children.map((v) => v.value)];
          }
          return result;
        })
        .flat();

      // queryParams から初期値をセット
      if (queryObj.has(`${this.name}[]`)) {
        this.checked = queryObj.getAll(`${this.name}[]`).filter((v) => checkValues.includes(v));
      }
      if (queryObj.has(`${this.name}`)) {
        this.checked = queryObj.getAll(`${this.name}`).filter((v) => checkValues.includes(v));
      }

      // 親子連動
      for (const parent of this.boxes) {
        if (parent.children) {
          // 子が全てチェック状態なら親にチェックを入れる
          if (parent.children.every((item) => this.checked.includes(item.value))) {
            this.checked.push(parent.value);
          }
          // 子が一つでもチェック状態ならアコーディオンを開く
          if (parent.children.some((item) => this.checked.includes(item.value))) {
            this.accordion.push(parent.value);
          }
        }
      }
      const init = {
        name: this.name,
        checked: this.checked,
      };

      this.$store.commit('stockSearchInput/setList', init);
    },

    /**
     * チェックの変更
     * @param {boolean} child - 子チェックボックスか
     * @param {event} event - イベントオブジェクト
     */
    async onChange(child = false, event) {
      // 親子連動
      if (child) {
        const parent = this.boxes.find((v) => {
          if (v.children) {
            return v.children.find((v) => v.value === event.target.value);
          }
        });

        // 子がすべて checked なら親も checked にする
        if (parent.children.map((v) => v.value).every((v) => this.checked.includes(v))) {
          this.checked = [...new Set([...this.checked, ...parent.value])];
        } else {
          this.checked = this.checked.filter((v) => v !== parent.value);
        }
      } else {
        const parent = this.boxes.find((v) => v.value === event.target.value);
        if (parent.children) {
          const children = parent.children.map((v) => v.value);
          if (event.target.checked) {
            this.checked = [...new Set([...this.checked, ...children])];
          } else {
            this.checked = this.checked.filter((v) => !children.includes(v));
          }
        }
      }

      const obj = {
        name: this.name,
        checked: this.checked,
      };

      this.$store.commit('stockSearchInput/setList', obj);

      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: true })
      );
    },
    /**
     * アコーディオン開閉
     * @param {string} value
     */
    toggleAccordion(value) {
      if (this.accordion.includes(value)) {
        this.accordion = this.accordion.filter((v) => v !== value);
      } else {
        this.accordion.push(value);
      }
    },
  },
};
</script>
