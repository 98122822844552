<template src="./StockTooltip.html" />
<script>
import dayjs from 'dayjs';
export default {
  name: 'StockTooltip',
  props: {
    stockGroup: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    /**
     * CSS
     * @return {Object}
     */
    styleObject() {
      let left = 0;
      let top = 0;
      const mouseCordinates = this.$store.getters['mouseCordinates'];
      const mousePadding = 15;
      const areaPadding = 30;

      if (this.$refs.stockTooltip) {
        const clientWidth = this.$refs.stockTooltip.clientWidth;
        const mouseX = mouseCordinates.x;
        const mouseY = mouseCordinates.y;

        if (mouseX < clientWidth + areaPadding) {
          left = `${mouseX + mousePadding}px`;
        } else {
          left = `${mouseX - (clientWidth + mousePadding)}px`;
        }
        top = `${mouseY - mousePadding}px`;
      }

      return { left, top };
    },
    /**
     * 銘柄の価格情報
     * @return {Object}
     */
    stockPrices() {
      let name, color, price;
      if (!this.stockGroup.points) return [];

      return this.stockGroup.points.map((point) => {
        name = point.series.name;
        color = point.series.color;
        price = point.y;
        return { name, color, price };
      });
    },
    /**
     * 取引日
     * @return {String}
     */
    priceDate() {
      return dayjs(this.stockGroup.x).format('YYYY/MM/DD');
    },
  },
  methods: {
    /**
     * 株価小数点
     * @return {Integer}
     */
    stockDecimalDigit(name) {
      return name === '出来高' ? 0 : 2;
    },
  },
};
</script>
