<template src="./NotificationBox.html"></template>

<script>
/**
 * ページ左下に出現するお知らせボックスコンポーネント
 * @vue-computed {Boolean} visible お知らせボックスが表示される場合は真
 * @vye-computed {string} message お知らせボックスに表示するメッセージ
 * @module Common/NotificationBox
 */
export default {
  name: 'NotificationBox',
  computed: {
    visible() {
      return this.$store.state.notificationBox.visible;
    },
    message() {
      return this.$store.state.notificationBox.message;
    }
  },
  methods: {
    close() {
      this.$store.commit("notificationBox/visible", false);
    }
  }
};
</script>
