/** Class MkIntersectionObserver */
class MkIntersectionObserver {
  constructor(callback, options = {}) {
    this.callback = callback;
    this.options = options;
    this.observer = new IntersectionObserver(this.callback, this.options);
  }

  observe(element) {
    this.observer.observe(element);
  }

  disconnect() {
    this.observer.disconnect();
    this.observer = null;
  }
}
export default MkIntersectionObserver;
