import FavoriteButton from '../Common/FavoriteButton.vue';
import NotificationBox from '../Common/NotificationBox.vue';
import VariantUtil from '../../utils/VariantUtil';
import BaseModal from '../Common/BaseModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 優待ランキングページのルートコンポーネント
 * @vue-data {Boolean} rankingLoadingDisplay ローディング画像表示
 * @vue-data {Boolean} isPopular 人気ランキングページかどうか
 * @vue-data {Boolean} showModal モーダルを表示するかどうか
 * @vue-components {FavoriteButton} favoriteButton お気に入り登録ボタンコンポーネント
 * @vue-components {NotificationBox} notificationBox お知らせBoxコンポーネント
 * @vue-components {BaseModal} baseModal 未ログイン時に表示するモーダルコンポーネント
 * @vue-event {Object} centeringMonthTab 月タブの表示位置調整
 * @vue-event {Object} centeringSpCategoriesTab SP版カテゴリタブ位置調整
 */
const Ranking = {
  el: '#v-yutai_ranking',
  data: {
    rankingLoadingDisplay: true,
    isPopular: false,
    showModal: false,
  },
  get components() {
    if (VariantUtil.isSp()) {
      return {
        favoriteButton: FavoriteButton,
        notificationBox: NotificationBox,
        baseModal: BaseModal,
        CampaignBanner,
      };
    } else {
      return {
        favoriteButton: FavoriteButton,
        baseModal: BaseModal,
        CampaignBanner,
      };
    }
  },
  /**
   * 初期化処理
   * 1. ランキングを表示
   * 2. 人気ランキングタブ、月タブを選択箇所までセンタリング
   */
  created() {
    this.rankingLoadingDisplay = false;
  },
  mounted() {
    this.popularExists();
    if (VariantUtil.isSp()) {
      if (this.isPopular) {
        this.centeringSpCategoriesTab();
      }
      this.centeringMonthTab();
    }
  },
  methods: {
    /**
     * 人気ランキングページの判定
     */
    popularExists() {
      if (document.querySelector('#v-yutai_ranking').dataset.rankType === 'popular') {
        this.isPopular = true;
      }
    },
    /**
     * 月タブが選択されている場合に選択箇所までセンタリング表示する
     */
    centeringMonthTab() {
      this._centeringTab('month_tab_box');
    },
    /**
     * 人気ランキングカテゴリが選択されている場合に選択箇所までセンタリング表示する
     */
    centeringSpCategoriesTab() {
      this._centeringTab('sp_categories_tab');
    },
    _centeringTab(idName) {
      const scrollBox = document.getElementById(idName);
      const centeringTarget = scrollBox.getElementsByClassName('cur')[0];
      scrollBox.scrollLeft = this._getTabPosition(centeringTarget);
    },
    _getTabPosition(target) {
      return target.offsetLeft - (window.innerWidth - target.offsetWidth) / 2;
    },
    /**
     * モーダル表示イベント
     * @returns {boolean} closeイベントが発火するとshowModalはfalseとなる
     */
    showModalEvent: function () {
      this.showModal = true;
    },
  },
};

export default Ranking;
