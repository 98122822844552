import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM as FRAGMENT_F11M } from './fragments/FinancialItemFragment';
import { FRAGMENT_SECURITIES_COMPANY } from './fragments/SecuritiesCompanyFragment';
import { FRAGMENT_PAGE_INFO } from './fragments/PageInfoFragment';

const IPO_ITEM_TYPE_FOR_IPO_ITEMS = `
  collection {
    financialItem {
      ...financialItem
    }
    financialItemCode
    ipoStockName
    exchangeSectionName
    listingDate
    securitiesCompanies {
      ...securitiesCompany
    }
    industrySectorName
    businessSummary
    openingPrice
    openPrice
    percentageChangeOfOpeningPrice
    ipoCanceled
  }
  pageInfo {
    ...pageInfo
  }
`;

export const QUERY_IPO_ITEMS = gql`
  query ipoItems($year: Int!, $month: Int, $page: Int, $order_by_percentage_change: String) {
    ipoItems(year: $year, month: $month, page: $page, orderByPercentageChange: $order_by_percentage_change) {
      ${IPO_ITEM_TYPE_FOR_IPO_ITEMS}
    }
  }
  ${FRAGMENT_F11M}
  ${FRAGMENT_SECURITIES_COMPANY}
  ${FRAGMENT_PAGE_INFO}
`;
