import gql from 'graphql-tag';

export const CORPORATE_ACTIONS_DELISTINGS = gql`
query corporateActionsDelistings($year: Int!, $month: Int!, $day: Int, $page: Int) {
  corporateActionsDelistings(year: $year, month: $month, day: $day, page: $page) {
    collection {
      date
      financialItem {
        code
        name
        nameAbbr
      }
      latestTradeWithChange {
        price
        priceDecimalDigit
        priceTime
        change
        changeRatio
      }
    }
    pageInfo {
      offsetValue
      totalPage
      currentPage
      totalCount
      count
    }
  }
}
`;
