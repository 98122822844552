import NotificationBox from '../../Common/NotificationBox.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';
import YutaiTopRankings from './YutaiTopRankings.vue';
import CampaignBanner from '../../modules/Banner/CampaignBanner.vue';

/**
 * 株主優待トップページのランキング用ルートコンポーネント
 * @vue-components {yutaiRankingListMini} yutaiRankingListMini ページ内に部分的に表示するランキング
 * @vue-components {notificationBox} notificationBox お知らせBox
 * @vue-components {FavoriteSignUpModal} FavoriteSignUpModal モーダル表示
 * @module Yutai/YutaiTopApp
 */
const YutaiTopApp = {
  el: '#v-yutai-top-app',
  apolloProvider: MkApolloProvider,
  mounted() {
    const dummyTabElements = document.querySelectorAll('[data-js-id="yutai-top-ranking-tab-dummy"]');
    dummyTabElements.forEach((dummyTabElement) => dummyTabElement.remove());
  },
  components: {
    yutaiTopRankings: YutaiTopRankings,
    notificationBox: NotificationBox,
    favoriteSignUpModal: FavoriteSignUpModal,
    CampaignBanner,
  },
};
export default YutaiTopApp;
