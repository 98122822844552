import gql from 'graphql-tag';

export const FRAGMENT_FINANCIAL_ITEM = gql`
  fragment financialItem on FinancialItem {
    code
    name
    nameAbbr
  }
`;

export const FRAGMENT_FINANCIAL_ITEM_CODE_WITH_NAME = gql`
  fragment financialItemCodeWithName on FinancialItem {
    code
    name
  }
`;

export const FRAGMENT_FINANCIAL_ITEM_CODE_WITH_NAME_ABBR = gql`
  fragment financialItemCodeWithNameAbbr on FinancialItem {
    code
    nameAbbr
  }
`;

export const FRAGMENT_FINANCIAL_ITEM_NAME = gql`
  fragment financialItemName on FinancialItem {
    name
  }
`;
