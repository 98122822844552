import BaseResource from './BaseResource';
import YutaiRanking from '../models/YutaiRanking';

/**
 * 株主優待ランキングAPI
 */
class YutaiRankingResource extends BaseResource {
  /**
   * 株主優待ランキング情報取得APIをコールし、ランキング情報を取得。
   * @param {Object} resourceName ランキングリソース名
   * @param {Object} month 月
   * @return {Array<YutaiRanking>} ランキング情報
   */
  async getYutaiRankings(resourceName, month) {
    const endpoint = this._toEndpoint(resourceName);
    const response = await this._clientGet(endpoint, { params: { month: month } });

    return this._toYutaiRankings(response.data);
  }

  _toEndpoint(resourceName) {
    return `/yutai/popular_ranking/${resourceName}`;
  }

  _toYutaiRankings(items) {
    if (items.length === 0) {
      return [];
    }
    return items.rankings.map(item => new YutaiRanking(item));
  }

  async _clientGet(path, option) {
    return this.client.get(path, option);
  }
}

export default YutaiRankingResource;
