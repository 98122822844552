<template src="./NewsTooltip.html" />
<script>
const DISPLAY_NEWS_COUNT = 8;
/**
 * NewsTooltip
 *
 * @vue-props {String} code 金融アイテムコード
 * @vue-props {Object} newGroup ニュースデータ＋Highchartsのイベント情報
 * @module pages/StockAnalysis/NewsTooltip
 */
export default {
  name: 'NewsTooltip',
  props: {
    code: {
      type: String,
      default: '',
      require: true,
    },
    newsGroup: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    /**
     * 表示位置のCSSスタイル
     * @return {Object}
     */
    styleObject() {
      let leftMargin;
      if (this.newsCount < 10) {
        leftMargin = 48;
      } else if (this.newsCount < 100) {
        leftMargin = 55;
      } else {
        leftMargin = 62;
      }
      const left = Math.ceil(this.newsGroup.plotX + leftMargin);
      const top = Math.ceil(this.newsGroup.plotY + 20);
      return {
        top: `${top}px`,
        left: `${left}px`,
      };
    },
    /**
     * ニュース一覧
     * @return {Array<Object>}
     */
    news() {
      if (!this.newsGroup.news) {
        return [];
      }
      return this.newsGroup.news;
    },
    /**
     * 表示対象のニュース一覧
     * @return {Array<Object>}
     */
    displayNews() {
      return this.news.slice(0, DISPLAY_NEWS_COUNT);
    },
    /**
     * ニュース件数
     * @return {Integer}
     */
    newsCount() {
      return this.news.length;
    },
    /**
     * 表示対象から外れたニュースの件数
     * @return {Array<Object>}
     */
    remainingNewsCount() {
      const totalCount = this.news.length;
      return totalCount <= DISPLAY_NEWS_COUNT ? 0 : totalCount - DISPLAY_NEWS_COUNT;
    },
  },
  methods: {
    /**
     * ツールチップにマウスポインタが入った時
     */
    mouseEnter(e) {
      this.$store.commit('showNewsTooltip');
    },
    /**
     * ツールチップからマウスポインタが離れた時
     */
    mouseLeave(e) {
      this.$store.commit('hideNewsTooltip');
    },
  },
};
</script>
