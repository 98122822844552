import StockNewsSearchInput from './StockNewsSearchInput.vue';
import StockNewsSearchResult from './StockNewsSearchResult.vue';
import StockNewsSourceSearchInput from './StockNewsSourceSearchInput.vue';
/**
 * 銘柄ニュース検索ルートコンポーネント
 *
 * @vue-components {StockNewsSearchInput} newsSearchInput キーワード検索
 * @vue-components {StockNewsSearchResult} newsSearchResult ニュース一覧表示
 * @vue-components {StockNewsSourceSearchInput} sourceSearchInput 絞り込み検索
 * @module StockNews
 */

const StockNews = {
  el: '#v-stock-news',
  components: {
    newsSearchInput: StockNewsSearchInput,
    newsSearchResult: StockNewsSearchResult,
    sourceSearchInput: StockNewsSourceSearchInput
  }
};
export default StockNews;
