import FavoriteFinancialItemResource from '../../resources/FavoriteFinancialItemResource';
import UserLoginStatus from '../../utils/UserLoginStatus';

/**
 * @property {FavoriteFinancialItemResource} resource 銘柄お気に入り登録APIクラス
 * @property {array} financialItemCodes お気に入り登録している金融アイテムコード
 * @property {string} notificationMessage 通知メッセージ
 * @property {string} loadingState financialItemCodesのロード処理が開始されたかどうかの状態
 */
const FavoriteFinancialItemsStore = {
  namespaced: true,
  state: {
    resource: new FavoriteFinancialItemResource(),
    financialItemCodes: [],
    notificationMessage: '',
    loadingState: 'none',
  },
  getters: {
    /* *
     * 金融アイテムがお気に入り登録されているか
     * @params {string} code 金融アイテムコード
     * @return {boolean} お気に入り登録されている場合はtrue
     * */
    isFavored: (state) => (code) => {
      return state.financialItemCodes.indexOf(code) >= 0;
    },
    notificationMessage: (state) => {
      return state.notificationMessage;
    },
  },
  mutations: {
    addFinancialItemCode(state, financialItemCode) {
      state.financialItemCodes.push(financialItemCode);
    },
    removeFinancialItemCode(state, financialItemCode) {
      state.financialItemCodes = state.financialItemCodes.filter((code) => code !== financialItemCode);
    },
    registerLoadingState(state, loadingState) {
      state.loadingState = loadingState;
    },
    fetchFavoriteFinancialItemCode(state, response) {
      state.financialItemCodes = response;
    },
    registerNotificationMessage(state, notificationMessage) {
      state.notificationMessage = notificationMessage;
    },
  },
  actions: {
    /**
     * 初期処理
     * 複数のボタンから呼ばれても処理が並列しないようにフラグを持つ
     */
    async init({ state, dispatch, commit }) {
      if (!UserLoginStatus.isLoggedIn()) {
        return;
      }
      if (state.loadingState === 'loading') {
        return;
      }
      commit('registerLoadingState', 'loading');

      dispatch('get');
    },
    /**
     * お気に入り登録済み銘柄コードを取得する
     * エラーの場合は、間隔を開け再取得処理
     */
    async get({ state, dispatch, commit }) {
      const response = await state.resource.get();
      if (response === 'error') {
        setTimeout(() => dispatch('get'), 10000);
      } else {
        commit('fetchFavoriteFinancialItemCode', response);
      }
    },
    /**
     * お気に入り登録状態変更
     */
    async toggle({ getters, dispatch }, financialItemCode) {
      if (getters['isFavored'](financialItemCode)) {
        await dispatch('destroy', financialItemCode);
      } else {
        await dispatch('create', financialItemCode);
      }
    },
    /**
     * お気に入り登録
     */
    async create({ commit, state }, financialItemCode) {
      const response = await state.resource.create(financialItemCode);

      if (response.status === 200) {
        commit('addFinancialItemCode', financialItemCode);
        commit('registerNotificationMessage', 'お気に入りに登録しました');
      } else {
        commit('registerNotificationMessage', 'システムエラーが発生しました。時間を置いてからもう一度試してください。');
      }
    },
    /**
     * お気に入り削除
     */
    async destroy({ commit, state }, financialItemCode) {
      const response = await state.resource.delete(financialItemCode);

      if (response.status === 200) {
        commit('removeFinancialItemCode', financialItemCode);
        commit('registerNotificationMessage', 'お気に入りを削除しました');
      } else {
        commit('registerNotificationMessage', 'システムエラーが発生しました。時間を置いてからもう一度試してください。');
      }
    },
  },
};
export default FavoriteFinancialItemsStore;
