<template src="./SecurityMatrix.html" />

<script>
import FeeRankingSecurity from '../../models/FeeRankingSecurity';
/**
 * 証券会社比較テーブルコンポーネント
 *
 * @vue-prop {Array<FeeRankingSecurity>} securityRankings 証券会社ランキング一覧
 * @vue-prop {FeeRankingSecurity} topSecurity 手数料最安の証券会社
 * @vue-prop {number} displayCount 表示件数
 * @vue-prop {String} displayPosition 表示位置
 * @module Fee/SecuritiesMatrix
 */
export default {
  name: 'SecurityMatrix',
  props: {
    securityRankings: {
      type: Array,
      required: true,
    },
    topSecurity: {
      type: FeeRankingSecurity,
      required: true,
    },
    displayCount: {
      type: Number,
      required: true,
    },
    displayPosition: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * ループの何番目からがloading="lazy"の対象か
     * @return {Integer}
     */
    idxThresholdLoadingLazy() {
      switch (this.displayPosition) {
        case 'stocksFee':
          return 1;
        case 'stocksYutai':
        default:
          return 0;
      }
    },
    /**
     * ランキングトップがloading="lazy"の対象なら真
     * @return {Boolean}
     */
    isLoadingLazyTop() {
      return this.displayPosition === 'stocksYutai';
    },
  },
};
</script>
