<template src="./SideNavigationHistories.html" />

<script>
import { ContentLoader } from 'vue-content-loader';
import CookieUtil from '../../../utils/CookieUtil';
import CurrentPriceResource from '../../../resources/CurrentPriceResource';
import ITFBaseValueResource from '../../../resources/ITFBaseValueResource';
import SideNavigationFinancialItemHistories from './SideNavigationFinancialItemHistories.vue';
import SideNavigationITFHistories from './SideNavigationITFHistories.vue';

/**
 * 右ナビ閲覧履歴コンポーネント
 * @vue-components {ContentLoader} contentLoader
 * @vue-components {SideNavigationFinancialItemHistories} sideNavigationFinancialItemHistories
 * @vue-components {SideNavigationITFHistories} sideNavigationItfHistories
 * @vue-data {string} current 表示するタブ(株式/投資信託)
 * @vue-data {Array} f11mHistories 株式の閲覧履歴
 * @vue-data {Array} itfHistories 投資信託の閲覧履歴
 * @vue-data {boolean} isF11mLoaded 株式の閲覧履歴取得APIの結果が返却されたか
 * @vue-data {boolean} isITFLoaded 投資信託の閲覧履歴取得APIの結果が返却されたか
 * @module SideNavigation/SideNavigationHistories
 */
export default {
  name: 'SideNavigationHistories',
  components: {
    contentLoader: ContentLoader,
    sideNavigationFinancialItemHistories: SideNavigationFinancialItemHistories,
    sideNavigationItfHistories: SideNavigationITFHistories,
  },
  data() {
    return {
      current: 'f11m',
      f11mHistories: [],
      itfHistories: [],
      isF11mLoaded: false,
      isITFLoaded: false,
    };
  },
  computed: {
    /**
     * CurrentPriceResourceクラスのインスタンス作成
     * @return {CurrentPriceResource}
     */
    currentPriceResource() {
      return new CurrentPriceResource();
    },
    /**
     * ITFBaseValueResourceクラスのインスタンス作成
     * @return {CurrentPriceResource}
     */
    itfBaseValueResource() {
      return new ITFBaseValueResource();
    },
    /**
     * cookieから取得した株式の閲覧履歴
     * @return {array<Object>}
     */
    f11mCookieHistories() {
      const f11mCookie = CookieUtil.get('recent_browsed_financial_items');
      if (!f11mCookie) return [];

      return JSON.parse(f11mCookie);
    },
    /**
     * cookieから取得した株式の閲覧履歴（codeのみ）
     * @return {array<string>}
     */
    f11mCookieHistoriesCodes() {
      if (!this.f11mCookieHistories.length) return [];

      return this.f11mCookieHistories.map((f11mCookieHistory) => {
        return f11mCookieHistory.stock_code;
      });
    },
    f11mCookieHistoryLength() {
      const f11mLength = this.f11mCookieHistories.length;
      return f11mLength > 10 ? 10 : f11mLength;
    },
    /**
     * cookieから取得した投資信託の閲覧履歴
     * @return {Object}
     */
    itfCookieHistories() {
      const itfCookie = CookieUtil.get('fund_data');
      if (!itfCookie) return [];

      return JSON.parse(itfCookie);
    },
    /**
     * cookieから取得した投資信託の閲覧履歴（codeのみ）
     * @return {array<string>}
     */
    itfCookieHistoriesCodes() {
      if (!this.itfCookieHistories || !this.itfCookieHistories.fund_id_arr) return [];

      return this.itfCookieHistories.fund_id_arr;
    },
    /**
     * 金融アイテムタブが表示されていれば真
     * @return {Boolean}
     */
    isF11m() {
      return this.current == 'f11m';
    },
    /**
     * 投信タブが表示されていれば真
     * @return {Boolean}
     */
    isITF() {
      return this.current != 'f11m';
    },
  },
  mounted() {
    if (this.f11mCookieHistoryLength === 0) {
      this.isF11mLoaded = true;
    } else {
      this.loadCurrentPrices();
    }
    const dummyElement = document.querySelector('[data-js-id="sideNavigationHistoriesDummy"]');
    if (dummyElement) dummyElement.remove();
  },
  methods: {
    /**
     * 最近閲覧した金融アイテムの現在値オブジェクトの配列をdataへロードする
     * @return {void}
     */
    async loadCurrentPrices() {
      this.f11mHistories = await this.currentPriceResource.get(this.f11mCookieHistoriesCodes);
      this.isF11mLoaded = true;
    },
    /**
     * 最近閲覧した投信の基準価額オブジェクトの配列をdataへロードする
     * @return {void}
     */
    async loadITFBaseValues() {
      if (this.isITFLoaded) return;

      if (this.itfCookieHistories.length === 0) {
        this.isITFLoaded = true;
        return;
      }

      const itfBaseValues = await this.itfBaseValueResource.get(this.itfCookieHistoriesCodes);
      this.itfHistories = itfBaseValues;
      this.isITFLoaded = true;
    },
    /**
     * 株式タブ切り替え
     * @return {void}
     */
    changeToF11m() {
      this.current = 'f11m';
    },
    /**
     * 投資信託タブ切り替え（API呼び出し含む）
     * @return {void}
     */
    changeToITF() {
      this.current = 'ITF';
      this.loadITFBaseValues();
    },
  },
};
</script>
