<template src="./EvaluationComponent.html" />
<script>
/**
 * 銘柄検索 個別銘柄サマリーモーダル 業績評価コンポーネント
 * @module StockSearch/modules/StockSummaryModal/body/evaluation/EvaluationComponent
 */
export default {
  name: 'EvaluationsComponent',
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    showSection() {
      return (
        this.summaryData.settlement &&
        this.summaryData.settlement.evaluation &&
        this.summaryData.settlement.evaluationTexts.length > 0
      );
    },
    /**
     * 画像のパスを返す
     */
    images() {
      const base = 'https://assets.minkabu.jp/images/icon/';
      const name = [
        '',
        'icon_results_thunder_v3.png',
        'icon_results_rain_v3.png',
        'icon_results_cloudy_v3.png',
        'icon_results_partly_cloudy_v3.png',
        'icon_results_sun_v3.png',
      ];
      return this.summaryData.settlement.evaluation ? base + name[this.summaryData.settlement.evaluation] : '';
    },
  },
};
</script>
