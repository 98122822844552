/**
 * 環境変数を扱うクラス
 */
class EnvironmentUtil {
  static isProduction() {
    const hostname = window.location.hostname;
    return hostname === 'minkabu.jp' || hostname === 's.minkabu.jp';
  }
}
export default EnvironmentUtil;
