<template src="./LinkComponent.html" />
<script>
import exportEventLabel from '../../../../../../../utils/exportEventLabel.js';
import UrlGenerateUtil from '../../../../../../../utils/UrlGenerateUtil';
/**
 * 銘柄検索 個別銘柄サマリーモーダル リンクバーコンポーネント
 * @module StockSearch/modules/header/link/LinkComponent
 */
export default {
  name: 'LinkComponent',

  data() {
    return {
      tooltip: false,
    };
  },
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    isFinancialItemFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.summaryData.code);
    },
    eventLabel() {
      return exportEventLabel({
        isUserLoginStatus: true,
        isFinancialItemFavored: this.isFinancialItemFavored,
      });
    },
    /**
     * プレミアム登録とログインのコールバックURLを返す
     */
    callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        return UrlGenerateUtil.idMinkabuUrl({
          pathString: path,
          currentUrl: window.location.href,
          source: 'shoken_minkabu',
          medium: 'modal',
          campaign: '/stock/search',
        });
      };
    },
  },
  methods: {
    async toggle() {
      if (this.isGuest) {
        this.tooltip = !this.tooltip;
        return;
      }
      await this.$store.dispatch('favoriteFinancialItems/toggle', this.summaryData.code);
    },
  },
};
</script>
