<template src="./CampaignBanner.html"></template>
<script>
/**
 * キャンペーンバナーコンポーネント
 * @module Banner/CampaignBanner
 */
export default {
  name: 'CampaignBanner',
  props: {
    /**
     * プレミアムLPへのURL
     * @type {string}
     */
    premiumLpUrl: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * キャンペーンURL
     * @type {string}
     */
    campaignUrl: {
      type: String,
      required: true,
      default: null,
    },
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod: {
      type: Boolean,
      required: true,
    },
    /** キャンペーン画像
     * @type {string}
     */
    customImg: {
      type: String,
      required: false,
      default: null,
    },
    /** キャンペーン画像の横幅(px指定)
     * @type {string}
     */
    customImgWidth: {
      type: String,
      required: false,
      default: null,
    },
    /** キャンペーン画像の縦幅(px指定)
     * @type {string}
     */
    customImgHeight: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    /**
     * キャンペーンメッセージを返す
     * @return {String}
     */
    campaignMessage() {
      return this.premiumLpUrl
        ? '＼ 会員限定！お得なキャンペーン開催中 ／'
        : '＼ お得にはじめられるキャンペーン開催中！ ／';
    },
    /**
     * 特に小さい画面かどうか(iPhone5など、iPhone 6/7/8より小さいサイズを想定)
     * @return {boolean}
     */
    isSmallScreen() {
      return window.innerWidth < 375;
    },
    /**
     * キャンペーンバナーの画像URLを返す
     * @return {string}
     */
    campaignImgSrc() {
      if (this.customImg) {
        return this.customImg;
      }
      return this.isSmallScreen
        ? 'https://assets.minkabu.jp/images/banner/asset_formation_supports_2024_summer_265x84.png'
        : 'https://assets.minkabu.jp/images/banner/asset_formation_supports_2024_summer_307x84.png';
    },
    /**
     * キャンペーンバナーの画像のwidthを返す
     * @return {string}
     */
    campaignImgWidth() {
      if (this.customImgWidth) {
        return this.customImgWidth;
      }
      return this.isSmallScreen ? '265px' : '307px';
    },
    /**
     * キャンペーンバナーの画像のheightを返す
     * @return {string}
     */
    campaignImgHeight() {
      if (this.customImgHeight) {
        return this.customImgHeight;
      }
      return '84px';
    },
  },
};
</script>
