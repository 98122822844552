<template src="./SideNavigationFinancialItemHistories.html" />

<script>
/**
 * 右ナビ 金融アイテム閲覧履歴コンポーネント
 * @vue-data {Array<Object>} histories 金融アイテム閲覧履歴オブジェクト
 * @module modules/SideNavigation/SideNavigationFinancialItemHistories
 */
export default {
  name: 'SideNavigationFinancialItemHistories',
  props: {
    histories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * 閲覧履歴が存在すれば真
     * @return {Boolean}
     */
    hasHistory() {
      if (!this.histories) return false;

      return this.histories.length > 0;
    },
  },
};
</script>
