<template src="./IPOItem.html"></template>
<script>
import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';

/**
 * 上場済みIPO表示コンポーネント
 *
 * @vue-components {FavoriteButtonHeart} favoriteButtonHeart お気に入りボタン
 * @module pages/IPOItems/IPOItem
 */
export default {
  name: 'IPOItem',
  components: {
    favoriteButtonHeart: FavoriteButtonHeart,
  },
  props: {
    /**
     * IPO情報
     * @vue-props {Object}
     */
    ipoItem: {
      type: Object,
      required: true,
    },
    /**
     * List表示なら真
     * @vue-props {Boolean}
     */
    isList: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    /**
     * 詳細表示なら真
     * @return {Boolean}
     */
    isDetail() {
      return !this.isList;
    },
  },
  methods: {
    /**
     * モーダルを表示する
     * @return {void}
     */
    showModal() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
</script>
