/**
 * シグナルのパターンごとにクラス名とテキストを返す
 * @param {string} title
 * @return {Array}
 */
const generateSignalPatternList = (title) => {
  const tgt = title.split('_to_');
  if (tgt.length !== 2) {
    return false;
  }
  let res = [];
  for (let item of tgt) {
    if (item === 'none') {
      res.push({ classObj: 'bg-minkabuPicksNone', text: '対象外' });
    } else if (item === 'buy') {
      res.push({ classObj: 'bg-minkabuPicksBuy', text: '買い' });
    } else if (item === 'sell') {
      res.push({ classObj: 'bg-minkabuPicksSell', text: '売り' });
    } else {
      return false;
    }
  }
  return res;
};

export default generateSignalPatternList;
