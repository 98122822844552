<template src="./ReportButtonBox.html"></template>
<script>
/**
 * レポート銘柄ボックスボタン コンポーネント
 * @vue-prop {string} financialItemCode 銘柄コード
 * @vue-computed {boolean} isFinancialItemFavored レポート銘柄登録している銘柄なら真
 * @module Common/ReportButtonBox
 */
export default {
  name: 'ReportButtonBox',
  props: {
    financialItemCode: {
      type: String,
      required: true
    },
  },
  computed: {
    isFinancialItemReported() {
      return this.$store.getters['reportFinancialItems/isReported'](this.financialItemCode);
    },
    toggleCSSKlass() {
      return (this.isFinancialItemReported ? 'is_on' : 'is_off');
    },
    toggleGoogleTag() {
      return (this.isFinancialItemReported ? 'report_remove' : 'report_add');
    }
  },
  async created() {
    await this.$store.dispatch('reportFinancialItems/init');
  },
  methods: {
    /**
     * 1. 金融アイテムをレポート銘柄登録または削除
     * 2. 1が完了したらお知らせボックスを表示(5秒間)
     */
    async toggle() {
      await this.$store.dispatch('reportFinancialItems/toggle', this.financialItemCode);
      await this.$store.dispatch('notificationBox/show', this.$store.state.reportFinancialItems.notificationMessage);
    }
  }
};
</script>
