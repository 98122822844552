import YutaiRankingListMini from './YutaiRankingListMini.vue';
import NotificationBox from '../Common/NotificationBox.vue';
import FavoriteButtonWithCount from '../Common/FavoriteButtonWithCount.vue';
import BaseModal from '../Common/BaseModal.vue';
import MkApolloProvider from '../../utils/MkApolloProvider';
import FavoriteSignUpModal from '../Modal/FavoriteSignUpModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 株主優待ページ用ルートコンポーネント
 * 右ナビとFavorite状態を同期させたいので大きく領域を確保する予定
 * @vue-components {yutaiRankingListMini} yutaiRankingListMini ページ内に部分的に表示するランキング
 * @vue-components {notificationBox} notificationBox お知らせBox
 * @vue-components {FavoriteButtonWithCount} FavoriteButtonWithCount お気に入り数付きお気に入りボタン
 * @vue-components {BaseModal} BaseModal モーダル表示
 * @property {boolean} showModal モーダルを表示するかどうか
 * @property {boolean} isTailwindPage Tailwindでスタイルを適用しているページかどうか
 * @property {array} classArrayTemplate divタグに付けるclassを持つ配列のベース
 * @module Yutai/YutaiApp
 */
const YutaiApp = {
  el: '#v-yutai-app',
  apolloProvider: MkApolloProvider,
  data() {
    return {
      showModal: false,
      isTailwindPage: false,
      classArrayTemplate: [
        [],
        ['yutai_card_favorite_wrap', 'ly_flex bgwt'],
        ['yutai_card_favorite_button', 'tac'],
        ['yutai_favorite_icon'],
        ['fsize_sss', 'mt15'],
        [],
        ['fsize_ss'],
      ],
    };
  },
  components: {
    yutaiRankingListMini: YutaiRankingListMini,
    notificationBox: NotificationBox,
    FavoriteButtonWithCount: FavoriteButtonWithCount,
    favoriteSignUpModal: FavoriteSignUpModal,
    baseModal: BaseModal,
    CampaignBanner,
  },
  methods: {
    /**
     * モーダル表示イベント
     * @returns {boolean} closeイベントが発火するとshowModalはfalseとなる
     */
    showModalEvent: function () {
      this.showModal = true;
    },
  },
};
export default YutaiApp;
