import PeerCompaniesInfoComponent from './PeerCompaniesInfo.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';

/*
 * 個別銘柄 他社比較ルートコンポーネント
 */
const PeerCompaniesInfo = {
  el: '#v-peer-companies-info',
  apolloProvider: MkApolloProvider,
  data: { isHide: false },
  components: {
    PeerCompaniesInfo: PeerCompaniesInfoComponent,
    FavoriteSignUpModal,
  },
  methods: {
    switchHideFlg(val) {
      this.isHide = !val;
    },
  },
};

export default PeerCompaniesInfo;
