var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"fixed top-0 left-0 z-30 h-screen w-screen bg-black/60",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal()}}},[_c('div',{staticClass:"fixed",class:{
      'w-[750px] h-5/6 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2': _vm.isPc,
      'ui-stock-search-summary-body w-full h-[90%] bottom-0 left-0 flex flex-col': _vm.isSp,
    }},[_c('button',{staticClass:"absolute top-0 right-0 -translate-y-full p-2",on:{"click":function($event){return _vm.closeModal()}}},[_c('i',{staticClass:"fa-regular fa-xmark text-3xl text-white"})]),_vm._v(" "),_c('div',{staticClass:"relative flex h-full flex-col bg-white shadow",class:{
        'w-[750px] border-2 border-white z-10 relative rounded-lg  overflow-hidden': _vm.isPc,
        'w-full rounded-t-lg overflow-y-scroll': _vm.isSp
      }},[(!_vm.summaryError)?_c('modal-header'):_vm._e(),_vm._v(" "),(!_vm.summaryError)?_c('modal-body'):_c('div',{staticClass:"absolute top-1/2 w-full -translate-y-1/2 text-center text-neutral-500"},[_c('p',[_c('i',{staticClass:"fa-solid fa-triangle-exclamation"})]),_vm._v(" "),_c('p',[_vm._v("読み込みに失敗しました。"),_c('br'),_vm._v("しばらくしてからもう一度お試しください")]),_vm._v(" "),_c('div',{staticClass:"mt-4 flex flex-col items-center text-xs"},[_c('button',{staticClass:"payment-profiles-link block w-1/2 rounded-lg bg-blue-700 p-2 font-bold text-white shadow",class:{'hover:opacity-90': _vm.isPc},on:{"click":function($event){return _vm.getSummary()}}},[_vm._v("\n            再読み込み\n          ")])])])],1),_vm._v(" "),(_vm.isSp)?_c('div',{staticClass:"truncate border-t border-slate-300 bg-white py-2 px-4 text-center text-xs"},[_vm._v("\n      "+_vm._s(_vm.summaryData.code)+" "+_vm._s(_vm.summaryData.name)+"\n    ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"flex justify-between",class:{
        'absolute left-1/2 top-1/2 w-[850px] -translate-x-1/2 -translate-y-1/2 text-2xl text-white': _vm.isPc,
        'bg-white border-t border-slate-300 text-lg': _vm.isSp
      }},[_c('li',{staticClass:"flex items-center p-4 pr-8",class:_vm.hasPrev ? 'cursor-pointer' : 'opacity-40',attrs:{"data-gtm-click":"screening_summary_browsing_more"},on:{"click":_vm.prev}},[_c('i',{staticClass:"fa-solid fa-angle-left mr-1"}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSp),expression:"isSp"}],staticClass:"text-sm"},[_vm._v("前の銘柄")])]),_vm._v(" "),_c('li',{staticClass:"flex items-center p-4 pl-8",class:_vm.hasNext ? 'cursor-pointer' : 'opacity-40',attrs:{"data-gtm-click":"screening_summary_browsing_more"},on:{"click":_vm.next}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSp),expression:"isSp"}],staticClass:"text-sm"},[_vm._v("次の銘柄")]),_vm._v(" "),_c('i',{staticClass:"fa-solid fa-angle-right ml-1"})])])])])}
var staticRenderFns = []
export { render, staticRenderFns }