import FavoriteButtonHeart from '../../Common/FavoriteButtonHeart.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';

const FinancialItemList = {
  el: '#v-financial_item_list',
  components: {
    FavoriteButtonHeart,
    FavoriteSignUpModal
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    openSignUpModal() {
      this.$store.commit('favoriteSignUpModal/show');
    }
  },
};
export default FinancialItemList;

