import TailwindPaginationStore from '../Pagination/TailwindPaginationStore';
import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';

/**
 * IPO一覧コンテンツルートストア
 *
 * @module IPOItems/IPOItemsStore
 */
const IPOItemsStore = {
  modules: {
    tailwindPagination: TailwindPaginationStore,
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
  },
};
export default IPOItemsStore;
