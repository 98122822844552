<template src="./FinancialItemRankingPayLockLink.html"></template>
<script>
/**
 * 株式ランキングPayLockコンポーネント
 * @module FinancialItemRanking/FinancialItemRankingPayLock
 */
export default {
  name: 'FinancialItemRankingPayLock',
  methods: {
    /**
     * モーダルの表示
     */
    async open(name) {
      this.$store.commit('show', name);
    },
  },
};
</script>
