import BaseResource from './BaseResource';
import axiosJsonpAdapter from 'axios-jsonp';
import ITFBaseValue from '../models/ITFBaseValue';
import EnvironmentUtil from '../utils/EnvironmentUtil';

/**
 * 投信基準価額API
 */
class ITFBaseValueResource extends BaseResource {
  /*
   * 現在値のリストを取得する
   * @param {array<String>}
   * @return {array<CurrentPrice>}
   */
  async get(codes) {
    return await this.client
      .get(`https://${this._hostname()}/fund/get_display_funds`, {
        params: { fund_id_arr: codes.join(',') },
        adapter: axiosJsonpAdapter,
      })
      .then((response) => this._toBaseValues(response.data))
      .catch(() => 'error');
  }

  _toBaseValues(data) {
    if (data.fund_data.length === 0) {
      return [];
    }

    return data.fund_data.map((item) => this._toBaseValue(item));
  }

  _toBaseValue(item) {
    const splitedFundId = item.fund_id.split(',');
    const fundCode = splitedFundId[0];
    const fundName = splitedFundId[1];

    const splitedFundCompare = item.fund_compare.split(',');
    const change = parseInt(splitedFundCompare[0]);

    const price = item.fund_price;

    return new ITFBaseValue({ fundCode, fundName, price, change });
  }

  _hostname() {
    if (EnvironmentUtil.isProduction) {
      return 'itf.minkabu.jp';
    } else {
      return 'stg.itf.minkabu.jp';
    }
  }
}
export default ITFBaseValueResource;
