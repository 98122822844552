import MkApolloProvider from '../../../utils/MkApolloProvider';
import CorporateActionsWeeklyCalendarVue from './CorporateActionsWeeklyCalendar.vue';

/**
 * トップページのコーポレートアクション導線用カレンダーのルートコンポーネント
 * @module CorporateActions/CorporateActionsWeeklyCalendar
 */
const CorporateActionsWeeklyCalendar = {
  el: '#v-corporate-actions-weekly-calendar',
  apolloProvider: MkApolloProvider,
  components: {
    CorporateActionsWeeklyCalendar: CorporateActionsWeeklyCalendarVue,
  },
};

export default CorporateActionsWeeklyCalendar;
