/**
 * お気に入りボタンのGTM送信用のイベントラベル名を返す
 * @param {{isUserLoginStatus: bool, isFinancialItemFavored: bool}} obj
 * @param {Boolean} obj.isUserLoginStatus - ログインしていれば真
 * @param {Boolean} obj.isFinancialItemFavored - 銘柄をお気に入り登録していれば真
 * @return {String} イベントラベル名
 */
const exportEventLabel = (obj) => {
  if (obj.isUserLoginStatus) {
    if (obj.isFinancialItemFavored) {
      return 'favorite_remove';
    } else {
      return 'favorite_add';
    }
  } else {
    return 'show_modal';
  }
};

export default exportEventLabel;
