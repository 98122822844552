<template src="./JamChart.html" />
<script>
import JamChartLumpResource from '../../../resources/JamChartLumpResource.js';
import { Chart } from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsIgnoreWhenScaling from './HighchartsIgnoreWhenScaling.js';
import NumberFormatMixin from '../../../mixins/NumberFormatMixin.js';
import dayjs from 'dayjs';
import PickTooltip from './PickTooltip.vue';
import NewsTooltip from './NewsTooltip.vue';
import StockTooltip from './StockTooltip.vue';

stockInit(Highcharts);
HighchartsMore(Highcharts);
HighchartsIgnoreWhenScaling(Highcharts);
Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '期間 |',
  },
});

/**
 * JamChart
 *
 * @vue-components {Chart} highcharts Highchartsモジュール
 * @vue-components {PickTooltip} pickTooltip Pickのツールチップ
 * @vue-components {NewsTooltip} pickTooltip ニュースのツールチップ
 * @vue-props {String} code 金融アイテムコード
 * @vue-data {Object} lumpData JamChart表示データ
 * @vue-data {Object} visiblePickTooltip Pickのツールチップが表示されていれば真
 * @vue-data {Object} currentPick 現在ツールチップが表示されているPickの情報
 * @module pages/StockAnalysis/JamChart
 */
export default {
  name: 'JamChart',
  components: {
    highcharts: Chart,
    pickTooltip: PickTooltip,
    newsTooltip: NewsTooltip,
    stockTooltip: StockTooltip,
  },
  props: {
    code: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      extremes: { min: 0, max: 0 },
      lumpData: {},
      currentPick: {},
      currentNewsGroup: {},
      currentStockGroup: {},
    };
  },
  computed: {
    /**
     * チャートの高さ[%]
     * @return {Integer}
     */
    topChartPer() {
      return 70;
    },
    /**
     * 出来高チャートの余白[%]
     * @return {Integer}
     */
    marginChartPer() {
      return 5;
    },
    /**
     * 出来高チャートの高さ[%]
     * @return {Integer}
     */
    bottomChartPer() {
      return 25;
    },
    /**
     * チャートの色情報
     * @return {Object}
     */
    color() {
      return {
        close: { line: '#4573a7' },
        stock: { label: '#666' },
        mkPrice: { line: '#fc6296' },
        theoreticPrice: { line: '#ffc107' },
        pickPrice: { line: '#c273fa' },
        n225: { line: '#999999' },
        usdjpy: { line: '#0081cc' },
        pick: {
          bubble: {
            buy: 'rgb(242,129,6)',
            sell: 'rgb(39,174,96)',
          },
        },
        volume: {
          label: '#666',
          bar: '#4572a7',
        },
        news: {
          flag: '#999',
          fillFlag: '#f2f2f2',
          fillHoverFlag: '#efefef',
          text: '#444',
        },
      };
    },
    /**
     * Pickのツールチップが表示されている時に真
     * @return {Boolean}
     */
    isPickTooltip() {
      return this.$store.getters['visiblePickTooltip'];
    },
    /**
     * ニュースのツールチップが表示されている時に真
     * @return {Boolean}
     */
    isNewsTooltip() {
      return this.$store.getters['visibleNewsTooltip'];
    },
    /**
     * 株価のツールチップが表示されている時に真
     * @return {Boolean}
     */
    isStockTooltip() {
      return this.$store.getters['visibleStockTooltip'];
    },
    /**
     * 描画設定
     * @return {Object}
     */
    plotOptions() {
      return {
        shadow: false,
        series: {
          animation: false,
          stickyTracking: false,
          dataGrouping: { enabled: false },
          marker: { symbol: 'circle' },
        },
        line: {
          lineWidth: 1,
          states: {
            inactive: { enabled: false },
          },
        },
        area: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, this.color.close.line],
              [1, Highcharts.Color(this.color.close.line).setOpacity(0).get('rgba')],
            ],
          },
          marker: { radius: 2 },
          lineWidth: 2,
          states: {
            hover: { lineWidth: 3 },
            inactive: { enabled: false },
          },
          threshold: null,
        },
        bubble: {
          minSize: 3,
          maxSize: 11,
          marker: { lineWidth: 0 },
          point: {
            events: {
              mouseOver: this.pickMouseOver,
              mouseOut: this.pickMouseOut,
            },
          },
          states: {
            inactive: { enabled: false, opacity: 1 },
          },
        },
        flags: {
          point: {
            events: {
              mouseOver: this.newsMouseOver,
              mouseOut: this.newsMouseOut,
            },
          },
          states: {
            inactive: { enabled: false },
          },
        },
      };
    },
    /**
     * チャート表示項目の設定
     * @return {Array<Object>}
     */
    getXAxisVisibleSeries() {
      return [
        {
          id: 'closes',
          name: '株価',
          type: 'area',
          softThreshold: false,
          data: this.chartData.stockCloses,
          color: this.color.close.line,
          lineWidth: 1,
          showInLegend: false,
          yAxis: 0,
        },
        {
          id: 'picks_buy',
          name: '買い予想',
          type: 'bubble',
          data: this.chartData.stockBuyPicks,
          color: this.color.pick.bubble.buy,
          zIndex: 2,
          yAxis: 0,
          ignoreWhenScaling: true,
        },
        {
          id: 'picks_sell',
          name: '売り予想',
          type: 'bubble',
          data: this.chartData.stockSellPicks,
          color: this.color.pick.bubble.sell,
          zIndex: 1,
          yAxis: 0,
          ignoreWhenScaling: true,
        },
        {
          id: 'n225_closes',
          name: '日経平均',
          type: 'line',
          data: this.chartData.n225Prices,
          color: this.color.n225.line,
          yAxis: 1,
        },
        {
          id: 'mk_prices',
          name: '目標株価',
          type: 'line',
          data: this.chartData.stockMkPrices,
          color: this.color.mkPrice.line,
          yAxis: 0,
        },
        {
          id: 'usdjpy_closes',
          name: 'ドル円',
          type: 'line',
          data: this.chartData.usdjpyPrices,
          color: this.color.usdjpy.line,
          visible: false,
          yAxis: 2,
        },
        {
          id: 'theoretic_prices',
          name: '理論株価',
          type: 'line',
          data: this.chartData.stockTheoreticPrices,
          color: this.color.theoreticPrice.line,
          visible: false,
          yAxis: 0,
        },
        {
          id: 'picks_prices',
          name: '予想株価',
          type: 'line',
          data: this.chartData.stockPicksPrices,
          color: this.color.pickPrice.line,
          visible: false,
          yAxis: 0,
        },
        {
          id: 'news',
          name: 'ニュース',
          type: 'flags',
          data: this.newsData,
          color: this.color.news.flag,
          fillColor: this.color.news.fillFlag,
          style: { color: this.color.news.text },
          y: -40,
          onSeries: 'closes',
          yAxis: 0,
        },
        {
          id: 'volumes',
          yAxis: 3,
          name: '出来高',
          type: 'column',
          data: this.chartData.stockVolumes,
          color: this.color.volume.bar,
          endOnTick: false,
          showFirstLabel: false,
          showInLegend: false,
        },
      ];
    },
    /**
     * チャート表示データ
     * @return {Object}
     */
    chartData() {
      let stockCloses = [];
      let stockMkPrices = [];
      let stockNews = [];
      let stockBuyPicks = [];
      let stockSellPicks = [];
      let stockPicksPrices = [];
      let stockTheoreticPrices = [];
      let stockVolumes = [];
      let n225Prices = [];
      let usdjpyPrices = [];

      if (this.lumpData && this.lumpData.dates) {
        this.lumpData.dates.forEach((dateString, idx) => {
          const date = new Date(dateString);
          const ms = date.getTime();

          if (this.lumpData.n225 && this.lumpData.n225.closes[idx]) {
            n225Prices.push([ms, parseFloat(this.lumpData.n225.closes[idx])]);
          }

          if (this.lumpData.usdjpy && this.lumpData.usdjpy.closes[idx]) {
            usdjpyPrices.push([ms, parseFloat(this.lumpData.usdjpy.closes[idx])]);
          }

          if (!this.lumpData.stock) return;
          if (!this.lumpData.stock.closes) return; // 上場前及び上場日当日にstockは存在するがstock.closesがない場合がある

          if (this.lumpData.stock.closes[idx]) {
            stockCloses.push([ms, parseFloat(this.lumpData.stock.closes[idx])]);
          }

          if (this.lumpData.stock.mk_prices[idx]) {
            stockMkPrices.push([ms, parseFloat(this.lumpData.stock.mk_prices[idx])]);
          }

          //@note KSISU1028等、ニュース自体が配信されていない場合の考慮
          if (this.lumpData.stock.news && this.lumpData.stock.news[idx]) {
            this.lumpData.stock.news[idx].forEach((news) => {
              stockNews.push({
                x: ms,
                title: news.title,
                news_id: news.id,
                page_view: news.page_view,
                source_id: news.source_id,
                time: dayjs(news.published_at).valueOf(),
              });
            });
          }

          if (this.lumpData.stock.picks[idx]) {
            this.lumpData.stock.picks[idx].forEach((pick) => {
              if (
                pick.target_price < this.lumpData.stock.closes[idx] * 3 &&
                pick.target_price > this.lumpData.stock.closes[idx] / 3
              ) {
                const plotData = {
                  x: ms,
                  y: parseFloat(pick.target_price),
                  stockCode: this.code,
                  pickId: pick.id,
                  userId: pick.user.id,
                  title: pick.title,
                  date: dayjs(dateString).format('MM/DD'),
                  period: pick.period,
                  reason: pick.reason,
                  position: pick.position,
                  nickname: pick.user.nickname,
                  profileImageUrl: pick.user.profile_image_url,
                };

                pick.position === 0 ? stockBuyPicks.push(plotData) : stockSellPicks.push(plotData);
              }
            });
          }

          if (this.lumpData.stock.picks_prices[idx]) {
            stockPicksPrices.push([ms, parseFloat(this.lumpData.stock.picks_prices[idx])]);
          }

          if (this.lumpData.stock.theoretic_prices[idx]) {
            stockTheoreticPrices.push([ms, parseFloat(this.lumpData.stock.theoretic_prices[idx])]);
          }

          if (this.lumpData.stock.volumes[idx]) {
            stockVolumes.push([ms, this.lumpData.stock.volumes[idx]]);
          }
        });
      }

      return {
        stockCloses,
        stockMkPrices,
        stockNews,
        stockBuyPicks,
        stockSellPicks,
        stockPicksPrices,
        stockTheoreticPrices,
        stockVolumes,
        n225Prices,
        usdjpyPrices,
      };
    },
    /**
     * チャート設定
     * @return {Object}
     */
    chartOptions() {
      const thisVue = this;
      return {
        chart: {
          animation: false,
          alignTicks: false,
          marginTop: 32,
          spacingTop: 0,
          zoomType: 'x',
          height: '418px',
          events: {
            redraw: this.redrawCallback,
          },
        },
        legend: {
          enabled: true,
          floating: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: 3,
          itemWidth: 90,
          itemStyle: {
            color: '#333',
            fontWeight: 'normal',
          },
          x: 22,
          y: -31,
          width: 400,
        },
        rangeSelector: {
          buttonSpacing: 5,
          enabled: true,
          inputEnabled: false,
          buttonPosition: { x: 206, y: -20 },
          buttonTheme: {
            stroke: 'none',
            r: 3,
            width: 32,
            height: 20,
            padding: 1,
            fill: '#fff',
            states: {
              hover: {
                fill: '#545991',
                style: {
                  color: '#fff',
                  fontWeight: 'bold',
                },
              },
              select: {
                fill: '#545991',
                style: {
                  color: '#fff',
                  fontWeight: 'bold',
                },
              },
            },
          },
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1ヶ月',
            },
            {
              type: 'month',
              count: 3,
              text: '3ヶ月',
            },
            {
              type: 'month',
              count: 6,
              text: '6ヶ月',
            },
            {
              type: 'month',
              count: 12,
              text: '1年',
            },
          ],
        },
        credits: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        plotOptions: this.plotOptions,
        tooltip: {
          shared: true,
          snap: 50,
          hideDelay: 0,
          formatter: function () {
            if (this.points) {
              thisVue.stockMouseOver(this);
            }
            return false;
          },
        },
        series: this.getXAxisVisibleSeries,
        xAxis: {
          dateTimeLabelFormats: {
            day: '%y/%m/%e',
            week: '%y/%m/%e',
            month: '%Y/%m',
            year: '(%Y)',
          },
        },
        yAxis: [
          {
            maxPadding: 0.15,
            startOnTick: false,
            endOnTick: false,
            title: {
              text: '株価',
              style: {
                color: this.color.stock.label,
              },
            },
            labels: {
              align: 'left',
              x: 4,
              formatter: function () {
                return NumberFormatMixin.methods.numFormat(this.value, 0);
              },
            },
            opposite: false,
            lineWidth: 2,
            height: `${this.topChartPer}%`,
          },
          {
            gridLineWidth: 0,
            title: { text: '' },
            labels: { enabled: false },
            startOnTick: false,
            endOnTick: false,
            height: `${this.topChartPer}%`,
          },
          {
            gridLineWidth: 0,
            title: { text: '' },
            labels: { enabled: false },
            startOnTick: false,
            endOnTick: false,
            height: `${this.topChartPer}%`,
          },
          {
            title: {
              text: '出来高(株)',
              style: {
                color: this.color.volume.label,
              },
            },
            labels: {
              align: 'left',
              x: 4,
            },
            showFirstLabel: false,
            lineWidth: 2,
            offset: 0,
            top: `${this.topChartPer + this.marginChartPer}%`,
            height: `${this.bottomChartPer}%`,
            opposite: false,
            min: 0,
          },
        ],
      };
    },
    /**
     * ニュースの期間設定(flag単位)
     * @return {Integer}
     */
    groupings() {
      const span = this.extremes.max - this.extremes.min;
      if (span > 100 * 24 * 60 * 60 * 1000) {
        return 21 * 24 * 60 * 60 * 1000;
      } else {
        return 2 * 24 * 60 * 60 * 1000;
      }
    },
    /**
     * ツールチップに表示するnewsデータ
     * @return {Array}
     */
    newsData() {
      let newsMap = {};
      this.chartData.stockNews.forEach((e) => {
        const t = e.x - (e.x % this.groupings);
        if (!newsMap[t]) {
          newsMap[t] = [];
        }
        newsMap[t].push(e);
      });
      let data = [];
      for (let t in newsMap) {
        data.push({ x: t, title: newsMap[t].length, news: newsMap[t] });
      }

      return data;
    },
  },
  mounted() {
    this.loadJamChartLumpData();
    const dummyElement = document.querySelector('[data-js-id="stock-analysis-dummy"]');
    if (dummyElement) dummyElement.remove();
  },
  methods: {
    /**
     * JamChart表示用データを取得する
     * @return {void}
     */
    async loadJamChartLumpData() {
      const resource = new JamChartLumpResource();
      this.lumpData = await resource.get(this.code);
    },
    /**
     * Highcharts再描画時のコールバック
     */
    redrawCallback() {
      const extremes = this.$refs.jamChart.chart.xAxis[0].getExtremes();
      this.extremes.min = extremes.min;
      this.extremes.max = extremes.max;
    },
    /**
     * Pickのプロットにマウスオーバーした時
     * @return {void}
     */
    pickMouseOver(event) {
      this.$store.commit('showPickTooltip');
      this.currentPick = event.target;
    },
    /**
     * Pickのプロットからマウスアウトした時
     * @return {void}
     */
    pickMouseOut() {
      this.$store.commit('hidePickTooltip');
    },
    /**
     * ニュースのプロットにマウスオーバーした時
     */
    newsMouseOver(e) {
      this.$store.commit('showNewsTooltip');
      this.currentNewsGroup = e.target;
    },
    /**
     * ニュースのプロットからマウスアウトした時
     * @return {void}
     */
    newsMouseOut() {
      this.$store.commit('hideNewsTooltip');
    },
    /**
     * 株価のツールチップからマウスオーバーした時
     * @return {void}
     */
    stockMouseOver(e) {
      this.currentStockGroup = e;
      this.$store.commit('showStockTooltip');
    },
    /**
     * マウス座標（ツールチップ表示/非表示用）
     * @return {void}
     */
    mouseMove(e) {
      const x = e.chartX;
      const y = e.chartY;
      if (34 < x && x < 638 && 66 < y && y < 333) {
        this.$store.commit('mouseCordinates', { x, y });
      } else {
        this.$store.commit('hideStockTooltip');
      }
    },
  },
};
</script>
