<template src="./FavoriteFinancialItems.html" />

<script>
import FavoriteFinancialItemsPagination from './FavoriteFinancialItemsPagination.vue';
import FavoriteFinancialItemsSearch from './FavoriteFinancialItemsSearch.vue';
import FavoriteFinancialItemsTable from './FavoriteFinancialItemsTable.vue';
import { generateUrlParams, replaceUrlParams } from './utils/urlParams.js';
import { loadingPage } from './utils/loadingPage.js';
/**
 * お気に入り銘柄一覧コンポーネント
 * @vue-components {FavoriteFinancialItemsPagination} FavoriteFinancialItemsPagination ページネーション
 * @vue-components {FavoriteFinancialItemsSearch} FavoriteFinancialItemsSearch 検索
 * @vue-components {FavoriteFinancialItemsTable} FavoriteFinancialItemsTable テーブル
 * @module Mypage/FavoriteFinancialItems
 */
export default {
  name: 'FavoriteFinancialItems',
  components: {
    FavoriteFinancialItemsPagination,
    FavoriteFinancialItemsSearch,
    FavoriteFinancialItemsTable,
  },
  computed: {
    /**
     * 現在ページ
     * @return {boolean}
     */
    pageCurrent() {
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current;
    },
    search() {
      return this.$store.getters['favoriteFinancialItemDetails/search'];
    },
    sort() {
      return this.$store.getters['favoriteFinancialItemDetails/sort'];
    },
  },
  mounted() {
    // ブラウザのページバック・プレビューイベント
    window.addEventListener('popstate', this.setCurrentPage);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.setCurrentPage);
  },
  async created() {
    await loadingPage({
      vm: this,
      scroll: true,
      callback: async () => {
        await this.$store.dispatch('favoriteFinancialItemDetails/getFavoriteFinancialItems');
        this.setCurrentPage();
        await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
        await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
        await this.$store.dispatch('favoriteFinancialItemDetails/changePage');
        replaceUrlParams(generateUrlParams({ page: this.pageCurrent, search: this.search, sort: this.sort }));
      },
    });
  },
  methods: {
    /**
     * クエリパラメタから表示するお気に入り銘柄を設定する
     */
    async setCurrentPage() {
      const searchParams = new URLSearchParams(window.location.search);
      let totalPages = this.$store.getters['favoriteFinancialItemDetails/totalPages'];
      // 検索
      if (searchParams.has('keyword')) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchKeyword', searchParams.get('keyword'));
      }
      if (searchParams.has('news')) {
        this.$store.commit('favoriteFinancialItemDetails/setSearchNews', Number(searchParams.get('news')));
      }
      if (searchParams.has('analysisRating')) {
        this.$store.commit(
          'favoriteFinancialItemDetails/setSearchAnalysisRating',
          searchParams
            .get('analysisRating')
            .split('_')
            .map((v) => Number(v))
        );
      }
      if (searchParams.has('fiscalEvaluation')) {
        this.$store.commit(
          'favoriteFinancialItemDetails/setSearchFiscalEvaluation',
          searchParams
            .get('fiscalEvaluation')
            .split('_')
            .map((v) => Number(v))
        );
      }
      // ソート
      if (searchParams.has('key')) {
        this.$store.commit('favoriteFinancialItemDetails/setSortKey', searchParams.get('key'));
      }
      if (searchParams.has('asc')) {
        this.$store.commit(
          'favoriteFinancialItemDetails/setSortAsc',
          JSON.parse(searchParams.get('asc').toLowerCase())
        );
      }
      if (this.isSp && searchParams.has('key') && searchParams.has('asc')) {
        this.$store.commit('favoriteFinancialItemDetails/setSortKey', searchParams.get('key'));
        this.$store.commit(
          'favoriteFinancialItemDetails/setSortAsc',
          JSON.parse(searchParams.get('asc').toLowerCase())
        );
        this.$store.commit(
          'favoriteFinancialItemDetails/setSortSpSelected',
          `${searchParams.get('key')}-${searchParams.get('asc')}`
        );
      }
      // アコーディオン
      if (searchParams.has('open')) {
        const open = searchParams.get('open').split('_');
        let res = [];
        // 開いている銘柄の情報を詳細APIから取得
        for (let item of open) {
          if (!(item in this.$store.getters['favoriteFinancialItemDetails/details'])) {
            res.push(this.$store.dispatch('favoriteFinancialItemDetails/getDetail', item));
          }
        }
        await Promise.all(res);
        this.$store.commit('favoriteFinancialItemDetails/setAccordion', open);
      }

      // 検索・ソートにマッチするデータを作る
      await this.$store.dispatch('favoriteFinancialItemDetails/filteringItem');
      await this.$store.dispatch('favoriteFinancialItemDetails/sortItem');
      totalPages = this.$store.getters['favoriteFinancialItemDetails/totalPages'];
      // ページ
      let query = Number(searchParams.get('page'));
      if (!Number.isInteger(query) || query < 1) {
        query = 1;
      } else if (query > totalPages) {
        query = totalPages;
      }
      this.$store.commit('favoriteFinancialItemDetails/setPagesCurrent', query);
    },
  },
};
</script>
