import BaseResource from './BaseResource';
import StockYutaiNews from '../models/StockYutaiNews';

/*
 *銘柄、優待関連ニュースAPI
 * */
class StockYutaiNewsResource extends BaseResource {
  /*
   * 優待関連ニュース取得APIをコールして、優待関連ニュース情報取得
   * @param {Object} option リクエストパラメータ
   * @return Array<StockYutaiNews> ニュース記事
   */
  async get(option) {
    return await this._getStockYutaiNews(`/stock/${option.params.stock_code}/news/search_for_yutai`, option);
  }

  async _getStockYutaiNews(path, option) {
    const response = await this._clientGet(path, option);
    return this._toStockYutaiNews(response.data);
  }

  _clientGet(path, option) {
    return this.client.get(path, option);
  }

  _toStockYutaiNews(items) {
    if (items.length === 0) {
      return [];
    }

    const articles = items.articles.map(item => new StockYutaiNews(item));
    const stockYutaiNewsItems = {};
    stockYutaiNewsItems.articles = articles;
    stockYutaiNewsItems.maxPage = items.total_page;

    return stockYutaiNewsItems;
  }
}
export default StockYutaiNewsResource; 
