import YutaiSearchYieldRangeSlider from './YutaiSearchYieldRangeSlider.vue';
import YutaiSearchMinimumPurchasePriceRangeSlider from './YutaiSearchMinimumPurchasePriceRangeSlider.vue';
import YutaiSearchCheckboxes from './YutaiSearchCheckboxes.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 検索ボックス表示のルートコンポーネント
 * @module Yutai/YutaiSearchBox
 */
const YutaiSearchBox = {
  el: '#v-yutai-search-box',
  components: {
    yutaiSearchYieldRangeSlider: YutaiSearchYieldRangeSlider,
    yutaiSearchMinimumPurchasePriceRangeSlider: YutaiSearchMinimumPurchasePriceRangeSlider,
    yutaiSearchCheckboxes: YutaiSearchCheckboxes,
    CampaignBanner,
  },
};
export default YutaiSearchBox;
