import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import NotificationBoxStore from '../Common/NotificationBoxStore';
import BaseLoading from '../Base/BaseLoading';

/**
 * @module ShareholderBenefits/ShareholderBenefitStore
 */
const ShareholderBenefitStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    Loading: BaseLoading,
    notificationBox: NotificationBoxStore
  }
};

export default ShareholderBenefitStore;
