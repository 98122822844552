<template src="./IPOCalendarMonthPicker.html"></template>

<script>
/**
 * IPOカレンダーMonthPickerコンポーネント
 * @module IPOCalendars/IPOCalendarMonthPicker
 */
export default {
  name: 'IPOCalendarMonthPicker',
  data() {
    return {
      /**
       * MonthPickerの月表示グルーピング
       * @return {Array<Array<Number>>}
       */
      monthOrderTable: [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [10, 11, 12],
      ],
    };
  },
  computed: {
    /**
     * カレンダーの年
     * @return {Number}
     */
    calendarYear() {
      return this.$store.getters['ipoCalendar/calendarYear'];
    },
    /**
     * カレンダーの月
     * @return {Number}
     */
    calendarMonth() {
      return this.$store.getters['ipoCalendar/calendarMonth'];
    },
    /**
     * MonthPicker上の年
     * @return {Number}
     */
    pickerYear() {
      return this.$store.getters['ipoCalendar/pickerYear'] || this.calendarYear;
    },
    /**
     * カレンダー表示可能範囲開始日
     * @return {Date}
     */
    calendarPeriodFrom() {
      return this.$store.getters['ipoCalendar/calendarPeriodFrom'];
    },
    /**
     * カレンダー表示可能範囲終了日
     * @return {Date}
     */
    calendarPeriodTo() {
      return this.$store.getters['ipoCalendar/calendarPeriodTo'];
    },
    /**
     * 前年が表示可能であれば真
     * @return {boolean}
     */
    hasPrevYear() {
      return this.calendarPeriodFrom ? this.calendarPeriodFrom.getFullYear() < this.pickerYear : false;
    },
    /**
     * 翌年が表示可能であれば真
     * @return {boolean}
     */
    hasNextYear() {
      return this.calendarPeriodTo ? this.calendarPeriodTo.getFullYear() > this.pickerYear : false;
    },
  },
  mounted() {
    this.initPickerYear();
  },
  methods: {
    /**
     * MonthPicker表示年の初期化
     * @return {void}
     */
    initPickerYear() {
      this.$store.commit('ipoCalendar/pickerYear', this.calendarYear);
    },
    /**
     * MonthPickerを前年に切り替える
     * @return {void}
     */
    switchPrevYear() {
      this.$store.commit('ipoCalendar/pickerYear', this.pickerYear - 1);
    },
    /**
     * MonthPickerを翌年に切り替える
     * @return {void}
     */
    switchNextYear() {
      this.$store.commit('ipoCalendar/pickerYear', this.pickerYear + 1);
    },
    /**
     * MonthPickerで月を選択する
     * @return {void}
     */
    select(month) {
      this.$store.commit('ipoCalendar/calendarYear', this.pickerYear);
      this.$store.commit('ipoCalendar/calendarMonth', month);
      this.$store.dispatch('ipoCalendar/hideMonthPicker');
      this.$store.dispatch('ipoCalendar/cancelStickyScroll');
    },
    /**
     * カレンダーの年とMonthPickerの年が一致、
     * かつ与えられた月がカレンダーの月と一致すれば真
     * @param {Number} month
     * @return {boolean}
     */
    isCurrentMonth(month) {
      return this.pickerYear === this.calendarYear && this.calendarMonth === month;
    },
    /**
     * カレンダーの年と与えられた月がカレンダーの表示可能範囲であれば真
     * @param {Number} month
     * @return {boolean}
     */
    isBetweenCalendarPeriod(month) {
      if (!this.calendarPeriodTo) {
        return false;
      }

      const periodFromYm = this.calendarPeriodFrom.getFullYear() * 12 + this.calendarPeriodFrom.getMonth() + 1;
      const periodToYm = this.calendarPeriodTo.getFullYear() * 12 + this.calendarPeriodTo.getMonth() + 1;
      const pickerYm = this.pickerYear * 12 + month;

      return periodFromYm <= pickerYm && pickerYm <= periodToYm;
    },
    /**
     * 月毎のref属性名
     * @param {Number} month
     * @return {String}
     */
    formatRef(month) {
      return `ipoCalendarMonth${month}`;
    },
  },
};
</script>
