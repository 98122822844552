<template src="./PeerStocksOfInterest.html"></template>
<script>
import PerPbrChart from './PerPbrChart.vue';
import { QUERY_PEER_STOCKS_OF_INTEREST_BY_EXCHANGE_SECTION_ID } from '../../../queries/PeerStocksOfInterestByExchangeSectionIDQuery';
import { QUERY_PEER_STOCKS_OF_INTEREST_BY_INDUSTRY_SECTOR_CODE } from '../../../queries/PeerStocksOfInterestByIndustrySectorCodeQuery';
import { QUERY_PEER_STOCKS_OF_INTEREST_BY_MARKET_CAPITALIZATION_ORDER } from '../../../queries/PeerStocksOfInterestByMarketCapitalizationOrderQuery';
import { QUERY_PEER_STOCKS_OF_INTEREST_IN_WHOLE_SHARE } from '../../../queries/PeerStocksOfInterestInWholeShareQuery';

/**
 * 比較対象における注目企業一覧のテーブルコンポーネント
 * @module Pages/StockResearch/PeerStocksOfInterest
 */
export default {
  name: 'PeerStocksOfInterest',
  components: {
    PerPbrChart,
  },
  data() {
    return {
      /**
       * 現在表示している相対比較先 (同一業種分類、同一取引市場、類似時価総額、日本株全体)
       * @type {string}
       */
      currentPeer: this.peerKpiPeer,
      /**
       * 同一取引市場の注目企業一覧
       * @type {Array<Object>}
       */
      peerStocksOfInterestByExchangeSectionId: [],
      /**
       * 同一業種分類の注目企業一覧
       * @type {Array<Object>}
       */
      peerStocksOfInterestByIndustrySectorCode: [],
      /**
       * 類似時価総額の注目企業一覧
       * @type {Array<Object>}
       */
      peerStocksOfInterestByMarketCapitalizationOrder: [],
      /**
       * 日本株全体の注目企業一覧
       * @type {Array<Object>}
       */
      peerStocksOfInterestInWholeShare: [],
      /**
       * 相対比較先のリスト
       * @type {Array<Object>}
       */
      peerList: [
        { name: 'industrySector', label: '同一業種分類' },
        { name: 'exchangeSection', label: '同一取引市場' },
        { name: 'capitalOrder', label: '類似時価総額' },
        { name: 'wholeShare', label: '日本株全体' },
      ],
    };
  },
  props: {
    /**
     * 取引市場ID
     * @type {number}
     */
    exchangeSectionId: {
      type: Number,
      required: true,
    },
    /**
     * 業種分類コード
     * @type {string}
     */
    industrySectorCode: {
      type: String,
      required: true,
    },
    /**
     * 類似時価総額
     * @type {number}
     */
    capitalOrder: {
      type: Number,
      required: true,
    },
    /**
     * 相対比較先
     * @type {string}
     */
    peerKpiPeer: {
      type: String,
      required: true,
    },
    /**
     * 銘柄コード
     * @type {string}
     */
    stockCode: {
      type: String,
      required: true,
    },
    /**
     * 銘柄短縮名
     * @type {string}
     */
    stockNameAbbr: {
      type: String,
      required: true,
    },
    /**
     * 最新のPER
     * @type {number}
     */
    latestPer: {
      type: Number,
      defalut: null,
    },
    /**
     * 最新のPBR
     * @type {number}
     */
    latestPbr: {
      type: Number,
      defalut: null,
    },
  },
  apollo: {
    peerStocksOfInterestByExchangeSectionId: {
      query: QUERY_PEER_STOCKS_OF_INTEREST_BY_EXCHANGE_SECTION_ID,
      skip() {
        return true;
      },
      variables() {
        return {
          exchange_section_id: this.exchangeSectionId,
        };
      },
    },
    peerStocksOfInterestByIndustrySectorCode: {
      query: QUERY_PEER_STOCKS_OF_INTEREST_BY_INDUSTRY_SECTOR_CODE,
      skip() {
        return true;
      },
      variables() {
        return {
          industry_sector_code: this.industrySectorCode,
        };
      },
    },
    peerStocksOfInterestByMarketCapitalizationOrder: {
      query: QUERY_PEER_STOCKS_OF_INTEREST_BY_MARKET_CAPITALIZATION_ORDER,
      skip() {
        return true;
      },
      variables() {
        return {
          capital_order: this.capitalOrder,
        };
      },
    },
    peerStocksOfInterestInWholeShare: {
      query: QUERY_PEER_STOCKS_OF_INTEREST_IN_WHOLE_SHARE,
      skip() {
        return true;
      },
    },
  },
  computed: {
    /**
     * 同一取引市場なら真
     */
    isExchangeSection() {
      return this.currentPeer === 'exchangeSection';
    },
    /**
     * 同一業種分類なら真
     */
    isIndustrySector() {
      return this.currentPeer === 'industrySector';
    },
    /**
     * 類似時価総額なら真
     */
    isCapitalOrder() {
      return this.currentPeer === 'capitalOrder';
    },
    /**
     * 日本株全体なら真
     */
    isWholeShare() {
      return this.currentPeer === 'wholeShare';
    },
    /**
     * 比較対象における注目企業一覧のテーブルのタブ
     */
    currentPeerTab() {
      if (this.isExchangeSection) {
        return this.peerStocksOfInterestByExchangeSectionId;
      } else if (this.isIndustrySector) {
        return this.peerStocksOfInterestByIndustrySectorCode;
      } else if (this.isCapitalOrder) {
        return this.peerStocksOfInterestByMarketCapitalizationOrder;
      } else if (this.isWholeShare) {
        return this.peerStocksOfInterestInWholeShare;
      } else {
        return [];
      }
    },
    /**
     * 現在表示されている銘柄
     */
    currentStock() {
      return {
        code: this.stockCode,
        nameAbbr: this.stockNameAbbr,
        per: this.latestPer,
        pbr: this.latestPbr,
      };
    },
  },
  mounted() {
    const dom = document.getElementById('v-stock-research');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadPeer(this.currentPeer);
        }
      });
    }, this.intersectionObserverOptions);
    observer.observe(dom);
  },
  methods: {
    /**
     * 評価のラベルを返す
     * @param {string} rating - 評価
     * @returns {string}
     */
    ratingLabel(rating) {
      switch (rating) {
        case 'buy':
          return '買';
        case 'sell':
          return '売';
        default:
          return '対象外'; // 'unknown'もこちら
      }
    },
    /**
     * 評価のCSSを返す
     * @param {string} rating - 評価
     * @returns {string}
     */
    ratingCss(rating) {
      switch (rating) {
        case 'buy':
          return 'bg-minkabuPicksBuy px-2 py-0.5 text-xs';
        case 'sell':
          return 'bg-minkabuPicksSell px-2 py-0.5 text-xs';
        default:
          return 'bg-minkabuPicksNone px-2 py-0.5 text-xs'; // 'unknown'もこちら
      }
    },
    /**
     * 評価のテキスト用CSSを返す
     * @param {string} rating - 評価
     * @returns {string}
     */
    ratingTextCss(rating) {
      switch (rating) {
        case 'buy':
          return 'text-minkabuPicksBuy';
        case 'sell':
          return 'text-minkabuPicksSell';
        default:
          return 'text-minkabuPicksNone'; // 'unknown'もこちら
      }
    },
    /**
     * タブ切替え処理
     * @returns {void}
     */
    change(nextPeer) {
      this.loadPeer(nextPeer);
      this.currentPeer = nextPeer;
    },
    /**
     * 現在のタブが引数の名前と一致するかどうか
     * @returns {boolean}
     */
    isCurrentPeer(peer) {
      return this.currentPeer === peer;
    },
    /**
     * 「比較対象における注目企業一覧」を読み込む
     * @returns {void}
     */
    loadPeer(peerName) {
      switch (peerName) {
        case 'wholeShare':
          this.$apollo.queries.peerStocksOfInterestInWholeShare.skip = false;
          break;
        case 'exchangeSection':
          this.$apollo.queries.peerStocksOfInterestByExchangeSectionId.skip = false;
          break;
        case 'industrySector':
          this.$apollo.queries.peerStocksOfInterestByIndustrySectorCode.skip = false;
          break;
        case 'capitalOrder':
          this.$apollo.queries.peerStocksOfInterestByMarketCapitalizationOrder.skip = false;
          break;
      }
    },
  },
};
</script>
