<template src="./StockSummaryModal.html" />
<script>
import ModalHeader from './header/ModalHeader.vue';
import ModalBody from './body/ModalBody.vue';

/**
 * 銘柄検索 個別銘柄サマリーモーダル コンポーネント
 * @module StockSearch/modules/StockSummaryModal
 */
export default {
  name: 'StockSummaryModal',
  components: {
    'modal-header': ModalHeader,
    'modal-body': ModalBody,
  },
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    summaryCodeList() {
      return this.$store.getters['stockSearchInput/summaryCodeList'];
    },
    fullscreen() {
      return this.$store.getters['stockSearchInput/fullscreen'];
    },
    summaryError() {
      return this.$store.getters['stockSearchInput/summaryError'];
    },
    /**
     * 前の銘柄が存在するか
     */
    hasPrev() {
      const current = this.summaryCodeList.indexOf(this.summaryData.code);
      return current !== 0;
    },
    /**
     * 次の銘柄が存在するか
     */
    hasNext() {
      const current = this.summaryCodeList.indexOf(this.summaryData.code);
      return current !== this.summaryCodeList.length - 1;
    },
  },
  mounted() {
    window.addEventListener('popstate', this.closeModal);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.closeModal);
  },
  methods: {
    /**
     * モーダルを閉じる
     */
    closeModal() {
      if (!this.fullscreen) {
        const body = document.querySelector('body');
        const offsetY = -parseFloat(getComputedStyle(body).top);
        body.classList.remove('ui-drawer-scroll-stop');
        body.style.top = null;
        window.scrollTo(0, offsetY);
      }
      this.$store.commit('stockSearchInput/setSummaryShow', false);
    },
    /**
     * 前の銘柄
     */
    async prev() {
      if (!this.hasPrev) {
        return;
      }
      const current = this.summaryCodeList.indexOf(this.summaryData.code);
      this.$store.commit('stockSearchInput/setSummaryCode', this.summaryCodeList[current - 1]);
      this.getSummary();
    },
    /**
     * 次の銘柄
     */
    async next() {
      if (!this.hasNext) {
        return;
      }
      const current = this.summaryCodeList.indexOf(this.summaryData.code);
      if (current === this.summaryCodeList.length - 1) {
        return;
      }
      this.$store.commit('stockSearchInput/setSummaryCode', this.summaryCodeList[current + 1]);
      this.getSummary();
    },
    /**
     * データ取得
     */
    async getSummary() {
      await this.$store.dispatch('stockSearchInput/getSummary');
    },
  },
};
</script>
