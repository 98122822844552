import BaseResource from './BaseResource';

/* *
 * フォローAPI
 * */
class FollowResource extends BaseResource {
  /*
   * お気に入り登録APIをコールしてフォローに追加
   * @param {integer} userId 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async follow(userId) {
    return await this.client.post('/member_follows', { follower_id: userId });
  }

  /*
   * お気に入り解除APIをコールしてフォローから削除
   * @param {integer} userId 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async unfollow(userId) {
    return await this.client.delete(`/member_follows/${userId}`);
  }
}
export default FollowResource;
