import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM_CODE_WITH_NAME as FRAGMENT_F11M_CODE_WITH_NAME } from './fragments/FinancialItemFragment';
import { FRAGMENT_SECURITIES_COMPANY } from './fragments/SecuritiesCompanyFragment';

const IPO_ITEM_TYPE_FOR_UPCOMING = `
  financialItem {
    ...financialItemCodeWithName
  }
  exchangeSectionName
  listingDate
  provisionalConditionFilingDate
  upperLimitOfProvisionalCondition
  lowerLimitOfProvisionalCondition
  bookBuildingPeriodFrom
  bookBuildingPeriodTo
  subscriptionPriceDeterminationDate
  subscriptionPeriodFrom
  subscriptionPeriodTo
  securitiesCompanies {
    ...securitiesCompany
  }
  industrySectorName
  businessSummary
  openPrice
  ipoCanceled
`;

export const QUERY_IPO_UPCOMING_ITEMS = gql`
  query ipoUpcomingItems {
    ipoUpcomingItems {
      ${IPO_ITEM_TYPE_FOR_UPCOMING}
    }
  }
  ${FRAGMENT_F11M_CODE_WITH_NAME}
  ${FRAGMENT_SECURITIES_COMPANY}
`;
