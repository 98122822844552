<template src="./YutaiRankingRow.html" />
<script>
import YutaiRanking from '../../models/YutaiRanking';
import FavoriteButton from '../Common/FavoriteButton.vue';

/**
 * 株主優待ランキング一覧表示の一行
 *
 * @vue-props {YutaiRanking} ranking 株主優待ランキングオブジェクト
 * @module Yutai/YutaiRankingRow
 */
export default {
  name: 'YutaiRankingRow',
  components: {
    favoriteButton: FavoriteButton,
  },
  props: {
    ranking: {
      type: YutaiRanking,
      required: true,
    },
  },
};
</script>
