<template src="./CardSlider.html"></template>
<script>
import Swiper, { Navigation } from 'swiper';

/**
 * おすすめ銘柄トップ カードコンテンツスライダー
 * @module Discover/modules/CardSlider {CardSlider}
 */
export default {
  name: 'CardSlider',
  props: {
    classObject: {
      type: Object,
      default: function () {
        return {
          navigation: {
            prev: '-left-4',
            next: '-right-4',
          },
        };
      },
    },
  },
  mounted() {
    new Swiper(this.$refs.discoverCardSwiper, {
      modules: [Navigation],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'swiper-button-disabled',
      },
      loop: false,
      slidesPerView: 'auto',
      centeredSlides: false,
    });
  },
};
</script>
