import ItfRiskReturnChartVue from './ItfRiskReturnChart.vue';

/**
 * 個別銘柄の投信ページのルートコンポーネント
 * @module Itf/ItfRiskReturnChart
 */
const ItfRiskReturnChart = {
  el: '#v-fund-chart',
  components: {
    ItfRiskReturnChart: ItfRiskReturnChartVue
  }
};

export default ItfRiskReturnChart;
