import FavoriteFinancialItemsStore from './Common/FavoriteFinancialItemsStore';
import ReportFinancialItemsStore from './Common/ReportFinancialItemsStore';
import NotificationBoxStore from './Common/NotificationBoxStore';

/**
 * 個別銘柄ヘッダー ルートストア
 *
 * @module IndividualFinancialItemHeaderStore
 */
const IndividualFinancialItemHeaderStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    reportFinancialItems: ReportFinancialItemsStore,
    notificationBox: NotificationBoxStore
  }
};
export default IndividualFinancialItemHeaderStore;
