import YutaiInfo from './YutaiInfo';
/**
 * 株主優待ランキングモデル
 *
 * @property {number} number ランキング順位
 */
class YutaiRanking extends YutaiInfo {
  /**
   * 初期化処理
   *
   * @params {object} data
   * @constructor
   */
  constructor(data) {
    super(data);
    this.number = data.number;
  }
}
export default YutaiRanking;
