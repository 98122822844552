<template src="./SearchCustomize.html" />
<script>
import GlossaryTooltip from '../tooltip/GlossaryTooltip.vue';
import PremiumTooltip from '../tooltip/PremiumTooltip.vue';
/**
 * 銘柄スクリーニング 表示カスタマイズモーダルコンポーネント
 * @module StockSearch/modules/customize/SearchCustomize
 */

export default {
  name: 'SearchCustomize',
  components: {
    'glossary-tooltip': GlossaryTooltip,
    'premium-tooltip': PremiumTooltip,
  },
  data() {
    return {
      list: [
        {
          heading: 'みんかぶ予想',
          checked: false,
          items: [
            {
              id: 'minkabuTargetPrice',
              name: '目標株価',
              checked: true,
              premium: false,
            },
            {
              id: 'analystTargetPrice',
              name: 'アナリスト予想',
              checked: true,
              premium: false,
            },
            {
              id: 'userExpectationTargetPrice',
              name: 'みんかぶ会員予想',
              checked: true,
              premium: false,
            },
            {
              id: 'researchTargetPrice',
              name: 'AI株価診断',
              checked: true,
              premium: false,
            },
            {
              id: 'buyPickTotal',
              name: '買い予想総数',
              checked: true,
              premium: false,
            },
            {
              id: 'sellPickTotal',
              name: '売り予想総数',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '決算',
          checked: false,
          items: [
            {
              id: 'settlementEvaluations',
              name: '業績評価',
              checked: true,
              premium: false,
              showPremiumTooltip: 'hidden',
            },
            {
              id: 'sales',
              name: '売上高',
              checked: true,
              premium: false,
            },
            {
              id: 'salesCAGR3y',
              name: '3年平均売上高成長率',
              checked: true,
              premium: false,
            },
            {
              id: 'operatingIncome',
              name: '営業利益',
              checked: true,
              premium: false,
            },
            {
              id: 'operatingIncomeMargin',
              name: '売上高営業利益率',
              checked: true,
              premium: false,
              showPremiumTooltip: 'hidden',
            },
            {
              id: 'ordinaryIncome',
              name: '経常利益',
              checked: true,
              premium: false,
            },
            {
              id: 'netIncome',
              name: '当期利益',
              checked: true,
              premium: false,
            },
            {
              id: 'eps',
              name: '1株あたり利益',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '配当',
          checked: false,
          items: [
            {
              id: 'dividendYield',
              name: '配当利回り',
              checked: true,
              premium: false,
            },
            {
              id: 'payoutRatio',
              name: '配当性向',
              checked: true,
              premium: false,
            },
            {
              id: 'consecutiveIncreasedDividendCount',
              name: '連続増配回数',
              checked: true,
              premium: false,
            },
            {
              id: 'dps',
              name: '1株あたり配当',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '企業情報',
          checked: false,
          items: [
            {
              id: 'capitalAdequacyRatio',
              name: '自己資本比率',
              checked: true,
              premium: false,
            },
            {
              id: 'listingDate',
              name: '上場年月日',
              checked: true,
              premium: false,
            },
            {
              id: 'industrySectors',
              name: '業種',
              checked: true,
              premium: false,
            },
            {
              id: 'netAssets',
              name: '純資産',
              checked: true,
              premium: false,
            },
            {
              id: 'totalAssets',
              name: '総資産',
              checked: true,
              premium: false,
            },
            {
              id: 'bps',
              name: '1株あたり純資産',
              checked: true,
              premium: false,
            },
            {
              id: 'capital',
              name: '資本金',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '株価指標',
          checked: false,
          items: [
            {
              id: 'per',
              name: '調整後PER',
              checked: true,
              premium: false,
            },
            {
              id: 'pbr',
              name: 'PBR',
              checked: true,
              premium: false,
            },
            {
              id: 'psr',
              name: 'PSR',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '財務指標',
          checked: false,
          items: [
            {
              id: 'roa',
              name: 'ROA',
              checked: true,
              premium: false,
            },
            {
              id: 'roe',
              name: 'ROE',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '優待',
          checked: false,
          items: [
            {
              id: 'yutaiExist',
              name: '優待有無',
              checked: true,
              premium: false,
            },
            {
              id: 'yutaiYield',
              name: '優待利回り',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '株価騰落率',
          checked: false,
          items: [
            {
              id: 'sharePriceCr1d',
              name: '前日比',
              checked: true,
              premium: false,
            },
            {
              id: 'sharePriceCr1w',
              name: '前週比',
              checked: true,
              premium: false,
            },
            {
              id: 'sharePriceCr1m',
              name: '前月比',
              checked: true,
              premium: false,
            },
            {
              id: 'sharePriceCr1y',
              name: '前年比',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: 'マーケット情報',
          checked: false,
          items: [
            {
              id: 'volume',
              name: '出来高',
              checked: true,
              premium: false,
            },
            {
              id: 'vroc',
              name: '出来高変化率',
              checked: true,
              premium: false,
            },
            {
              id: 'marketCapitalization',
              name: '時価総額',
              checked: true,
              premium: false,
            },
          ],
        },
        {
          heading: '株価とみんかぶ予想の乖離率',
          checked: false,
          items: [
            {
              id: 'minkabuTpSpDr',
              name: '目標株価',
              checked: true,
              premium: true,
            },
            {
              id: 'analystTpSpDr',
              name: 'アナリスト予想',
              checked: true,
              premium: true,
            },
            {
              id: 'researchTpSpDr',
              name: 'AI株価診断',
              checked: true,
              premium: true,
            },
            {
              id: 'userExpectationTpSpDr',
              name: 'みんかぶ会員予想',
              checked: true,
              premium: true,
            },
          ],
        },
        {
          heading: 'みんかぶ目標株価の変化率',
          checked: false,
          items: [
            {
              id: 'minkabuTpCr1d',
              name: '前日比',
              checked: true,
              premium: true,
            },
            {
              id: 'minkabuTpCr1w',
              name: '前週比',
              checked: true,
              premium: true,
            },
            {
              id: 'minkabuTpCr1m',
              name: '前月比',
              checked: true,
              premium: false,
            },
            {
              id: 'minkabuTpCr1y',
              name: '前年比',
              checked: true,
              premium: true,
            },
          ],
        },
        {
          heading: 'アナリスト予想株価の変化率',
          checked: false,
          items: [
            {
              id: 'analystTpCr1d',
              name: '前日比',
              checked: true,
              premium: true,
            },
            {
              id: 'analystTpCr1w',
              name: '前週比',
              checked: true,
              premium: true,
            },
            {
              id: 'analystTpCr1m',
              name: '前月比',
              checked: true,
              premium: false,
            },
            {
              id: 'analystTpCr1y',
              name: '前年比',
              checked: true,
              premium: true,
            },
          ],
        },
        {
          heading: 'AI株価診断の変化率',
          checked: false,
          items: [
            {
              id: 'researchTpCr1d',
              name: '前日比',
              checked: true,
              premium: true,
            },
            {
              id: 'researchTpCr1w',
              name: '前週比',
              checked: true,
              premium: true,
            },
            {
              id: 'researchTpCr1m',
              name: '前月比',
              checked: true,
              premium: false,
            },
            {
              id: 'researchTpCr1y',
              name: '前年比',
              checked: true,
              premium: true,
            },
          ],
        },
        {
          heading: 'みんかぶ会員予想の変化率',
          checked: false,
          items: [
            {
              id: 'userExpectationTpCr1d',
              name: '前日比',
              checked: true,
              premium: true,
            },
            {
              id: 'userExpectationTpCr1w',
              name: '前週比',
              checked: true,
              premium: true,
            },
            {
              id: 'userExpectationTpCr1m',
              name: '前月比',
              checked: true,
              premium: false,
            },
            {
              id: 'userExpectationTpCr1y',
              name: '前年比',
              checked: true,
              premium: true,
            },
          ],
        },
        {
          heading: 'その他',
          checked: false,
          items: [
            {
              id: 'minimumPurchasePrice',
              name: '最低投資金額',
              checked: true,
              premium: false,
            },
          ],
        },
      ],
    };
  },
  computed: {
    defaultCustomize() {
      return this.$store.getters['stockSearchInput/defaultCustomize'];
    },
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    showCustomize() {
      return this.$store.getters['stockSearchInput/showCustomize'];
    },
  },
  watch: {
    /**
     * カスタマイズモーダルが表示された時("showCustomize" の値が変更された時)に、チェック状態を反映する
     */
    showCustomize: {
      handler: function (val) {
        this.refrectedCheckbox(val);
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    /**
     * モーダルにチェック状態を反映する
     * @param {boolean} val
     */
    refrectedCheckbox(val) {
      if (!val) {
        return;
      }
      const checkedCustomize = this.$store.getters['stockSearchInput/checkedCustomize'];
      for (const group of this.list) {
        for (const item of group.items) {
          item.checked = checkedCustomize.includes(item.id);
        }
        this.checkItem(group);
      }
    },
    /**
     * モーダルを非表示にする
     * @return {void}
     */
    hide() {
      if (!this.fullscreen) {
        const body = document.querySelector('body');
        const offsetY = -parseFloat(getComputedStyle(body).top);
        body.classList.remove('ui-drawer-scroll-stop');
        body.style.top = null;
        window.scrollTo(0, offsetY);
      }
      this.$store.commit('stockSearchInput/setShowCustomize', false);
      this.$refs.customizeWrapper.scrollTop = 0;
    },
    /**
     * 全てにチェックを入れる
     */
    checkAll() {
      for (const group of this.list) {
        for (const item of group.items) {
          if (this.isCheckableItem(item.premium)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        }

        // チェック可能な項目が一つもないカテゴリにはチェックを入れない
        if (this.isCheckableSection(group.items)) {
          group.checked = true;
        } else {
          // 一応チェックを外しておく
          group.checked = false;
        }
      }
    },
    /**
     * 全てチェックを外す
     */
    uncheckAll() {
      for (const group of this.list) {
        for (const item of group.items) {
          item.checked = false;
        }
        group.checked = false;
      }
    },
    /**
     * 親のチェックボックス連動
     * @param {object} group
     */
    checkSection(group) {
      group.items.forEach((item) => {
        if (this.isCheckableItem(item.premium)) {
          item.checked = group.checked;
        }
      });
    },
    /**
     * 子のチェックボックス連動
     * @param {object} group
     */
    checkItem(group) {
      // 子のチェックボックスが全てチェックされている場合は親のチェックボックスもチェックする
      // ただし、チェック可能な項目が一つもない場合には親のチェックボックスはチェックしない
      const checkableItems = group.items.filter((item) => this.isCheckableItem(item.premium));
      if (checkableItems.length === 0) {
        group.checked = false;
        return;
      }
      group.checked = checkableItems.every((list) => list.checked);
    },
    /**
     * チェック可能なセクションであるかどうかを判定する
     * @param {string} group
     * @returns {boolean}
     */
    isCheckableSection(items) {
      // すべての項目がチェック不可な項目であるセクションはチェック不可
      return items.some((item) => this.isCheckableItem(item.premium));
    },
    /**
     * チェック可能な項目であるかどうかを判定する
     * @param {boolean} isPremiumItem プレミアム会員限定の項目であれば true
     * @returns {boolean}
     */
    isCheckableItem(isPremiumItem) {
      const isPremiumUser = this.$store.getters['stockSearchInput/premium'];
      if (isPremiumItem && !isPremiumUser) {
        return false;
      }
      return true;
    },
    /**
     * 検索条件を確定させてモーダルを閉じる
     */
    confirmCriteria() {
      const checkedNames = ['financialItemName', 'latestTradePrice'];
      for (const group of this.list) {
        for (const item of group.items) {
          if (item.checked) {
            checkedNames.push(item.id);
          }
        }
      }
      // 指定順に並び替えてからセット
      const sortedCheckedNames = checkedNames
        .filter((item) => this.defaultCustomize.includes(item))
        .sort((x, y) => this.defaultCustomize.indexOf(x) - this.defaultCustomize.indexOf(y));
      this.$store.commit('stockSearchInput/setCheckedCustomize', sortedCheckedNames);
      this.hide();
    },
    /**
     * スネークからキャメルに変換
     * @param {String} c
     */
    toUpperCase(c) {
      return c.replace(/_([0-9a-z])/g, (_, v) => v.toUpperCase());
    },
  },
};
</script>
