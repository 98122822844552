/**
 * ページネーションの数値セルクラス
 *
 * @property {number} page ページ番号
 * @property {boolean} isCurrent 現在のページかどうか
 * @property {boolean} isGap gapかどうか
 * @module TailwindPagination/PageCell
 */
class PageCell {
  constructor(page, isCurrent, isGap) {
    this.page = page;
    this.isCurrent = isCurrent;
    this.isGap = isGap;
  }
}
export default PageCell;
