<template src="./FavoriteFinancialItemsPagination.html" />

<script>
import { generateUrlParams, pushUrlParams } from './utils/urlParams.js';
import { loadingPage } from './utils/loadingPage.js';
/**
 * お気に入り銘柄ページネーションコンポーネント
 * @module Mypage/FavoriteFinancialItemsPagination
 */
export default {
  name: 'FavoriteFinancialItemsPagination',
  computed: {
    /**
     * 前ページが存在するか
     * @return {boolean}
     */
    hasPrev() {
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current > 1;
    },
    /**
     * 次ページが存在するか
     * @return {boolean}
     */
    hasNext() {
      return (
        this.$store.getters['favoriteFinancialItemDetails/pages'].current <
        this.$store.getters['favoriteFinancialItemDetails/totalPages']
      );
    },
    /**
     * 1ページ目のリンクを出すか
     * @return {boolean}
     */
    hasFirst() {
      if (this.isPc) {
        return this.$store.getters['favoriteFinancialItemDetails/pages'].current > 4;
      }
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current > 2;
    },
    /**
     * 三点リーダを出すか
     * @return {boolean}
     */
    hasGap() {
      if (this.isPc) {
        return this.$store.getters['favoriteFinancialItemDetails/pages'].current > 5;
      }
      return this.$store.getters['favoriteFinancialItemDetails/pages'].current > 3;
    },
    /**
     * ページネートの表示番号を配列で返す
     * @return {array} range
     */
    paginateList() {
      const totalPages = this.$store.getters['favoriteFinancialItemDetails/totalPages'];
      const current = this.$store.getters['favoriteFinancialItemDetails/pages'].current;
      const pageShift = this.isPc ? 3 : 1;
      const rangeBegin = Math.max(current - pageShift, 1);
      const rangeEnd = Math.min(current + pageShift, totalPages);
      return Array.from({ length: rangeEnd - rangeBegin + 1 }, (_, i) => rangeBegin + i);
    },
    search() {
      return this.$store.getters['favoriteFinancialItemDetails/search'];
    },
    sort() {
      return this.$store.getters['favoriteFinancialItemDetails/sort'];
    },
  },
  methods: {
    /**
     * ページ変更
     * @param {string} key
     */
    async changePage(n) {
      await loadingPage({
        vm: this,
        scroll: true,
        callback: async () => {
          await this.$store.dispatch('favoriteFinancialItemDetails/changePage', n);
          pushUrlParams(generateUrlParams({ page: n, search: this.search, sort: this.sort }));
          this.$store.commit('favoriteFinancialItemDetails/setAccordion', []);
          this.$gtag.pageview({ page_path: `${location.pathname}${location.search}` });
        },
      });
    },
  },
};
</script>
