<template src="./PerformanceTrendsChart.html" />

<script>
import {Chart} from "highcharts-vue";
import RoundUtil from "../../utils/RoundUtil";

/**
 * アナリスト予想ページ業績推移チャートコンポーネント
 *
 * @vue-prop {Json} chartElements
 * @module AnalystConsensus/PerformanceTrendsChart
 */
export default {
  name: 'PerformanceTrendsChart',
  props: {
    financialItemName: {
      type: String,
      required: true
    },
    chartElements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      performanceType: '売上高',
      chartOptions: {
        title: {
          text: ''
        },
        chart: {
          type: 'column',
          backgroundColor: '#ffffff',
          height: 155
        },
        series: [{
          color: '#95a5b1',
          data: this.companyResultData(),
          name: '実績値',
          pointPlacement: 0.20
        },{
          color: '#FC9F02',
          data: this.analystProjectionData(),
          name: 'アナリスト予想',
          pointPlacement: -0.10
        },{
          color: '#0096fa',
          data: this.companyProjectionData(),
          name: '会社予想',
          pointPlacement: -0.10
        }
        ],
        xAxis: {
          categories: this.xLabels()
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function() {
              return RoundUtil.roundJapaneseUnit(this.value, 1);
            }
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            return `<small>${this.x}</small><br>${this.series.name}: <b>${RoundUtil.roundJapaneseUnit(this.point.y, 1)}</b>`;
          }
        }
      }
    };
  },
  components: {
    highcharts: Chart
  },
  methods: {
    companyResultData() {
      let data = [];
      switch (this.performanceType) {
      case "売上高":
        data = this.chartElements["result"]["sales"];
        break;
      case "純利益":
        data = this.chartElements["result"]["net_income"];
        break;
      case "営業利益":
        data = this.chartElements["result"]["operating_income"];
        break;
      case "経常利益":
        data = this.chartElements["result"]["ordinary_income"];
        break;
      default:
      }

      return this.revertRoundedValues(data);
    },
    analystProjectionData() {
      const analystProjection = this.projectionData()["analyst"];
      return this.revertRoundedValues(this.baseArray().concat(analystProjection));
    },
    companyProjectionData() {
      const companyProjection = this.projectionData()["company"];
      return this.revertRoundedValues(this.baseArray().concat(companyProjection));
    },
    baseArray() {
      const result = this.companyResultData();
      return Array(result.length).fill(null);
    },
    xLabels() {
      return this.chartElements["labels"];
    },
    redraw() {
      this.$refs.performanceChart.chart.series[0].setData(this.companyResultData());
      this.$refs.performanceChart.chart.series[1].setData(this.analystProjectionData());
      this.$refs.performanceChart.chart.series[2].setData(this.companyProjectionData());
    },
    projectionData() {
      switch (this.performanceType) {
      case "売上高":
        return this.chartElements["projection"]["sales"];
      case "純利益":
        return this.chartElements["projection"]["net_income"];
      case "営業利益":
        return this.chartElements["projection"]["operating_income"];
      case "経常利益":
        return this.chartElements["projection"]["ordinary_income"];
      default:
        return {};
      }
    },
    /**
     * 配列の要素の値を100万掛けて返す
     * @param {Array<integer|null>} list
     * @return {Array<integer|null>}
     */
    revertRoundedValues: function(list) {
      return list === [] ? list : list.map(function(val) { return RoundUtil.revertRoundedMillion(val); });
    }
  },
  watch: {
    performanceType() {
      this.redraw();
    }
  },
  mounted() {
    this.redraw();
  }
};
</script>
