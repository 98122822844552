import MkApolloProvider from '../../../utils/MkApolloProvider';
import IPOItemsComponent from './IPOItems.vue';

const IPOItems = {
  el: '#v-ipo-items',
  apolloProvider: MkApolloProvider,
  components: {
    ipoItems: IPOItemsComponent,
  },
};
export default IPOItems;
