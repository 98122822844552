import Vue from 'vue';
import Vuex from 'vuex';
import VariantUtil from './VariantUtil';

// Vue plugins;
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import VueScrollTo from 'vue-scrollto';
import VueApollo from 'vue-apollo';
import VueGtag from 'vue-gtag';

/**
 * vueComponentのelに対応するElementが存在する場合だけ
 * vueComponentをMountする
 * vueComponentStoreが指定された場合は
 * vueComponentにVuex.Storeされたモジュールをマージする
 *
 * @param {Object} vueComponent
 * @param {Object} vueComponentStore
 */
export function vueMount(vueComponent, vueComponentStore) {
  const el = vueComponent.el.replace(/^#/, '');
  if (document.getElementById(el)) {
    if (vueComponentStore) {
      Vue.use(Vuex);
      const store = vueStore(vueComponentStore);
      vueComponent = Object.assign(vueComponent, { store });
    }
    new Vue(vueComponent);
  }
}

/**
 * フィルターをVueに登録する
 *
 * @param {Object} filter Vue用のフィルタークラス
 */
export function vueFilter(filter) {
  const object = new filter();
  object.exportMethods.forEach((methodName) => {
    Vue.filter(methodName, eval(`object.${methodName}`));
  });
}

/**
 * グローバルMixinをVueに登録する
 *
 * @param {Object} mixin Vue用のMixinオブジェクト
 */
export function vueMixin(mixin) {
  Vue.mixin(mixin);
}

/**
 * Vuex.Storeを実態化する
 *
 * @param {Object} vueComponentStore
 * @returns {Object}
 */
export function vueStore(vueComponentStore) {
  return new Vuex.Store(vueComponentStore);
}

/**
 * Vueのpluginを読み込む
 *
 * @param {Object} vuePlugins
 * @returns {Object}
 */
export function vuePlugins() {
  Vue.use(HighchartsVue, {
    highcharts: Highcharts,
  });
  Vue.use(VueScrollTo, {
    container: 'body',
    duration: 400,
    easing: 'swing',
    offset: -110,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  });
  Vue.use(VueApollo);

  const trackingId = VariantUtil.isSp() ? 'UA-49750180-1' : 'UA-49946038-1';
  Vue.use(VueGtag, {
    config: { id: trackingId },
  });
}
