<template src="./CorporateActionsDelistings.html"></template>
<script>
import { CORPORATE_ACTIONS_DELISTINGS } from '../../../queries/CorporateActionsDelistingsQuery';
import dayjs from 'dayjs';
import TailwindPagination from '../../Pagination/TailwindPagination.vue';

/**
 * 上場廃止コーポレートアクションコンポーネント
 * @vue-data {Object} corporateActionsDelistings 上場廃止コーポレートアクション
 * @vue-components {TailwindPagination} TailwindPagination ページネーション
 * @vue-data {Number} page ページ
 * @module CorporateActions/CorporateActionsDelistings
 */
export default {
  name: 'CorporateActionsDelistings',
  components: {
    TailwindPagination,
  },
  props: {
    page: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      corporateActionsDelistings: {
        collection: [],
        pageInfo: {
          offsetValue: 0,
          totalPage: 1,
          currentPage: this.page,
          totalCount: 0,
          count: 0
        }
      },
    };
  },
  computed: {
    collection() {
      return this.corporateActionsDelistings.collection.map((item) => {
        const latestTradeWithChange = item.latestTradeWithChange || {};
        const changeRatio = latestTradeWithChange.changeRatio || null;
        const changeRatioString = changeRatio ? this.numFormatWithPlusSign(changeRatio, 1) : '---';
        return {
          ...item,
          date: dayjs(item.date).format('YYYY/MM/DD'),
          latestTradeWithChange: {
            ...latestTradeWithChange,
            price: this.numFormat(latestTradeWithChange.price, 1),
            priceTime: latestTradeWithChange.price ? this.formatPriceTime(latestTradeWithChange.priceTime) : '--:--',
            changeRatio,
            changeRatioString,
          }
        };
      });
    },
    pageInfo() {
      return this.corporateActionsDelistings.pageInfo;
    },
    calendarYear() {
      return this.$store.getters['corporateActionsCalendar/calendarYear'];
    },
    calendarMonth() {
      return this.$store.getters['corporateActionsCalendar/calendarMonth'];
    },
    selectedDate() {
      return this.$store.getters['corporateActionsCalendar/selectedDate'];
    },
    selectedYear() {
      return this.selectedDate && this.selectedDate.year() || null;
    },
    selectedMonth() {
      return this.selectedDate && this.selectedDate.month() + 1 || null;
    },
    selectedDay() {
      return this.selectedDate && this.selectedDate.date() || null;
    },
    isDateSelected() {
      return this.selectedDate !== null;
    },
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    }
  },
  apollo: {
    corporateActionsDelistings: {
      query: CORPORATE_ACTIONS_DELISTINGS,
      variables() {
        if (this.isDateSelected) {
          return {
            year: this.selectedYear,
            month: this.selectedMonth,
            day: this.selectedDay,
            page: this.currentPage,
          };
        } else {
          return {
            year: this.calendarYear,
            month: this.calendarMonth,
            page: this.currentPage,
          };
        }
      }
    }
  },
  mounted() {
    this.$store.commit('tailwindPagination/currentPage', this.pageInfo.currentPage);
  },
  methods: {
    clearSelectedDate() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
    },
    changeRatioColor(changeRatio) {
      if (changeRatio > 0) {
        return 'red';
      } else if (changeRatio < 0) {
        return 'text-minkabuBlueDown';
      } else {
        return 'text-minkabuGrayEven';
      }
    },
    financialItemName(financialItem) {
      return this.isPc ? financialItem.name : financialItem.nameAbbr;
    }
  }
};
</script>
