import GuideTimelineComponent from './GuideTimeline.vue';

/*
 * 個別銘柄 マイページタイムライン誘導枠
 *
 */
const GuideTimeline = {
  el: '#v-stock-guide-timeline',
  components: {
    GuideTimeline: GuideTimelineComponent,
  },
  mounted() {
    document.querySelector('[data-js-id="guide-timeline-dummy"]').remove();
  },
};

export default GuideTimeline;
