import SettlementChartQuarterlyHistories from './SettlementChartQuarterlyHistories.vue';
import SettlementChartQuarterlyHistoriesLineUpSideways from './SettlementChartQuarterlyHistoriesLineUpSideways.vue';
import SettlementChartYearlyComparison from './SettlementChartYearlyComparison.vue';
import SettlementChartProfitability from './SettlementChartProfitability.vue';
import SettlementChartCashFlow from './SettlementChartCashFlow.vue';
import QuarterlySwitcher from './QuarterlySwitcher.vue';

/**
 * 個別銘柄・決算ページのチャート描画ルートコンポーネント
 * @module Settlements/SettlementChart
 */
const SettlementPack = {
  el: '#xcompany_info',
  components: {
    SettlementChartQuarterlyHistories: SettlementChartQuarterlyHistories,
    SettlementChartQuarterlyHistoriesLineUpSideways: SettlementChartQuarterlyHistoriesLineUpSideways,
    SettlementChartYearlyComparison: SettlementChartYearlyComparison,
    SettlementChartProfitability: SettlementChartProfitability,
    SettlementChartCashFlow: SettlementChartCashFlow,
    QuarterlySwitcher: QuarterlySwitcher
  }
};

export default SettlementPack;
