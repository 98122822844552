/**
 * 株価の整数部を返す
 * @param {string} price
 * @return {string}
 */
export const priceInteger = (price) => price.split('.')[0];

/**
 * 株価に小数部があれば真
 * @param {string} price
 * @return {string}
 */
export const hasDecimal = (price) => price.split('.').length === 2;

/**
 * 株価に小数部があれば小数、そうでなければ空文字を返す
 * @param {string} price
 * @return {string}
 */
export const priceDecimal = (price) => (hasDecimal(price) ? price.split('.')[1] : '');
