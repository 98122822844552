<template src="./StockSearchPagination.html" />
<script>
import generateQueryParams from '../../utils/generateQueryParams';
import StockSearchPaginationText from './StockSearchPaginationText.vue';
/**
 * 銘柄検索条件画面 ページネーションコンポーネント
 * @module StockSearch/modules/pagination/StockSearchPagination
 */
export default {
  name: 'StockSearchPagination',
  components: {
    StockSearchPaginationText,
  },
  data() {
    return {
      checked: [],
    };
  },
  computed: {
    total() {
      return this.$store.getters['stockSearchInput/total'];
    },
    page() {
      return Number(this.$store.getters['stockSearchInput/page']);
    },
    totalPages() {
      return this.$store.getters['stockSearchInput/result'].pagination.totalPages;
    },
    currentPage() {
      return this.$store.getters['stockSearchInput/result'].pagination.currentPage;
    },
    hasNext() {
      return this.page < this.totalPages;
    },
    hasPrev() {
      return this.page > 1;
    },
    /**
     * 1ページ目のリンクを出すか
     * @return {boolean}
     */
    hasFirst() {
      if (this.isPc) {
        this.page > 4;
      }
      return this.page > 2;
    },
    /**
     * 三点リーダを出すか
     * @return {boolean}
     */
    hasGap() {
      if (this.isPc) {
        return this.page < this.totalPages - 2;
      }
      return this.page < this.totalPages - 1;
    },
    /**
     * ページネートの表示番号
     * @return {array} range
     */
    paginateNumber() {
      const pageShift = this.isPc ? 3 : 1;
      const rangeBegin = this.page - pageShift;
      let rangeEnd = this.page + pageShift;
      rangeEnd = rangeEnd > this.totalPages ? this.totalPages : rangeEnd;
      let range = [];

      for (let i = rangeBegin; i <= rangeEnd; i++) {
        if (i > 0) {
          range.push(i);
        }
      }
      return range;
    },
  },

  methods: {
    next() {
      if (!this.hasNext) {
        return;
      }
      this.changePage(Number(this.page) + 1);
    },
    prev() {
      if (!this.hasPrev) {
        return;
      }
      this.changePage(Number(this.page) - 1);
    },
    /**
     * ページ遷移
     */
    async changePage(page = 1) {
      if (page === this.page) {
        return;
      }
      window.scrollTo(0, 0);

      const fullscreenWrapper = document.querySelector('#js_stockSearch_fs_wrapper');
      if (fullscreenWrapper) {
        fullscreenWrapper.scrollTo(0, 0);
      }
      await this.$store.commit('stockSearchInput/setPage', page);
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: 'result', paginationOnly: false })
      );
      history.pushState(null, null, generateQueryParams({ _this: this, view: 'result', paginationOnly: false }));
    },
  },
};
</script>
