/**
 * ページローダーを表示する
 * @param {object} _this
 * @param {boolean} scroll
 * @param {function} callback
 */
export const loadingPage = async ({ vm, scroll = false, callback }) => {
  if (scroll) {
    window.scrollTo({ top: 0 });
  }
  vm.$store.commit('favoriteFinancialItemDetails/setIsLoading', true);

  await callback();
  vm.$nextTick(() => {
    vm.$store.commit('favoriteFinancialItemDetails/setIsLoading', false);
  });
};
