import TailwindPaginationStore from '../Pagination/TailwindPaginationStore';

/**
 * ニュース一覧コンテンツルートストア
 *
 * @module News/NewsItemsStore
 */
const NewsItemsStore = {
  modules: {
    tailwindPagination: TailwindPaginationStore,
  },
};
export default NewsItemsStore;
