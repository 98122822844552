import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';

const hostname = window.location.hostname;

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: `//${hostname}/graphql`,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

const MkApolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default MkApolloProvider;
