<template src="./HistoricalChart.html" />
<script>
import { Chart } from 'highcharts-vue';
import StockChartResource from '../../../resources/StockChartResource';
import StockChartTooltip from './StockChartTooltip.vue';
import dayjs from 'dayjs';

const CHART_STYLE = {
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 履歴チャートコンポーネント(SP版)
 *
 * @module StockChartSp/HistoricalChart
 */
export default {
  components: {
    highcharts: Chart,
    StockChartTooltip,
  },
  props: {
    /**
     * 金融アイテムコード
     * @type {String}
     */
    code: {
      type: String,
      required: true,
    },
    /**
     * 市場ID
     * @type {Number}
     */
    exchangeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      /**
       * チャートデータ
       * @type {StockChart}
       */
      stockChart: null,
      /**
       * プロットの情報
       * @type {Object}
       */
      plotData: {},
    };
  },
  computed: {
    /**
     * ツールチップを表示するかどうか
     * @return {Boolean}
     */
    isVisible() {
      return this.$store.getters['visibleTooltip'];
    },
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          height: '200px',
          margin: [26, vueThis.yAxisLabelWidth, 46, 0],
          animation: false,
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        legend: {
          enabled: true,
          floating: false,
          align: 'left',
          verticalAlign: 'bottom',
          itemStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 10,
          },
          x: -16,
          y: 20,
        },
        title: {
          text: '',
        },
        tooltip: {
          formatter: function () {
            const price = this.y;
            const priceTime = this.x;
            const plotX = this.points[0].point.plotX;
            vueThis.chartClick({ price, priceTime, plotX });
            return false;
          },
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#64748b',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
              fillColor: '#3B82F6',
            },
          },
        },
        series: [
          {
            type: 'area',
            name: '株価',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
            showInLegend: false,
          },
          {
            type: 'line',
            name: '25日',
            data: this.ma25,
            color: '#EF4444',
            lineWidth: 1,
            animation: false,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
            events: {
              legendItemClick: function (e) {
                e.preventDefault(); // レジェンドのクリック無効
              },
            },
          },
          {
            type: 'line',
            name: vueThis.ma75Legend,
            data: this.ma75,
            color: '#2563EB',
            lineWidth: 1,
            animation: false,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
            events: {
              legendItemClick: function (e) {
                e.preventDefault(); // レジェンドのクリック無効
              },
            },
          },
        ],
        xAxis: [
          {
            tickLength: 4,
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            dateTimeLabelFormats: {
              day: '%m/%d',
              week: '%m/%d',
              month: '%Y/%m',
              year: '%Y',
            },
            max: this.xAxisMax,
            min: this.xAxisMin,
            crosshair: {
              dashStyle: 'Dash',
            },
          },
        ],
        yAxis: [
          {
            showFirstLabel: true,
            showLastLabel: true,
            gridLineColor: '#ddd',
            opposite: true,
            max: this.yAxisMax,
            min: this.yAxisMin,
            offset: vueThis.yAxisLabelWidth,
            minTickInterval: 1,
            labels: {
              format: '{value:,.0f}',
              y: 4,
            },
          },
        ],
      };
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.chartData(this.barType);
    },
    /**
     * 25日移動平均線
     * @return {Array}
     */
    ma25() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.movingAverage(this.barType, 25);
    },
    /**
     * 75日移動平均線
     * @return {Array}
     */
    ma75() {
      if (!this.stockChart) {
        return [];
      }

      return this.stockChart.movingAverage(this.barType, 75);
    },
    /**
     * 75日移動平均線
     * @return {Array}
     */
    ma75Legend() {
      return `75日　　　(${this.barPeriod.split(' ')[1]})`;
    },
    /**
     * 表示するチャートの足の種類
     * @return {String}
     */
    barType() {
      return this.$store.getters['barType'];
    },
    /**
     * 表示されている分のチャートデータ
     * @return {Array}
     */
    displayChartData() {
      const xMax = this.latestPriceDate;
      const xMin = this.startPriceDate;
      return this.chartData.filter((e) => {
        return xMin <= e[0] && e[0] <= xMax;
      });
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 0.9995;
    },
    /**
     * X軸の最大値
     * @return {Number}
     */
    xAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * X軸の最小値
     * @return {Number}
     */
    xAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * 最新の取引日
     * @return {Number}
     */
    latestPriceDate() {
      if (this.chartData.length === 0) return;

      return this.chartData[this.chartData.length - 1][0];
    },
    /**
     * X軸の描画開始日
     * @return {Number}
     */
    startPriceDate() {
      const strTerm = this.$store.getters['periodType'];
      const numTerm = this.$store.getters['periodTypeInYear'];

      let day = dayjs(this.latestPriceDate).subtract(numTerm, strTerm);
      return day.valueOf();
    },
    /**
     * ラベルごとの足(日足、週足、月足)
     * @return {String}
     */
    barPeriod() {
      return this.$store.getters['barPeriod'];
    },
    /**
     * Y軸のラベルの幅
     * @return {Number}
     */
    yAxisLabelWidth() {
      const pitch = 7.25;
      const margin = 4;
      const pitchDelimiter = 2.65;
      const words = parseInt(this.yAxisMax).toString().length;

      if (words <= 3) {
        return pitch * words + margin;
      } else {
        return pitch * words + margin + pitchDelimiter;
      }
    },
  },
  beforeUpdate() {
    this.loadStockChart();
  },
  methods: {
    /**
     * チャートを読み込む
     * @return {void}
     */
    async loadStockChart() {
      if (this.stockChart || !this.$store.getters['isHistorical']) {
        return;
      }

      const stockChartResource = new StockChartResource();
      this.stockChart = await stockChartResource.get(this.code, this.exchangeId);
    },
    /**
     * チャートクリック時にツールチップを表示する
     * @param {void}
     */
    chartClick(plotData) {
      this.$store.commit('showTooltip');
      this.plotData = plotData;
    },
  },
};
</script>
