import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import NotificationBoxStore from '../Common/NotificationBoxStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';

/**
 * 株主優待ルートストア
 *
 * @module Yutai/YutaiAppStore
 */
const YutaiAppStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    notificationBox: NotificationBoxStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
  },
};
export default YutaiAppStore;
