<template src="./FavoriteButtonHeartWithCount.html"></template>
<script>
/**
 * 個別銘柄フローティングお気に入りボタンのハートコンポーネント(お気に入り数付き)
 * @module Stocks/FavoriteButtonHeartWithCount
 */
export default {
  name: 'FavoriteButtonHeartWithCount',
  props: {
    /**
     * 金融アイテムコード
     * @type {String}
     */
    code: {
      type: String,
      required: true,
    },
    /**
     * お気に入り数
     * @type {Number}
     */
    count: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    /**
     * お気に入り登録されているかどうか
     * @returns {boolean}
     */
    isFavored() {
      return this.$store.getters['favoriteFinancialItems/isFavored'](this.code);
    },
    /**
     * お気に入り登録前後のお気に入り数
     * @returns {number}
     */
    localCount() {
      if (this.isFavored) {
        return this.count + 1;
      } else {
        return this.count;
      }
    },
  },
  async created() {
    await this.$store.dispatch('favoriteFinancialItems/init');
  },
  methods: {
    /**
     * 金融アイテムをお気に入り登録または削除
     */
    async toggle() {
      await this.$store.dispatch('favoriteFinancialItems/toggle', this.code);
    },
  },
};
</script>
