/**
 * 株主優待ランキング用フィルター
 *
 * @property {Array<string>} Vue.filterに登録するメソッド名
 * @module filters/YutaiRankingFilter
 */
class YutaiRankingFilter {
  constructor() {
    this.exportMethods = ['monthName'];
  }
  /**
   * 0は総合、1−12は月を付加する
   *
   * @params {integer} month 月番号
   * @return {string} 変換済みの文字列
   */
  monthName(month) {
    return month === 0 ? '総合' : `${month}月`;
  }
}

export default YutaiRankingFilter;
