<template src="./HistoricalFxChart.html" />
<script>
import dayjs from 'dayjs';
import NumberFormatMixin from '../../../mixins/NumberFormatMixin';
import HistoricalFxChartResource from '../../../resources/HistoricalFxChartResource.js';

const CHART_STYLE = {
  labelPosition: 12,
  labelColor: '#666',
  labelFontSize: '10px',
  tooltipBorderColor: '#666',
  fontFamily: '"Noto Sans Japanese", sans-serif',
};

/*
 * 為替の日中チャートコンポーネント
 * @vue-data {HistoricalFxChart} historicalFxChart
 * @module FxCharts/FxChart
 */
export default {
  props: {
    code: {
      type: String,
      required: false,
      default: 'USDJPY',
    },
    height: {
      type: String,
      required: false,
      default: '250px',
    },
  },
  data() {
    return {
      fxChart: null,
      codeLabel: null,
      lastDateLabel: null,
    };
  },
  computed: {
    /**
     * チャートオプション
     * @return {Object}
     */
    chartOptions() {
      const vueThis = this;
      return {
        chart: {
          height: vueThis.height,
          margin: [26, 0, 26, 0],
          pinchType: false,
          animation: false,
          events: {
            redraw: function (chart) {
              const color = CHART_STYLE.labelColor;
              const fontSize = CHART_STYLE.labelFontSize;

              if (!vueThis.codeLabel) {
                vueThis.codeLabel = chart.target.renderer
                  .text('', 0, CHART_STYLE.labelPosition)
                  .css({ color, fontSize })
                  .add();
              }
              vueThis.codeLabel.attr({
                text: `[${vueThis.code}] ${vueThis.barPeriod}`,
              });

              if (!vueThis.lastDateLabel) {
                vueThis.lastDateLabel = chart.target.renderer
                  .text('', chart.target.chartWidth - 65, CHART_STYLE.labelPosition)
                  .css({ color, fontSize })
                  .add();
              }

              if (vueThis.lastDateData) {
                vueThis.lastDateLabel.attr({
                  text: dayjs(vueThis.lastDateData).format('YYYY/MM/DD'),
                });
              }
            },
          },
          style: {
            fontFamily: CHART_STYLE.fontFamily,
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          borderColor: CHART_STYLE.tooltipBorderColor,
          xDateFormat: '%Y/%m/%d %H:%M',
          formatter: function () {
            const st = this.points.find((p) => p.series.name === '終値');
            if (!st) {
              return false;
            }

            const point = st.point;
            const dayjsPriceTime = dayjs(point.x);
            let lines = [];

            lines.push(dayjsPriceTime.format('YYYY/MM/DD'));

            lines.push(`終値：${NumberFormatMixin.methods.numFormat(point.y, 2)}`);

            return lines.join('<br />');
          },
          shared: true,
          useHTML: true,
          valueDecimals: 1,
        },
        credits: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        plotOptions: {
          area: {
            color: '#4572A7',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
            },
          },
        },
        series: [
          {
            type: 'area',
            name: '終値',
            data: this.chartData,
            animation: false,
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(69,114,167,0.4)'],
                [1, 'rgba(0,0,0,0)'],
              ],
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
            showInLegend: false,
          },
        ],
        xAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            dateTimeLabelFormats: {
              day: '%m/%d',
              week: '%m/%d',
              month: '%Y/%m',
              year: '%Y',
            },
            max: this.xAxisMax,
            min: this.xAxisMin,
          },
        ],
        yAxis: [
          {
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            labels: {
              align: 'left',
              x: 0,
            },
            gridLineColor: '#ddd',
            opposite: false,
            max: this.yAxisMax,
            min: this.yAxisMin,
          },
        ],
      };
    },
    /**
     * 最終営業日
     * @return {String}
     */
    lastDateData() {
      if (!this.fxChart) return;

      return this.fxChart.lastDateData();
    },
    /**
     * チャート描画用データ
     * @return {Array}
     */
    chartData() {
      if (!this.fxChart) {
        return [];
      }

      return this.fxChart.chartData(this.barType);
    },
    /**
     * 表示するチャートの足の種類
     * @return {String}
     */
    barType() {
      switch (this.$store.getters['chartTerm']) {
        case '6m':
        case '1y':
          return 'weekly';
        case '3y':
        case '5y':
          return 'monthly';
        case '3m':
        default:
          return 'daily';
      }
    },
    /**
     * 表示されている分のチャートデータ
     * @return {Array}
     */
    displayChartData() {
      const xMax = this.latestPriceDate;
      const xMin = this.startPriceDate;
      return this.chartData.filter((e) => {
        return xMin <= e[0] && e[0] <= xMax;
      });
    },
    /**
     * Y軸の補正値(最大)
     * @return {Number}
     */
    yAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 1.001;
    },
    /**
     * Y軸の補正値(最小)
     * @return {Number}
     */
    yAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[1]).filter((e) => e)) * 0.9995;
    },
    /**
     * X軸の最大値
     * @return {Number}
     */
    xAxisMax() {
      return Math.max(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * X軸の最小値
     * @return {Number}
     */
    xAxisMin() {
      return Math.min(...this.displayChartData.map((e) => e[0]));
    },
    /**
     * 最新の取引日
     * @return {Number}
     */
    latestPriceDate() {
      if (this.chartData.length === 0) return;

      return this.chartData[this.chartData.length - 1][0];
    },
    /**
     * X軸の描画開始日
     * @return {Number}
     */
    startPriceDate() {
      let numTerm, strTerm;
      switch (this.$store.getters['chartTerm']) {
        case '3m':
          numTerm = 3;
          strTerm = 'month';
          break;
        case '6m':
          numTerm = 6;
          strTerm = 'month';
          break;
        case '1y':
          numTerm = 1;
          strTerm = 'year';
          break;
        case '3y':
          numTerm = 3;
          strTerm = 'year';
          break;
        case '5y':
          numTerm = 5;
          strTerm = 'year';
          break;
      }

      let day = dayjs(this.latestPriceDate).subtract(numTerm, strTerm);
      return day.valueOf();
    },
    /**
     * ラベルごとの足(日足、週足、月足)
     * @return {String}
     */
    barPeriod() {
      let period;
      switch (this.$store.getters['chartTerm']) {
        case '3m':
          period = '3ヶ月 日足';
          break;
        case '6m':
          period = '6ヶ月 週足';
          break;
        case '1y':
          period = '1年 週足';
          break;
        case '3y':
          period = '3年 月足';
          break;
        case '5y':
          period = '5年 月足';
          break;
        default:
          period = '日中';
      }
      return period;
    },
  },
  beforeUpdate() {
    if (!this.fxChart && this.$store.getters['isHistorical']) {
      this.loadFxChart();
    }
  },
  methods: {
    /**
     * チャートを読み込む
     * @return {void}
     */
    async loadFxChart() {
      const resource = new HistoricalFxChartResource();
      this.fxChart = await resource.get(this.code);
    },
  },
};
</script>
