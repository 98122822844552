import VariantUtil from '../../../utils/VariantUtil';
/**
 * ベースURLとアイコン名を結合して返す
 * @param {string} base
 * @param {string} icon
 * @return {string}
 */
export const iconUrl = (base, icon) => {
  const prefix = VariantUtil.isSp && icon.includes('_to_') ? 'sp_' : '';
  return base + prefix + icon;
};
