import BaseResource from './BaseResource';
import EnvironmentUtil from '../utils/EnvironmentUtil';

/**
 * JamChart用の全部入りデータ取得
 */
class JamChartLumpResource extends BaseResource {
  /**
   * JamChart表示用データを取得
   *
   * @param code {String} 金融アイテムコード
   * @returns {Promise<Object>}
   */
  async get(code) {
    return await this.client
      .get(this._endpoint(code))
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return {};
        }
      })
      .catch(() => 'error');
  }

  _endpoint(code) {
    if (EnvironmentUtil.isProduction()) {
      return `https://assets.minkabu.jp/jsons/stock-jam/stocks/${code}/lump.json`;
    } else {
      return `https://stg-assets.minkabu.jp/jsons/stock-jam/stocks/${code}/lump.json`;
    }
  }
}
export default JamChartLumpResource;
