<template src="./ModalBody.html" />
<script>
import UrlGenerateUtil from '../../../../../../utils/UrlGenerateUtil';
import InfoComponent from './info/InfoComponent.vue';
import EvaluationComponent from './evaluation/EvaluationComponent.vue';
import SettlementComponent from './settlement/SettlementComponent.vue';
import DividendComponent from './dividend/DividendComponent.vue';
import NewsComponent from './news/NewsComponent.vue';

/**
 * 銘柄検索 個別銘柄サマリーモーダル bodyコンポーネント
 * @module StockSearch/modules/stockSummaryModal/body/ModalBody
 */
export default {
  name: 'ModalBody',
  components: {
    InfoComponent,
    EvaluationComponent,
    SettlementComponent,
    DividendComponent,
    NewsComponent,
  },
  computed: {
    premium() {
      return this.$store.getters['stockSearchInput/premium'];
    },

    /**
     * プレミアム登録とログインのコールバックURLを返す
     */
    callbackUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return (path) => {
        if (path === 'premium_lp') {
          return UrlGenerateUtil.premiumPath({
            currentUrl: window.location.href,
            source: 'shoken_minkabu',
            medium: 'modal',
            campaign: '/stock/search',
          });
        } else {
          return UrlGenerateUtil.idMinkabuUrl({
            pathString: path,
            currentUrl: window.location.href,
            source: 'shoken_minkabu',
            medium: 'modal',
            campaign: '/stock/search',
          });
        }
      };
    },
  },
};
</script>
