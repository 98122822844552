<template src="./BaseModal.html"></template>
<script>
/**
 * モーダルコンポーネント
 * @module Common/BaseModal
 */
export default {
  name: 'BaseModal',
};
</script>
