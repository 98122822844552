/**
 * お気に入りボタンを押下した際のモーダルの状態
 * @property {boolean} isVisible モーダルの状態[true: 表示, false: 表示しない]
 */
const FavoriteSignUpModalStore = {
  namespaced: true,
  state: {
    isVisible: false,
  },
  getters: {
    /* @returns {boolean} モーダルが表示されるなら真 */
    isVisible(state) {
      return state.isVisible;
    },
  },
  mutations: {
    /**
     * モーダル表示
     * @returns {void}
     */
    show(state) {
      state.isVisible = true;
    },
    /**
     * モーダル非表示
     * @returns {void}
     */
    hide(state) {
      state.isVisible = false;
    },
  },
};
export default FavoriteSignUpModalStore;
