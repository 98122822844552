<template src="./SettlementComponent.html" />
<script>
/**
 * 銘柄検索 個別銘柄サマリーモーダル 本決算コンポーネント
 * @module StockSearch/modules/StockSummaryModal/body/settlement/SettlementComponent
 */
export default {
  name: 'SettlementComponent',
  computed: {
    summaryData() {
      return this.$store.getters['stockSearchInput/summaryData'];
    },
    fiscalDates() {
      const obj = JSON.parse(JSON.stringify(this.summaryData.settlement.fiscalDates));
      obj.result = obj.result.slice(2);
      obj.projection = obj.projection.slice(2);
      return obj;
    },
    settlement() {
      const obj = JSON.parse(JSON.stringify(this.summaryData.settlement));
      const names = ['sales', 'operatingIncome', 'ordinaryIncome', 'netIncome'];

      for (const name of names) {
        obj[name].data.result = this.formatJpUnit(obj[name].data.result);
        obj[name].data.projection = this.formatJpUnit(obj[name].data.projection);
        obj[name].data.difference = this.formatJpUnit(obj[name].data.difference, true);
      }
      return obj;
    },
    labelClass() {
      return [
        '',
        'minkabuRedUp',
        'minkabuBlueDown',
        '',
        'minkabuBlueDown',
        'minkabuRedUp',
        'minkabuRedUp',
        'minkabuBlueDown',
      ];
    },
  },
  methods: {
    /**
     * 兆億万円表記に変換する
     * @param {*} number
     * @param {*} useSign 符号をつけるかどうか
     * @returns {Object}
     */
    formatJpUnit(number, useSign = false) {
      if (number === null) {
        return {
          price: null,
          unit: null,
        };
      }
      if (Number(number) === 0) {
        return {
          price: '0.0',
          unit: '円',
        };
      }

      // numberの省略単位（百万）
      const restore = 1000000;
      const log = Math.floor(Math.log10(Math.abs(number) * restore) / 4);
      const unit = ['万円', '万円', '億円', '兆円'];

      // 符号
      const sign = () => {
        if (Math.sign(number) < 0) {
          return '-';
        } else if (useSign && Math.sign(number) > 0) {
          return '+';
        }
        return '';
      };

      const format = () => {
        if (log === 0) {
          // 1万以下の場合は、0.n万円にする
          return Math.floor(number * 100);
        }
        return (Math.floor((Math.abs(number * restore) / Math.pow(10000, log)) * 10) / 10).toLocaleString(undefined, {
          minimumFractionDigits: 1,
        });
      };

      return {
        price: sign() + format(),
        unit: unit[log],
      };
    },
  },
};
</script>
