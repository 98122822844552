<template src="./ItemForListDisplay.html"></template>

<script>
export default {
  name: 'ItemForListDisplay',
  props: {
    articleItem: {
      type: Object,
      required: true
    }  
  },
};
</script>
