import BaseResource from './BaseResource';
import IntradayStockChart from '../models/StockCharts/IntradayStockChart.js';
/* *
 * 日中足API
 * */
class IntradayStockChartResource extends BaseResource {
  /*
   * 日中足データを取得する
   * @param {String} code 金融アイテムコード
   * @param {Number} exchangeId 市場コード
   * @param {String} dateString YYYY-mm-dd
   * @param {Number} coefficient 株式異動係数
   * @return {IntradayStockChart}
   */
  async get(code, exchangeId, dateString, coefficient = 1.0) {
    const url = this._toUrl(code, exchangeId, dateString);
    return await this.client
      .get(url)
      .then((response) => {
        let prices = [];
        let lastPrice = null;
        if (response.status !== 200) {
          // 正常値以外はどのみち表示できないので空データを返す
          return new IntradayStockChart(prices, lastPrice, dateString);
        }
        const data = response.data;
        prices = this._toPrices(data.items, coefficient);
        lastPrice = this._toLastPrice(data.last_price, coefficient);
        return new IntradayStockChart(prices, lastPrice, dateString);
      })
      .catch(() => {
        return 'error';
      });
  }

  /*
   * 日中足データのURL
   * @return {string}
   */
  _toUrl(code, exchangeId, dateString) {
    const yyyymmdd = dateString.split('-').join('');
    const hostname = this._jsonHostname();
    return `${hostname}/intraday/${yyyymmdd}/${code}_${exchangeId}.json?_=${new Date().getTime()}`;
  }

  _toPrices(items, coefficient) {
    if (!Array.isArray(items) || items.length === 0) {
      return [];
    }

    return items.map((item) => [item[0], this._truncate(item[1] * coefficient, 1)]);
  }

  _toLastPrice(last_price, coefficient) {
    if (!last_price || Object.keys(last_price).length === 0) {
      return null;
    }
    return this._truncate(last_price.close * coefficient, 1);
  }

  /**
   * 切り捨て
   * @param {Number} value
   * @param {Number} digits
   * @returns {Number}
   */
  _truncate(value, digits) {
    const pow = Math.pow(10, digits);
    return Math.floor(value * pow) / pow;
  }
}
export default IntradayStockChartResource;
