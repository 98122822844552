<template src="./SecuritiesCompaniesButton.html"></template>
<script>

/**
 * 証券会社リンクコンポーネント
 * @vue-prop {String} securitiesCompanies 証券会社情報
 * @vue-data {boolean} showDropDown ドロップダウン表示フラグ
 * @vue-computed {JSON} securitiesCompaniesJson 証券会社情報(JSON形式)
 * @module Stock/securitiesCompaniesButton
 */
export default {
  name: 'SecuritiesCompaniesButton',
  props: {
    securitiesCompanies: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDropDown: false
    };
  },
  computed: {
    securitiesCompaniesJson() {
      return JSON.parse(this.securitiesCompanies);
    }
  },
  /**
   * 枠外クリック時にドロップダウンを閉じる処理
   */
  mounted() {
    window.addEventListener('click', this._onBlurHandler = (event) => {
      // クリック位置がコンポーネント内なら何もしない
      if (this.$el.contains(event.target)) {
        return;
      }
      this.showDropDown = false;
    });
  },
  /**
   * 枠外クリック時にドロップダウンを閉じる処理（後始末）
   */
  beforeDestroy() {
    // コンポーネントが破棄されるタイミングにイベントリスナーも消す
    window.removeEventListener('click', this._onBlurHandler);
  }
};
</script>
