<template src="./PeriodLabel.html" />
<script>
export default {
  props: {
    /**
     * 海外銘柄かどうか
     * @type {Boolean}
     */
    isForeign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    /**
     * 期間の設定
     * @return {Array<Object>}
     */
    termTabs() {
      return [
        { term: 'today', label: '日中' },
        { term: '3m', label: '3ヶ月' },
        { term: '6m', label: '6ヶ月' },
        { term: '1y', label: '1年' },
        { term: '3y', label: '3年' },
        { term: '5y', label: '5年' },
      ];
    },
    /**
     * 現在のタブ
     * @return {string}
     */
    currentTerm() {
      return this.$store.getters['chartTerm'];
    },
  },
  mounted() {
    this.initTerm();
  },
  methods: {
    /**
     * タブが選択されている場合のCSSクラス
     * @return {string}
     */
    tabClass(tabTerm) {
      return tabTerm === this.currentTerm ? 'cur' : '';
    },
    /**
     * タブの変更
     * @return {void}
     */
    changeTerm(term) {
      this.$store.commit('chartTerm', term);
    },
    /**
     * タブ毎のref属性名
     * @param {String} term
     * @return {String}
     */
    formatRef(term) {
      return `periodLabel${term}`;
    },
    /**
     * タブが有効かどうか
     * @param {String} term
     * @return {Boolean}
     */
    isAvailable(term) {
      return !(term === 'today' && this.isForeign);
    },
    /**
     * タブの変更(海外銘柄)
     * @note デフォルトがtodayなので、海外銘柄でない場合は何も処理しない
     * @return {void}
     */
    initTerm() {
      if (this.isForeign) {
        this.$store.commit('chartTerm', '3m');
      }
    },
  },
};
</script>
