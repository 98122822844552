<template src="./PayoutRatioDistributionChart.html"></template>
<script>
import VariantUtil from '../../../utils/VariantUtil';
import { Chart } from 'highcharts-vue';

/*
 * 個別銘柄配当ページの全市場配当性向分布チャートコンポーネント
 *
 * @vue-prop {Dividends::ReadList::PayoutRatioDistributionCharts::ViewModel} chartElements
 * @module PayoutRatioDistributionChart
 */

export default {
  name: 'PayoutRatioDistributionChart',
  components: {
    highcharts: Chart,
  },
  props: {
    chartElements: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: this.readHeight,
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        xAxis: {
          title: {
            text: '',
          },
          categories: this.chartElements['distribution_ranges'].map(
            (_, i) => this.chartElements['distribution_ranges'][i]
          ),
          labels: {
            x: 18,
            style: {
              fontSize: 12,
              fontWeight: 'bold',
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: 12,
            },
          },
          title: {
            text: '（銘柄）',
            rotation: 0,
            align: 'low',
            offset: 0,
            y: 20,
            x: -54,
          },
          min: 0,
        },
        series: [
          {
            data: this.seriesDataArray,
          },
        ],
        plotOptions: {
          series: {
            pointWidth: this.readPointWidth,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            if (this.point.onTooltip == true) {
              return (
                '<span class="dividend-legend dividend-legend--base-expected"></span> ' +
                `当銘柄の配当性向: ${this.point.payoutRatio}`
              );
            }
            return false;
          },
        },
        credits: {
          enabled: false,
        },
      };
    },
    seriesDataArray() {
      return this.chartElements['distribution_values'].map((value, key_id) => {
        let colorCode = key_id === this.chartElements['value_located_range_idx'] ? '#4294F3' : '#BECAD3';
        let tooltipEnabled = key_id === this.chartElements['value_located_range_idx'] ? true : false;
        let payoutRatio =
          key_id === this.chartElements['value_located_range_idx'] ? this.chartElements['payout_ratio'] : null;
        return {
          y: value,
          color: colorCode,
          onTooltip: tooltipEnabled,
          payoutRatio: payoutRatio,
        };
      });
    },
    readHeight() {
      return VariantUtil.isSp() ? 220 : 330;
    },
    readPointWidth() {
      return VariantUtil.isSp() ? 24 : 50;
    },
  },
};
</script>
