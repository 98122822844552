<template src="./StockSearchPaginationText.html" />
<script>
/**
 * 銘柄検索条件画面 ページネーションテキストコンポーネント
 * @module StockSearch/modules/pagination/StockSearchPaginationText
 */
export default {
  name: 'StockSearchPaginationText',
  computed: {
    total() {
      return this.$store.getters['stockSearchInput/total'];
    },
    offsetValue() {
      return this.$store.getters['stockSearchInput/result'].pagination.offsetValue + 1;
    },
    count() {
      return this.offsetValue + this.$store.getters['stockSearchInput/result'].pagination.count - 1;
    },
  },
};
</script>
