<template src="./QuarterlyDPSDetailsChart.html"></template>

<script>
import { Chart } from 'highcharts-vue';
import VariantUtil from '../../../utils/VariantUtil';

/*
 * 個別銘柄配当ページの配当額推移(四半期配当)チャートコンポーネント
 *
 * @vue-prop {Hash} quarterlyDpsDetails
 * @module quarterlyDpsDetailsChart
 */

export default {
  name: 'QuarterlyDPSDetailsChart',
  props: {
    quarterlyDpsDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: this.readHeight,
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        lang: {
          decimalPoint: '.',
          thousandsSep: ',',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: '',
          },
          offset: 1,
          lineWidth: 2,
          lineColor: '#808080',
          categories: this.categoriesData,
          labels: {
            autoRotationLimit: 40,
            style: {
              fontSize: 12,
            },
          },
        },
        yAxis: [
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: -35,
              style: {
                fontSize: 12,
              },
            },
            min: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: 35,
              style: {
                fontSize: 12,
              },
            },
            opposite: true,
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
        ],
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: '第4四半期配当',
            data: this.q4DpsData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'column',
            name: '第3四半期配当',
            data: this.q3DpsData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'column',
            name: '第2四半期配当',
            data: this.q2DpsData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'column',
            name: '第1四半期配当',
            data: this.q1DpsData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'line',
            color: '#3F3E83',
            name: '1株あたりの純利益',
            data: this.seriesLineData,
            yAxis: 1,
            zoneAxis: 'x',
            zones: [{ value: 3 }, { dashStyle: 'ShortDash' }],
          },
        ],
        tooltip: {
          shared: true,
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            return this.points.reduce(function (s, p) {
              let tooltipShape = '';
              if (p.key === '第1四半期配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--1q-expected'
                  : 'dividend-legend dividend-legend--1q';
              } else if (p.key === '第2四半期配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--base-expected'
                  : 'dividend-legend dividend-legend--base';
              } else if (p.key === '第3四半期配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--3q-expected'
                  : 'dividend-legend dividend-legend--3q';
              } else if (p.key === '第4四半期配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--4q-expected'
                  : 'dividend-legend dividend-legend--4q';
              } else if (p.key === '調整後配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--base-expected'
                  : 'dividend-legend dividend-legend--adjusted-dps';
              } else if (p.key === '1株あたりの純利益') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--eps-expected'
                  : 'dividend-legend dividend-legend--eps';
              }
              return (
                s +
                '<br/>' +
                `<span class="${tooltipShape}"></span> ` +
                `${p.key}:${p.y.toLocaleString('ja-JP', { minimumFractionDigits: p.point.precision })}${p.point.unit}`
              );
            }, '<span style="font-size:12px">' + this.points[0].point.period);
          },
        },
      };
    },
    /**
     * 配当額推移チャートの第1四半期配当の表示用のデータを返す
     * @return {Array}
     */
    q1DpsData() {
      return this.quarterlyDpsDetails['q1_dps_array'].map((q1Dps, idx) => ({
        y: this.q1DpsValue(q1Dps, idx),
        name: this.tooltipName('第1四半期配当', idx),
        period: this.tooltipPeriod(this.quarterlyDpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.q1DpsChartColor(idx),
      }));
    },
    /**
     * 配当額推移チャートの第2四半期配当の表示用のデータを返す
     * @return {Array}
     */
    q2DpsData() {
      return this.quarterlyDpsDetails['q2_dps_array'].map((q2Dps, idx) => ({
        y: this.q2DpsValue(q2Dps, idx),
        name: this.tooltipName('第2四半期配当', idx),
        period: this.tooltipPeriod(this.quarterlyDpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.q2DpsChartColor(idx),
      }));
    },
    /**
     * 配当額推移チャートの第3四半期配当の表示用のデータを返す
     * @return {Array}
     */
    q3DpsData() {
      return this.quarterlyDpsDetails['q3_dps_array'].map((q3Dps, idx) => ({
        y: this.q3DpsValue(q3Dps, idx),
        name: this.tooltipName('第3四半期配当', idx),
        period: this.tooltipPeriod(this.quarterlyDpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.q3DpsChartColor(idx),
      }));
    },
    /**
     * 配当額推移チャートの第4四半期配当の表示用のデータを返す
     * @return {Array}
     */
    q4DpsData() {
      return this.quarterlyDpsDetails['q4_dps_array'].map((q4Dps, idx) => ({
        y: this.q4DpsValue(q4Dps, idx),
        name: this.tooltipName('第4四半期配当', idx),
        period: this.tooltipPeriod(this.quarterlyDpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.q4DpsChartColor(idx),
      }));
    },
    /**
     * 1株あたりの純利益のチャート表示用のデータを返す
     * @return {Array}
     */
    seriesLineData() {
      return this.quarterlyDpsDetails['eps_array'].map((eps, idx) => ({
        y: eps == undefined ? null : Math.trunc(eps * 100) / 100,
        name: '1株あたりの純利益',
        period: this.tooltipPeriod(this.quarterlyDpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: '#3F3E83',
        marker: this.isProjection(idx)
          ? { radius: 5, lineColor: '#3F3E83', lineWidth: 2, fillColor: '#FFFFFF' }
          : { radius: 5 },
      }));
    },
    /**
     * x軸に表示するメモリの値を返す
     * @return {Array}
     */
    categoriesData() {
      return this.quarterlyDpsDetails['fiscal_periods'].map((period, idx) => this.tooltipPeriod(period, idx));
    },
    /**
     * SPかどうか判定して高さを返す
     * @return {Number}
     */
    readHeight() {
      return VariantUtil.isSp() ? 220 : 365;
    },
  },
  methods: {
    /**
     * 第1四半期配当のチャートで表示する値を返す
     * 予想または実績データが全て揃っていなければ調整後の値を出す
     * 予想または実績データの合計と調整後の値が一致しない場合調整後の値を出す
     * @params {Number} q1Dps
     * @params {Number} idx
     * @return {String}
     */
    q1DpsValue: function (q1Dps, idx) {
      let value = q1Dps;
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        value = this.quarterlyDpsDetails['adjusted_dps_array'].slice(-1)[0];
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        value = this.quarterlyDpsDetails['adjusted_dps_array'][idx];
      }
      return value == undefined ? null : Math.floor(value * 100) / 100;
    },
    /**
     * 第2四半期配当のチャートで表示する値を返す
     * 積み上げチャートのため第2四半期配当では
     * 予想または実績データが全て揃っていない場合値をnullにしておく
     * 予想または実績データの合計と調整後の値が一致しない場合値をnullにしておく
     * @params {Number} q2Dps
     * @params {Number} idx
     * @return {String}
     */
    q2DpsValue: function (q2Dps, idx) {
      let value = q2Dps;
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        return null;
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        return null;
      }
      return value == undefined ? null : Math.floor(value * 100) / 100;
    },
    /**
     * 第3四半期配当のチャートで表示する値を返す
     * 積み上げチャートのため第3四半期配当では
     * 予想または実績データが全て揃っていない場合値をnullにしておく
     * 予想または実績データの合計と調整後の値が一致しない場合値をnullにしておく
     * @params {Number} q3Dps
     * @params {Number} idx
     * @return {String}
     */
    q3DpsValue: function (q3Dps, idx) {
      let value = q3Dps;
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        return null;
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        return null;
      }
      return value == undefined ? null : Math.floor(value * 100) / 100;
    },
    /**
     * 第4四半期配当のチャートで表示する値を返す
     * 積み上げチャートのため第4四半期配当では
     * 予想または実績データが全て揃っていない場合値をnullにしておく
     * 予想または実績データの合計と調整後の値が一致しない場合値をnullにしておく
     * @params {Number} q4Dps
     * @params {Number} idx
     * @return {String}
     */
    q4DpsValue: function (q4Dps, idx) {
      let value = q4Dps;
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        return null;
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        return null;
      }
      return value == undefined ? null : Math.floor(value * 100) / 100;
    },
    /**
     * 第1四半期配当のチャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    q1DpsChartColor: function (idx) {
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        return '#4294F3';
      } else if (this.isProjection(idx)) {
        return '#56BFFC';
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        return '#B0C0E1';
      } else {
        return '#D7E1E7';
      }
    },
    /**
     * 第2四半期配当のチャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    q2DpsChartColor: function (idx) {
      return this.isProjection(idx) ? '#4294F3' : '#BECAD3';
    },
    /**
     * 第3四半期配当のチャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    q3DpsChartColor: function (idx) {
      return this.isProjection(idx) ? '#2C68F1' : '#98A5B0';
    },
    /**
     * 第4四半期配当のチャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    q4DpsChartColor: function (idx) {
      return this.isProjection(idx) ? '#0037FA' : '#758096';
    },
    /**
     * ツールチップに表示する年度情報を返す
     * @params {String} period
     * @params {Number} idx
     * @return {String}
     */
    tooltipPeriod: function (period, idx) {
      const array = period.split('.');
      if (this.isProjection(idx)) {
        return array[0] + '年' + array[1] + '月期 (予想)';
      } else {
        return array[0] + '年' + array[1] + '月期';
      }
    },
    /**
     * ツールチップに表示する名称を返す
     * @params {String} 名称
     * @params {Number} idx
     * @return {String}
     */
    tooltipName: function (name, idx) {
      if (this.isProjection(idx) && this.quarterlyDpsDetails['projection_adjusted']) {
        return '調整後配当';
      } else if (this.quarterlyDpsDetails['result_adjusted_array'][idx]) {
        return '調整後配当';
      } else {
        return name;
      }
    },
    /**
     * 予想データ列ならtrueを返す
     * @params {Number} idx
     * @return {Boolean}
     */
    isProjection: function (idx) {
      return idx === this.quarterlyDpsDetails['fiscal_periods'].length - 1;
    },
  },
};
</script>
