/**
 * XMLHttpRequestの状態
 *
 * @property {string} loadingStatus ローディング状態 [true, false]
 * @module BaseLoading
 */
const BaseLoading = {
  namespaced: true,
  state: {
    loadingStatus: false
  },
  mutations: {
    setLoadingStatus (state, status) {
      state.loadingStatus = status;
    }
  },
  actions: {
    uploadLoadingStatus ({ commit }, status) {
      commit('setLoadingStatus', status);
    },
  },
};

export default BaseLoading;
