/**
 * Variantの判定を行うクラス
 */
class VariantUtil {
  /**
   * @returns {boolean} VariantがSPかどうかbodyタグのidで判定する
   */
  static isSp() {
    const dom = window.document.getElementById('contents_wrapper_sp');
    if (dom) {
      return true;
    } else {
      return false;
    }
  }
}
export default VariantUtil;
