import FavoriteFinancialItemsStore from '../Common/FavoriteFinancialItemsStore';
import FavoriteSignUpModalStore from '../Modal/FavoriteSignUpModalStore';
/**
 * テーマ ルートストア
 *
 * @module Themes/ThemeStore
 */
const ThemeStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
  },
};
export default ThemeStore;
