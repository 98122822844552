import RelationshipPercentagesGraph from './RelationshipPercentagesGraph.vue';
import FavoriteButtonWithCount from '../../Common/FavoriteButtonWithCount.vue';
import FavoriteSignUpModal from '../../Modal/FavoriteSignUpModal.vue';

/**
 * テーマ詳細ページ ルートコンポーネント
 *
 * @vue-components {RelationshipPercentagesGraph} 関連度グラフコンポーネント
 * @module Theme
 */
const Theme = {
  el: '#v-theme',
  components: {
    RelationshipPercentagesGraph,
    FavoriteButtonWithCount,
    FavoriteSignUpModal,
  },
  data() {
    return {
      favoriteButton: [
        ['flex', 'flex-col', 'justify-center', 'text-slate-500', 'block', 'cursor-pointer'],
        ['flex', 'justify-center', 'ui-favorite-button-click', 'ui-favorite-text-active'],
        ['relative', 'text-xss', 'text-center', 'w-7.5'],
        ['ui-favorite-button', 'ui-favorite-button-normal', 'ui-favorite-button-active'],
        ['-mt-1', 'leading-4'],
        [],
        ['hidden'],
      ],
    };
  },
  methods: {
    /**
     * ログインモーダルの表示
     */
    async loginModalOpen() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
export default Theme;
