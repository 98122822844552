import BaseResource from './BaseResource';

/* *
 * レポート銘柄API
 * */
class ReportFinancialItemResource extends BaseResource {
  /*
   * レポート銘柄登録済み金融アイテムコードリストAPIをコールし
   * レポート銘柄登録済み金融アイテムコードリストを取得
   * @return {array<string>} 金融アイテムリスト
   */
  async get() {
    const url = '/report_financial_items';
    return await this.client.get(url)
      .then(response => response.data.items.map(report => report.code))
      .catch(() => { return 'error'; });
  }

  /*
   * レポート銘柄登録APIをコールしてレポート銘柄金融アイテムに追加
   * @param {string} financialItemCode 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async create(financialItemCode) {
    return await this.client.post('/report_financial_items', { financial_item_code: financialItemCode });
  }

  /*
   * レポート銘柄解除APIをコールしてレポート銘柄金融アイテムから削除
   * @param {string} financialItemCode 金融アイテムコード
   * @return {Promise} リクエスト結果
   */
  async delete(financialItemCode) {
    return await this.client.delete(`/report_financial_items/${financialItemCode}`, { data: { code: financialItemCode } } );
  }
}
export default ReportFinancialItemResource;
