import YutaiSearchYieldRangeSlider from './YutaiSearchYieldRangeSlider.vue';
import YutaiSearchMinimumPurchasePriceRangeSlider from './YutaiSearchMinimumPurchasePriceRangeSlider.vue';
import YutaiSearchCheckboxes from './YutaiSearchCheckboxes.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';

/**
 * 検索ボックスとリンク集切り替え表示のルートコンポーネント
 * @vue-data {string} selectedTab
 * @vue-computed {boolean} isCategoriesTabSelected カテゴリ別人気ランキングから探すタブが選択されていれば真
 * @vue-computed {boolean} isConditionsTabSelected キーワード及び条件から探すタブが選択されていれば真
 * @module Yutai/YutaiSearchOrLinks
 */
const YutaiSearchOrLinks = {
  el: '#v-yutai-search-or-links',
  components: {
    yutaiSearchYieldRangeSlider: YutaiSearchYieldRangeSlider,
    yutaiSearchMinimumPurchasePriceRangeSlider: YutaiSearchMinimumPurchasePriceRangeSlider,
    yutaiSearchCheckboxes: YutaiSearchCheckboxes,
    CampaignBanner,
  },
  data: {
    selectedTab: 'categoriesTab',
  },
  computed: {
    isCategoriesTabSelected() {
      return this.selectedTab === 'categoriesTab';
    },
    isConditionsTabSelected() {
      return this.selectedTab === 'conditionsTab';
    },
  },
  created() {
    this.selectTab(document.querySelector('#v-yutai-search-or-links').dataset.tabName);
  },
  methods: {
    /**
     * タブを選択する
     *
     * @param {string} tabName
     */
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
  },
};
export default YutaiSearchOrLinks;
