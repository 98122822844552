import DeviationRateChartVue from './DeviationRateChart.vue';

/**
 * 個別銘柄のチャートページの乖離率チャートのルートコンポーネント
 * @module DeviationRates/DeviationRateChart
 */
const DeviationRateChart = {
  el: '#v-deviation-rate-chart',
  components: {
    DeviationRateChart: DeviationRateChartVue
  }
};

export default DeviationRateChart;
