<template src="./AccordionWrapper.html" />
<script>
import AccordionInfo from './info/AccordionInfo.vue';
import AccordionChart from '../chart/ChartComponent.vue';
import AccordionButtons from './buttons/AccordionButtons.vue';
import AccordionActivity from './activity/AccordionActivity.vue';
/**
 * お気に入り銘柄アコーディオンコンポーネント
 * @vue-components {AccordionInfo} AccordionInfo 株価情報
 * @vue-components {AccordionChart} AccordionChart チャート
 * @vue-components {AccordionControl} AccordionControl ボタン類
 * @vue-components {AccordionActivity} AccordionActivity アクティビティ
 * @module Mypage/AccordionWrapper
 */
export default {
  name: 'AccordionWrapper',
  components: {
    AccordionInfo,
    AccordionChart,
    AccordionButtons,
    AccordionActivity,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
