<template src="./DPSDetailsChart.html"></template>

<script>
import { Chart } from 'highcharts-vue';
import VariantUtil from '../../../utils/VariantUtil';

/*
 * 個別銘柄配当ページの配当額推移チャートコンポーネント
 *
 * @vue-prop {Hash} dpsDetails
 * @module dpsDetailsChart
 */

export default {
  name: 'DPSDetailsChart',
  props: {
    dpsDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: this.readHeight,
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        lang: {
          decimalPoint: '.',
          thousandsSep: ',',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: '',
          },
          offset: 1,
          lineWidth: 2,
          lineColor: '#808080',
          categories: this.dpsDetails['fiscal_periods'].map((period, idx) =>
            idx === this.dpsDetails['fiscal_periods'].length - 1 ? `${period}<br>(予想)` : period
          ),
          labels: {
            autoRotationLimit: 40,
            style: {
              fontSize: 12,
            },
          },
        },
        yAxis: [
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: -35,
              style: {
                fontSize: 12,
              },
            },
            min: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: 35,
              style: {
                fontSize: 12,
              },
            },
            opposite: true,
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
            },
          },
        ],
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: '期末配当',
            data: this.termEndData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'column',
            name: '中間配当',
            data: this.interimData,
            pointWidth: 40,
            yAxis: 0,
            stack: 0,
          },
          {
            type: 'line',
            color: '#3F3E83',
            name: '1株あたりの純利益',
            data: this.seriesLineData,
            yAxis: 1,
            zoneAxis: 'x',
            zones: [{ value: 3 }, { dashStyle: 'ShortDash' }],
          },
        ],
        tooltip: {
          shared: true,
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            return this.points.reduce(function (s, p) {
              let tooltipShape = '';
              if (p.key === '中間配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--base-expected'
                  : 'dividend-legend dividend-legend--base';
              } else if (p.key === '期末配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--end-expected'
                  : 'dividend-legend dividend-legend--end';
              } else if (p.key === '調整後配当') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--base-expected'
                  : 'dividend-legend dividend-legend--adjusted-dps';
              } else if (p.key === '1株あたりの純利益') {
                tooltipShape = p.point.period.match(/予想/)
                  ? 'dividend-legend dividend-legend--eps-expected'
                  : 'dividend-legend dividend-legend--eps';
              }
              return (
                s +
                '<br/>' +
                `<span class="${tooltipShape}"></span> ` +
                `${p.key}:${p.y.toLocaleString('ja-JP', { minimumFractionDigits: p.point.precision })}${p.point.unit}`
              );
            }, '<span style="font-size:12px">' + this.points[0].point.period);
          },
        },
      };
    },
    /**
     * 配当額推移のチャートの中間配当の表示用のデータを返す
     * @return {Array}
     */
    interimData() {
      return this.dpsDetails['interim_array'].map((interim, idx) => ({
        y: this.interimValue(interim, idx),
        name: this.tooltipName('中間配当', idx),
        period: this.tooltipPeriod(this.dpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.interimChartColor(idx),
      }));
    },
    /**
     * 配当額推移のチャートの期末配当の表示用のデータを返す
     * @return {Array}
     */
    termEndData() {
      return this.dpsDetails['term_end_array'].map((term_end, idx) => ({
        y: this.termEndValue(term_end, idx),
        name: this.tooltipName('期末配当', idx),
        period: this.tooltipPeriod(this.dpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: this.termEndChartColor(idx),
      }));
    },
    /**
     * 1株あたりの純利益のチャート表示用のデータを返す
     * @return {Array}
     */
    seriesLineData() {
      return this.dpsDetails['eps_array'].map((eps, idx) => ({
        y: eps == undefined ? null : Math.trunc(eps * 100) / 100,
        name: '1株あたりの純利益',
        period: this.tooltipPeriod(this.dpsDetails['fiscal_periods'][idx], idx),
        precision: 2,
        unit: '円',
        color: '#3F3E83',
        marker: this.isProjection(idx)
          ? { radius: 5, lineColor: '#3F3E83', lineWidth: 2, fillColor: '#FFFFFF' }
          : { radius: 5 },
      }));
    },
    /**
     * x軸に表示するメモリの値を返す
     * @return {Array}
     */
    categoriesData() {
      return this.dpsDetails['fiscal_periods'].map((period, idx) => this.tooltipPeriod(period, idx));
    },
    /**
     * SPかどうか判定して高さを返す
     * @return {Number}
     */
    readHeight() {
      return VariantUtil.isSp() ? 220 : 365;
    },
  },
  methods: {
    /**
     * 中間配当のチャートで表示する値を返す
     * 予想データが全て揃っていなければ調整後の値を出す
     * 予想データの合計と調整後の値が一致しない場合調整後の値を出す
     * @params {Number} interim
     * @params {Number} idx
     * @return {String}
     */
    interimValue: function (interim, idx) {
      let value = interim;
      if (this.isProjection(idx) && this.dpsDetails['projection_adjusted']) {
        value = this.dpsDetails['adjusted_dps_array'].slice(-1)[0];
      } else if (this.dpsDetails['result_adjusted_array'][idx]) {
        value = this.dpsDetails['adjusted_dps_array'][idx];
      }
      return value == undefined ? null : Math.floor(value * 100) / 100;
    },
    /**
     * 期末配当のチャートで表示する値を返す
     * 積み上げチャートのため期末配当側では
     * 予想または実績データが全て揃っていない場合値をnullにしておく
     * 予想または実績データの合計と調整後の値が一致しない場合値をnullにしておく
     * @params {Number} termEnd
     * @params {Number} idx
     * @return {String}
     */
    termEndValue: function (termEnd, idx) {
      if (this.isProjection(idx) && this.dpsDetails['projection_adjusted']) {
        return null;
      } else if (this.dpsDetails['result_adjusted_array'][idx]) {
        return null;
      }
      return termEnd == undefined ? null : Math.floor(termEnd * 100) / 100;
    },
    /**
     * 中間配当のチャートの色を返す
     * 調整後配当の値を使っているなら調整後配当の色を返す
     * @params {Number} idx
     * @return {String}
     */
    interimChartColor: function (idx) {
      if (this.isProjection(idx)) {
        return '#4294F3';
      } else if (this.dpsDetails['result_adjusted_array'][idx]) {
        return '#B0C0E1';
      } else {
        return '#BECAD3';
      }
    },
    /**
     * 期末配当のチャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    termEndChartColor: function (idx) {
      return this.isProjection(idx) ? '#2C68F1' : '#98A5B0';
    },
    /**
     * ツールチップに表示する年度情報を返す
     * @params {String} period
     * @params {Number} idx
     * @return {String}
     */
    tooltipPeriod: function (period, idx) {
      const array = period.split('.');
      if (this.isProjection(idx)) {
        return array[0] + '年' + array[1] + '月期 (予想)';
      } else {
        return array[0] + '年' + array[1] + '月期';
      }
    },
    /**
     * ツールチップに表示する名称を返す
     * @params {String} name
     * @params {Number} idx
     * @return {String}
     */
    tooltipName: function (name, idx) {
      if (this.isProjection(idx) && this.dpsDetails['projection_adjusted']) {
        return '調整後配当';
      } else if (this.dpsDetails['result_adjusted_array'][idx]) {
        return '調整後配当';
      } else {
        return name;
      }
    },
    /**
     * 予想データ列ならtrueを返す
     * @params {Number} idx
     * @return {Boolean}
     */
    isProjection: function (idx) {
      return idx === this.dpsDetails['fiscal_periods'].length - 1;
    },
  },
};
</script>
