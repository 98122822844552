<template src="./LowestPurchasePriceYutai.html" />

<script>
import FeeRankingSecurity from '../../models/FeeRankingSecurity';
/**
 * 最安購入金額コンポーネント(優待用)
 *
 * @vue-prop {FeeRankingSecurity} security 手数料最安の証券会社
 * @module Fee/LowestPurchasePrice
 */
export default {
  name: 'LowestPurchasePriceYutai',
  props: {
    security: {
      type: FeeRankingSecurity,
      required: true
    }
  }
};
</script>
