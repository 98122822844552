import HistoricalChartComponent from '../../modules/StockChart/HistoricalChart.vue';
import PeriodLabel from './PeriodLabel.vue';

/*
 * 個別銘柄チャートページ
 *
 */
const StockChart = {
  el: '#v-stock-chart-page',
  components: {
    historicalChart: HistoricalChartComponent,
    periodLabel: PeriodLabel,
  },
};

export default StockChart;
