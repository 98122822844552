import dayjs from 'dayjs';

class StockCalendar {
  /**
   * 営業日の文字列の配列
   * @param {Object} json
   */
  constructor(json) {
    this.items = json.items;
    this.events = json.events;
  }

  /**
   * 最新営業日
   * @note 当日が土日祝の場合は直近の営業日、引数で渡された時刻を境に取得する日時を変更している
   * @param {String} 日付の区切り時刻
   * @returns
   */
  currentDay(switchingTime = '00:00') {
    const now = dayjs();
    for (let i = this.items.length - 1; i >= 0; i--) {
      const day = dayjs(`${this.items[i]}T${switchingTime}:00.000+09:00`);
      if (day.isBefore(now)) {
        return this.items[i];
      }
    }
  }

  /**
   * 最新営業日の1営業日前
   * @returns
   */
  priorDay(switchingTime = '00:00') {
    const currentDayIndex = this.items.indexOf(this.currentDay(switchingTime));
    return this.items[currentDayIndex - 1];
  }

  /**
   * 最新営業日の2営業日前
   * @todo N営業日前を取得できるようにする => https://github.com/minkabu/minkabu_pc/issues/14477
   * @return {String} YYYY-MM-DD
   */
  priorLastDay(switchingTime = '00:00') {
    const currentDayIndex = this.items.indexOf(this.currentDay(switchingTime));
    return this.items[currentDayIndex - 2];
  }

  /**
   * 株式異動係数の取得
   * @note
   *  - 対象日が当日より未来の場合は常に1.0を返す
   *  - 実行日が当日より未来の場合は常に1.0を返す
   *  - 対象日が実行日より過去の場合は実行日に行われる株式異動の係数を返す
   * @param {String} code 銘柄コード
   * @param {String} date 対象日 YYYY-MM-DD
   * @return {Number} coefficient 株式異動係数
   */
  coefficientByCodeAndDate(code, date) {
    const today = dayjs();
    const targetDay = dayjs(date);
    const f11m_events = this.events[code];
    let executionDay;
    let coefficient;

    let totalCoefficient = 1.0;
    // 金融アイテムコードに紐づく株式異動データが無い場合
    if (!f11m_events || today.isBefore(targetDay, 'day')) {
      return totalCoefficient;
    }

    f11m_events.forEach((event) => {
      executionDay = dayjs(event[0]);
      coefficient = event[1];

      if (today.isBefore(executionDay, 'day')) {
        return;
      } else if (targetDay.isBefore(executionDay, 'day')) {
        totalCoefficient *= coefficient;
      }
    });
    return totalCoefficient;
  }
}
export default StockCalendar;
