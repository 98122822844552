import DividendYieldChartVue from './DividendYieldChart.vue';

/**
 * 個別銘柄の配当ページの年間1株配当額推移チャートのルートコンポーネント
 * @module Dividends/DividendYieldChart
 */
const DividendYieldChart = {
  el: '#v-dividend-yield-chart',
  components: {
    DividendYieldChart: DividendYieldChartVue,
  },
};

export default DividendYieldChart;
