import Axios from 'axios';
import EnvironmentUtil from '../utils/EnvironmentUtil';

/**
 * API基本クラス
 *
 * @property {URL} url URLオブジェクト
 * @property {string} format リクエストフォーマット
 * @property {Object} client Axiosのインスタンス
 */
class BaseResource {
  constructor(params = {}) {
    this.url = params['url'];
    this.format = 'json';
    this.client = Axios.create({
      headers: {
        'ContentType': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      responseType: 'json',
      validateStatus: function (status) {
        return status >= 200 && status <= 500;
      },
    });
  }

  /**
   * JSON APIのhostnameを取得
   * @returns {string}
   */
  _jsonHostname() {
    if (EnvironmentUtil.isProduction()) {
      return 'https://json.minkabu.jp';
    } else {
      return 'https://stg-json.minkabu.jp';
    }
  }
}

export default BaseResource;
