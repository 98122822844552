<template src="./StockSearchSlider.html" />
<script>
import VueSlider from 'vue-slider-component';
import generateQueryParams from '../../utils/generateQueryParams';
/**
 * 銘柄検索条件画面 スライダーコンポーネント
 * @vue-components {VueSlider} VueSlider スライダー
 * @module StockSearchSlider/StockSearchSlider
 */
export default {
  name: 'StockSearchSlider',
  components: {
    vueSlider: VueSlider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    graph: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    range: {
      type: Array,
      required: true,
    },
    marks: {
      type: Array,
      required: true,
    },
    stepSlider: {
      type: Number,
      required: true,
    },
    stepInput: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    premium: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      focus: [false, false], // input のフォーカス
      tmp: [0, 0],
    };
  },
  computed: {
    getList() {
      return this.$store.getters['stockSearchInput/list'](this.name);
    },
    /**
     * スライドの背景色を返す
     * @return {object}
     */
    sliderColor() {
      if (this.premium && !this.$store.getters['stockSearchInput/premium']) {
        return { backgroundColor: '#94A3B8' };
      }
      return { backgroundColor: '#014099' };
    },
    /**
     * ハンドルのボーダー色を返す
     * @return {object}
     */
    handleColor() {
      if (this.premium && !this.$store.getters['stockSearchInput/premium']) {
        return { 'border-slate-400': true };
      }
      return { 'border-minkabuOldLink': true };
    },
    /**
     * 小数桁数を返す
     * @return {number}
     */
    digitsNumber() {
      const arr = String(this.getList.stepInput).split('.');
      if (arr.length <= 1) {
        return 0;
      }
      return arr[1].length;
    },
    /**
     * 丸め誤差対策のために、有効桁数分（this.getList.step.input の小数点以下桁数）を
     * 整数に戻すための乗数を返す
     * Ex)
     *  この関数内
     *    1. digitsNumber: 3（小数点以下3桁）
     *    2. 10^3 === 1000 ← この関数の戻り値
     *  呼び出し元
     *    3. 0.123 * 1000 + 0.432 * 1000 = 555 ← 戻り値を元に整数で演算（丸め誤差対策）
     *    4. 555 / 1000 = 0.555 ← 戻り値を実際の値に戻す
     *
     * @return {number}
     */
    digitsMultiplier() {
      return 10 ** this.digitsNumber;
    },
    /**
     * 指定なしラベルを表示するか
     * @param {number} n - inputの番号
     * @return {boolean}
     */
    showLabel() {
      return (n) => (this.getList.value[n] === this.getList.range[n] || this.getList.value[n] === '') && !this.focus[n];
    },
    isPremiumUser() {
      return this.$store.getters['stockSearchInput/premium'];
    },
    /**
     * 利用不可かどうかを返す
     */
    isDisabled() {
      return this.premium && !this.isPremiumUser;
    },
  },
  created() {
    const queryObj = new URLSearchParams(window.location.search);
    const value = this.value;

    // デフォルト値をセット
    this.$store.commit('stockSearchInput/setDefault', {
      name: this.name,
      graph: this.graph,
      value: [...value],
      range: this.range,
      marks: { [this.value[0]]: this.marks[0], [this.value[1]]: this.marks[1] },
      stepSlider: this.stepSlider,
      stepInput: this.stepInput,
      unit: this.unit,
    });

    // queryParams から初期値をセット
    if (queryObj.has(`${this.name}[0]`)) {
      value[0] = this.validateQuery(queryObj.getAll(`${this.name}[0]`)[0], 0);
    }
    if (queryObj.has(`${this.name}[1]`)) {
      value[1] = this.validateQuery(queryObj.getAll(`${this.name}[1]`)[0], 1);
    }

    const init = {
      name: this.name,
      graph: this.graph,
      value,
      range: this.range,
      marks: { [this.range[0]]: this.marks[0], [this.range[1]]: this.marks[1] },
      stepSlider: this.stepSlider,
      stepInput: this.stepInput,
      unit: this.unit,
    };

    this.$store.commit('stockSearchInput/setList', init);
    this.tmp = init.value;
  },

  methods: {
    /**
     * クエリパラメタのバリデーションと整形
     * @param {string} q - クエリパラメタ
     * @param {number} i - inputの番号
     */
    validateQuery(q, i) {
      const hasNumber = /^[+,-]?([1-9]\d*|0)(\.\d+)?$/.test(q);

      if (q <= this.range[0] || q === 'min') {
        return this.range[0];
      } else if (q >= this.range[1] || q === 'max') {
        return this.range[1];
      } else if (!hasNumber) {
        return this.range[i];
      } else {
        return Number(q);
      }
    },

    /**
     * 入力欄にフォーカスされたら「指定なし」を非表示にする
     * @param {number} n - inputの番号
     */
    onFocus(n) {
      // 指定なしラベル表示用
      this.$set(this.focus, n, true);
    },
    /**
     * 入力欄からフォーカスが外れた OR エンターが押されたら
     * バリデーションと入力値を整形する
     * @param {number} n - inputの番号
     * @param {object} event
     */
    onBlur(n, event) {
      // 指定なしラベル表示用
      this.$set(this.focus, n, false);

      const oldValue = this.getList.value;
      let newValue = parseFloat(event.target.value);

      // 入力が range から溢れた場合：min, maxに丸める

      if (newValue < this.getList.range[0]) {
        newValue = this.getList.range[0];
      } else if (newValue > this.getList.range[1]) {
        newValue = this.getList.range[1];
      }

      // 数値以外が入力 OR 入力がテレコ：入力前に戻す
      if (Number.isNaN(newValue) || (n === 0 && newValue > oldValue[1]) || (n === 1 && newValue < oldValue[0])) {
        this.$store.commit('stockSearchInput/setListValue', {
          name: this.name,
          value: [0, 0],
        });
        this.$store.commit('stockSearchInput/setListValue', {
          name: this.name,
          value: oldValue,
        });
        return;
      }

      // 有効桁数でトリミング
      let trim = Math.floor(parseFloat(newValue) * this.digitsMultiplier);
      trim = Math.floor(trim - (trim % (this.getList.stepInput * this.digitsMultiplier))) / this.digitsMultiplier;
      const detail = {
        name: this.name,
        value: n === 0 ? [trim, oldValue[1]] : [oldValue[0], trim],
      };
      this.$store.commit('stockSearchInput/setListValue', detail);
      this.tmp = detail.value;

      this.getTotal();
    },

    /**
     * 件数を取得
     */
    async getTotal() {
      await this.$store.dispatch(
        'stockSearchInput/getResult',
        generateQueryParams({ _this: this, view: null, paginationOnly: true })
      );
    },

    /**
     * ドラッグ開始時点での数値を保存する
     */
    onDragStart() {
      this.tmp = this.getList.value;
      if (!this.$store.getters['stockSearchInput/criteria'].includes(this.name)) {
        this.$store.commit('stockSearchInput/setCriteria', { name: this.name });
      }
    },

    /**
     * ドラッグ終了時に件数を取得する
     */
    async onDragEnd() {
      this.getTotal();
    },

    /**
     * 変更したスライドの数値だけを変更する
     * @param {array} v - 変更後の数値
     * @param {number} n - ドラッグ中のハンドル番号
     */
    async onChange(v, n) {
      const obj = {
        name: this.name,
        value: this.tmp.map((el, i) => {
          if (i === n) {
            return (v[n] * this.digitsMultiplier) / this.digitsMultiplier;
          }
          return el;
        }),
      };
      this.$store.commit('stockSearchInput/setListValue', obj);
      this.tmp = obj.value;
    },
  },
};
</script>
