import axios from 'axios';
import _get from 'lodash/get';
import dayjs from 'dayjs';

/** @type {object} 検索クエリ初期値 */
const INIT_SEARCH_QUERY = Object.freeze({
  /** 銘柄コード・銘柄名 */
  keyword: '',
  /** お知らせ 0:すべて 1:新着あり */
  news: 0,
  /** 売買予想 1:買い 2:売り -1:対象外 */
  analysisRating: [1, 2, -1],
  /** 業績 1:雷 2:雨 3:曇り 4:曇り時々晴れ 5:晴れ -1:対象外 */
  fiscalEvaluation: [1, 2, 3, 4, 5, -1],
});

/** @type {object} ソートクエリ初期値 */
const INIT_SORT_QUERY = Object.freeze({
  /** ソート順 */
  key: 'default',
  /** 昇降順 */
  asc: true,
  /** SP版 */
  spSelected: 'default-true',
});

const state = {
  favoriteFinancialItems: {
    original: [],
    filtering: [],
  },
  details: {},
  iconUrlBase: '',
  isLoading: true,
  pages: {
    current: 1,
    numberOfDisplayed: 20,
  },
  error: {
    dataload: false,
  },
  search: JSON.parse(JSON.stringify(INIT_SEARCH_QUERY)),
  sort: JSON.parse(JSON.stringify(INIT_SORT_QUERY)),
  isSpSearch: false,
  accordion: [],
  isShowDrawer: false,
  chartData: {},
  decimalDigit: {},
};

const getters = {
  /**
   * 表示するお気に入り銘柄
   */
  pageItems(state) {
    const start = (state.pages.current - 1) * state.pages.numberOfDisplayed;
    return state.favoriteFinancialItems.filtering.slice(start, start + state.pages.numberOfDisplayed);
  },
  /**
   * 総ページ数
   * @return {number}
   */
  totalPages(state) {
    return Math.ceil(state.favoriteFinancialItems.filtering.length / state.pages.numberOfDisplayed) || 1;
  },
  favoriteFinancialItemsOriginal(state) {
    return state.favoriteFinancialItems.original;
  },
  favoriteFinancialItemsFiltering(state) {
    return state.favoriteFinancialItems.filtering;
  },
  iconUrlBase(state) {
    return state.iconUrlBase;
  },
  pages(state) {
    return state.pages;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isSpSearch(state) {
    return state.isSpSearch;
  },
  error(state) {
    return state.error;
  },
  search(state) {
    return state.search;
  },
  sort(state) {
    return state.sort;
  },
  accordion(state) {
    return state.accordion;
  },
  isShowDrawer(state) {
    return state.isShowDrawer;
  },
  details(state) {
    return state.details;
  },
  detailsFullName: (state) => (code) => {
    return state.details[code] && state.details[code].fullName;
  },
  detailsTargetPrice: (state) => (code) => {
    return state.details[code] && state.details[code].targetPrice;
  },
  detailsPriceTags: (state) => (code) => {
    return state.details[code] && state.details[code].priceTags;
  },
  detailsPrices: (state) => (code) => {
    return state.details[code] && state.details[code].prices;
  },
  detailsActivities: (state) => (code) => {
    return state.details[code] && state.details[code].activities;
  },
  chartData: (state) => (code) => {
    return state.chartData[code];
  },
  decimalDigit: (state) => (code) => {
    return state.decimalDigit[code];
  },
  /**
   * お気に入り銘柄を登録しているか
   * @return {boolean}
   */
  isFavoriteFinancialItemsAvailable(state) {
    return state.favoriteFinancialItems.original.length > 0 && !state.isLoading && !state.error.dataload;
  },
  /**
   * お気に入り銘柄が未登録か
   * @return {boolean}
   */
  isFavoriteFinancialItemsUnavailable(state) {
    return !state.favoriteFinancialItems.original.length > 0 && !state.isLoading && !state.error.dataload;
  },
  /**
   * デフォルトの検索条件か
   * @return {boolean}
   */
  isDefaultSearch(state) {
    return Object.keys(state.search).every((key) => {
      // 配列であればソートする
      const nowSearch = Array.isArray(state.search[key]) ? state.search[key].slice().sort() : state.search[key];
      const defaultSearch = Array.isArray(INIT_SEARCH_QUERY[key])
        ? INIT_SEARCH_QUERY[key].slice().sort()
        : INIT_SEARCH_QUERY[key];
      return JSON.stringify(nowSearch) === JSON.stringify(defaultSearch);
    });
  },
};

const mutations = {
  setFavoriteFinancialItemsOriginal(state, data) {
    state.favoriteFinancialItems.original = Object.freeze(data);
  },
  setFavoriteFinancialItemsFiltering(state, data) {
    state.favoriteFinancialItems.filtering = data;
  },
  setIconUrlBase(state, data) {
    state.iconUrlBase = Object.freeze(data);
  },
  setIsLoading(state, data) {
    state.isLoading = data;
  },
  setErrorDataload(state, data) {
    state.error.dataload = data;
  },
  setSearch(state, data) {
    state.search = data;
  },
  setSearchKeyword(state, data) {
    state.search.keyword = data;
  },
  setSearchNews(state, data) {
    state.search.news = data;
  },
  setSearchAnalysisRating(state, data) {
    state.search.analysisRating = data;
  },
  setSearchFiscalEvaluation(state, data) {
    state.search.fiscalEvaluation = data;
  },
  setPagesCurrent(state, data) {
    state.pages.current = data;
  },
  setSortKey(state, data) {
    state.sort.key = data;
  },
  setSortAsc(state, data) {
    state.sort.asc = data;
  },
  setSortSpSelected(state, data) {
    state.sort.spSelected = data;
  },
  setDetails(state, data) {
    state.details = Object.assign(state.details, { [data.code]: data });
  },
  toggleSpSearch(state) {
    state.isSpSearch = !state.isSpSearch;
  },
  setAccordion(state, data) {
    state.accordion = data;
  },
  setIsShowDrawer(state, data) {
    state.isShowDrawer = data;
  },
  setChartData(state, data) {
    state.chartData[data.code] = data.chartData;
  },
  setDecimalDigit(state, data) {
    state.decimalDigit[data.code] = data.decimalDigit;
  },
};

const actions = {
  /**
   * お気に入り銘柄一覧を取得
   * @return {Promise}
   */
  getFavoriteFinancialItems({ commit }) {
    return axios
      .get('/mypage/favorites.json')
      .then(function (response) {
        commit('setFavoriteFinancialItemsOriginal', response.data.favoriteFinancialItems);
        commit('setFavoriteFinancialItemsFiltering', response.data.favoriteFinancialItems);
        commit('setIconUrlBase', response.data.iconUrlBase);
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        commit('setErrorDataload', true);
      });
  },

  /**
   * お気に入り銘柄詳細を取得
   * @param {String} code
   * @return {Promise}
   */
  async getDetail({ commit, dispatch, state }, code) {
    if (state.details.hasOwnProperty(code)) {
      return false;
    }
    return await axios
      .get(`/mypage/favorites/${code}`)
      .then(function (response) {
        commit('setDetails', response.data);
        dispatch('commitChartData', { code: code, prices: response.data.prices });
        dispatch('commitDecimalDigit', { code: code, prices: response.data.prices });
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        commit('setErrorDataload', true);
      });
  },

  /**
   * チャート用にデータを加工してcommitする処理
   * @param {Object} obj
   * @return {Promise}
   */
  commitChartData({ state, commit }, obj) {
    const favoriteFinancialItem = state.favoriteFinancialItems.original.find((el) => el.code === obj.code);
    if (!favoriteFinancialItem) {
      return;
    }

    // Highchartsが扱いやすい形に整形
    let chartData = obj.prices.dates.map((date, index) => [
      Date.parse(date),
      obj.prices.closes.map((close) => parseFloat(close) || null)[index],
    ]);

    // 一覧で表示している最新取引値があれば UPSERT する
    if (favoriteFinancialItem.priceTime.datetime) {
      const latestTrade = [
        dayjs(favoriteFinancialItem.priceTime.datetime).startOf('d').valueOf(),
        parseFloat(favoriteFinancialItem.price.replace(/,/g, '')),
      ];
      const duplicateDateIndex = chartData.findIndex((el) => el[0] === latestTrade[0]);
      if (duplicateDateIndex < 0) {
        chartData.push(latestTrade);
      } else {
        chartData[duplicateDateIndex] = latestTrade;
      }
    }
    commit('setChartData', { code: obj.code, chartData });
  },

  /**
   * 株価から小数桁数を抽出する処理
   * @param {Object} obj
   * @return {Promise}
   */
  commitDecimalDigit({ commit }, obj) {
    let decimalDigit = null;
    const notNullCloses = obj.prices.closes.filter((close) => close); // nullの終値を除外
    if (notNullCloses.length) {
      // '1234.56' => ['1234', '56'] のように整数部と小数部に分割している
      const splitted = String(notNullCloses[0]).split('.');
      if (splitted.length <= 1) {
        decimalDigit = 0;
      } else {
        decimalDigit = splitted[1].length;
      }
    }
    commit('setDecimalDigit', { code: obj.code, decimalDigit });
  },

  /**
   * 絞り込み処理
   */
  async filteringItem({ commit, state }) {
    let filtering = state.favoriteFinancialItems.original;
    // 銘柄コード・銘柄名検索
    if (state.search.keyword) {
      /**
       * 全半角大小文字にかかわらずマッチさせる
       * ? 銘柄名、銘柄コード、ユーザ入力の英数字を半角小文字に変換して比較する
       */
      filtering = filtering.filter((v) => {
        /**
         * @param {string} item
         * @return {boolean}
         */
        const toHalfLower = (item) =>
          item.replace(/[！-～]/g, (str) => String.fromCharCode(str.charCodeAt(0) - 0xfee0)).toLowerCase();
        return (
          toHalfLower(v.code).includes(toHalfLower(state.search.keyword)) ||
          toHalfLower(v.name).includes(toHalfLower(state.search.keyword))
        );
      });
    }

    // 売買予想検索
    filtering = filtering.filter((v) => state.search.analysisRating.includes(v.analysisRating.status));

    // 業績評価検索
    if (state.search.fiscalEvaluation.length) {
      filtering = filtering.filter((v) => state.search.fiscalEvaluation.includes(v.fiscalEvaluation.status));
    } else {
      filtering = filtering.filter((v) => v.fiscalEvaluation.status === null);
    }

    // 新着情報検索（お知らせあり）
    if (state.search.news === 1) {
      filtering = filtering.filter((v) => state.search.news <= v.newArrivalActivity.count);
    }
    // 新着情報検索（適時のみ）
    if (state.search.news === 2) {
      filtering = filtering.filter((v) => v.newArrivalActivity.label === '適時');
    }

    commit('setFavoriteFinancialItemsFiltering', filtering);
  },

  /**
   * 検索のリセット
   */
  async resetForm({ commit }) {
    commit('setSearch', JSON.parse(JSON.stringify(INIT_SEARCH_QUERY)));
  },

  /**
   * ソート処理
   */
  async sortItem({ state, commit }) {
    const key = state.sort.key === 'fiscalEvaluation' ? 'status' : '';
    const path = key ? [state.sort.key, key] : [state.sort.key];
    const res = state.favoriteFinancialItems.filtering
      .slice()
      .sort(
        (a, b) =>
          parseFloat(_get(a, path)) - parseFloat(_get(b, path)) ||
          String(_get(a, path)).localeCompare(String(_get(b, path)))
      );

    commit('setFavoriteFinancialItemsFiltering', state.sort.asc ? res : res.reverse());
  },

  /**
   * 表示ページを変更する
   * @param {number} n
   */
  changePage({ commit, state, getters }, n = state.pages.current) {
    const searchParams = new URLSearchParams(window.location.search).toString();
    // 現在の検索条件をURLSearchParams形式にする
    const search = `page=${n}&keyword=${state.search.keyword}&news=${String(
      state.search.news
    )}&analysisRating=${state.search.analysisRating.join('_')}&fiscalEvaluation=${state.search.fiscalEvaluation.join(
      '_'
    )}&key=${state.sort.key}&asc=${state.sort.asc}`;

    // 移動先が不正値か、現在の条件とクエリパラメタが一致した場合はページを変更しない
    if (!Number.isInteger(n) || n < 1 || n > getters.totalPages || searchParams === search) {
      return false;
    }

    commit('setPagesCurrent', n);
  },

  /**
   * ソート順を変更する
   * @param {Object} payload
   */
  async changeSortOrder({ getters, commit }, payload) {
    const sort = getters.sort;
    if (payload.isSp) {
      const spSort = sort.spSelected.split('-');
      commit('setSortKey', spSort[0]);
      commit('setSortAsc', JSON.parse(spSort[1].toLowerCase()));
    } else {
      const obj = {
        key: INIT_SORT_QUERY.key,
        asc: INIT_SORT_QUERY.asc,
      };
      if (sort.key === payload.key && sort.asc) {
        obj.key = payload.key;
        obj.asc = false;
      } else if (sort.key !== payload.key) {
        obj.key = payload.key;
        obj.asc = true;
      }
      commit('setSortKey', obj.key);
      commit('setSortAsc', obj.asc);
    }
  },

  /**
   * アコーディオンの開閉ステータス変更
   * @param {string} code
   */
  async toggleAccordion({ state, commit }, code) {
    let arr = JSON.parse(JSON.stringify(state.accordion));
    const index = arr.indexOf(code);
    index < 0 ? arr.push(code) : arr.splice(index, 1);
    commit('setAccordion', arr);
  },

  /**
   * ドロワーの開閉ステータス変更
   * @param {Object} arg
   */
  async changeDisplayDrawer({ commit }, show) {
    commit('setIsShowDrawer', show);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
