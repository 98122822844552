import FavoriteFinancialItemsStore from '../../Common/FavoriteFinancialItemsStore';
import NotificationBoxStore from '../../Common/NotificationBoxStore';
import FavoriteSignUpModalStore from '../../Modal/FavoriteSignUpModalStore';
import ReportFinancialItemsStore from '../../Common/ReportFinancialItemsStore';
import ReportModalStore from '../Modal/ReportModalStore';
/**
 * 個別銘柄ルートストア
 *
 * @module Stocks/StocksStore
 */
const StocksStore = {
  modules: {
    favoriteFinancialItems: FavoriteFinancialItemsStore,
    notificationBox: NotificationBoxStore,
    favoriteSignUpModal: FavoriteSignUpModalStore,
    reportFinancialItems: ReportFinancialItemsStore,
    reportModal: ReportModalStore,
  },
};
export default StocksStore;
